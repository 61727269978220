import { takeLatest, put, select, all, call } from 'redux-saga/effects';
import * as actions from './actions';
import * as constants from './constants';
import * as apiName from '../../config/apiUrl';
import * as gService from '../../utility/GlobalServices';
import * as selectors from './selectors';
import * as gActions from '../App/actions';
import * as globalSelectors from '../App/selectors';
import { displayNotification } from '../../config/toastConfig';
import { getDataFromApiToGlobalStoreSaga } from 'containers/App/saga';

// ***** FUNCTION TO LOAD DATA ***** //
export function* getFranchiseeDetailSaga() {
  yield put(gActions.showLoader());
  try {
    const response = yield gService.callApi(apiName.getFranchiseURL);
    yield put(actions.setFranchiseeDetails(response));
    yield call(loadFranchiseeFormSaga);
  } catch (error) {
    displayNotification(`Something went wrong try again`, 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** FUNCTION TO LOAD DATA ***** //
export function* loadFranchiseeFormSaga() {
  yield put(gActions.showLoader());
  const { educationArr } = yield select(selectors.selectFranchiseDetailsStore);
  const allURLs = [
    apiName.franchiseeTypeURL,
    apiName.educationalQualificationsURL,
    apiName.entityTypeURL,
    apiName.domainURL,
  ];
  if (educationArr.length === 0) {
    try {
      const [franchiseTypeArr, educationArr, entityArr, domainData] = yield all(
        allURLs.map((url) => gService.callApi(url))
      );
      const response = {
        franchiseTypeArr,
        educationArr,
        entityArr,
        domainData,
      };
      yield put(actions.setFranchiseeFormData(response));
    } catch (error) {
      displayNotification(`Something went wrong try again`, 'danger');
    }
  }
  yield put(gActions.hideLoader());
}

// ***** UPDATE FRANCHISEE DETAILS ***** //
export function* saveFracnhiseeDataSaga({ payload }) {
  yield put(gActions.showLoader());
  const globalStore = yield select(globalSelectors.selectGlobalStorerDomain);
  const {
    userRoleData: { id },
  } = globalStore;
  if (payload && id) {
    try {
      const response = yield gService.callApi(
        `${apiName.getFranchiseURL}/${id}`,
        payload,
        'PUT'
      );
      if (response) {
        displayNotification('Saved Successfully');
      }
      yield put(actions.setFranchiseeDetails(response));
      yield call(getDataFromApiToGlobalStoreSaga);
    } catch (error) {
      displayNotification('Something went wrong! try again.', 'danger');
    }
  }
  yield put(gActions.hideLoader());
}

// ***** GET FRANCHISEE IDENTIFIER DETAILS ***** //
export function* getFranchiseeIdDetailSaga({ payload }) {
  yield put(gActions.showLoader());
  if (payload) {
    try {
      const response = yield gService.callApi(
        `${apiName.franchiseeIdentiferURL}/${payload}`
      );
      yield put(actions.setFranchiseeDetails(response));
    } catch (error) {
      displayNotification('Something went wrong! try again.', 'danger');
    }
  }
  yield put(gActions.hideLoader());
}

// ***** BINDING ACTION & FUNCTION LOGIC ***** //
export default function* franchiseDetailsSaga() {
  yield takeLatest(constants.LOAD_FRANCHISEE_DETAIL, getFranchiseeDetailSaga);
  yield takeLatest(constants.SAVE_FRANCHISEE_DATA, saveFracnhiseeDataSaga);
  yield takeLatest(
    constants.GET_FRANCHISEE_ID_DETAILS,
    getFranchiseeIdDetailSaga
  );
}
