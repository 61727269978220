/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formatCompanyList } from '../../utils/form';
import Select from '../common/Select';

import './style.css';

const RecruiterCompanyDropdown = ({ globalStore, changeCompanyDropdown }) => {
  const companyes = globalStore?.userRolesData?.filter(
    (data) => data.role === 'recruiter'
  )[0]?.data?.companies;
  const companies = companyes || globalStore?.userRoleData?.companies;
  const { control, errors } = useForm();

  const [currentCompany, setCurrentCompany] = useState(null);
  useEffect(() => {
    if (companies && companies.length > 0) {
      const defaultData = {
        value: companies[0]?.companyId,
        label: companies[0]?.companyName,
      };
      setCurrentCompany(defaultData);
    }
  }, [companies]);

  useEffect(() => {
    changeCompanyDropdown(currentCompany?.value);
  }, [changeCompanyDropdown, currentCompany]);

  const changeCompanyHandler = (val) => {
    if (val) {
      changeCompanyDropdown(val.value);
    }
  };

  if (!currentCompany) {
    return <></>;
  }

  return (
    <li className="dropdown nav-item mb-dropdown-sm recruiter-company-list">
      <Select
        errors={errors}
        control={control}
        rules={{ required: false }}
        name="recruiterid"
        wrapperClass="form-group header-company-dropdown recruiter-company-dropdown"
        defaultValue={currentCompany}
        options={formatCompanyList(companies)}
        handleChange={changeCompanyHandler}
      />
    </li>
  );
};

export default RecruiterCompanyDropdown;
