/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, memo, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as cdDashboardActions from '../../../containers/CandidateDashboard/actions';
import * as candidateAction from '../../../containers/CandidateBasicAdvanceJobSearch/actions';
import Noticebox from './Noticebox';
import duftherLogoName from '../../../images/DUFTHER_NAME_LOGO.png';
import faviconImage from '../../../images/FAVICON.png';
import userLogo from '../../../images/PROFILE_COMMON_HEADER.png';
import makeSelectGlobalStore from '../../../containers/App/selectors';
import makeSelectCandidateDashboard from '../../../containers/CandidateDashboard/selectors';

import './candidate-dashboard-sidebar.css';
import { displayNotification } from 'config/toastConfig';

let CDSidebar = (props) => {
  const {
    globalStore,
    submitAvaiability,
    submitActively,
    submitNoticePeriod,
    showSideMenu,
    showMobileMenu,
    candidateDashboardStore,
    resetNoticePeriod,
    loadDashboard,
    toggleMobileSideMenu,
    getCandidateAvatar,
    getMatchingJobs,
    verifyCandidate,
  } = props;

  const {
    activelySearchingJob,
    candidateAvailability,
    activelySearchJob,
    candidateImageUrl,
    profileImage,
  } = candidateDashboardStore;

  const isAvailableImmediately = pathOr(
    false,
    ['isAvailableImmediately'],
    candidateAvailability
  );

  const noticePeriodDays = pathOr(
    0,
    ['noticePeriodDays'],
    candidateAvailability
  );
  const noticePeriodMonths = pathOr(
    0,
    ['noticePeriodMonths'],
    candidateAvailability
  );

  const userRoleData = pathOr('', ['userRoleData'], globalStore);
  const isBackgroundVerified = pathOr(
    '',
    ['userRoleData', 'isBackgroundVerified'],
    globalStore
  );

  const userData = pathOr({}, ['userData'], globalStore);
  const userLastLogin = pathOr('', ['userData', 'lastLogin'], globalStore);
  let userNewName = `${userRoleData?.firstName} ${userRoleData?.lastName}`;

  useEffect(() => {
    if (userData?.id) verifyCandidate(userData?.id);
  }, [userData?.id]);

  useEffect(() => {
    if ((isBackgroundVerified === false || isBackgroundVerified === null) && !userData?.createdByRole) {
      displayNotification(
        'Your Profile is pending verification, please click Verify link on the left Panel',
        'warning'
      );
    }
  }, [isBackgroundVerified]);

  const franchiseeCode = pathOr(
    [],
    ['userData', 'associatedFranchiseeCodes'],
    globalStore
  );

  const academicVerification = pathOr(
    '',
    ['academicVerification'],
    candidateDashboardStore
  );
  const academicVerificationDisplayName = (status) => {
    if (status === 'Verification_Pending') {
      return { color: 'orange', display: 'Pending' };
    } else if (status === 'Verified') {
      return { color: 'green', display: status };
    } else {
      return { color: 'red', display: 'Rejected' };
    }
  };

  const [userAvatar, setUserAvatar] = useState('');

  const avatarId = pathOr('', ['id'], profileImage);
  useEffect(() => {
    avatarId && getCandidateAvatar(avatarId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarId]);

  useEffect(() => {
    candidateImageUrl
      ? setUserAvatar(candidateImageUrl)
      : setUserAvatar(userLogo);
  }, [candidateImageUrl]);

  // expand and collapse USer info
  const [userInfo, toggleUserInfo] = useState(true);
  // toogle noticebox to get notice period input
  const [noticeBox, toggleNoticeBox] = useState(false);
  // set the notice period
  const [noticePeriodValue, setNoticePeriodValue] = useState(0);
  // set available immediately via checkbox
  const [availableCheck, setAvailableCheck] = useState(isAvailableImmediately);
  // set actively searching via checkbox
  const [activelyCheck, setActivelyCheck] = useState(false);
  // toogle location box to set location input
  const [reset, setReset] = useState(false);

  useEffect(() => {
    setActivelyCheck(activelySearchingJob);
  }, [activelySearchingJob]);

  const isAvailableSubmit = (e) => {
    const val = e.currentTarget.checked;
    setAvailableCheck(val);
    const availablityFormData = {
      isAvailableImmediately: val,
    };
    submitAvaiability(availablityFormData);
    val && resetNoticePeriod();
  };

  // function to submit notice period data
  const noticePeriodFunc = () => {
    const noticeData = {
      ...candidateAvailability,
      noticePeriodDays: Number(noticePeriodValue || noticePeriodDays),
    };
    submitNoticePeriod(noticeData);
  };

  const isActivelySubmit = (e) => {
    setActivelyCheck(e.currentTarget.checked);
    submitActively(e.currentTarget.checked);
  };

  const matchingJobs = () => {
    toggleMobileSideMenu(false);
    getMatchingJobs();
  };

  // initialize checkboxes value based on set value in api
  useEffect(() => {
    setAvailableCheck(isAvailableImmediately);
    setReset(false);
  }, [isAvailableImmediately]);

  useEffect(() => {
    setAvailableCheck(isAvailableImmediately);
  }, [isAvailableImmediately]);

  useEffect(() => {
    setActivelyCheck(activelySearchJob);
  }, [activelySearchJob]);

  useEffect(() => {
    loadDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (noticePeriodDays == 0) {
      setAvailableCheck(true);
    }
  }, [noticePeriodDays]);

  const activeCheckHandler = (event) => isActivelySubmit(event);

  const showMessage = () => {
    displayNotification(
      'Your Profile is "Pending Verification", please click Verify link on the left panel to upload documents. Once submitted it will take upto 5 days to get verified.',
      'warning'
    );
  };

  const renderUser = () => (
    <div className="user">
      <div className="photo">
        <img src={userAvatar} alt="avatar" />
      </div>
      <div className="info" style={{ cursor: 'pointer' }}>
        <a
          data-toggle="collapse"
          className="collapsed"
          aria-expanded="true"
          onClick={() => toggleUserInfo(userInfo)}
        >
          <OverlayTrigger
            key=""
            placement="bottom"
            overlay={<Tooltip>{userNewName}</Tooltip>}
          >
            <span className="profile-name">
              {userNewName?.length < 20
                ? userNewName
                : userNewName?.substring(0, 20) + ' ...'}
              <b className="caret"></b>
            </span>
          </OverlayTrigger>
        </a>
        {userInfo && (
          <div className="profile-wrap">
            <ul className="nav" style={{ color: '#2d2822' }}>
              <li>
                <Link
                  to="/cdashboard/profile-menu"
                  className="profile-dropdown"
                  onClick={() => toggleMobileSideMenu(false)}
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-id-badge" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal sidebar-dropdown ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                    style={{ color: '#2d2822' }}
                  >
                    Profile Details
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/cdashboard/verification"
                  className="profile-dropdown"
                  onClick={() => toggleMobileSideMenu(false)}
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-search-minus" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal sidebar-dropdown ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                    style={{ color: '#2d2822' }}
                  >
                    Background Docs
                  </span>
                  <span
                    className={`sidebar-normal  ${
                      !showSideMenu
                        ? 'sidebar-normal-revised alert px-1 py-1'
                        : 'alert px-1 py-1'
                    }`}
                    style={{
                      background: isBackgroundVerified ? 'green' : isBackgroundVerified === false ? 'red' : 'orange',
                      color: '#FFFFFF',
                    }}
                  >
                    {isBackgroundVerified ? 'Verified' : isBackgroundVerified === false ? 'Rejected' : 'Pending'}
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/cdashboard/academic-records"
                  className="profile-dropdown mb-0"
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal  ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                    style={{ color: '#2d2822' }}
                  >
                    Academic Records
                  </span>
                  <span
                    className={`sidebar-normal  ${
                      !showSideMenu
                        ? 'sidebar-normal-revised alert px-1 py-1'
                        : 'alert px-1 py-1'
                    }`}
                    style={{
                      background:
                        academicVerificationDisplayName(academicVerification)
                          ?.color,
                      color: '#FFFFFF',
                    }}
                  >
                    {
                      academicVerificationDisplayName(academicVerification)
                        ?.display
                    }
                  </span>
                </Link>
              </li>
              {!userRoleData?.isHidden && (
                <li>
                  <Link
                    to="/cdashboard/preferences-menu"
                    className="profile-dropdown"
                    onClick={() => toggleMobileSideMenu(false)}
                  >
                    <span className="sidebar-mini">
                      <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
                    </span>
                    <span
                      className={`sidebar-normal sidebar-dropdown ${
                        !showSideMenu ? 'sidebar-normal-revised' : ''
                      }`}
                      style={{ color: '#2d2822' }}
                    >
                      Preferences
                    </span>
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to="/cdashboard/candidate-update-resume"
                  className="profile-dropdown"
                  onClick={() => toggleMobileSideMenu(false)}
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-file-text-o" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal sidebar-dropdown ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                    style={{ color: '#2d2822' }}
                  >
                    Update Resume
                  </span>
                </Link>
              </li>
              {(userData?.associatedFranchiseeCodes?.length > 0 ||
                userData?.associatedInstituteCodes?.length > 0) && (
                <li className="nav-item">
                  <Link
                    to="/cdashboard/empanelled-jobs"
                    className="profile-dropdown"
                    onClick={() => toggleMobileSideMenu(false)}
                  >
                    <span className="sidebar-mini">
                      <i className="fa fa-briefcase" aria-hidden="true"></i>
                    </span>
                    <span
                      className={`sidebar-normal  ${
                        !showSideMenu ? 'sidebar-normal-revised' : ''
                      }`}
                      style={{ color: '#2d2822' }}
                    >
                      Empanelled Jobs
                    </span>
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link
                  to={
                    userData?.createdByRole ||
                    userData?.associatedFranchiseeCodes?.length > 0 ||
                    userData?.associatedInstituteCodes?.length > 0 ||
                    (!userData?.createdByRole && isBackgroundVerified)
                      ? '/cdashboard/get-associated'
                      : '#'
                  }
                  className="profile-dropdown"
                  onClick={
                    !isBackgroundVerified &&
                    !userData?.createdByRole &&
                    (userData?.associatedFranchiseeCodes?.length > 0 ||
                      userData?.associatedInstituteCodes?.length > 0) &&
                    showMessage
                  }
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-plug" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal  ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                    style={{ color: '#2d2822' }}
                  >
                    Associations
                  </span>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to={
                    userData?.createdByRole ||
                    userData?.associatedFranchiseeCodes?.length > 0 ||
                    userData?.associatedInstituteCodes?.length > 0 ||
                    (!userData?.createdByRole && isBackgroundVerified)
                      ? '/cdashboard/institute-empanelment'
                      : '#'
                  }
                  className="profile-dropdown"
                  onClick={
                    !isBackgroundVerified &&
                    !userData?.createdByRole &&
                    (userData?.associatedFranchiseeCodes?.length > 0 ||
                      userData?.associatedInstituteCodes?.length > 0) &&
                    showMessage
                  }
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-university" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal  ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                    style={{ color: '#2d2822' }}
                  >
                    Institute Empanelment
                  </span>
                </Link>
              </li>
              {franchiseeCode.length > 0 && (
                <li className="nav-item">
                  <Link
                    to="/cdashboard/agency-agreements"
                    className="profile-dropdown"
                  >
                    <span className="sidebar-mini">
                      <i className="fa fa-file-text" aria-hidden="true"></i>
                    </span>
                    <span
                      className={`sidebar-normal  ${
                        !showSideMenu ? 'sidebar-normal-revised' : ''
                      }`}
                      style={{ color: '#2d2822' }}
                    >
                      Agency Agreements
                    </span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );

  const renderNav = () => (
    <ul className="nav">
      {(userData?.createdByRole !== 'Institute' || !userRoleData?.isHidden) && (
        <Noticebox
          availableCheck={availableCheck}
          isAvailableSubmit={isAvailableSubmit}
          noticeBox={noticeBox}
          isNoticePeriodInDays={noticeBox}
          noticePeriodDays={noticePeriodDays}
          noticePeriodMonths={noticePeriodMonths}
          setNoticePeriodValue={setNoticePeriodValue}
          toggleNoticeBox={toggleNoticeBox}
          noticePeriodFunc={noticePeriodFunc}
          reset={reset}
          setReset={setReset}
        />
      )}
      {(userData?.createdByRole !== 'Institute' || !userRoleData?.isHidden) && (
        <li className="nav-item">
          <span className="nav-link">
            <label className="switch toggle-switch">
              <input
                type="checkbox"
                name="activelySearching"
                id="chkBox"
                checked={activelyCheck === true}
                onChange={activeCheckHandler}
              />
              <span className="slider round"></span>
            </label>
            <span className="toggle switch-lable-sidebar zero-opacity">
              Actively Searching Jobs
            </span>
          </span>
        </li>
      )}
      <li className="nav-item">
        <Link
          className="nav-link"
          to="/cdashboard/candidate-basic-advance-job-search"
          onClick={() => matchingJobs()}
        >
          <i
            className="fa fa-address-card-o add-role-text"
            style={{ float: showSideMenu || showMobileMenu ? 'left' : 'none' }}
          />
          <p className="add-role-text">Job Vacancies</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link"
          to="/cdashboard"
          onClick={() => toggleMobileSideMenu(false)}
        >
          <i
            className="fa fa-line-chart"
            style={{ float: showSideMenu || showMobileMenu ? 'left' : 'none' }}
          />
          <p className="dashboardlink-sidebar">Dashboard</p>
        </Link>
      </li>
      <li className="nav-item">
        <button
          className="nav-link"
          style={{ background: 'none' }}
          onClick={() => {
            window.open(
              'https://dufther.atlassian.net/servicedesk/customer/portal/1',
              '_blank',
              'noopener,noreferrer'
            );
          }}
        >
          <i
            className="fa fa-wrench"
            style={{ float: showSideMenu || showMobileMenu ? 'left' : 'none' }}
          />
          <p>Report Issues</p>
        </button>
      </li>
    </ul>
  );

  const renderLogo = () => (
    <Fragment>
      <div className="logo">
        <Link
          to="/cdashboard"
          className="simple-text logo-mini"
          onClick={() => toggleMobileSideMenu(false)}
        >
          <img src={faviconImage} alt="duftherLogo" />
        </Link>
        <Link
          to="/cdashboard"
          className="simple-text logo-normal"
          onClick={() => toggleMobileSideMenu(false)}
        >
          <img src={duftherLogoName} alt="logo" />
        </Link>
        <Link
          to="/cdashboard"
          className="simple-text logo-mini mt-globe-sidebar padding-globe-top"
          onClick={() => toggleMobileSideMenu(false)}
        >
          <i
            className="fa fa-globe"
            style={{ color: '#11527e' }}
            aria-hidden="true"
            data-toggle="tooltip"
            data-placement="top"
            title="Last Logged"
          ></i>
        </Link>
        <Link
          to="/cdashboard"
          className="last-logged-date simple-text logo-normal padding-globe-top"
          onClick={() => toggleMobileSideMenu(false)}
        >
          <span className="last-logged-label">Last Logged</span>
          <span> &nbsp;{userLastLogin}</span>
        </Link>
      </div>
    </Fragment>
  );

  return (
    <div
      className={`sidebar ${!showSideMenu ? 'sidebar-mini-slip' : ''} ${
        showMobileMenu ? 'revised-show-mob-menu' : 'revised-hide-mob-menu'
      }`}
      id="sidebar_dash"
    >
      <div
        className={`sidebar-wrapper ${
          !showSideMenu ? 'sidebar-mini-slip' : ''
        }`}
      >
        {userLastLogin && renderLogo()}
        {userRoleData && renderUser()}
        {renderNav()}
      </div>
    </div>
  );
};

CDSidebar.propTypes = {
  submitAvaiability: PropTypes.func.isRequired,
  submitActively: PropTypes.func.isRequired,
  globalStore: PropTypes.object.isRequired,
  noticePeriodValue: PropTypes.any,
  submitNoticePeriod: PropTypes.func,
};

// select data from redux store
const mapStateToProps = createStructuredSelector({
  globalStore: makeSelectGlobalStore(),
  candidateDashboardStore: makeSelectCandidateDashboard(),
});

// dispatch actions
const mapDispatchToProps = (dispatch) => ({
  loadDashboard: () => dispatch(cdDashboardActions.loadDashboardPageData()),
  submitAvaiability: (e) =>
    dispatch(cdDashboardActions.submitAvaiabilityForm(e)),
  submitActively: (e) =>
    dispatch(cdDashboardActions.submitActivelySearchingForm(e)),
  submitNoticePeriod: (e) =>
    dispatch(cdDashboardActions.submitNoticePeriodForm(e)),
  resetNoticePeriod: () => dispatch(cdDashboardActions.resetNoticePeriod()),
  getCandidateAvatar: (id) =>
    dispatch(cdDashboardActions.getCandidateAvatar(id)),
  getMatchingJobs: (e) => dispatch(candidateAction.getMatchingJobs()),
  verifyCandidate: (id) => dispatch(cdDashboardActions.verifyCandidate(id)),
});

// connection of redux form with state & action dispatcher
const NewSidebar = connect(mapStateToProps, mapDispatchToProps)(CDSidebar);

export default compose(memo(NewSidebar));
