export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const RenderColumn = ({ icon, label, value }) => (
  <div className="col-md-4 mb-3">
    <div className="font-size-12 text-dark pb-0 mb-0 font-weight-bold">
      {label}
    </div>
    <div className="font-size-12 mt-1 pt-0 text-secondary">
      {value || <span className="text-warning">Not Updated</span>}
    </div>
  </div>
);
