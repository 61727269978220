import SelectBox from 'components/common/Select';
import { setInstituteEduSpecCategoryId } from 'containers/App/actions';
import makeSelectGlobalStore from 'containers/App/selectors';
import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { formatListByField } from 'utils/form';

const SpecializationEducationBox = ({ globalStore, setSelectedCategory }) => {
  const { userRoleData } = globalStore || {};
  const { specializations } = userRoleData || {};
  const { control, errors } = useForm();

  const [specialization, setSpecialization] = useState(null);
  const [category, setCategory] = useState(null);

  const handleSpecializationChange = useCallback((val) => {
    setSpecialization(val);
    setCategory(null);
    localStorage.setItem('selectedSpecialization', JSON.stringify(val));
  }, []);

  const handleCategoryChange = useCallback(
    (val) => {
      setCategory(val);
      localStorage.setItem('selectedCategory', JSON.stringify(val));
      setSelectedCategory(val?.value);
    },
    [setSelectedCategory]
  );

  const categoryOptions = useMemo(() => {
    if (!specialization) return null;
    return specializations?.find((data) => data.id === specialization.value)
      ?.educationCategories;
  }, [specialization, specializations]);

  useEffect(() => {
    const selectSpecialzation = localStorage.getItem('selectedSpecialization'); // Check if the value is present in local storage
    const selectCategory = localStorage.getItem('selectedCategory'); // Check if the value is present in local storage
    if (selectSpecialzation) {
      setSpecialization(JSON.parse(selectSpecialzation)); // Set the state of the select box to the stored value
      setCategory(JSON.parse(selectCategory)); // Set the state of the select box to the stored value
      setSelectedCategory(JSON.parse(selectCategory)?.value);
    }
  }, [setSelectedCategory]);

  const location = window.location.pathname;
  if (location?.includes('/students-listing')) {
    return null;
  }

  return (
    <Col md={12}>
      <Row className="pb-3">
        <Col md={6}>
          <SelectBox
            label="Select Specialization"
            errors={errors}
            control={control}
            name="specialization"
            rules={{ required: false }}
            wrapperClass="searchForm mr-2 pb-0 mb-0"
            labelClass="company__form-label"
            options={formatListByField(specializations, 'specialization', 'id')}
            handleChange={handleSpecializationChange}
            value={specialization}
          />
        </Col>
        <Col md={6}>
          <SelectBox
            label="Select Education Category"
            errors={errors}
            control={control}
            name="category"
            rules={{ required: false }}
            wrapperClass="searchForm mr-2 pb-0 mb-0"
            labelClass="company__form-label"
            options={formatListByField(categoryOptions, 'category', 'id')}
            handleChange={handleCategoryChange}
            value={category}
          />
        </Col>
      </Row>
    </Col>
  );
};

// select data from redux store
const mapStateToProps = createStructuredSelector({
  globalStore: makeSelectGlobalStore(),
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedCategory: (id) => dispatch(setInstituteEduSpecCategoryId(id)),
});

const SpecializationEducation = connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecializationEducationBox);

export default compose(memo(SpecializationEducation));
