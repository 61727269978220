/*
 *
 * CdChangeMobile constants
 *
 */

export const GET_CONFIG_DATA = 'app/CandidateJobSearch/GET_CONFIG_DATA';
export const SET_CONFIG_DATA = 'app/CandidateJobSearch/SET_CONFIG_DATA';
export const SEARCH_JOB = 'app/CandidateJobSearch/SEARCH_JOB';
export const SET_SEARCH_RESULT = 'app/CandidateJobSearch/SET_SEARCH_RESULT';
export const SET_PAYLOAD_DATA = 'app/CandidateJobSearch/SET_PAYLOAD_DATA';
export const SET_NOTIFICATION_ON = 'app/CandidateJobSearch/SET_NOTIFICATION_ON';
export const SET_NOTIFICATION_OFF =
  'app/CandidateJobSearch/SET_NOTIFICATION_OFF';
export const SAVE_JOB = 'app/CandidateJobSearch/SAVE_JOB';
export const DELETE_JOB = 'app/CandidateJobSearch/DELETE_JOB';
export const SET_PAGE_NUMBER_TO_STORE =
  'app/CandidateJobSearch/SET_PAGE_NUMBER_TO_STORE';
export const APPLY_JOB = 'app/CandidateJobSearch/APPLY_JOB';
export const GET_NEAREST_LOCATION =
  'app/CandidateJobSearch/GET_NEAREST_LOCATION';
export const SET_NEAREST_LOCATION =
  'app/CandidateJobSearch/SET_NEAREST_LOCATION';
export const GET_MATCHING_JOB = 'app/CandidateJobSearch/GET_MATCHING_JOB';
export const SET_MATCHING_JOB = 'app/CandidateJobSearch/SET_MATCHING_JOB';
export const RESET_MATCHING_JOB = 'app/CandidateJobSearch/RESET_MATCHING_JOB';
export const SET_CONSENT_MODAL = 'app/CandidateJobSearch/SET_CONSENT_MODAL';
export const APPROVE_CONSENT_FORM = 'app/CandidateJobSearch/APPROVE_CONSENT_FORM';
