import React, { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Error from './Error';

const getError = (name, errors) => {
  let error = errors;
  if (name?.includes('.')) {
    name?.split('.')?.forEach((x) => {
      if (error) {
        error = error[x];
      }
    });
  } else {
    error = errors[name];
  }

  return error;
};

const getErrorFromUseFieldArray = (id, name, errors) => {
  const arrayName = name?.includes('[') ? name.split('[')[0] : '';
  const fieldName = name?.includes('.') ? name.split('.')[1] : '';
  return errors[arrayName]?.[id]?.[fieldName] || {};
};

const Input = React.forwardRef(
  (
    {
      name,
      label,
      errors,
      id,
      labelClass,
      className,
      required,
      wrapperClass,
      type = 'text',
      defaultValue,
      value,
      maxLimit = 1000,
      fromUseFieldArray = false,
      rows,
      charLeftClass = 'text-bottom-blue',
      charRightClass = 'text-bottom-orange',
      charLeftText = 'Characters Left:',
      charRightText = '/',
      charLeftWrapperClass = 'char-left',
      onChange,
      ...props
    },
    ref
  ) => {
    const error = fromUseFieldArray
      ? getErrorFromUseFieldArray(id, name, errors)
      : getError(name, errors);

    const [charLeft, setCharLeft] = useState(defaultValue?.length || 0);

    const textChangeHandler = (evt) => {
      const textLength = evt.target.value.length;
      setCharLeft(textLength);
      // evt.target.name === 'jobDescription' &&
      onChange && onChange(evt);
    };

    return (
      <div className={`form-group ${wrapperClass}`}>
        <label htmlFor={id} className={labelClass || 'lbl_info'}>
          {label}
          {required && <span className="star_symbol">*</span>}
        </label>
        <TextareaAutosize
          ref={ref}
          {...props}
          name={name}
          id={id}
          className={className || 'form-control txt_animation'}
          type={type}
          defaultValue={defaultValue}
          onChange={textChangeHandler}
          minRows={rows}
          value={value}
        />
        {error && Object.keys(error).length > 0 && <Error {...error} />}
        <span className={charLeftWrapperClass}>
          {charLeftText}
          <span className={charLeftClass}> {charLeft}</span>
          <span className={charRightClass}>
            {charRightText}
            {maxLimit}
          </span>
        </span>
      </div>
    );
  }
);

export default Input;
