/*
 *
 * UserCompanyProfileReg actions
 *
 */

import {
  SET_MODAL_STATUS,
  GET_RECRUITER_LOGO,
  SET_RECRUITER_LOGO,
  GOTO_RECRUITER_PAGE,
  REMOVE_RECRUITER_LOGO,
  UPLOAD_RECRUITER_LOGO,
  SET_RECRUITER_PROFILE,
  SET_RECRUITER_PAGE_DATA,
  LOAD_RECRUITER_PAGE_DATA,
  FAILED_RECRUITER_PAGE_DATA,
  UPDATE_RECRUITER_LOGO_INFO,
  SUBMIT_RECRUITER_PAGE_FORM,
} from './constants';

export function removeRecruiterLogoAction(docId) {
  return {
    docId,
    type: REMOVE_RECRUITER_LOGO,
  };
}

export function getRecruiterLogoAction(docId) {
  return {
    docId,
    type: GET_RECRUITER_LOGO,
  };
}

export function updateRecruiterLogoAction(recruiterLogo) {
  return {
    recruiterLogo,
    type: SET_RECRUITER_LOGO,
  };
}

export function uploadRecruiterLogoAction(formData, imageUrl) {
  return {
    imageUrl,
    formData,
    type: UPLOAD_RECRUITER_LOGO,
  };
}

export function updateRecruiterLogoInfoAction(profileImage) {
  return {
    profileImage,
    type: UPDATE_RECRUITER_LOGO_INFO,
  };
}

export function recruiterPageGoToTab(goTo) {
  return {
    goTo,
    type: GOTO_RECRUITER_PAGE,
  };
}

export function submitRecruiterForm(formData, formStep, imageUrl) {
  return {
    formData,
    formStep,
    imageUrl,
    type: SUBMIT_RECRUITER_PAGE_FORM,
  };
}

export function loadRecruiterPageData() {
  return {
    type: LOAD_RECRUITER_PAGE_DATA,
  };
}

export function setRecruiterFormData(data) {
  return {
    data,
    type: SET_RECRUITER_PAGE_DATA,
  };
}

export function failedLoadingRecruiterPageData(msg) {
  return {
    msg,
    type: FAILED_RECRUITER_PAGE_DATA,
  };
}

export function setRecruiterProfile(data) {
  return {
    data,
    type: SET_RECRUITER_PROFILE,
  };
}

export function setModalStatusAction(status) {
  return {
    status,
    type: SET_MODAL_STATUS,
  };
}
