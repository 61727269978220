/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

function DashboardFooter() {
  return (
    <footer className="page-footer">
      <p className="copyright text-center">
        © {new Date().getFullYear()} Dufther, Made in India{' '}
      </p>
    </footer>
  );
}

export default DashboardFooter;
