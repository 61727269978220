import * as gConstants from './constants';

// global action to load data into globalstore
export const loadGlobalStoreData = () => ({
  type: gConstants.LOAD_GLOBAL_STORE_DATA,
});
export const setGlobalStoredData = (payload) => ({
  payload,
  type: gConstants.SET_GLOBAL_STORE_DATA,
});

export const loadPhysicalStatusData = (id) => ({
  id,
  type: gConstants.GET_PHYSICAL_STATUS,
});
export const setPhysicalStatusData = (payload) => ({
  payload,
  type: gConstants.SET_PHYSICAL_STATUS,
});
// global action to resend otp
export const resendOtpOnMobile = () => ({
  type: gConstants.RESEND_OTP,
});
export const resendOtpAfterLogin = (data, otpType) => ({
  data,
  otpType,
  type: gConstants.RESEND_OTP_AFTER_LOGIN,
});
export const otpGeneratedResponse = (msg) => ({
  msg,
  type: gConstants.OTP_GENERATED_RESPONSE,
});
// global actions to set Actively Searching jobs sidebar checkbox value

// global actions to show/hide loader
export const showLoader = () => ({
  type: gConstants.SHOW_LOADER,
});
export const hideLoader = () => ({
  type: gConstants.HIDE_LOADER,
});

export const setUserName = (firstName, lastName, status) => ({
  firstName,
  lastName,
  status,
  type: gConstants.SET_USER_NAME,
});

export const setDashboardRole = (data) => ({
  data,
  type: gConstants.SET_DASHBOARD_ROLE,
});

export const setDashboardRoleUserData = (data) => ({
  data,
  type: gConstants.SET_DASHBOARD_ROLE_USER_DATA,
});

export const selectRecruiterCompany = (data) => ({
  data,
  type: gConstants.SELECT_RECRUITER_COMPANY,
});

export const initWebSocket = (role) => ({
  type: gConstants.INITIALIZE_WEB_SOCKETS_CHANNEL,
  role,
});

export const getIndiaDistricts = () => ({
  type: gConstants.LOAD_DISTRICTS,
});

export const setIndiaDistricts = (data) => ({
  data,
  type: gConstants.SET_INDIA_DISTRICTS,
});

export const addEmailModalStatus = (data) => ({
  payload: data,
  type: gConstants.ADD_EMAIL_MODAL_STATUS,
});

export const submitEmailForOtp = (data) => ({
  payload: data,
  type: gConstants.SUBMIT_EMAIL_FOR_OTP,
});

export const emailOtpRespose = (data) => ({
  payload: data,
  type: gConstants.EMAIL_OTP_RESPONSE,
});

export const submitEmailChange = (data) => ({
  payload: data,
  type: gConstants.SUBMIT_EMAIL_CHANGE,
});

export const addMobileModalStatus = (data) => ({
  payload: data,
  type: gConstants.ADD_MOBILE_MODAL_STATUS,
});

export const submitMobileForOtp = (data) => ({
  payload: data,
  type: gConstants.SUBMIT_MOBILE_FOR_OTP,
});

export const mobileOtpRespose = (data) => ({
  payload: data,
  type: gConstants.MOBILE_OTP_RESPONSE,
});

export const submitMobileChange = (data) => ({
  payload: data,
  type: gConstants.SUBMIT_MOBILE_CHANGE,
});

export const resetOtpField = (data) => ({
  payload: data,
  type: gConstants.RESET_OTP_FIELD,
});

export const setInstituteEduSpecCategoryId = (id) => ({
  payload: id,
  type: gConstants.SET_INSTITUTE_EDU_SPEC_CATEGORY,
});

export const loadEmpPricingModelData = () => ({
  type: gConstants.LOAD_EMP_PRICING_DATA,
});

export const setEmpPricingModelData = (data) => ({
  data,
  type: gConstants.SET_EMP_PRICING_DATA,
});

export const getVerificationDocs = (id) => ({
  id,
  type: gConstants.GET_VERIFICATION_DOCS,
});

export const setVerificationDocs = (data) => ({
  data,
  type: gConstants.SET_VERIFICATION_DOCS,
});

export const uploadVerificationDocs = (id, data, formData, docType) => ({
  id,
  data,
  formData,
  docType,
  type: gConstants.UPLOAD_VERIFICATION_DOCS,
});

export const deleteVerificationDoc = (id, docType) => ({
  id,
  docType,
  type: gConstants.DELETE_VERIFICATION_DOCS,
});

export const setRedirectCallback = (callback) => ({
  callback,
  type: gConstants.SET_REDIRECT_CALLBACK,
});
