import { takeLatest, put, select, call, all } from 'redux-saga/effects';
import { pathOr } from 'ramda';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import * as gSelectors from 'containers/App/selectors';
import * as apiName from 'config/apiUrl';
import * as gService from 'utility/GlobalServices';
import * as gActions from 'containers/App/actions';
import { displayNotification } from 'config/toastConfig';

// ***** LOAD the config Data ***** //
export function* loadConfigDataSaga() {
  const allURLs = [
    apiName.postingDateURL,
    apiName.worktypeURL,
    apiName.hiredURL,
    apiName.empTypeURL,
    apiName.timingURL,
    apiName.workDurationURL,
    apiName.experienceURL,
    apiName.salaryModeURL,
    apiName.salRangeURL,
    apiName.ageRangeURL,
    apiName.genderURL,
    apiName.physicalStatusURL,
    apiName.associatedIndustriesURL,
    apiName.rolesPerformedURL,
    apiName.skillsURL,
    apiName.jobAllowanceURL,
    apiName.timeToFillPostURL,
    apiName.distanceKmsURL,
    apiName.activityJobURL,
    apiName.educationalQualificationsURL,
    apiName.travelRequireURL,
    apiName.locationPrefrenceURL,
    apiName.domainURL,
  ];
  yield put(gActions.showLoader());

  try {
    const [
      lastLoginDate,
      worktype,
      hired,
      empType,
      workingType,
      workDuration,
      experience,
      salaryMode,
      salRange,
      ageRange,
      gender,
      physicalStatus,
      associatedIndustries,
      rolesPerformed,
      skills,
      jobAllowance,
      timeToFillPost,
      distanceKms,
      activityJob,
      educationalQualifications,
      travelRequire,
      locationPrefrence,
      domain,
    ] = yield all(allURLs.map((url) => gService.callApi(url)));

    yield put(
      actions.setConfigData({
        lastLoginDate,
        worktype,
        hired,
        empType,
        workingType,
        workDuration,
        experience,
        salaryMode,
        salRange,
        ageRange,
        gender,
        physicalStatus,
        associatedIndustries,
        rolesPerformed,
        skills,
        jobAllowance,
        timeToFillPost,
        distanceKms,
        activityJob,
        educationalQualifications,
        travelRequire,
        locationPrefrence,
        domain,
        sucess: true,
      })
    );
  } catch (error) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

const parsePayLoad = (payload) => {
  let result = {};

  Object.keys(payload).forEach((key, index) => {
    const check =
      payload[key] != '' ||
      (Array.isArray(payload[key]) && payload[key].length) ||
      (typeof payload[key] === 'boolean' && payload[key]);
    if (check) {
      result = { ...result, [key]: payload[key] };
    }
  });
  return result;
};

// ***** SEARCH JOB DATA ***** //
export function* searchCandidateSaga({ payload, history }) {
  yield put(gActions.showLoader());
  yield put(actions.setPayloadData(payload));
  const { dashboardRole } = yield select(gSelectors.selectGlobalStorerDomain);
  const locations = yield gService.getGeocodeByPlaceId(payload.geoLocations);
  try {
    const serchResult = yield gService.callApi(
      apiName.employerSearchURL,
      parsePayLoad({
        ...payload,
        geoLocations: locations,
      }),
      'POST'
    );

    const isIssue = pathOr(200, ['status'], serchResult);
    if (isIssue == 400) {
      displayNotification(
        pathOr('Something went wrong! try again.', ['message'], serchResult),
        'warning'
      );
      yield put(actions.setSearchResult({}));
      yield put(actions.setSearchResultError(true));
    } else {
      yield put(
        actions.setSearchResult({
          success: true,
          result: serchResult,
        })
      );
      yield put(actions.setSearchResultError(false));
      if (history) {
        if (dashboardRole === 'Employer') {
          yield call(history.push('/edashboard/emp-candidate-search'));
        } else if (dashboardRole === 'Recruiter') {
          yield call(history.push('/rdashboard/recruiter-candidate-search'));
        } else if (dashboardRole === 'Franchisee') {
          yield call(history.push('/fdashboard/emp-candidate-search'));
        }
      }
    }
  } catch (err) {
    // displayNotification('Something went wrong! try again.', 'danger');
    yield put(gActions.hideLoader());
  }

  yield put(gActions.hideLoader());
}

// ***** GET NEAREST CITY SAGA ***** //
export function* getNearestCitySaga({ distance = 10, latitude, longitude }) {
  yield put(gActions.showLoader());

  try {
    const response = yield gService.callApi(
      `${apiName.nearestCity}?distanctInKm=${distance}&latitude=${latitude}&longitude=${longitude}`
    );
    yield put(actions.setNearestLocation(response));
    displayNotification('Cities based on your location');
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** GET COMPANY ID ***** //
export function* getCompanyIdSaga() {
  yield put(gActions.showLoader());
  const userId = window.localStorage.getItem('userId');
  const companyIdURL = apiName.employerDetail + `/${userId}`;
  try {
    const { companyId } = yield gService.callApi(companyIdURL);
    yield put(actions.setCompanyId(companyId));
  } catch (err) {
    displayNotification(
      'Something went wrong for fetching company Id ! try again.',
      'danger'
    );
  }
  yield put(gActions.hideLoader());
}

// ***** GET JOB POST LIST ***** //
export function* getJobListSaga({ companyId }) {
  yield put(gActions.showLoader());
  try {
    const response = yield gService.callApi(
      `${apiName.jobPostList}?companyId=${companyId}`
    );
    yield put(
      actions.setJobList({
        list: response,
        sucess: true,
      })
    );
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** GET CONTACT API ***** //
export function* getContactDataSaga({ candidateId }) {
  yield put(gActions.showLoader());
  yield put(actions.setContactData({ loading: true, data: {} }));
  try {
    const response = yield gService.callApi(
      `${apiName.userContactDetailURL}/${candidateId}`
    );
    yield put(actions.setContactData({ loading: false, data: response }));
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** SEND CANDIDATE INVITE FOR EMPLOYER LOGIN  ***** //
export function* sendCandidateInviteSaga({ data, selectionType }) {
  const { searchPayload } = yield select(
    selectors.selectEmployerCandidateSearchDomain
  );

  yield put(gActions.showLoader());
  try {
    if (selectionType === 'single') {
      const { candidateIds, jobId } = data;
      yield gService.callApi(
        `${apiName.employerActionShortlistURL}?candidateId=${candidateIds[0]}&jobId=${jobId}`,
        '',
        'POST'
      );
      displayNotification('Candidate has been shortlisted for the Job');
    } else if (selectionType === 'multi') {
      yield gService.callApi(`${apiName.employerActionMultiURL}`, data, 'POST');
      displayNotification(
        'Invitation has been sent to Candidate(s) to Apply for the Selected Job Post'
      );
    } else if (selectionType === 'remove') {
      const { candidateIds, jobId } = data;
      yield gService.callApi(
        `${apiName.employerActionShortlistURL}?candidateId=${candidateIds[0]}&jobId=${jobId}`,
        '',
        'DELETE'
      );
      displayNotification('Candidate has been unshortlisted for the Job');
    }
    yield put(actions.searchCandidate(searchPayload));
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** SEND CANDIDATE INVITE FOR FRANCHISEE LOGIN ***** //
export function* candidateFranchiseeShortListing({ data, flag }) {
  const { searchPayload } = yield select(
    selectors.selectEmployerCandidateSearchDomain
  );

  yield put(gActions.showLoader());
  try {
    const { candidateId, companyId } = data;
    yield gService.callApi(
      `${apiName.franchiseeActionShortlistURL}?candidateId=${candidateId}&companyId=${companyId}`,
      '',
      flag === 'Unshortlist' ? 'DELETE' : 'POST'
    );
    displayNotification(`Candidate has been ${flag}ed`);
    yield put(actions.searchCandidate(searchPayload));
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** DOWNLOAD RESUME SAGA ***** //

const readyForDownload = (responseData, name) => {
  const url = window.URL.createObjectURL(new Blob([responseData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
};

export function* resumeDownloadSaga({ data }) {
  const { documentId, name } = data;
  yield put(gActions.showLoader());
  try {
    const response = yield gService.callApi(
      `${apiName.documentURL}/${documentId}`,
      null,
      'GET',
      {
        fileResponse: true,
      }
    );

    readyForDownload(response, name);
    displayNotification('Resume Downloaded');
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** PRICING FIREWLL SAGA ***** //
export function* pricingFireWallSaga({ data }) {
  const { payload, callback } = data;
  const { candidateId, action, companyId, hasAccess } = payload;
  yield put(gActions.showLoader());
  if (hasAccess) {
    callback();
  } else {
    try {
      const response = yield gService.callApi(
        `${apiName.employerActionURL}/${candidateId}?action=${action}&companyId=${companyId}`,
        null,
        'POST'
      );
      const check = pathOr(false, ['hasAccess'], response);
      const message = pathOr(
        'Pack expired, Please recharge',
        ['message'],
        response
      );
      if (check) {
        callback();
      } else {
        yield put(actions.setFirewallMessage(message));
        displayNotification(message, 'warning');
      }
    } catch (err) {
      displayNotification('Something went wrong! try again.', 'danger');
    }
  }
  yield put(gActions.hideLoader());
}

// ***** GET MATCHING CANDIDATE ***** //
export function* getMangedCandidateSaga({ jobId }) {
  if (jobId) {
    yield put(gActions.showLoader());
    yield put(
      actions.setCandidateMatchData({
        loading: true,
        success: false,
      })
    );
    try {
      const response = yield gService.callApi(
        `${apiName.getMatchingCandidateURL}/${jobId}`
      );
      delete response['annualSalary'];
      delete response['experience'];
      delete response['salaryMode'];
      delete response['industry'];
      yield put(actions.setCandidateMatch(response));
      yield put(
        actions.setCandidateMatchData({
          loading: false,
          success: true,
        })
      );
    } catch (error) {
      displayNotification('Something went wrong! try again.', 'danger');
      yield put(
        actions.setCandidateMatchData({
          loading: false,
          success: false,
        })
      );
    }
  }
  yield put(gActions.hideLoader());
}

// Individual exports for testing
export default function* basicAdvanceJobSearchSaga() {
  yield takeLatest(constants.GET_CONFIG_DATA, loadConfigDataSaga);
  yield takeLatest(constants.SEARCH_CANDIDATE, searchCandidateSaga);
  yield takeLatest(constants.GET_NEAREST_LOCATION, getNearestCitySaga);
  yield takeLatest(constants.GET_COMPANY_ID, getCompanyIdSaga);
  yield takeLatest(constants.GET_JOB_LIST, getJobListSaga);
  yield takeLatest(constants.GET_CONTACT_DATA, getContactDataSaga);
  yield takeLatest(constants.SEND_CANDIDATE_INVITE, sendCandidateInviteSaga);
  yield takeLatest(
    constants.CANDIDATE_FRANCHISEE_SHORTLISTING,
    candidateFranchiseeShortListing
  );
  yield takeLatest(constants.DOWNLOAD_RESUME, resumeDownloadSaga);
  yield takeLatest(constants.GET_PRICING_FIREWALL_CHECK, pricingFireWallSaga);
  yield takeLatest(constants.GET_MANAGE_CANDIDATE, getMangedCandidateSaga);
}
