import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the companyProfileReg state domain
 */

const selectFranchiseDetailsStore = (state) =>
  state.franchiseDetail || initialState;

/**
 * Default selector used by CompanyProfileReg
 */

const makeSelectFranchiseDetails = () =>
  createSelector(selectFranchiseDetailsStore, (substate) => substate);

export default makeSelectFranchiseDetails;
export { selectFranchiseDetailsStore };
