import produce from 'immer';
import {
  SET_ANALYTICS_DATA,
  SET_APPLICATION_DATA,
  SET_PAGE_NUMBER_TO_STORE,
  SET_PAGE_PAGE_ITEMS_TO_STORE,
  FETCH_APPLICATION_JOBID,
  SET_APPLICATIONS_TO_STORE,
  UPDATE_APPLICATION_STATUS,
  SET_JOB_STATUS_TO_STORE,
  SET_EMP_DASHBOARD_NOTIFICATION_COUNT,
  SET_EMP_CAT_NOTIFICATION_COUNT,
  GET_CANDIDATE_CONTACT,
  SET_CANDIDATE_CONTACT,
  SET_AVATAR,
  INITIALIZE_EMP_STORE,
  SET_EMP_ACTION_WAY,
  SET_APPLICATION_STATUS,
  SET_STAGE_STATUS,
  SET_COMPANY_BASIC_DETAILS,
} from './constants';

export const initialState = {
  dashboard: 'employer',
  analyticsData: {},
  appStatusArr: [],
  jobListArr: [],
  applicationData: null,
  pageNumber: 0,
  perPage: 10,
  jobStatus: 'All',
  jobId: null,
  appStatusForm: null,
  categoriesNotificationCount: {
    ED_RD_APPLICATION: { count: 0, totalCount: 0 },
    ED_RD_CANDIDATE_INVITE: { count: 0, totalCount: 0 },
    ED_RD_CANDIDATE_REC: { count: 0, totalCount: 0 },
    ED_RD_PRICING_VALIDITY: { count: 0, totalCount: 0 },
    ED_RECRUITER_ACTIVITY: { count: 0, totalCount: 0 },
  },
  candidateUserId: null,
  contactDetail: null,
  avatarUrl: '',
  profileImage: {},
  empAction: null,
  candidateId: null,
  applicationStatus: [],
  stageStatus: [],
  companyDetail: {},
};

const updateAllNotifications = (draft, payload) => {
  const newPayload = Object.keys(payload);
  newPayload.length &&
    newPayload.map((key) => {
      if (draft.categoriesNotificationCount[key])
        draft.categoriesNotificationCount[key].count = payload[key];
    });
};

const updateCategoryNotification = (draft, payload) => {
  if (
    payload?.category &&
    draft.categoriesNotificationCount[payload?.category]
  ) {
    draft.categoriesNotificationCount[payload?.category].count =
      payload?.unReadCount;
  }
};

const employerDashboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_ANALYTICS_DATA:
        draft.analyticsData = action.data;
        break;
      case SET_APPLICATION_STATUS:
        draft.applicationStatus = action.data;
        break;
      case SET_STAGE_STATUS:
        draft.stageStatus = action.data;
        break;
      case SET_APPLICATION_DATA:
        draft.appStatusArr = action.data.appStatusArr;
        draft.jobListArr = action.data.jobListArr;
        break;
      case SET_PAGE_NUMBER_TO_STORE:
        draft.pageNumber = action.pageNum;
        break;
      case SET_PAGE_PAGE_ITEMS_TO_STORE:
        draft.pageNumber = 0;
        draft.perPage = action.perPage;
        break;
      case FETCH_APPLICATION_JOBID:
        draft.jobId = action.jobId;
        break;
      case SET_APPLICATIONS_TO_STORE:
        draft.applicationData = action.data;
        break;
      case SET_JOB_STATUS_TO_STORE:
        draft.pageNumber = 0;
        draft.perPage = 10;
        draft.jobStatus = action.jobStatus;
        break;
      case UPDATE_APPLICATION_STATUS:
        draft.appStatusForm = action.data;
        break;
      case SET_EMP_DASHBOARD_NOTIFICATION_COUNT:
        updateAllNotifications(draft, action.payload);
        break;
      case SET_EMP_CAT_NOTIFICATION_COUNT:
        updateCategoryNotification(draft, action.payload);
        break;
      case GET_CANDIDATE_CONTACT:
        draft.candidateUserId = action.candidateUserId;
        break;
      case SET_CANDIDATE_CONTACT:
        draft.contactDetail = action.data;
        break;
      case SET_COMPANY_BASIC_DETAILS:
        draft.companyDetail = action.payload;
        break;
      case SET_AVATAR:
        draft.avatarUrl = action.data;
        break;
      case SET_EMP_ACTION_WAY:
        draft.empAction = action.actionWay;
        draft.candidateId = action.id;
        break;
      case INITIALIZE_EMP_STORE:
        return state;
      default:
        return state;
    }
  });

export default employerDashboardReducer;
