/*
 *
 * App reducer
 *
 */
import produce from 'immer';
import {
  RESEND_OTP,
  OTP_GENERATED_RESPONSE,
  LOAD_GLOBAL_STORE_DATA,
  SET_GLOBAL_STORE_DATA,
  SHOW_LOADER,
  HIDE_LOADER,
  SET_USER_NAME,
  SET_DASHBOARD_ROLE,
  SET_NOTIFICATION_MSG,
  SELECT_RECRUITER_COMPANY,
  SET_DASHBOARD_ROLE_USER_DATA,
  SET_INDIA_DISTRICTS,
  ADD_EMAIL_MODAL_STATUS,
  EMAIL_OTP_RESPONSE,
  ADD_MOBILE_MODAL_STATUS,
  MOBILE_OTP_RESPONSE,
  RESET_OTP_FIELD,
  SET_INSTITUTE_EDU_SPEC_CATEGORY,
  SET_EMP_PRICING_DATA,
  SET_VERIFICATION_DOCS,
  SET_REDIRECT_CALLBACK,
  SET_PHYSICAL_STATUS,
} from './constants';

export const initialState = {
  otpResponse: null,
  userRoleData: null,
  userRolesData: null,
  availableImmediatelyData: null,
  showLoader: false,
  userData: {},
  dashboardRole: '',
  recruiterCurrentCompanyId: null,
  availableImmediately: null,
  latestRole: '',
  notificationMsg: '',
  indiaDistricts: [],
  emailModalStatus: false,
  emailIsSent: false,
  mobileModalStatus: false,
  mobileIsSent: false,
  resetOtpField: null,
  eduSpecCategoryId: null,
  empPricingModelData: {},
  verificationDocs: {},
  redirectCallBack: {},
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_GLOBAL_STORE_DATA:
        break;
      case SET_GLOBAL_STORE_DATA:
        const newPayload = action.payload;
        draft.userRoleData = newPayload.userRoleData;
        draft.userRolesData = newPayload.userRolesData;
        draft.profileScoreData = newPayload.profileScore;
        draft.availableImmediatelyData = newPayload.availableImmediatelyData;
        draft.activelySearchingData = newPayload.activelySearchingData;
        draft.availableImmediately = newPayload.availableImmediately;
        draft.activelySearchJob = newPayload.activelySearchJob;
        draft.userData = newPayload.userDataResponse;
        draft.latestRole = newPayload.role;
        break;
      case SET_PHYSICAL_STATUS:
        draft.userRoleData = action.payload;
        break;
      case RESEND_OTP:
        break;
      case OTP_GENERATED_RESPONSE:
        draft.otpResponse = action.msg;
        break;
      case SHOW_LOADER:
        draft.showLoader = true;
        break;
      case HIDE_LOADER:
        draft.showLoader = false;
        break;
      case SET_USER_NAME:
        draft.userRoleData = {
          ...draft.userRoleData,
          lastName: action.lastName,
          firstName: action.firstName,
          status: action.status,
        };
        break;
      case SET_DASHBOARD_ROLE:
        draft.dashboardRole = action.data;
        break;
      case SET_VERIFICATION_DOCS:
        draft.verificationDocs = action.data;
        break;
      case SET_DASHBOARD_ROLE_USER_DATA:
        draft.dashboardRole = action.data.role;
        draft.userRoleData = action.data.userData;
        break;
      case SET_NOTIFICATION_MSG:
        draft.notificationMsg = action.message;
        break;
      case SELECT_RECRUITER_COMPANY:
        draft.recruiterCurrentCompanyId = action.data;
        break;
      case SET_INDIA_DISTRICTS:
        draft.indiaDistricts = action.data;
        break;
      case ADD_EMAIL_MODAL_STATUS:
        draft.emailModalStatus = action.payload;
        draft.emailIsSent = null;
        break;
      case EMAIL_OTP_RESPONSE:
        draft.emailIsSent = action.payload;
        break;
      case ADD_MOBILE_MODAL_STATUS:
        draft.mobileModalStatus = action.payload;
        draft.mobileIsSent = null;
        break;
      case MOBILE_OTP_RESPONSE:
        draft.mobileIsSent = action.payload;
        break;
      case RESET_OTP_FIELD:
        draft.resetOtpField = action.payload;
        break;
      case SET_INSTITUTE_EDU_SPEC_CATEGORY:
        draft.eduSpecCategoryId = action.payload;
        break;
      case SET_EMP_PRICING_DATA:
        draft.empPricingModelData = action.data;
        break;
      case SET_REDIRECT_CALLBACK:
        draft.redirectCallBack = action.callback;
        break;
    }
  });

export default reducer;
