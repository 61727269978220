import { eventChannel, END } from 'redux-saga';
import { baseWebsocketUrl } from '../../config/config';

import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { pathOr } from 'ramda';
import * as candidateConstants from '../CandidateDashboard/constants';
import * as empConstants from '../EmployerDashboard/constants';
import { displayNotification } from '../../config/toastConfig';

let stompClient = null;
let currentRole = null;

export const setRoleForChannel = (role) => {
  currentRole = role;
};

export const initWebsocket = (role) => {
  return eventChannel((emitter) => {
    const socket = SockJS(
      `${baseWebsocketUrl}/ws?auth_token=Bearer ${localStorage.getItem(
        'Bearer'
      )}`
    );

    if (stompClient?.connected) {
      stompClient.disconnect();
    }

    stompClient = Stomp.over(socket);

    stompClient.connect({}, onConnected, onError);

    const newNotificationHandler = (payload) => {
      const map = JSON.parse(payload.body);
      // console.log(map);
      const div = document.createElement('div');
      div.innerHTML = pathOr('', ['content'], map);
      const text = div.textContent || div.innerText || '';
      displayNotification(text, 'info');
    };

    const newNotificationCountHandler = (payload) => {
      const map = JSON.parse(payload.body);
      // console.log(map);

      if (currentRole === 'Candidate' || role === 'Candidate') {
        return emitter({
          type: candidateConstants.SET_CAT_NOTIFICATION_COUNT,
          payload: map,
        });
      } else if (currentRole === 'Employer' || role === 'Employer') {
        return emitter({
          type: empConstants.SET_EMP_CAT_NOTIFICATION_COUNT,
          payload: map,
        });
      }
    };

    function onConnected() {
      // console.log('inside onConnected');
      let userName = localStorage.getItem('Mobile');
      if (!userName || userName === 'null') {
        userName = localStorage.getItem('Email');
      }
      // Tell your username to the server
      stompClient.send(
        '/app/server.addUser',
        {},
        JSON.stringify({ userName, role, type: 'ALERT' })
      );

      // Topic to get realtime new notification message
      stompClient.subscribe(
        `/topic/notification/user/${userName}`,
        newNotificationHandler
      );

      // Topic for all category
      stompClient.subscribe(
        `/topic/notification/user/${userName}/count`,
        newNotificationCountHandler
      );
    }

    function onError(error) {
      // console.log(
      //   'Could not connect to WebSocket server. Please refresh this page to try again!'
      // );
      return emitter(END);
    }

    // unsubscribe function
    return () => {
      // console.log('Socket off');
      stompClient.disconnect();
    };
  });
};
