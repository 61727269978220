import { useRef, useEffect } from 'react';

export default function useOnLoadHistory() {
    const firstRender = useRef(true);
    const handleEvent = () => {
        window.history.pushState({}, ''); 
    };

    useEffect(() => {
        firstRender.current = false;
        window.addEventListener("load", handleEvent);
        return () => window.removeEventListener("load", handleEvent);
    }, []);
  
    return firstRender.current;
}