/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Table } from 'react-bootstrap';
import { versionData } from './VersionHistory';

function LandingFooter() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <footer className="footerEnd">
        <div className="container padding-footer">
          <div className="row">
            <div className="copyright" style={{ fontSize: 11 }}>
              © {new Date().getFullYear()}{' '}
              <a href="javascript:;" onClick={handleShow}>
                <u>Version 2.2</u>
              </a>{' '}
              |{' '}
              <a
                href="https://bluesparx.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <u> BluesparX Technologies</u>{' '}
              </a>
            </div>
          </div>
          <div className="row">
            <ul
              className="nav nav-footer"
              style={{ margin: '0 auto', textAlign: 'center' }}
            >
              <li className="nav-item">
                <a
                  href="https://dufther.com/about-us"
                  className="nav-link pl-0"
                >
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <Link to="/user-agreement/terms" className="nav-link">
                  Policies
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/user-agreement/contact" className="nav-link">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="row" style={{ display: 'block' }}>
            <a
              className="social"
              href="https://www.youtube.com/channel/UCkMtaYSgsNNlD5V2gpF5wQg/?guided_help_flow=5"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-youtube-play fa-2x" />
            </a>
            <a
              className="social"
              href="https://www.facebook.com/Dufther-116505893077155"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook-square fa-2x" />
            </a>
            <a
              className="social"
              href="https://www.linkedin.com/company/dufther"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-linkedin-square fa-2x" />
            </a>
          </div>
        </div>
      </footer>

      <Modal show={show} onHide={handleClose} className="versionmodal">
        <Modal.Header className="text-cente">
          <p className="modal-title modal_header_txt">Version History</p>
          <button
            type="button"
            className="close"
            onClick={() => setShow(false)}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Table striped responsive hover size="sm">
            <thead>
              <tr>
                <th>Ver</th>
                <th>Release Dt.</th>
                <th>Change Logs</th>
              </tr>
            </thead>
            <tbody>
              {versionData?.map((data, index) => (
                <tr key={data.version}>
                  <td style={{ width: '80px' }}>{data.version}</td>
                  <td style={{ width: '120px' }}>{data.release}</td>
                  <td>{data.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default LandingFooter;
