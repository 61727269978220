export const LOAD_ANALYTICS_DATA = 'app/EmployerDashboard/LOAD_ANALYTICS_DATA';

export const SET_ANALYTICS_DATA = 'app/EmployerDashboard/SET_ANALYTICS_DATA';

export const LOAD_APPLICATION_DATA =
  'app/EmployerDashboard/LOAD_APPLICATION_DATA';

export const SET_APPLICATION_DATA =
  'app/EmployerDashboard/SET_APPLICATION_DATA';

export const SET_PAGE_NUMBER_TO_STORE =
  'app/EmployerDashboard/SET_PAGE_NUMBER_TO_STORE';

export const SET_PAGE_PAGE_ITEMS_TO_STORE =
  'app/EmployerDashboard/SET_PAGE_PAGE_ITEMS_TO_STORE';

export const FETCH_APPLICATION_JOBID =
  'app/EmployerDashboard/FETCH_APPLICATION_JOBID';

export const SET_APPLICATIONS_TO_STORE =
  'app/EmployerDashboard/SET_APPLICATIONS_TO_STORE';

export const SET_JOB_STATUS_TO_STORE =
  'app/EmployerDashboard/SET_JOB_STATUS_TO_STORE';

export const UPDATE_APPLICATION_STATUS =
  'app/EmployerDashboard/UPDATE_APPLICATION_STATUS';

export const SET_EMP_DASHBOARD_NOTIFICATION_COUNT =
  'app/EmployerDashboard/SET_EMP_DASHBOARD_NOTIFICATION_COUNT';

export const SET_EMP_CAT_NOTIFICATION_COUNT =
  'app/EmployerDashboard/SET_EMP_CAT_NOTIFICATION_COUNT';

export const GET_CANDIDATE_CONTACT =
  'app/EmployerDashboard/GET_CANDIDATE_CONTACT';

export const SET_CANDIDATE_CONTACT =
  'app/EmployerDashboard/SET_CANDIDATE_CONTACT';

export const DOWNLOAD_RESUME = 'app/EmployerDashboard/DOWNLOAD_RESUME';
export const GET_AVATAR = 'app/EmployerDashboard/GET_AVATAR';

export const SET_AVATAR = 'app/EmployerDashboard/SET_AVATAR';
export const INITIALIZE_EMP_STORE =
  'app/EmployerDashboard/INITIALIZE_EMP_STORE';
export const SET_EMP_ACTION_WAY = 'app/EmployerDashboard/SET_EMP_ACTION_WAY';
export const GET_APPLICATION_STATUS =
  'app/EmployerDashboard/GET_APPLICATION_STATUS';
export const SET_APPLICATION_STATUS =
  'app/EmployerDashboard/SET_APPLICATION_STATUS';
export const GET_STAGE_STATUS = 'app/EmployerDashboard/GET_STAGE_STATUS';
export const SET_STAGE_STATUS = 'app/EmployerDashboard/SET_STAGE_STATUS';
export const GET_COMPANY_BASIC_DETAILS = 'Employer/GET_COMPANY_BASIC_DETAILS';
export const SET_COMPANY_BASIC_DETAILS = 'Employer/SET_COMPANY_BASIC_DETAILS';
