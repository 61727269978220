import {
  LOAD_FRANCHISEE_DETAIL,
  SET_FRANCHISEE_DETAIL,
  SET_FRANCHISEE_DATA,
  SAVE_FRANCHISEE_DATA,
  GET_FRANCHISEE_ID_DETAILS,
} from './constants';

export const loadFranchiseeDetails = () => ({
  type: LOAD_FRANCHISEE_DETAIL,
});

export const setFranchiseeDetails = (data) => ({
  data,
  type: SET_FRANCHISEE_DETAIL,
});

export const setFranchiseeFormData = (data) => ({
  data,
  type: SET_FRANCHISEE_DATA,
});

export const saveFranchiseeData = (data) => ({
  payload: data,
  type: SAVE_FRANCHISEE_DATA,
});

export const getFranchiseeIdDetails = (id) => ({
  payload: id,
  type: GET_FRANCHISEE_ID_DETAILS,
});
