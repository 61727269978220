/*
 *
 * CandidateDashboard constants
 *
 */

export const LOAD_DASHBOARD_DATA = 'app/CandidateDashboard/LOAD_DASHBOARD_DATA';
export const SET_DASHBOARD_DATA = 'app/CandidateDashboard/SET_DASHBOARD_DATA';
export const INITIALIZE_WEB_SOCKETS_CHANNEL = 'INITIALIZE_WEB_SOCKETS_CHANNEL';
export const SET_DASHBOARD_NOTIFICATION_COUNT =
  'SET_DASHBOARD_NOTIFICATION_COUNT';

export const SET_CAT_NOTIFICATION_COUNT = 'SET_CAT_NOTIFICATION_COUNT';

export const SUBMIT_AVAIABILITY_FORM = 'SUBMIT_AVAIABILITY';
export const AVAILABLE_IMMEDIATELY_FORM_RESPONSE = 'AVAILABLITY_RESPONSE';
export const SUBMIT_NOTICE_PERIOD_FORM = 'SUBMIT_NOTICE_PERIOD';
export const NOTICE_PERIOD_FORM_RESPONSE = 'NOTICE_PERIOD_RESPONSE';
export const RESET_NOTICE_PERIOD = 'RESET_NOTICE_PERIOD';

export const SUBMIT_ACTIVELY_SEARCHING_FORM = 'SUBMIT_ACTIVELY_SEARCHING_FORM';
export const ACTIVELY_SEARCHING_RESPONSE = 'ACTIVELY_SEARCHING_RESPONSE';
export const GET_CANDIDATE_IMG_URL = 'GET_CANDIDATE_IMG_URL';
export const SET_CANDIDATE_IMG_URL = 'SET_CANDIDATE_IMG_URL';
export const DELETE_CANDIDATE_AVATAR_SUCCESS =
  'DELETE_CANDIDATE_AVATAR_SUCCESS';

export const DOMAIN_DROPDOWN = 'DOMAIN_DROPDOWN';
export const SET_DOMAIN_DROPDOWN = 'SET_DOMAIN_DROPDOWN';
export const GET_DOMAIN_BASED_SUBDATA = 'GET_DOMAIN_BASED_SUBDATA';
export const DOMAIN_BASED_SUBDATA = 'DOMAIN_BASED_SUBDATA';
export const GET_SPECIALIZED_SUBDATA = 'GET_SPECIALIZED_SUBDATA';
export const SPECIALIZED_SUBDATA = 'SPECIALIZED_SUBDATA';
export const SUBMIT_DOMAIN_FORM = 'SUBMIT_DOMAIN_FORM';
export const SET_CONSENT_MODAL = 'app/CandidateJobSearch/SET_CONSENT_MODAL';
export const APPROVE_CONSENT_FORM =
  'app/CandidateJobSearch/APPROVE_CONSENT_FORM';
export const VERIFY_CANDIDATE = 'app/VERIFY_CANDIDATE';
export const SET_VERIFY_CANDIDATE = 'app/SET_VERIFY_CANDIDATE';
export const SET_ACADEMIC_EDUCATION_DATA = 'app/SET_ACADEMIC_EDUCATION_DATA';
