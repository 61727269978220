/*
 *
 * CdChangeMobile actions
 *
 */

import {
  GET_CONFIG_DATA,
  SET_CONFIG_DATA,
  SEARCH_JOB,
  SET_SEARCH_RESULT,
  SET_PAYLOAD_DATA,
  SET_NOTIFICATION_ON,
  SET_NOTIFICATION_OFF,
  SAVE_JOB,
  DELETE_JOB,
  SET_PAGE_NUMBER_TO_STORE,
  APPLY_JOB,
  GET_NEAREST_LOCATION,
  SET_NEAREST_LOCATION,
  GET_MATCHING_JOB,
  SET_MATCHING_JOB,
  RESET_MATCHING_JOB,
  SET_CONSENT_MODAL,
  APPROVE_CONSENT_FORM,
} from './constants';

export function loadConfigData() {
  return {
    type: GET_CONFIG_DATA,
  };
}

export function setConfigData(data) {
  return {
    data,
    type: SET_CONFIG_DATA,
  };
}

export function searchJob(payload, history) {
  return {
    payload,
    history,
    type: SEARCH_JOB,
  };
}

export function setSearchResult(data) {
  return {
    data,
    type: SET_SEARCH_RESULT,
  };
}

export function setPayloadData(data) {
  return {
    data,
    type: SET_PAYLOAD_DATA,
  };
}

export function setNotificationON(data) {
  return {
    data,
    type: SET_NOTIFICATION_ON,
  };
}

export function setNotificationOFF(data) {
  return {
    data,
    type: SET_NOTIFICATION_OFF,
  };
}

export function saveJob(data) {
  return {
    data,
    type: SAVE_JOB,
  };
}

export function deleteJob(data) {
  return {
    data,
    type: DELETE_JOB,
  };
}

export function setPageNumber(pageIndex) {
  return {
    pageIndex,
    type: SET_PAGE_NUMBER_TO_STORE,
  };
}

export function applyJob(id, mode) {
  return {
    id,
    mode,
    type: APPLY_JOB,
  };
}

export function getNearestLocation(distance, latitude, longitude) {
  return {
    distance,
    latitude,
    longitude,
    type: GET_NEAREST_LOCATION,
  };
}

export function setNearestLocation(location) {
  return {
    location,
    type: SET_NEAREST_LOCATION,
  };
}

export function getMatchingJobs() {
  return {
    type: GET_MATCHING_JOB,
  };
}

export function setMatchingJobs(data) {
  return {
    data,
    type: SET_MATCHING_JOB,
  };
}

export function resetMatchingJob() {
  return {
    type: RESET_MATCHING_JOB,
  };
}

export function setConsentModalStatus(status) {
  return {
    status,
    type: SET_CONSENT_MODAL,
  };
}

export function approveConsentForm(instituteId, consentStatus) {
  return {
    instituteId,
    consentStatus,
    type: APPROVE_CONSENT_FORM,
  };
}
