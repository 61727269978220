/*
 *
 * Employer search for candidates reducer
 *
 */
import produce from 'immer';

import {
  SET_CONFIG_DATA,
  SET_SEARCH_RESULT,
  SET_PAYLOAD_DATA,
  SET_PAGE_NUMBER_TO_STORE,
  SET_NEAREST_LOCATION,
  SET_COMPANY_ID,
  SET_JOB_LIST,
  SET_CONTACT_DATA,
  SELECT_JOB_ID,
  SET_SEARCH_RESULT_ERROR,
  SET_PRICING_FIREWALL_CHECK,
  SET_MANAGE_CANDIDATE,
  RESET_MANAGE_CANDIDATE,
  SET_MANAGE_CANDIDATE_DATA,
  SET_FIREWALL_MESSAGE,
} from './constants';

export const initialState = {
  configData: {
    lastLoginDate: [],
    worktype: [],
    hired: [],
    empType: [],
    workingType: [],
    workDuration: [],
    experience: [],
    salaryMode: [],
    salRange: [],
    ageRange: [],
    gender: [],
    physicalStatus: [],
    associatedIndustries: [],
    rolesPerformed: [],
    skills: [],
    jobAllowance: [],
    timeToFillPost: [],
    distanceKms: [],
    activityJob: [],
    educationalQualifications: [],
    travelRequire: [],
    locationPrefrence: [],
    workingTime: [],
    domain: [],
    sucess: false,
  },
  searchPayload: {
    ageRange: '',
    allowanceProvision: [],
    annualSalaryRange: '',
    availabilityToJoin: '',
    distanctInKm: 0,
    durationOfWork: [],
    experience: '',
    gender: [],
    hiringType: [],
    industry: [],
    keywords: [],
    lastLoginDate: [],
    locations: [],
    geoLocations: [],
    locationsReadyToRelocate: [],
    page: 0,
    physicalStatus: [],
    rolePerformed: [],
    salaryMode: [],
    lostJobPast6Months: false,
    activityJobLevel: [],
    isActivelySearchingJob: false,
    educationQualification: [],
    workType: [],
    workingTime: [],
    travelRequirements: '',
    domain: '',
    skills: [],
  },
  searchResult: {
    result: {},
    sucess: false,
  },
  searchResultError: false,
  pageNumber: 0,
  companyId: 0,
  jobList: {
    sucess: false,
    list: [],
  },
  selectedJobData: {},
  contactData: {
    loading: false,
    data: {},
  },
  pricingFireWallCheck: {},
  matchCandidate: {
    loading: false,
    success: false,
  },
  firewallMessage: null,
};

const setConfigData = (draft, responseData) => {
  const {
    lastLoginDate,
    worktype,
    hired,
    empType,
    workingType,
    workDuration,
    experience,
    salaryMode,
    salRange,
    ageRange,
    gender,
    physicalStatus,
    associatedIndustries,
    rolesPerformed,
    skills,
    jobAllowance,
    timeToFillPost,
    distanceKms,
    activityJob,
    educationalQualifications,
    travelRequire,
    locationPrefrence,
    domain,
    sucess,
  } = responseData;

  draft.configData.lastLoginDate = lastLoginDate;
  draft.configData.worktype = worktype;
  draft.configData.hired = hired;
  draft.configData.empType = empType;
  draft.configData.workingType = workingType;
  draft.configData.workDuration = workDuration;
  draft.configData.experience = experience;
  draft.configData.salaryMode = salaryMode;
  draft.configData.salRange = salRange;
  draft.configData.ageRange = ageRange;
  draft.configData.gender = gender;
  draft.configData.physicalStatus = physicalStatus;
  draft.configData.associatedIndustries = associatedIndustries;
  draft.configData.rolesPerformed = rolesPerformed;
  draft.configData.skills = skills;
  draft.configData.jobAllowance = jobAllowance;
  draft.configData.timeToFillPost = timeToFillPost;
  draft.configData.distanceKms = distanceKms;
  draft.configData.activityJob = activityJob;
  draft.configData.educationalQualifications = educationalQualifications;
  draft.configData.travelRequire = travelRequire;
  draft.configData.locationPrefrence = locationPrefrence;
  draft.configData.domain = domain;
  draft.configData.sucess = sucess;
};

const setNearestCity = (draft, location) => {
  const data = new Set(draft.searchPayload.geoLocations);
  data.add(location);
  draft.searchPayload.geoLocations = [...data];
};

const setMatchCandidate = (draft, data) => {
  Object.keys(data).forEach((key) => {
    draft.searchPayload[key] = data[key];
    // if (key === 'industry' || key === 'salaryMode') {
    //   draft.searchPayload[key] = [data[key]];
    // } else if (draft.searchPayload[key]) {
    //   if (key === 'locations') {
    //     draft.searchPayload['geoLocations'] = data[key];
    //   } else {
    //     draft.searchPayload[key] = data[key];
    //   }
    // }
  });
};

/* eslint-disable default-case, no-param-reassign */
const employerCandidateSearchReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CONFIG_DATA:
        const responseConfigData = action.data;
        setConfigData(draft, responseConfigData);
        break;
      case SET_SEARCH_RESULT:
        draft.searchResult.result = action.data.result;
        draft.searchResult.success = action.data.success;
        break;
      case SET_PAYLOAD_DATA:
        draft.searchPayload = action.data;
        break;
      case SET_PAGE_NUMBER_TO_STORE:
        draft.pageNumber = action.pageIndex;
        break;
      case SET_NEAREST_LOCATION:
        setNearestCity(draft, action.location);
        break;
      case SET_COMPANY_ID:
        draft.companyId = action.companyId;
        break;
      case SET_JOB_LIST:
        draft.jobList.list = action.data.list;
        draft.jobList.sucess = action.data.sucess;
        break;
      case SET_CONTACT_DATA:
        draft.contactData = action.contactData;
        break;
      case SELECT_JOB_ID:
        if (action.data) draft.selectedJobData = action.data;
        break;
      case SET_SEARCH_RESULT_ERROR:
        draft.searchResultError = action.data;
        break;
      case SET_PRICING_FIREWALL_CHECK:
        draft.pricingFireWallCheck = action.data;
        break;
      case SET_MANAGE_CANDIDATE:
        setMatchCandidate(draft, action.data);
        break;
      case SET_MANAGE_CANDIDATE_DATA:
        const { loading, success } = action.data;
        draft.matchCandidate = {
          loading,
          success,
        };
        break;
      case RESET_MANAGE_CANDIDATE:
        draft.matchCandidate = {
          loading: false,
          success: false,
        };
        break;
      case SET_FIREWALL_MESSAGE:
        draft.firewallMessage = action.data;
        break;
      default:
        return state;
    }
  });

export default employerCandidateSearchReducer;
