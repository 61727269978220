/*
 *
 * UserCompanyProfileReg actions
 *
 */

import {
  GET_EMPLOYER_LOGO,
  SET_EMPLOYER_LOGO,
  SHOW_CONFIRM_MODAL,
  GOTO_EMPLOYER_PAGE,
  REMOVE_EMPLOYER_LOGO,
  UPLOAD_EMPLOYER_LOGO,
  SET_EMPLOYER_PAGE_DATA,
  LOAD_EMPLOYER_PAGE_DATA,
  FAILED_EMPLOYER_PAGE_DATA,
  UPDATE_EMPLOYER_LOGO_INFO,
  SUBMIT_EMPLOYER_PAGE_FORM,
  SET_EMPLOYER_ADMIN_PROFILE,
} from './constants';

export function removeCompanyLogoAction(docId, docAssociated) {
  return {
    docId,
    docAssociated,
    type: REMOVE_EMPLOYER_LOGO,
  };
}

export function showConfirmModalAction(status) {
  return {
    status,
    type: SHOW_CONFIRM_MODAL,
  };
}

export function getEmployerLogoAction(docId) {
  return {
    docId,
    type: GET_EMPLOYER_LOGO,
  };
}

export function updateEmployerLogoAction(employerLogo) {
  return {
    employerLogo,
    type: SET_EMPLOYER_LOGO,
  };
}

export function uploadEmployerLogoAction(formData, imageUrl) {
  return {
    imageUrl,
    formData,
    type: UPLOAD_EMPLOYER_LOGO,
  };
}

export function updateEmployerLogoInfoAction(profileImage) {
  return {
    profileImage,
    type: UPDATE_EMPLOYER_LOGO_INFO,
  };
}

export function employerPageGoToTab(goTo) {
  return {
    goTo,
    type: GOTO_EMPLOYER_PAGE,
  };
}

export function submitEmployerForm(formData, formStep, imageUrl) {
  return {
    formData,
    formStep,
    imageUrl,
    type: SUBMIT_EMPLOYER_PAGE_FORM,
  };
}

export function loadEmployerPageData() {
  return {
    type: LOAD_EMPLOYER_PAGE_DATA,
  };
}

export function setEmployerFormData(data) {
  return {
    data,
    type: SET_EMPLOYER_PAGE_DATA,
  };
}

export function failedLoadingEmployerPageData(msg) {
  return {
    msg,
    type: FAILED_EMPLOYER_PAGE_DATA,
  };
}

export function setEmployerAdminProfile(data) {
  return {
    data,
    type: SET_EMPLOYER_ADMIN_PROFILE,
  };
}
