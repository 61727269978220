/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react';
import { pathOr } from 'ramda';

function TermsTab({ match }) {
  return (
    <div
      className={
        // eslint-disable-next-line eqeqeq
        pathOr('', ['params', 'slug'], match) == 'terms'
          ? 'tab-pane fade show active'
          : 'tab-pane fade'
      }
      id="map-logo"
      aria-labelledby="info-tab"
    >
      <div className="card">
        <div className="card-header">
          <h4 className="card-title ">Terms and Conditions</h4>
          <p className="card-category">Effective date: February 10, 2021</p>
        </div>
        <div className="card-body">
          <h5 className="content-sub-title">Introduction</h5>
          <p>
            This document has been created as an electronic record in terms with
            the Information Technology Act, 2000, and rules there under, as
            applicable, and any amended provisions pertaining to electronic
            records in various statutes as amended by the Information Technology
            Acts in India or any other Country.
          </p>
          <p>
            In accordance with the provisions of Rule 3(1) of the Information
            Technology (Intermediaries guidelines) Rules, 2011, it is required
            to publish the rules and regulations, Privacy Policy and user
            agreement for access or usage of{' '}
            <a href="#" target="_blank">
              <u>dufther.com</u>
            </a>
            . This document serves this purpose. Since this is an electronic
            record, it does not require any physical or digital signatures.
          </p>
          <p>
            The Portal, "www.dufther.com" (hereinafter referred to as
            "Website"), owned and managed by BLUESPARX TECHNOLOGIES PRIVATE
            LIMITED, a private limited company, incorporated under the Indian
            Companies Act, 1956 and having its registered office at E-109/F-117,
            Nerul Railway station Complex, Nerul West, Navi Mumbai 400706, India
            (hereinafter referred to as "Bluesparx"). All activities associated
            with Dufther.com will be associated and connected with "Bluesparx"
            and Dufther.com wording will mean Bluesparx.
          </p>
          <p>
            Dufther.com is a Job Portal site where candidates can register and
            apply for jobs while the employers (Industrial Companies)/agencies
            (manpower/Human resources) can register, post jobs, post candidates
            (agencies) and hire prospective candidates. We will be operating as
            an Omni-channel platform which will enable access through mobile
            devices, tablets, desktop etc.
          </p>
          <p>
            For any transaction on the website, you shall be subject to the
            policies that are applicable to this Website. By mere use of the
            Website, You will be contracting with Dufther and these terms and
            conditions including the policies constitute your binding
            obligations, with the Website. "You" or "User" shall mean any legal
            person who has accessed the Website by providing Registration Data
            while registering on the Website as a Registered User using the
            computer systems. The terms "We", "Us", "Our" shall mean Dufther.
          </p>
          <p>
            You accept the terms and conditions in full for using our website
            and if you disagree with these terms and conditions or any part of
            these terms and conditions, you must not use our website.
          </p>
          <p>
            We will ask you to explicitly agree to these terms and conditions
            while registering on our site.
          </p>
          <p>
            {' '}
            <b>
              You must be at least 18 years of age for using our website or
              agreeing to these terms and conditions, you confirm that you are
              at least 18 years of age.{' '}
            </b>
          </p>
          <p>
            Our website uses cookies; by using our website or agreeing to these
            terms and conditions, you consent to our use of cookies in
            accordance with the terms of our policies.
          </p>
          <p>
            When you use any of the services provided by us through the Website,
            including but not limited to candidate registration, job postings,
            exhaustive search facilities etc. you will be subject to the rules,
            guidelines, policies, terms, and conditions applicable to such
            service, and they shall be deemed to be incorporated into this Terms
            of Use and shall be considered as part and parcel of this Terms of
            Use. Dufther reserve the right to change, modify, add or remove
            portions of these Terms of Use, at any time without any prior
            written notice to you. It is your responsibility to review these
            Terms of Use periodically for updates / changes. Your continued use
            of the Website following the posting of changes will mean that you
            accept and agree to the revisions.
          </p>
          <h5 className="content-sub-title">Usage</h5>
          <ul>
            <li>
              The main purpose of this website is to facilitate the
              communication between the candidates, Employers and Agencies for
              getting connected and achieve the purpose of fulfilling the
              requirements in terms of Jobs and candidates. You will use this
              site for the objective of only fulfilling your requirements for
              jobs or candidate or both. You will abide by the applicable laws
              and regulations for usage of the information available on our site
              in terms of job information and Candidate details.
            </li>
            <li>
              You may only use our website for employment purpose, and you must
              not use our website for any other purposes.
            </li>
            <li>
              Except as expressly permitted by these terms and conditions, you
              must not edit or otherwise modify any material on our website.
            </li>
            <li>
              Unless you own or control the relevant rights in the material, you
              must not: republish materials from our site, sell, or rent
              materials from our website, publicise material from our website,
              exploit material from our website for a commercial purpose or
              redistribute material from our website.
            </li>
            <li>
              We reserve the right to restrict access to areas of our website,
              or indeed our whole website, at our discretion; you must not
              circumvent or bypass, or attempt to circumvent or bypass, any
              access restriction measures on our website.
            </li>
          </ul>
          <h5 className="content-sub-title">Registration</h5>
          <p>
            For using our website effectively it is required for you to register
            on the site. It will be our sole discretion to Permit or deny your
            registration. Once permitted you can access the site using your
            registered mobile number and the OTP. You will be responsible for
            managing the confidentiality of your OTP and you agree to receive
            OTP and required notifications on your registered mobile number. You
            agree that the registered mobile number can be associated with
            multiple roles while registering on www.dufther.com. You may change
            your registered mobile number on our site. Dufther.com will not be
            responsible for any issues that may occur incase the registered
            mobile is no longer associated with your profile (lost your mobile,
            change of mobile number etc.).
          </p>
          <p>
            <b>
              You shall be responsible for the accuracy of the registration
              details provided by you.
            </b>{' '}
            You shall be responsible for maintaining the confidentiality of
            display name and mobile number and you shall be responsible for all
            activities that occur under your Display Name and mobile number. You
            agree that if You provide any information that is untrue,
            inaccurate, not current or incomplete or We have reasonable grounds
            to suspect that such information is untrue, inaccurate, not current
            or incomplete, or not in accordance with the this Terms of Use, We
            shall have the right to indefinitely suspend or terminate or block
            access of your membership on the Website and refuse to provide You
            with access to the Website. Such act on your part can accrue legal
            implications due to violation of laws not limited to criminal
            liability on your part. Further, the user agrees to indemnify
            Dufther for all losses incurred by it due to any false, distorted,
            manipulated, defamatory, libelous, vulgar, obscene, fraudulent or
            misleading facts or otherwise objectionable averments made by the
            user on the network of Dufther.
          </p>
          <p>
            If you are registering as a Recruiter, Placement/Manpower Consultant
            and Manpower/ Placement Agency, you represent that you are duly
            authorized to register and by accepting these Terms of Use you bind
            such underlying business entity to these Terms of Use. Any data of
            your usage may be retained or deleted at Dufther's sole discretion.
            You agree that the User Content uploaded can be used and viewed by
            other third parties who access the Website.
          </p>
          <p>
            By using our Services and providing us with your contact
            information, you agree that we can send you communications about our
            services and job / employment related matters / alerts. By using our
            services or by filling out any forms/agreements on or through the
            services, providing us with information or making an inquiry, you
            acknowledge that we have an established business relationship with
            you and you expressly consent to being contacted by us or our
            service providers, whether by phone, mobile phone, text (SMS),
            email, postal mail or otherwise. From time to time, when you call us
            we may wish to record those phone calls for quality assurance and
            customer's / user's satisfaction purposes. In those jurisdictions
            where consent is required in order for a party to record any
            telephone call, you consent for us to record your call when you call
            us.
          </p>
          <p>
            All User Content will be used and processed in accordance with these
            Terms of Use and the Privacy Policy. You agree that you have read
            and understood the Privacy Policy. All the documentation mentioned
            in our Privacy policy are accepted by you after agreeing to the
            Terms and condition of this document.
          </p>
          <p>
            You may register for an account with our website by submitting the
            account registration form on our website.
          </p>
          <p>
            You must not allow any other person to use your account to access
            the website. You must notify us in writing immediately if you become
            aware of any unauthorised use of your account. You must not use any
            other person's account to access the website, unless you have that
            person's express permission to do so.
          </p>
          <p>
            We may suspend your account, cancel your account; and/or edit your
            account details at any time in our sole discretion without notice or
            explanation. Alternately you may also cancel your account on our
            website using your account control panel on the website.
          </p>
          <h5 className="content-sub-title">Acceptable Use</h5>
          <p>You must not:</p>
          <ul>
            <li>
              Use our website in any way or take any action that causes, or may
              cause, damage to the website or impairment of the performance,
              availability or accessibility of the website;
            </li>
            <li>
              Use our website in any way that is unlawful, illegal, fraudulent
              or harmful, or in connection with any unlawful, illegal,
              fraudulent or harmful purpose or activity;
            </li>
            <li>
              Use our website to copy, store, host, transmit, send, use, publish
              or distribute any material which consists of (or is linked to) any
              spyware, computer virus, Trojan horse, worm, keystroke logger,
              rootkit or other malicious computer software;
            </li>
            <li>
              conduct any systematic or automated data collection activities
              (including without limitation scraping, data mining, data
              extraction and data harvesting) on or [in relation to our website
              without our express written consent];
            </li>
            <li>
              Access or otherwise interact with our website using any robot,
              spider or other automated means [ except for the purpose of
              [search engine indexing]];
            </li>
            <li>
              You must ensure that all the information you supply to us through
              our website, or in relation to our website, is true, accurate,
              current, complete and non-misleading.
            </li>
          </ul>
          <h5 className="content-sub-title">Content: Rules</h5>
          <ul>
            <li>
              You warrant and represent that your content will comply with these
              terms and conditions.
            </li>
            <li>
              Your content must not be illegal or unlawful, must not infringe
              any person's legal rights, and must not be capable of giving rise
              to legal action against any person (in each case in any
              jurisdiction and under any applicable law).
            </li>
            <li>
              Your content, and the use of your content by us in accordance with
              these terms and conditions, must not:
            </li>
            <ul>
              <li>
                be libellous or maliciously false; be obscene or indecent;
                infringe any copyright, moral right, database right, trade mark
                right, design right, right in passing off, or other intellectual
                property right; infringe any right of confidence, right of
                privacy or right under data protection legislation; constitute
                negligent advice or contain any negligent statement;
              </li>
              <li>
                constitute an incitement to commit a crime, instructions for the
                commission of a crime or the promotion of criminal activity;
              </li>
              <li>
                be in contempt of any court, or in breach of any court order;
              </li>
              <li>
                be in breach of racial or religious hatred or discrimination
                legislation;
              </li>
              <li>be blasphemous;</li>
              <li>be in breach of official secrets legislation;</li>
              <li>
                be in breach of any contractual obligation owed to any person;
              </li>
              <li>
                depict violence in an explicit, graphic or gratuitous manner;
              </li>
              <li>be pornographic, lewd, suggestive or sexually explicit;</li>
              <li>be untrue, false, inaccurate or misleading;</li>
              <li>
                consist of or contain any instructions, advice or other
                information which may be acted upon and could, if acted upon,
                cause illness, injury or death, or any other loss or damage;
              </li>
              <li>constitute spam;</li>
              <li>
                be offensive, deceptive, fraudulent, threatening, abusive,
                harassing, anti-social, menacing, hateful, discriminatory or
                inflammatory; or cause annoyance, inconvenience or needless
                anxiety to any person.
              </li>
            </ul>
            <p className="li-warrent">We do not warrant or represent:</p>
            <li>
              the completeness or accuracy of the information published on our
              website;
            </li>
            <li>that the material on the website is up to date; or</li>
            <li>
              that the website or any service on the website will remain
              available.
            </li>
          </ul>
          <p>
            We reserve the right to discontinue or alter any or all of our
            website services, and to stop publishing our website, at any time in
            our sole discretion without notice or explanation; and save to the
            extent expressly provided otherwise in these terms and conditions,
            you will not be entitled to any compensation or other payment upon
            the discontinuance or alteration of any website services, or if we
            stop publishing the website.
          </p>
          <h5 className="content-sub-title">Obligation</h5>
          <p>
            The Accuracy of the information given at the time of registration
            will be the sole responsibility of the candidate, employer or
            agencies who are using the site (hereby termed as "User" or "Users")
          </p>
          <ul>
            <li>
              The User has all the Prerequisites/Licenses/approvals for feeding
              the information on the site. Any claims or damages which arises
              out of the information put on the site will not be the
              responsibility of Dufther or it's affiliated companies. The user
              agrees to keep Dufther indemenified against any demands, damages,
              or claims arising out of the information put on dufther.
            </li>
            <li>
              Any permissions, consents, approvals and intellectual property or
              any other rights as required for using the information on our site
              shall be obtained by the User at his own cost and reputation.
            </li>
            <li>
              You will agree and comply to all the notices or instructions given
              by Dufther from time to time to enable the use of the Service.
            </li>
            <li>
              For any payments, the User understands and agrees that User is
              responsible for the payment of all applicable taxes and for all
              costs that are incurred in using the Website's service.
            </li>
            <li>
              For all information stored, retrieved, and transmitted through
              Dufther, user will be the responsible for the content,
            </li>
            <li>
              The User understands and agrees that he/she shall at all times
              comply with the requirements of Information Technology Act 2000 as
              well as all rules, regulations, guidelines, bye laws and
              notifications made thereunder.
            </li>
            <li>
              The User hereby explicitly agrees that Dufther has performed and
              complied with the requirements of Information Technology Act 2000
              as well as all rules, regulations, guidelines, bye laws and
              notifications made thereunder.
            </li>
            <li>
              The User shall immediately notify Dufther incase of any
              un-authorized usage of the User's account or any other breach of
              security.
            </li>
            <li>
              The Usage of the OTP for the site shall be kept confidential by
              user and will not be disclosed to any other person. The user
              identification and all activities and transmission performed by
              the User will be through his user credentials only.
            </li>
            <li>
              The User shall promptly make the payment when it is due for the
              various services on the site.
            </li>
            <li>
              The user will Indemnify Dufther for any instance where the user
              finds his information being used by any third party sites or
              services either in the online mode or in the offline mode/any
              action or claim committed/made by any third party resulting from
              any information posted on the site by the user or/and anybody else
              on his behalf.
            </li>
            <li>
              For communication and additional functionalities on the website,
              we will be using third party software tools and linkages. This
              will/may result in your data to be accessed by Third party vendors
              and by accepting to this Terms and Conditions, you agree to share
              your data as required for the software to serve the
              functionalities required.
            </li>
          </ul>
          <h5 className="content-sub-title">Prohibited actions</h5>
          <ul>
            <li>
              Users are not authorized to resell or assign his/her rights or
              obligations to another third party.
            </li>
            <li>
              The site and it's services will be used only for the purpose for
              which the site has been created.
            </li>
            <li>
              The User agrees to adhere and comply with all applicable laws of
              India relating to the Services, including any regulation made
              pursuant thereto.
            </li>
            <li>
              User is prohibited from uploading multiple resumes of the same
              User/Individual using same or different accounts.
            </li>
            <li>
              Dufther reserves all rights to remove, edit the content of the
              users.
            </li>
            <li>
              The User shall not share the Service with any person without the
              prior written approval of the Dufther.
            </li>
            <li>
              The services will not be used by the users for any unlawful or
              criminal activities.
            </li>
            <li>
              The User shall not infringe on any intellectual property rights of
              any individual/organization or/and retain information in any form.
            </li>
          </ul>
          <h5 className="content-sub-title">
            Limitations and exclusions of liability
          </h5>
          <p>
            Nothing in these terms and conditions will govern any liabilities
            arising under these terms and conditions or relating to the subject
            matter of these terms and conditions, including liabilities arising
            in contract, in tort (including negligence) and for breach of
            statutory duty, except to the extent expressly provided otherwise in
            these terms and conditions.
          </p>
          <p>
            To the extent that our website and for the information and services
            on our website we will not be liable for any loss or damage of any
            nature.
          </p>
          <p>
            We will not be liable to you in respect of any losses arising out of
            any event or events beyond our reasonable control.
          </p>
          <p>
            We will not be liable to you in respect of any business losses,
            including (without limitation) loss of or damage to profits, income,
            revenue, use, production, anticipated savings, business, contracts,
            commercial opportunities or goodwill.
          </p>
          <p>
            We will not be liable to you in respect of any loss or corruption of
            any data, database or software.
          </p>
          <p>
            We will not be liable to you in respect of any special, indirect or
            consequential loss or damage.
          </p>
          <p>
            You accept that we have an interest in limiting the personal
            liability of our officers and employees and, having regard to that
            interest; you agree that you will not bring any claim personally
            against our officers or employees in respect of any losses you
            suffer in connection with the website or these terms and conditions.
          </p>
          <h5 className="content-sub-title">
            Breaches of these terms and conditions
          </h5>
          <p>
            Without prejudice to our other rights under these terms and
            conditions, if you breach these terms and conditions in any way, or
            if we reasonably suspect that you have breached these terms and
            conditions in any way, we may:
          </p>
          <ol>
            <li>Send you one or more formal warnings;</li>
            <li>Temporarily suspend your access to our website;</li>
            <li>Permanently prohibit you from accessing our website;</li>
            <li>
              Block computers using your IP address from accessing our website;
            </li>
            <li>
              Contact any or all of your internet service providers and request
              that they block your access to our website;
            </li>
            <li>
              Commence legal action against you, whether for breach of contract
              or otherwise; and/or
            </li>
            <li>Suspend or delete your account on our website.</li>
          </ol>
          <p>
            Where we suspend or prohibit or block your access to our website or
            a part of our website, you must not take any action to circumvent
            such suspension or prohibition or blocking [(including without
            limitation [creating and/or using a different account])].
          </p>
          <h5 className="content-sub-title">Variation</h5>
          <p>We may revise these terms and conditions from time to time.</p>
          <p>
            The revised terms and conditions shall apply to the use of our
            website from the date of publication of the revised terms and
            conditions on the website, and you hereby waive any right you may
            otherwise have to be notified of, or [to consent to, revisions of
            these terms and conditions.] OR [We will give you written notice of
            any revision of these terms and conditions, and the revised terms
            and conditions will apply to the use of our website from the date
            that we give you such notice; if you do not agree to the revised
            terms and conditions, you must stop using our website.]
          </p>
          <p>
            If you have given your express agreement to these terms and
            conditions, we will ask for your express agreement to any revision
            of these terms and conditions; and if you do not give your express
            agreement to the revised terms and conditions within such period as
            we may specify, we will disable or delete your account on the
            website, and you must stop using the website.
          </p>
          <h5 className="content-sub-title">Assignment</h5>
          <p>
            You hereby agree that we may assign, transfer, sub-contract or
            otherwise deal with our rights and/or obligations under these terms
            and conditions.
          </p>
          <p>
            You may not without our prior written consent assign, transfer,
            sub-contract or otherwise deal with any of your rights and/or
            obligations under these terms and conditions.
          </p>
          <h5 className="content-sub-title">Third party rights</h5>
          <p>
            A contract under these terms and conditions is for our benefit and
            your benefit, and is not intended to benefit or be enforceable by
            any third party.
          </p>
          <p>
            The exercise of the parties' rights under a contract under these
            terms and conditions is not subject to the consent of any third
            party.
          </p>
          <h5 className="content-sub-title">Entire agreement</h5>
          <p>
            These terms and conditions together with our privacy and cookies
            policy shall constitute the entire agreement between you and us in
            relation to your use of our website and shall supersede all previous
            agreements between you and us in relation to your use of our
            website.
          </p>
          <h5 className="content-sub-title">Law and jurisdiction</h5>
          <p>
            Terms of Use shall be governed by and interpreted and construed in
            accordance with the laws of India. The place of jurisdiction shall
            be exclusively in Navi Mumbai.
          </p>
          <h5 className="content-sub-title">
            Trademark, copyrights and Restriction
          </h5>
          <p>
            All material on this site, including images, illustrations, audio
            clips, and video clips, are protected by copyrights, trademarks, and
            other intellectual property rights.
          </p>
          <p>
            Material on Website is solely for your personal, non- commercial
            use. You must not copy, reproduce, republish, upload, post, transmit
            or distribute such material in any way, including by email or other
            electronic means and whether directly or indirectly and you must not
            assist any other person to do so.
          </p>
          <p>
            Without the prior written consent of the owner, modification of the
            materials, use of the materials on any other website or networked
            computer environment or use of the materials for any purpose other
            than personal, non- commercial use is a violation of the copyrights,
            trademarks and other proprietary rights, and is prohibited. Any use
            for which you receive any remuneration, whether in money or
            otherwise, is a commercial use for the purposes of this clause.
          </p>
          <h5 className="content-sub-title">Our details</h5>
          <p>
            This website is owned and operated by BLUESPARX TECHNOLOGIES PRIVATE
            LIMITED. We are registered as a Private Limited Entity wth Company
            CIN as "U72900Mh3020PTC340744" and you can find the online version
            of the register at{' '}
            <a
              href="http://mca.gov.in/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>mca.gov.in</u>
            </a>
            .
          </p>
          <p>
            We are also registered as a startup entity in India and are subject
            to governing rules which can be found at{' '}
            <a
              href=" https://www.startupindia.gov.in/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>startupindia.gov.in</u>
            </a>
            .
          </p>
          <p>
            You can contact us at{' '}
            <span className="mail-id-support">support@dufther.com</span> for any
            queries on our Terms and Conditions.
          </p>
        </div>
      </div>
    </div>
  );
}

export default memo(TermsTab);
