import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as gService from '../../utility/GlobalServices';

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const authenticated = gService.checkIsLoggedIn();

  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        authenticated ? (
          <Layout>
            <Component {...rest} {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
