/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import GearLoader from '../../components/LoadingIndicator/GearLoading';
import EmployerSearch from '../../components/common/EmployerSearchBox';
import EmployerDashboardSidebar from './sidebar/employerDashboardSidebar';
import EmployerDashboardHeader from '../common/dashboardHeader/dashboardHeader';
import EmployerDashboardFooter from '../common/dashboardFooter/dashboardFooter';
import CDTranslator from '../common/translator/FrontTranslator';
import makeSelectGlobalStore from 'containers/App/selectors';
import { Breadcrumb } from '../candidateDashboardLayout/Breadcrumb';
import { getValidityInDays } from 'utility/GlobalServices';
import * as gConst from '../../utility/GlobalConstants';
import * as gActions from 'containers/App/actions';
import { createStructuredSelector } from 'reselect';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import history from 'utils/history';
import { isUsernamePhoneNumber } from '../../utils/helper';
import '../css/dashboard-layout.css';
import './employer-dashboard.css';
import makeSelectEmployerDashboard from 'containers/EmployerDashboard/selectors';
import VerifyProfile from 'layouts/common/dashboardHeader/VerifyProfile';
import ReactSlidingPane from 'react-sliding-pane';
import makeSelectEmployerPricingModel from 'containers/EmployerPricingModel/selectors';
import moment from 'moment';

const EmployerDashboardLayout = (props) => {
  const {
    addEmailHandler,
    addMobileHandler,
    globalStore,
    employerDashboardStore,
    uploadVerificationDocs,
    getVerificationDocs,
    deleteDoc,
    empPricingDataStore,
  } = props;
  const { userRoleData, dashboardRole, userData } = globalStore || {};
  const { associatedInstituteCodes, email, userName } = userData || {};
  const { companyDetail } = employerDashboardStore || {};
  const { basicDetail } = companyDetail || {};
  const { verificationStatus, companyId } = basicDetail || {};
  const [verifyPanel, setVerifyPanel] = useState(false);
  const verificationDocs = pathOr('', ['verificationDocs'], globalStore);
  const { empPricingModelData } = empPricingDataStore || {};
  const endDate = pathOr('', ['candidatePlan', 'endDate'], empPricingModelData);
  const type = pathOr('FREE', ['candidatePlan', 'type'], empPricingModelData);
  const profiles = pathOr(
    0,
    ['candidatePlan', 'profiles'],
    empPricingModelData
  );
  const validity = pathOr(
    null,
    ['candidatePlan', 'validity'],
    empPricingModelData
  );

  const isMob = useMediaQuery({ query: '(max-width: 667px)' });
  const isTab = useMediaQuery({ query: '(max-width: 1024px)' });

  const params = useLocation();

  const [webSideMenu, setWebSideMenu] = useState(false);
  const [mobileSideMenu, setMobileSideMenu] = useState(false);
  const [mobileDropDownMenu, setMobileDropDownMenu] = useState(false);

  const contractEndDate = pathOr(
    null,
    ['userRoleData', 'contractEndDate'],
    globalStore
  );
  const status = pathOr(null, ['userRoleData', 'status'], globalStore);
  const roles = pathOr([], ['userRoleData', 'user', 'roles'], globalStore);

  const [isContractExpired, setIsContractExpired] = useState(false);

  const excludedPathsForEmployerSearch = [
    '/edashboard/empanelment-institute-requests-management',
  ];

  useEffect(() => {
    if (
      (contractEndDate && getValidityInDays(contractEndDate) <= 0) ||
      status === 'INACTIVE'
    ) {
      setIsContractExpired(true);
    }
    if (companyId) {
      getVerificationDocs(companyId);
    }
  }, [contractEndDate, status, companyId]);

  const handleBuyPlanEvent = () => {
    history.push('/edashboard/commercials/emp-plan');
  };

  const getValidity = (date) => {
    if (date) {
      const end = moment(date);
      const current = moment(new Date()).format('YYYY-MM-DD');
      const start = moment(current);
      return end.diff(start, 'days') + 1;
    }
    return '';
  };

  const renderTranslateBanner = () => (
    <>
      <div className="candidate-dashboard employer-dashboard">
        <GearLoader />
        <div className="container-fluid language-container">
          <div className="row d-flex align-items-center justify-content-center">
            {(!userData?.createdBy ||
              userData?.createdById === userData?.id) && (
              <div className="col-sm-4 col-md-4 search-candidates">
                <a
                  href="#"
                  className="togglebox-top"
                  onClick={(e) => {
                    e.preventDefault();
                    setMobileDropDownMenu(!mobileDropDownMenu);
                  }}
                >
                  <span className="pl-emp-search search-text-top">Search</span>
                  <i
                    className="fa fa-search"
                    aria-hidden="true"
                    style={{ color: '#fff' }}
                  ></i>
                </a>
              </div>
            )}
            <div className="col-sm-8 col-md-8 col-lg-12 text-right">
              <ul className="list-unstyled">
                <li>
                  <div id="ytWidget">
                    <CDTranslator />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {isMob && isContractExpired && (
        <div
          className="container-fluid my-2 pt-1"
          style={{ backgroundColor: '#f3f4f5' }}
        >
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <Button className="btn btn-danger" onClick={handleBuyPlanEvent}>
                Buy Plan
              </Button>
            </div>
          </div>
        </div>
      )}
      {isMob && !userRoleData?.user?.email && (
        <div className="container-fluid">
          <div className="row mx-auto">
            <Button
              className="mt-3 btn btn-block"
              style={{ background: '#f7931e' }}
              onClick={() => addEmailHandler(true)}
            >
              Add Email
            </Button>
          </div>
        </div>
      )}
      {isMob &&
        !userRoleData?.user?.phoneNumber &&
        !isUsernamePhoneNumber(userRoleData?.user?.userName) && (
          <div className="container-fluid">
            <div className="row mx-auto">
              <Button
                className="mt-3 btn btn-block"
                style={{ background: '#f7931e' }}
                onClick={() => addMobileHandler(true)}
              >
                Add Mobile
              </Button>
            </div>
          </div>
        )}
      {isMob &&
        roles?.includes('Employer') &&
        (!verificationStatus ||
          verificationStatus === 'PENDING' ||
          verificationStatus === 'REJECTED') && (
          <div className="container-fluid mt-2">
            <div className="row mx-auto">
              <Button
                className="btn btn-info btn-block"
                type="button"
                onClick={() => setVerifyPanel(true)}
              >
                <span className="emp-post-job-header">VERIFY PROFILE</span>{' '}
                &nbsp;
              </Button>
            </div>
          </div>
        )}
    </>
  );

  const renderBuyPlan = () => {
    if(
      (type === 'PAY_AS_USE' && !validity && profiles <= 0) ||
        (type !== 'PAY_AS_USE' && !validity) ||
        (type !== 'PAY_AS_USE' && !profiles) ||
        (type !== 'PAY_AS_USE' && getValidity(endDate) <= 0)) {
          return (
            <div className="container-fluid mt-2">
              <div className="row mx-auto">
                <Button
                  className="btn btn-warning btn-block"
                  onClick={handleBuyPlanEvent}
                >
                  Buy Plan
                </Button>
              </div>
            </div>
          );
        }
  }

  const showMenuHandler = () => {
    setWebSideMenu(!webSideMenu);
  };

  const showMobileMenuHandler = () => {
    setMobileSideMenu(!mobileSideMenu);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (associatedInstituteCodes?.length > 0 && !email) {
      addEmailHandler(true);
    }
    if (userName === '' || userName === null) {
      addMobileHandler(true);
    }
  }, [associatedInstituteCodes, email, userName]);

  return (
    <div className={`${!webSideMenu ? 'sidebar-mini' : ''}`}>
      <div className="wrapper candidate-dashboard">
        <EmployerDashboardSidebar
          showSideMenu={webSideMenu}
          showMobileMenu={mobileSideMenu}
          toggleMobileSideMenu={setMobileSideMenu}
          toggleWebSideMenu={setWebSideMenu}
        />
        <div className={`main-panel `}>
          <EmployerDashboardHeader
            showSideMenu={webSideMenu}
            showMobileMenu={mobileSideMenu}
            showMenuHandler={showMenuHandler}
            showMobileMenuHandler={showMobileMenuHandler}
            isEmployer={true}
            currentRoute={'Employer'}
            setVerifyPanel={setVerifyPanel}
          />
          {renderTranslateBanner()}
          {isMob && renderBuyPlan()}
          {!excludedPathsForEmployerSearch.includes(params.pathname) &&
            (!userData?.createdByRole ||
              userData?.createdByRole === 'Self') && (
              <EmployerSearch mobileDropDownMenu={mobileDropDownMenu} />
            )}
          <div className="content">
            <Breadcrumb
              {...props}
              params={params}
              parentLinkAddress={`/edashboard`}
              parentLinkName="Dashboard"
              childLinkName={gConst.pageNameByPath(
                params?.pathname,
                dashboardRole
              )}
              childLinkAddress={params?.pathname}
              subChildLinkName={
                gConst.pageNameByPath(params?.pathname) === 'Pricing Sheet'
                  ? 'Pricing Plan'
                  : null
              }
              subChildLinkAddress={
                gConst.pageNameByPath(params?.pathname) === 'Pricing Sheet'
                  ? '/edashboard/emp-plan'
                  : null
              }
            />
            {props.children}
          </div>
          <EmployerDashboardFooter />
          <div
            className={`${mobileSideMenu ? 'close-layer visible' : ''}`}
            onClick={showMobileMenuHandler}
          ></div>
        </div>
      </div>
      <ReactSlidingPane
        overlayClassName={'slidingPaneOverlay'}
        isOpen={verifyPanel}
        width={isMob || isTab ? '100%' : '60%'}
        closeIcon={
          isMob && (
            <button type="button" className="slider-pane-close-button">
              Close
            </button>
          )
        }
        onRequestClose={() => {
          setVerifyPanel(false);
        }}
      >
        <VerifyProfile
          onClose={setVerifyPanel}
          {...props}
          uploadVerificationDocs={uploadVerificationDocs}
          companyId={companyId}
          verificationDocs={verificationDocs}
          deleteDoc={deleteDoc}
          verificationStatus={verificationStatus}
        />
      </ReactSlidingPane>
    </div>
  );
};

EmployerDashboardLayout.propTypes = {
  children: PropTypes.any,
  addEmailHandler: PropTypes.func,
  addMobileHandler: PropTypes.func,
  globalStore: PropTypes.object,
};

// select data from redux store
const mapStateToProps = createStructuredSelector({
  globalStore: makeSelectGlobalStore(),
  employerDashboardStore: makeSelectEmployerDashboard(),
  empPricingDataStore: makeSelectEmployerPricingModel(),
});

// dispatch actions
const mapDispatchToProps = (dispatch) => ({
  addEmailHandler: (e) => dispatch(gActions.addEmailModalStatus(e)),
  addMobileHandler: (e) => dispatch(gActions.addMobileModalStatus(e)),
  getVerificationDocs: (id) => dispatch(gActions.getVerificationDocs(id)),
  uploadVerificationDocs: (id, data, formData, type) =>
    dispatch(gActions.uploadVerificationDocs(id, data, formData, type)),
  deleteDoc: (id, type) => dispatch(gActions.deleteVerificationDoc(id, type)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EmployerDashboardLayout
);
