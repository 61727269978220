import React from 'react';

export default function useOnBackHistory() {
  const [isBack, setIsBack] = React.useState(false);
  const handleEvent = (e) => {
    setIsBack(true);
    window.history.pushState({}, '');
  };

  React.useEffect(() => {
    window.addEventListener("popstate", handleEvent);
    return () => window.removeEventListener("popstate", handleEvent);
  });

  return isBack;
}