import { useForm } from 'react-hook-form';
import * as pattern from '../common/Patterns';
import React, { useState, memo } from 'react';
import { allowOnlyNums } from 'components/common/FormFields/formValidations';

import './setpin.css';
import Input from 'components/common/Input';

function SetPin({ onSubmit }) {
  const { register, handleSubmit, errors, control } = useForm({
    mode: 'all',
  });
  const [btnDisabled, setbtnDisabled] = useState(true);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container-login set-pin">
        <div className="set-pin-wrap-login">
          <div className="form-info flex-sb flex-w">
            <div className="set_pin_new_container">
              <div className="txt_header_pin_cnp">Set PIN</div>
            </div>
            <div className="form-group mt-3">
              {/* <OtpInputbox
                required
                rules={{
                  required: true,
                  pattern: {
                    value: pattern.onlyNumber,
                    message: 'Invalid PIN provided',
                  },
                  maxLength: 4,
                  minLength: 4,
                }}
                autoFocus
                errors={errors}
                defaultValue={null}
                control={control}
                onKeyPress={allowOnlyNums}
                name="pin"
                label="Create Login PIN"
                numInputs={4}
                onlyNumber
                handleChange={(event) => {
                  if (event?.length >= 4) {
                    setbtnDisabled(false);
                  } else {
                    setbtnDisabled(true);
                  }
                }}
              /> */}
              <div className="otp-input-outer-container">
                <div className="otp-input-inner-container">
                  <Input
                    inputMode="numeric"
                    title="Non-negative integral number"
                    pattern="[0-9]*"
                    required
                    ref={register({
                      required: true,
                      pattern: pattern.onlyNumber,
                    })}
                    onKeyPress={allowOnlyNums}
                    errors={errors}
                    type="password"
                    maxLength={4}
                    minLength={4}
                    id="enter-new-pin"
                    className="pin-input"
                    name="pin"
                    label="Create Login PIN"
                    wrapperClass="mt-3"
                    autoFocus
                    onChange={(event) => {
                      if (event.target.value.length >= 4) {
                        setbtnDisabled(false);
                        handleSubmit(onSubmit)();
                      } else {
                        setbtnDisabled(true);
                      }
                    }}
                  />
                </div>
              </div>
              <span className="hint-text font-italic">
                Enter a 4 digit number
              </span>
              <div className="remember-text">
                <span className="future-hint-text">
                  Please remember this PIN for future Logins
                </span>
              </div>
            </div>
            <div className="mt-4 mb-4">
              <button
                type="submit"
                disabled={btnDisabled ? 'disabled' : ''}
                className={btnDisabled ? 'login_btn disabled' : 'login_btn'}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default memo(SetPin);
