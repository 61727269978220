/**
 *
 * Asynchronously loads the component for UserSignup
 *
 */

import React from 'react';
import loadable from '../../utils/loadable';
import LoadingIndicator from '../../components/LoadingIndicator';

export default loadable(() => import('./index'), {
  fallback: (
    <div className='container-fluid pl-0 pr-0 pt-0 pb-0 bg-color center-content'>
      <div className='allbox'>
        <div className='first_limiter pt-5'>
          <div className='container-login'>
            <div className='wrap-login_new_use'>
              <LoadingIndicator />
            </div>
          </div>
        </div>
      </div>
    </div>
  ),
});
