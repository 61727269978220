import React from 'react';
import { Modal } from 'react-bootstrap';

function PopupModal(props) {
  const {
    modalStatus,
    setModalStatus,
    setLocalModalStatus,
    className,
    size,
    headerChildren,
    children,
    classNameBody,
    modalName,
    noModalHeader = false,
    centered = false,
    zIndexValue = 9999,
  } = props;

  const modalStatusHandler = () => {
    setModalStatus && setModalStatus(modalName, false);
    setLocalModalStatus && setLocalModalStatus(false);
  };

  return (
    <Modal
      show={modalStatus}
      onHide={modalStatusHandler}
      backdrop="static"
      keyboard={false}
      onRequestHide={modalStatusHandler}
      className={className}
      size={size || 'md'}
      centered={centered}
      style={{ zIndex: zIndexValue }}
    >
      {!noModalHeader && (
        <Modal.Header closeButton>{headerChildren}</Modal.Header>
      )}
      <Modal.Body className={classNameBody}>{children}</Modal.Body>
    </Modal>
  );
}

export default PopupModal;
