/*
 *
 * CdChangeMobile actions
 *
 */

import {
  GET_CONFIG_DATA,
  SET_CONFIG_DATA,
  SEARCH_CANDIDATE,
  SET_SEARCH_RESULT,
  SET_SEARCH_RESULT_ERROR,
  SET_PAYLOAD_DATA,
  SET_NOTIFICATION_ON,
  SET_NOTIFICATION_OFF,
  SAVE_JOB,
  DELETE_JOB,
  SET_PAGE_NUMBER_TO_STORE,
  APPLY_JOB,
  GET_NEAREST_LOCATION,
  SET_NEAREST_LOCATION,
  GET_COMPANY_ID,
  SET_COMPANY_ID,
  GET_JOB_LIST,
  SET_JOB_LIST,
  GET_CONTACT_DATA,
  SET_CONTACT_DATA,
  SEND_CANDIDATE_INVITE,
  SELECT_JOB_ID,
  DOWNLOAD_RESUME,
  GET_PRICING_FIREWALL_CHECK,
  SET_PRICING_FIREWALL_CHECK,
  GET_MANAGE_CANDIDATE,
  SET_MANAGE_CANDIDATE,
  RESET_MANAGE_CANDIDATE,
  SET_MANAGE_CANDIDATE_DATA,
  SET_FIREWALL_MESSAGE,
  CANDIDATE_FRANCHISEE_SHORTLISTING,
} from './constants';

export function loadConfigData() {
  return {
    type: GET_CONFIG_DATA,
  };
}

export function setConfigData(data) {
  return {
    data,
    type: SET_CONFIG_DATA,
  };
}

export function searchCandidate(payload, history) {
  return {
    payload,
    history,
    type: SEARCH_CANDIDATE,
  };
}

export function setSearchResult(data) {
  return {
    data,
    type: SET_SEARCH_RESULT,
  };
}

export function setPayloadData(data) {
  return {
    data,
    type: SET_PAYLOAD_DATA,
  };
}

export function setNotificationON(data) {
  return {
    data,
    type: SET_NOTIFICATION_ON,
  };
}

export function setNotificationOFF(data) {
  return {
    data,
    type: SET_NOTIFICATION_OFF,
  };
}

export function saveJob(data) {
  return {
    data,
    type: SAVE_JOB,
  };
}

export function deleteJob(data) {
  return {
    data,
    type: DELETE_JOB,
  };
}

export function setPageNumber(pageIndex) {
  return {
    pageIndex,
    type: SET_PAGE_NUMBER_TO_STORE,
  };
}

export function applyJob(id, mode) {
  return {
    id,
    mode,
    type: APPLY_JOB,
  };
}

export function getNearestLocation(distance, latitude, longitude) {
  return {
    distance,
    latitude,
    longitude,
    type: GET_NEAREST_LOCATION,
  };
}

export function setNearestLocation(location) {
  return {
    location,
    type: SET_NEAREST_LOCATION,
  };
}

export function getCompanyId() {
  return {
    type: GET_COMPANY_ID,
  };
}

export function setCompanyId(companyId) {
  return {
    companyId,
    type: SET_COMPANY_ID,
  };
}

export function getJobList(companyId) {
  return {
    companyId,
    type: GET_JOB_LIST,
  };
}

export function setJobList(data) {
  return {
    data,
    type: SET_JOB_LIST,
  };
}

export function getContactData(candidateId) {
  return {
    candidateId,
    type: GET_CONTACT_DATA,
  };
}

export function setContactData(contactData) {
  return {
    contactData,
    type: SET_CONTACT_DATA,
  };
}

export function sendCandidateInvite(data, selectionType) {
  return {
    data,
    selectionType,
    type: SEND_CANDIDATE_INVITE,
  };
}

export function selectJobId(data) {
  return {
    data,
    type: SELECT_JOB_ID,
  };
}

export function downloadResume(data) {
  return {
    data,
    type: DOWNLOAD_RESUME,
  };
}

export function setSearchResultError(data) {
  return {
    data,
    type: SET_SEARCH_RESULT_ERROR,
  };
}

export const getPricingFirewallCheck = (data) => ({
  type: GET_PRICING_FIREWALL_CHECK,
  data,
});

export const setPricingFirewallCheck = (data) => ({
  type: SET_PRICING_FIREWALL_CHECK,
  data,
});

export const getCandidateMatch = (jobId) => ({
  jobId,
  type: GET_MANAGE_CANDIDATE,
});

export const setCandidateMatch = (data) => ({
  data,
  type: SET_MANAGE_CANDIDATE,
});

export const resetCandidateMatch = () => ({
  type: RESET_MANAGE_CANDIDATE,
});

export const setCandidateMatchData = (data) => ({
  data,
  type: SET_MANAGE_CANDIDATE_DATA,
});

export const setFirewallMessage = (data) => ({
  data,
  type: SET_FIREWALL_MESSAGE,
});

export const candidateFranchiseeShortListing = (data, flag) => ({
  data,
  flag,
  type: CANDIDATE_FRANCHISEE_SHORTLISTING,
});
