/*
 *
 * CandidateDashboard reducer
 *
 */
import produce from 'immer';
import { pathOr } from 'ramda';
import {
  LOAD_DASHBOARD_DATA,
  SET_DASHBOARD_DATA,
  SET_CAT_NOTIFICATION_COUNT,
  AVAILABLE_IMMEDIATELY_FORM_RESPONSE,
  SUBMIT_ACTIVELY_SEARCHING_FORM,
  ACTIVELY_SEARCHING_RESPONSE,
  RESET_NOTICE_PERIOD,
  NOTICE_PERIOD_FORM_RESPONSE,
  SET_CANDIDATE_IMG_URL,
  DELETE_CANDIDATE_AVATAR_SUCCESS,
  DOMAIN_DROPDOWN,
  SET_DOMAIN_DROPDOWN,
  GET_DOMAIN_BASED_SUBDATA,
  DOMAIN_BASED_SUBDATA,
  GET_SPECIALIZED_SUBDATA,
  SPECIALIZED_SUBDATA,
  SUBMIT_DOMAIN_FORM,
  SET_CONSENT_MODAL,
  SET_VERIFY_CANDIDATE,
  SET_ACADEMIC_EDUCATION_DATA,
} from './constants';

export const initialState = {
  chinaVirusStatus: null,
  applicationStatusCount: {},
  employerActionCount: {},
  candidateAvailability: {},
  activelySearchingJob: false,
  availableImmediatelyFormData: {},
  applicationCount: 0,
  followedCompanyCount: 0,
  followedRecruiterCount: 0,
  profileScore: 0,
  savedJobCount: 0,
  updatedJobCount: 0,
  matchingJobCount: 0,
  categoriesNotificationCount: {
    CD_APPLICATION_STATUS_NS: { count: 0, totalCount: 0 },
    CD_APPLICATION_STATUS_SH: { count: 0, totalCount: 0 },
    CD_APPLICATION_STATUS_ST: { count: 0, totalCount: 0 },
    CD_APPLICATION_STATUS_UR: { count: 0, totalCount: 0 },
    CD_EMPLOYER_ACTION_CONTACT: { count: 0, totalCount: 0 },
    CD_EMPLOYER_ACTION_DOWNLOAD: { count: 0, totalCount: 0 },
    CD_EMPLOYER_ACTION_SHORTLIST: { count: 0, totalCount: 0 },
    CD_EMPLOYER_ACTION_VISIT: { count: 0, totalCount: 0 },
    CD_JOB_APPLIED: { count: 0, totalCount: 0 },
    CD_JOB_CHANGE: { count: 0, totalCount: 0 },
    CD_JOB_INVITE: { count: 0, totalCount: 0 },
    CD_JOB_NEW: { count: 0, totalCount: 0 },
    CD_PROFILE_CHANGE: { count: 0, totalCount: 0 },
  },
  activelySearchJob: false,
  success: false,
  candidateImageUrl: '',
  profileImage: {},
  highestQualification: {},
  academicVerification: null,
  educationalArea: [],
  engSpecialization: [],
  dipSpecialization: [],
  itiSpecialization: [],
  medSpecialization: [],
  domainData: [],
  domainSelected: null,
  domainSubData: [],
  subDataSelected: null,
  specializedData: [],
  workDetails: {},
  adharVerified: null,
  consentResponse: [],
  consentModalStatus: false,
  isCandidateVerified: null,
};

const updateCategoryNotification = (draft, payload) => {
  if (payload?.category) {
    draft.categoriesNotificationCount[payload?.category].count =
      payload?.unReadCount;
  }
};

const setNotificationCountForDashboard = (draft, payload) => {
  const newPayload = Object.keys(payload);
  newPayload.length &&
    newPayload.map((key) => {
      if (draft.categoriesNotificationCount[key])
        draft.categoriesNotificationCount[key].count = payload[key];
    });
};

/* eslint-disable default-case, no-param-reassign */
const candidateDashboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_DASHBOARD_DATA:
        break;
      case SET_DASHBOARD_DATA:
        draft.progressLeft = action.data.progressMark;
        draft.workDetails = action.data.workDetails;
        draft.highestQualification = action.data.highestQualification;
        draft.educationalArea = action.data.educationalArea;
        draft.engSpecialization = action.data.engSpecialization;
        draft.dipSpecialization = action.data.dipSpecialization;
        draft.itiSpecialization = action.data.itiSpecialization;
        draft.medSpecialization = action.data.medSpecialization;
        draft.consentResponse = action.data.consentResponse;
        draft.academicVerification = action.data.eduResponse.academicVerification;
        const payload = action.data.config;
        draft.chinaVirusStatus = pathOr(null, ['lostJobPast6Months'], payload);
        draft.applicationStatusCount = pathOr(
          {},
          ['applicationStatusCount'],
          payload
        );
        draft.employerActionCount = pathOr(
          {},
          ['employerActionCount'],
          payload
        );
        draft.candidateAvailability = pathOr(
          {},
          ['candidateAvailability'],
          payload
        );
        draft.activelySearchingJob = pathOr(
          false,
          ['activelySearchingJob'],
          payload
        );
        draft.applicationCount = pathOr(0, ['applicationCount'], payload);
        draft.followedCompanyCount = pathOr(
          0,
          ['followedCompanyCount'],
          payload
        );
        draft.followedRecruiterCount = pathOr(
          0,
          ['followedRecruiterCount'],
          payload
        );
        draft.profileScore = pathOr(0, ['profileScore'], payload);
        draft.savedJobCount = pathOr(0, ['savedJobCount'], payload);
        setNotificationCountForDashboard(
          draft,
          pathOr(0, ['unreadNotificationCount'], payload)
        );
        draft.updatedJobCount = payload.updatedJobCount;
        draft.matchingJobCount = payload.matchingJobCount;
        draft.success = true;
        draft.profileImage = pathOr(0, ['profileImage'], payload);
        draft.adharVerified = pathOr(null, ['adharVerified'], payload);
        draft.jobSharedByInstituteCount = pathOr(0, ['jobSharedByInstitute'], payload);
        draft.totalApplicationsCount = pathOr(0, ['totalApplications'], payload);
        draft.totalApplicationsInterviewStageCount = pathOr(0, ['totalApplicationsInterviewStage'], payload);
        draft.totalApplicationsOfferEvaluationCount = pathOr(0, ['totalApplicationsOfferEvaluation'], payload);
        draft.totalApplicationsOfferEvaluationOfferNotAcceptedCount = pathOr(0, ['totalApplicationsOfferEvaluationOfferNotAccepted'], payload);
        break;
      case SET_CAT_NOTIFICATION_COUNT:
        updateCategoryNotification(draft, action.payload);
        break;
      case AVAILABLE_IMMEDIATELY_FORM_RESPONSE:
        const data = action.data;
        draft.candidateAvailability.isAvailableImmediately =
          data.isAvailableImmediately;
        break;
      case SUBMIT_ACTIVELY_SEARCHING_FORM:
        draft.activelySearchingFormData = action.data;
        break;
      case ACTIVELY_SEARCHING_RESPONSE:
        draft.activelySearchingResponse = action.res;
        break;
      case NOTICE_PERIOD_FORM_RESPONSE:
        draft.candidateAvailability = action.res;
        break;
      case RESET_NOTICE_PERIOD:
        draft.candidateAvailability = {
          ...draft.candidateAvailability,
          noticePeriodDays: 0,
        };
        break;
      case SET_CANDIDATE_IMG_URL:
        draft.candidateImageUrl = action.data;
        break;
      case DELETE_CANDIDATE_AVATAR_SUCCESS:
        draft.candidateImageUrl = action.data;
        draft.profileImage = {};
        break;
      case DOMAIN_DROPDOWN:
        break;
      case SET_DOMAIN_DROPDOWN:
        draft.domainData = action.data;
        break;
      case GET_DOMAIN_BASED_SUBDATA:
        draft.domainSelected = action.data;
        break;
      case DOMAIN_BASED_SUBDATA:
        draft.domainSubData = action.data;
        break;
      case GET_SPECIALIZED_SUBDATA:
        draft.subDataSelected = action.data;
        break;
      case SPECIALIZED_SUBDATA:
        draft.specializedData = action.data;
        break;
      case SET_VERIFY_CANDIDATE:
        draft.isCandidateVerified = action.data;
        break;
      case SUBMIT_DOMAIN_FORM:
        draft.progressLeft = null;
        break;
      case SET_CONSENT_MODAL:
        draft.consentModalStatus = action.status;
        break;
      case SET_ACADEMIC_EDUCATION_DATA:
        draft.academicVerification = action.data.academicVerification;
        break;
    }
  });

export default candidateDashboardReducer;
