/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import ScrollUpButton from 'react-scroll-up-button';
// import rcbImg from '../../images/rcb.png';
import LandingFooter from './footer/LandingFooter';
import FrontTranslator from '../common/translator/FrontTranslator';
import PredashboardHeader from '../common/predashboardHeader/PredashboardHeader';

import './landing-layout.css';

function LandingLayout(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  return (
    <div className="landing-page-wrapper">
      <div className="landing-page-header">
        <PredashboardHeader />
        {/* <FrontTranslator /> */}
      </div>
      <div className="landing-page-content">{props.children}</div>
      <LandingFooter />
      {/* <ScrollUpButton
        StopPosition={0}
        ShowAtPosition={250}
        EasingType="easeOutCubic"
        AnimationDuration={1000}
        ContainerClassName="scrollTopButtonContainer"
        TransitionClassName="scrollTopButtonTransition"
      >
        <button type="button" title="Go to top">
          Top
        </button>
      </ScrollUpButton> */}
    </div>
  );
}

LandingLayout.propTypes = {
  children: PropTypes.any,
};

export default LandingLayout;
