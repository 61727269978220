/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useLocation } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { useForm, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { pathOr } from 'ramda';

import { displayNotification } from '../../../config/toastConfig';

import * as gService from '../../../utility/GlobalServices';
import {
  // formatOptions,
  convertSelectOpt,
  // convertMultiSelectOpt,
} from '../../../utils/form';

// import AsyncMulti from '../AsyncSelect';
import Select from '../Select';
import * as actions from '../../../containers/EmployerSearch/actions';
import makeSelectCandidateProfileReg from '../../../containers/EmployerSearch/selectors';
import './cd-job-search.css';
// import PopupModal from '../PopupModal';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useInjectReducer } from '../../../utils/injectReducer';
import employerSearchReducer from '../../../containers/EmployerSearch/reducer';
import employerSearchSaga from '../../../containers/EmployerSearch/saga';
import SelectGoogleLocation from '../SelectGoogleLocation';

// const getCurrentLocation = async (evt, getAutoDetectedLocation) => {
//   evt.preventDefault();
//   await navigator.geolocation.getCurrentPosition((position) => {
//     getAutoDetectedLocation(10, position.coords.latitude, position.coords.longitude);
//   });
// };

const getCurrentLocation = async (evt, getAutoDetectedLocation) => {
  evt.preventDefault();
  navigator.geolocation.getCurrentPosition((position) => {
    gService.getGeocodeByLatLng(
      position.coords.latitude,
      position.coords.longitude,
      getAutoDetectedLocation
    );
  });
  // gService.getGeocodeByLatLng(26.9124, 75.7873, getAutoDetectedLocation);

  // }, (err) => {
  //   console.log("error in fetching current location", err)
  // }, {
  //   enableHighAccuracy: true,
  //   maximumAge: Infinity,
  //   timeout: 10000
  // });
};

const locationChangeHandler = (
  locationsData,
  setPayloadData,
  searchPayload
) => {
  const newPayload = {
    ...searchPayload,
    geolocations: locationsData.length
      ? locationsData.map((item) => item.value)
      : [],
  };
  setPayloadData(newPayload);
};

const renderLocation = (errors, control, watchLocations) => (
  <SelectGoogleLocation
    errors={errors}
    control={control}
    isMulti
    name="geoLocations"
    rules={{ required: false }}
    label=""
    value={watchLocations}
    maxLimit={5}
    placeholder="Enter Location(s) for Candidates"
  />
);

// const renderLocation = (
//   errors,
//   control,
//   watchLocations,
//   locationsData,
//   setPayloadData,
//   searchPayload
// ) => (
//   <AsyncMulti
//     errors={errors}
//     control={control}
//     isMulti
//     // name="locations"
//     name="geoLocations"
//     rules={{ required: false }}
//     label=""
//     value={watchLocations}
//     maxLimit={5}
//     placeholder="Enter Location(s) for Candidates"
//     defaultValue={convertMultiSelectOpt(locationsData)}
//     changeHandler={(e) =>
//       locationChangeHandler(e, setPayloadData, searchPayload)
//     }
//     loadOptions={(val) => {
//       if (val && val.length > 2) {
//         return getLocation(val).then((data) => formatOptions(data));
//       }
//       return new Promise((resolve) => []);
//     }}
//   />
// );

const renderMobileSearch = (
  register,
  errors,
  control,
  keywords,
  locationsData,
  getAutoDetectedLocation,
  watchSearchKey,
  watchLocations
) => (
  <div className="row d-flex">
    <div className="col-lg-12">
      <form action="#">
        <div id="collapsesearch" className="">
          <div className="col-lg-4 pt-2 pb-2 pr-0 pl-0 search-select-header">
            {renderLocation(errors, control, watchLocations)}
            <a
              href="#"
              className="location-img-search-mob"
              onClick={(evt) => {
                evt.preventDefault();
                getCurrentLocation(evt, getAutoDetectedLocation);
              }}
            >
              <i className="fa fa-crosshairs" aria-hidden="true"></i>
            </a>
          </div>

          <div className="col-lg-6 pb-2 pr-0 pl-0">
            <Select
              isMulti
              isCreateable
              placeholder="Enter Keyword(s) eg. Industry, Role, Skillset etc."
              errors={errors}
              control={control}
              value={watchSearchKey}
              name="searchKey"
              rules={{ required: false }}
              defaultValue={convertSelectOpt(keywords)}
              wrapperClass="searchForm mr-2"
            />
          </div>

          <div className="col-lg-2 pr-0 pl-0">
            <button type="search" className="btn btn-primary width-max-sm mb-0">
              SEARCH
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
);

const getLocation = (e) => {
  return gService.fetchNewLocations(e);
};

const keyWordChangeHandler = (keywordsData, setPayloadData, searchPayload) => {
  if (keywordsData) {
    const newPayload = {
      ...searchPayload,
      keywords: keywordsData.length
        ? keywordsData.map((item) => item.value)
        : [],
    };
    setPayloadData(newPayload);
  }
};

const renderWebSearch = (
  register,
  errors,
  control,
  keywords,
  locationsData,
  getAutoDetectedLocation,
  watchSearchKey,
  watchLocations,
  searchPayload,
  setPayloadData
) => {
  return (
    <div className="row web-view-search">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-4 pr-2 search-select-header">
            {renderLocation(
              errors,
              control,
              watchLocations,
              locationsData,
              setPayloadData,
              searchPayload
            )}
            <a
              href="#"
              className="location-img-search"
              onClick={(evt) => {
                evt.preventDefault();
                getCurrentLocation(evt, getAutoDetectedLocation);
              }}
            >
              <i className="fa fa-crosshairs" aria-hidden="true"></i>
            </a>
          </div>
          <div className="col-lg-8 pr-dis-search-btn">
            <Select
              isMulti
              isCreateable
              placeholder="Enter Keyword(s) eg. Industry, Role, Skillset etc."
              errors={errors}
              control={control}
              name="searchKey"
              value={watchSearchKey}
              rules={{ required: false }}
              defaultValue={convertSelectOpt(keywords)}
              // handleChange={(e) =>
              //   keyWordChangeHandler(e, setPayloadData, searchPayload)
              // }
              wrapperClass="searchForm mr-2"
            />
            <div>
              <button
                type="submit"
                style={{ width: '88px' }}
                className="btn btn-fill btn-info"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EmployerJobSearch = (props) => {
  useInjectReducer({
    key: 'empCandidateSearch',
    reducer: employerSearchReducer,
  });
  useInjectSaga({ key: 'empCandidateSearch', saga: employerSearchSaga });
  const params = useLocation();
  const {
    mobileDropDownMenu,
    candidateBasicJobSearchStore,
    searchCandidate,
    history,
    getCompanyId,
    getJobList,
    selectJobId,
    setPayloadData,
    setPageNumber,
  } = props;
  const { searchPayload, jobList, companyId, selectedJobData } =
    candidateBasicJobSearchStore;
  // search location based on user input
  const keywords = pathOr([], ['keywords'], searchPayload);
  // const locations = pathOr([], ['locations'], searchPayload);
  const geoLocations = pathOr([], ['geoLocations'], searchPayload);
  const {
    register,
    control,
    setValue,
    handleSubmit,
    errors,
    formState: { isDirty },
    reset,
  } = useForm();

  const getLocationFroGeo = (geoLocations) => {
    if (!geoLocations || geoLocations.length == 0) {
      return [];
    }
    const fmtLocs = geoLocations.map((loc) => {
      return { label: loc.label, value: loc.city };
    });
    return fmtLocs;
  };

  const watchSearchKey = useWatch({
    control,
    name: 'searchKey',
    defaultValue: convertSelectOpt(keywords),
  });

  const watchLocations = useWatch({
    control,
    name: 'geoLocations',
  });
  // get coordinates

  useEffect(() => {
    if (
      params.pathname !== '/edashboard/emp-candidate-search' &&
      params.pathname !== '/fdashboard/emp-candidate-search'
    ) {
      const newPayload = {
        ...searchPayload,
        page: 0,
        keywords: [],
        geoLocations: [],
      };
      setPayloadData(newPayload);
    }
  }, [params.pathname]);

  useEffect(() => {
    !jobList.sucess && companyId && getJobList(companyId);
  }, [companyId]);

  useEffect(() => {
    // preventing franchisee user to get company if from this actions as it is taken from global store
    if (!params.pathname?.includes('/fdashboard')) {
      !companyId && getCompanyId();
    }
  }, [companyId]);

  useEffect(() => {
    !keywords.length && setValue('searchKey', '');
    !geoLocations.length && setValue('geoLocations', '');
    // geoLocations.length &&
    //   setValue('geoLocations', convertSelectOpt(geoLocations));
  }, [keywords]);

  const getAutoDetectedLocation = (geoLoc) => {
    setValue('geoLocations', [geoLoc]);
  };

  const beforeSubmit = (data) => {
    const searchKeys = pathOr([], ['searchKey'], data);
    // const locations = pathOr([], ['locations'], data);
    // if (searchKeys.length || locations.length) {
    const geoLocations = pathOr([], ['geoLocations'], data);
    if (searchKeys.length || geoLocations.length) {
      let newPayload = {};
      if (jobList.list.length) {
        const selectedJobId = pathOr('', ['value'], selectedJobData);
        newPayload = {
          ...searchPayload,
          keywords: searchKeys.length
            ? searchKeys.map((item) => item.value)
            : [],
          // locations: locations.length
          //   ? locations.map((item) => item.value)
          //   : [],
          geoLocations:
            geoLocations.length > 0
              ? geoLocations.map((locObj) => {
                  if (typeof locObj.value === 'string') {
                    return locObj;
                  }
                  return {
                    place_id: locObj.value.place_id,
                    label: locObj.value.description,
                    city: locObj.value.structured_formatting.main_text,
                  };
                })
              : [],
          page: 0,
          jobId: selectedJobId,
        };
        const newJobData = {
          value: jobList.list[0].jobId,
          label: jobList.list[0].title,
        };

        !Object.keys(selectedJobData).length
          ? selectJobId(newJobData)
          : selectJobId(selectedJobData);
      } else {
        newPayload = {
          ...searchPayload,
          page: 0,
          keywords: searchKeys.length
            ? searchKeys.map((item) => item.value)
            : [],
          // locations: locations.length
          //   ? locations.map((item) => item.value)
          //   : [],
          geoLocations:
            geoLocations.length > 0
              ? geoLocations.map((locObj) => {
                  if (typeof locObj.value === 'string') {
                    return locObj;
                  }
                  return {
                    place_id: locObj.value.place_id,
                    label: locObj.value.description,
                    city: locObj.value.structured_formatting.main_text,
                  };
                })
              : [],
        };
      }
      setPageNumber(0);
      searchCandidate(newPayload, history);
    } else {
      displayNotification(
        'Please enter atleast one search criteria for Location or Keyword',
        'warning'
      );
    }
  };

  return (
    <Fragment>
      <div className="container-fluid cd-jobsearch">
        <form onSubmit={handleSubmit(beforeSubmit)}>
          {renderWebSearch(
            register,
            errors,
            control,
            keywords,
            geoLocations,
            getAutoDetectedLocation,
            watchSearchKey,
            watchLocations,
            searchPayload,
            setPayloadData
          )}
          <div className="mobile-view-search">
            {mobileDropDownMenu &&
              renderMobileSearch(
                register,
                errors,
                control,
                keywords,
                geoLocations,
                getAutoDetectedLocation,
                watchSearchKey,
                watchLocations
              )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EmployerJobSearch.propTypes = {
  change: PropTypes.func,
  candidateBasicJobSearchStore: PropTypes.object,
  searchCandidate: PropTypes.func,
};

// dispatch state
const mapStateToProps = createStructuredSelector({
  candidateBasicJobSearchStore: makeSelectCandidateProfileReg(),
});

// dispatch actions
const mapDispatchToProps = (dispatch) => ({
  searchCandidate: (data, history) => {
    dispatch(actions.searchCandidate(data, history));
  },
  getNearestLocation: (distance, latitude, longitude) => {
    dispatch(actions.getNearestLocation(distance, latitude, longitude));
  },
  getCompanyId: () => {
    dispatch(actions.getCompanyId());
  },
  getJobList: (companyId) => {
    dispatch(actions.getJobList(companyId));
  },
  selectJobId: (data) => {
    dispatch(actions.selectJobId(data));
  },
  setPayloadData: (data) => {
    dispatch(actions.setPayloadData(data));
  },
  setPageNumber: (data) => {
    dispatch(actions.setPageNumber(data));
  },
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withRouter, withConnect, memo)(EmployerJobSearch);
