import React, { memo, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import SetPin from '../../components/SetPin';
import * as gService from '../../utility/GlobalServices';
import * as actions from './actions';

export function SetPinScreen({ onSubmit }) {
  useInjectReducer({ key: 'setPin', reducer });
  useInjectSaga({ key: 'setPin', saga });
  // if user authorized
  React.useEffect(() => {
    gService.isAuthenticated();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>SetPinScreen</title>
        <meta name="description" content="Description of SetPinScreen" />
      </Helmet>
      <SetPin onSubmit={onSubmit} />
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (e) => dispatch(actions.submitSetPinForm(e)),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(SetPinScreen);
