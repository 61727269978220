import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the manage job post state domain
 */

const selectEmployerPricingModel = (state) =>
  state.employerPricingModel || initialState;

/**
 * Other specific selectors
 */

const makeSelectEmployerPricingModel = () =>
  createSelector(selectEmployerPricingModel, (substate) => substate);

export default makeSelectEmployerPricingModel;
export { selectEmployerPricingModel };
