/* eslint-disable react/jsx-no-undef */

import React, { Fragment, memo } from 'react';
// import AboutUsTab from './AboutUsTab';
import CokiesPolicyTab from './CokiesPolicyTab';
import RefundPolicyTab from './RefundPolicyTab';
import ContactUsTab from './ContactUsTab';
import NavigationMenu from './NavigationMenu';
import PrivacyPolicyTab from './PrivacyPolicyTab';
import TermsTab from './TermsTab';
import './employer-user-agreement.css';

function UserAgreement(props) {
  const { match } = props;

  const pageName = match.params.slug;
  return (
    <Fragment>
      <NavigationMenu {...props} />
      <div className="content user-agreement">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pl-0 pr-0">
              <div className="tab-content">
                {/* {pageName === 'about' && <AboutUsTab {...props} />} */}
                {pageName === 'terms' && <TermsTab {...props} />}
                {pageName === 'privacy' && <PrivacyPolicyTab {...props} />}
                {pageName === 'cookie' && <CokiesPolicyTab {...props} />}
                {pageName === 'Refund' && <RefundPolicyTab {...props} />}
                {pageName === 'contact' && <ContactUsTab {...props} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default memo(UserAgreement);
