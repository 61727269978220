import React, { memo, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'components/common/Input';
import PopupModal from 'components/common/PopupModal';
import * as pattern from 'components/common/Patterns';
import { allowOnlyNums } from 'components/common/FormFields/formValidations';
import 'components/CandidateViewProfileDetails/candidate-view-profile-details.css';
import { displayNotification } from 'config/toastConfig';
import { Button } from 'react-bootstrap';

const AddEmailToAccount = (props) => {
  const {
    globalStore,
    addEmailHandler,
    submitEmailForOtp,
    submitEmailChange,
    resetOtpFieldValue,
    isCorporate,
    isCorporateEmail,
    centered = false,
    onOK,
  } = props;
  const {
    emailModalStatus,
    emailIsSent,
    resetOtpField,
    userData,
    otpResponse,
  } = {
    ...globalStore,
  };
  const [resendCount, setResendCount] = useState(0);
  const [timer, setTimer] = useState(true);
  const [displayMsg, setDisplayMsg] = useState(null);

  const { associatedInstituteCodes } = userData || {};
  const otpRef = useRef(null);

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    setError,
    getValues,
  } = useForm({
    mode: 'all',
  });

  React.useEffect(() => {
    if (emailIsSent && otpRef && otpRef.current) {
      otpRef.current.focus();
    }
  }, [emailIsSent]);

  React.useEffect(() => {
    if (resetOtpField && otpRef && otpRef.current) {
      setValue('code', undefined);
      otpRef.current.focus();
      setTimeout(() => {
        resetOtpFieldValue(null);
      }, 500);
    }
  }, [resetOtpField, resetOtpFieldValue, setValue]);

  useEffect(() => {
    setDisplayMsg(
      'OTP has been sent, please wait for 2 minutes if you wish to generate a new OTP.'
    );
    setTimer(true);
    const timer = setTimeout(() => {
      setTimer(false);
    }, 120000);
    return () => clearTimeout(timer);
  }, [otpResponse]);

  const emailOtpHandler = () => {
    const { email } = watch();
    if (!email) {
      return setError('email', {
        message: 'This field is mandatory',
      });
    }
    if (isCorporate && isCorporateEmail(email)) {
      return setError('email', {
        message: 'Please enter a Corporate Email ID',
      });
    }
    submitEmailForOtp({ email });
  };

  const onCancel = () => {
    displayNotification(
      'Email is not added. Kindly fill the details as soon as possible!',
      'danger'
    );
    addEmailHandler(false);
  };

  const handleOtpChange = (e) => {
    if (e.target.value.length >= 6) {
      const { email } = watch();
      const formData = {
        email,
        code: e.target.value,
      };
      submitEmailChange(formData);
    }
  };

  const onSubmit = (data) => {
    submitEmailChange(data);
  };

  const setOtpAndLimit = () => {
    if (resendCount < 6) {
      emailOtpHandler();
      setResendCount((resendCount) => resendCount + 1);
    }
  };

  const resendOtpEventHandler = (evt) => {
    evt.preventDefault();
    if (resendCount < 7 && !timer) {
      setOtpAndLimit();
      otpRef.current.focus();
    }
  };

  return (
    <PopupModal
      setLocalModalStatus={addEmailHandler}
      modalStatus={emailModalStatus}
      noModalHeader
      modalName="basicModal"
      headerChildren="Add E-Mail Address"
      className="welcome-modal"
      centered={centered}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6 style={{ color: 'grey', textTransform: 'none' }} className="pb-3">
          Add E-Mail Address
        </h6>

        <Input
          required
          name="email"
          type="email"
          label="Email ID"
          errors={errors}
          ref={register({
            required: true,
            pattern: {
              value: pattern.emailValid,
              message: 'Please enter valid email',
            },
            maxLength: {
              value: 50,
              message: 'Should be less than 50 characters',
            },
          })}
          placeholder="Enter Your Email ID"
        />

        {emailIsSent && (
          <>
            <div className="otp-input-outer-container">
              <div className="otp-input-inner-container">
                <Input
                  inputMode="numeric"
                  title="Non-negative integral number"
                  pattern="[0-9]*"
                  required
                  ref={(e) => {
                    register(e, {
                      required: true,
                      maxLength: 6,
                      pattern: pattern.onlyNumber,
                      minLength: 6,
                    });
                    otpRef.current = e;
                  }}
                  onKeyPress={allowOnlyNums}
                  errors={errors}
                  type="number"
                  className="otp-input"
                  name="code"
                  maxLength={6}
                  label="Enter OTP"
                  // helptext={<small>Enter OTP Recieved on E-Mail</small>}
                  helptextClass="text-secondary"
                  handleChange={handleOtpChange}
                />
              </div>
            </div>
            <span className="error_class">{displayMsg}</span>

            <p className="font-size-12">
              {emailIsSent}
              <Button
                variant="link"
                className={resendCount >= 6 || timer ? 'disabled' : ''}
                onClick={resendOtpEventHandler}
              >
                <span className={'text-info text-capitalize'}>
                  <u>Resend OTP</u>
                </span>
              </Button>
            </p>
          </>
        )}

        <div className="modal-footer1">
          {!emailIsSent ? (
            <button
              type="button"
              className="btn btn-info mb-1"
              data-dismiss="modal"
              onClick={emailOtpHandler}
            >
              Verify
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-info mb-1"
              data-dismiss="modal"
            >
              Submit
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger ml-3"
            data-dismiss="modal"
            onClick={() =>
              associatedInstituteCodes?.length > 0
                ? onCancel()
                : isCorporate
                ? onOK()
                : addEmailHandler(false)
            }
          >
            Cancel
          </button>
        </div>
      </form>
    </PopupModal>
  );
};

export default memo(AddEmailToAccount);
