import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css';

export const displayNotification = (
  message,
  type = 'success',
  delay,
  closeButton
) => {
  toast.configure({
    autoClose: delay || 5000,
    draggable: false,
    hideProgressBar: false,
    newestOnTop: true,
    closeButton: closeButton || true,
    position: toast.POSITION.BOTTOM_RIGHT,
    transition: Slide,
  });
  if (type === 'warning') {
    toast.warning(message);
  } else if (type === 'danger') {
    toast.error(message);
  } else if (type === 'info') {
    toast.info(message);
  } else {
    toast.success(message);
  }
};
