/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Button } from 'react-bootstrap';
import { pathOr } from 'ramda';
import { useMediaQuery } from 'react-responsive';

import { Breadcrumb } from '../candidateDashboardLayout/Breadcrumb';
import GearLoader from '../../components/LoadingIndicator/GearLoading';
import EmployerSearch from '../../components/common/EmployerSearchBox';
import FranchiseDashboardSidebar from './sidebar/FranchiseDashboardSidebar';
import FranchiseDashboardHeader from '../common/dashboardHeader/dashboardHeader';
import makeSelectGlobalStore from 'containers/App/selectors';
import CDTranslator from '../common/translator/FrontTranslator';
import FranchiseDashboardFooter from '../common/dashboardFooter/dashboardFooter';

import * as gConst from '../../utility/GlobalConstants';
import { isUsernamePhoneNumber } from '../../utils/helper';
import {
  addEmailModalStatus,
  addMobileModalStatus,
} from 'containers/App/actions';
import { getValidityInDays } from 'utility/GlobalServices';
import { scrollToTop } from 'utils/helper';

import '../css/dashboard-layout.css';
import './franchise-dashboard.css';

const FranchiseDashboardLayout = (props) => {
  const { addEmailHandler, addMobileHandler, globalStore } = props;
  const { userRoleData, dashboardRole } = globalStore || {};
  const isMob = useMediaQuery({ query: '(max-width: 667px)' });

  useEffect(() => {
    scrollToTop();
  }, []);

  const params = useLocation();
  const history = useHistory();

  const [webSideMenu, setWebSideMenu] = useState(false);
  const [mobileSideMenu, setMobileSideMenu] = useState(false);
  const [mobileDropDownMenu, setMobileDropDownMenu] = useState(false);
  const [searchStatus, setSearchStatus] = useState(false);
  const toggleSearchMenu = () => (setSearchStatus(!searchStatus));
  const closeSearchMenu = () => (setSearchStatus(false));

  const contractEndDate = pathOr(
    null,
    ['userRoleData', 'contractEndDate'],
    globalStore
  );
  const status = pathOr(null, ['userRoleData', 'status'], globalStore);

  const [isContractExpired, setIsContractExpired] = useState(false);
  useEffect(() => {
    if (
      (contractEndDate && getValidityInDays(contractEndDate) <= 0) ||
      status === 'INACTIVE'
    ) {
      setIsContractExpired(true);
    }
  }, [contractEndDate, status]);

  const handleBuyPlanEvent = () => {
    history.push('/fdashboard/franchisee-plan');
  };

  const renderTranslateBanner = () => (
    <>
      <div className="candidate-dashboard franchise-dashboard">
        <GearLoader />
        <div className="container-fluid language-container">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-sm-4 col-md-4 search-candidates">
              <a
                href="#"
                className="togglebox-top"
                onClick={(e) => {
                  e.preventDefault();
                  setMobileDropDownMenu(!mobileDropDownMenu);
                  toggleSearchMenu();
                }}
              >
                <span className="pl-emp-search search-text-top">Search</span>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#fff' }}
                ></i>
              </a>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-12 text-right">
              <ul className="list-unstyled">
                <li>
                  <div id="ytWidget">
                    <CDTranslator />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {isMob && !isContractExpired && (
        <div
          className="container-fluid my-2 pt-1"
          style={{ backgroundColor: '#f3f4f5' }}
        >
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <Button
                className="mt-3 btn btn-block btn btn-danger"
                onClick={handleBuyPlanEvent}
              >
                Buy Plan
              </Button>
            </div>
          </div>
        </div>
      )}
      {isMob && !userRoleData?.user?.email && (
        <div className="container-fluid">
          <div className="row mx-auto">
            <Button
              className="mt-3 btn btn-block"
              style={{ background: '#f7931e' }}
              onClick={() => addEmailHandler(true)}
            >
              Add Email
            </Button>
          </div>
        </div>
      )}
      {isMob &&
        !userRoleData?.user?.phonenumber &&
        !isUsernamePhoneNumber(userRoleData?.user?.userName) && (
          <div className="container-fluid">
            <div className="row mx-auto">
              <Button
                className="mt-3 btn btn-block"
                style={{ background: '#f7931e' }}
                onClick={() => addMobileHandler(true)}
              >
                Add Mobile
              </Button>
            </div>
          </div>
        )}
    </>
  );

  const showMenuHandler = () => {
    setWebSideMenu(!webSideMenu);
  };

  const showMobileMenuHandler = () => {
    setMobileSideMenu(!mobileSideMenu);
  };

  return (
    <div className={`${!webSideMenu ? 'sidebar-mini' : ''}`}>
      <div className="wrapper candidate-dashboard">
        <FranchiseDashboardSidebar
          showSideMenu={webSideMenu}
          showMobileMenu={mobileSideMenu}
          toggleMobileSideMenu={setMobileSideMenu}
          toggleWebSideMenu={setWebSideMenu}
          toggleSearchMenu={toggleSearchMenu}
          closeSearchMenu={closeSearchMenu}
        />
        <div className={`main-panel `}>
          <FranchiseDashboardHeader
            showSideMenu={webSideMenu}
            showMobileMenu={mobileSideMenu}
            showMenuHandler={showMenuHandler}
            showMobileMenuHandler={showMobileMenuHandler}
            isEmployer={true}
            currentRoute={'Franchisee'}
          />
          {renderTranslateBanner()}
          {searchStatus && (
            <EmployerSearch mobileDropDownMenu={mobileDropDownMenu} />
          )}
          <div className="content">
            <Breadcrumb
              {...props}
              params={params}
              parentLinkAddress={`/fdashboard`}
              parentLinkName="Dashboard"
              childLinkName={gConst.pageNameByPath(
                params?.pathname,
                dashboardRole
              )}
              childLinkAddress={params?.pathname}
              subChildLinkName={
                gConst.pageNameByPath(params?.pathname) === 'Pricing Sheet'
                  ? 'Pricing Plan'
                  : null
              }
              subChildLinkAddress={
                gConst.pageNameByPath(params?.pathname) === 'Pricing Sheet'
                  ? '/fdashboard/franchisee-plan'
                  : null
              }
            />
            {props.children}
          </div>
          <FranchiseDashboardFooter />
          <div
            className={`${mobileSideMenu ? 'close-layer visible' : ''}`}
            onClick={showMobileMenuHandler}
          ></div>
        </div>
      </div>
    </div>
  );
};

FranchiseDashboardLayout.propTypes = {
  children: PropTypes.any,
  addEmailHandler: PropTypes.func.isRequired,
  addMobileHandler: PropTypes.func.isRequired,
  globalStore: PropTypes.object.isRequired,
};

// Select data from redux store
const mapStateToProps = createStructuredSelector({
  globalStore: makeSelectGlobalStore(),
});

// Dispatch actions
const mapDispatchToProps = (dispatch) => ({
  addEmailHandler: (e) => dispatch(addEmailModalStatus(e)),
  addEmailHandler: (e) => dispatch(addMobileModalStatus(e)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  React.memo
)(FranchiseDashboardLayout);
