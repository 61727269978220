import React, { useEffect, useState } from 'react';
import './style.css';

const InstituteNameDropdown = ({ globalStore }) => {
  const institutes =
    globalStore?.userRoleData?.instituteName ||
    globalStore?.userRoleData?.company?.companyName;

  const [currentInstitute, setCurrentInstitute] = useState(null);
  useEffect(() => {
    if (institutes) {
      const defaultData = {
        value: institutes,
        label: institutes,
      };
      setCurrentInstitute(defaultData);
    }
  }, [institutes]);

  if (!currentInstitute || institutes === 'Individual Franchisee') {
    return <></>;
  }

  return (
    <>
      <input
        value={institutes}
        className="institute-title text-info"
        disabled
      />
    </>
  );
};

export default InstituteNameDropdown;
