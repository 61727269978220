import React, { useEffect } from 'react';
import Select, { components } from 'react-select';
import { Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { useHistory } from 'react-router-dom';

import Error from './Error';
import { Button } from 'react-bootstrap';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: '12px',
  }),
};

const getError = (name, errors) => {
  let error = errors;
  if (name.includes('.')) {
    name.split('.').forEach((x) => {
      if (error) {
        error = error[x];
      }
    });
  } else {
    error = errors[name];
  }

  return error;
};

export default function SelectBox({
  name,
  label,
  rules = {},
  errors,
  labelClass,
  wrapperClass,
  control,
  validation,
  isCreateable,
  defaultValue,
  disabled,
  maxLimit = 99,
  showStar = true,
  handleChange = () => {},
  noFormGp = false,
  titleHint = null,
  wrapperStyle,
  specialization = false,
  ...props
}) {
  const error = getError(name, errors);
  const history = useHistory();
  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < maxLimit;
  const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0;
    return (
      <components.Menu {...props}>
        {optionSelectedLength < maxLimit ? (
          props.children
        ) : (
          <div style={{ margin: 15, fontSize: '12px' }}>Max limit achieved</div>
        )}
      </components.Menu>
    );
  };

  // Scroll to the error if it exists
  useEffect(() => {
    if (error) {
      const element = document.querySelector(`[name="${name}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          element.focus();
        }, 100);
      }
    }
  }, [error, name]);

  return (
    <div
      className={noFormGp ? wrapperClass : `form-group ${wrapperClass || ''}`}
      style={wrapperStyle}
    >
      <div className="row mx-auto d-flex justify-content-between">
        {label ? (
          <label className={labelClass || 'lbl_info'}>
            {label}
            {rules.required && showStar && <star>*</star>}
            {titleHint}
          </label>
        ) : null}
        {specialization && (
          <Button
            className="btn btn-info btn-next"
            onClick={() => history.push('/idashboard/specialization')}
          >
            Create Specialization
          </Button>
        )}
      </div>
      <Controller
        name={name}
        rules={rules}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value }) =>
          isCreateable ? (
            <CreatableSelect
              {...props}
              onChange={(e) => {
                handleChange(e);
                onChange(e);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 9) e.preventDefault();
              }}
              maxMenuHeight={120}
              components={{ Menu }}
              styles={customStyles}
              isDisabled={disabled}
              defaultValue={defaultValue}
              isValidNewOption={isValidNewOption}
              blurInputOnSelect={false}
            />
          ) : (
            <Select
              {...props}
              onKeyDown={(e) => {
                if (e.keyCode === 9) e.preventDefault();
              }}
              onChange={(e) => {
                handleChange(e);
                onChange(e);
              }}
              maxMenuHeight={120}
              components={{ Menu }}
              styles={customStyles}
              isDisabled={disabled}
              defaultValue={defaultValue}
              isValidNewOption={isValidNewOption}
              blurInputOnSelect={false}
            />
          )
        }
      />
      {error && <Error {...error} />}
    </div>
  );
}
