import React from 'react';

export default function Error({ type, message }) {
  let errMsg = '';

  switch (type) {
    case 'required':
      errMsg = 'This field is mandatory';
      break;
    case 'pattern':
      errMsg = message || 'Invalid data';
      break;
    case 'validate':
      errMsg =
        message ||
        'Mobile number is associated with account. Please enter new number';
      break;
    default:
      errMsg = message || 'Invalid data';
      break;
  }

  return <span className="error_class">{errMsg}</span>;
}
