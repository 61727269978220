/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, memo, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { compose } from 'redux';
import duftherLogoName from 'images/DUFTHER_NAME_LOGO.png';
import faviconImage from 'images/FAVICON.png';
import userLogo from 'images/PROFILE_COMMON_HEADER.png';
import makeSelectGlobalStore from 'containers/App/selectors';
import makeSelectRecruiterDashboard from 'containers/RecruiterDashboard/selectors';
import constantMapper from 'utils/constantMapper';
import * as rDashboardActions from 'containers/RecruiterDashboard/actions';

import '../../css/dashboard-layout.css';
import './recruiter-dashboard-sidebar.css';

let RecruiterDashboardSidebar = (props) => {
  const {
    globalStore,
    showSideMenu,
    showMobileMenu,
    recDashboardStore,
    toggleWebSideMenu,
    toggleMobileSideMenu,
  } = props;

  const userRoleData = pathOr('', ['userRoleData'], globalStore);
  const userLastLogin = pathOr('', ['userData', 'lastLogin'], globalStore);
  const userStatus = pathOr('', ['userRoleData', 'status'], globalStore);
  const userRoles = pathOr(null, ['userData', 'roles'], globalStore);
  let userNewName = `${userRoleData?.firstName} ${userRoleData?.lastName}`;

  // expand and collapse USer info
  const [userInfo, toggleUserInfo] = useState(true);

  const { avatarUrl, profileImage } = recDashboardStore;
  // initialize checkboxes value based on set value in api

  const avatarId = pathOr('', ['id'], profileImage);

  useEffect(() => {
    avatarId && props.getAvatar(avatarId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarId]);

  const renderUser = () => (
    <div className="user">
      <div className="photo">
        <img src={avatarUrl ? avatarUrl : userLogo} alt="img" />
      </div>

      <div className="info " style={{ cursor: 'pointer' }}>
        <a
          data-toggle="collapse"
          className="collapsed"
          onClick={() => toggleUserInfo(userInfo)}
        >
          <OverlayTrigger
            key=""
            placement="bottom"
            overlay={<Tooltip>{userNewName}</Tooltip>}
          >
            <span className="profile-name">
              {userNewName?.length < 20
                ? userNewName
                : userNewName?.substring(0, 20) + ' ...'}
              <b className="caret"></b>
            </span>
          </OverlayTrigger>
        </a>
        {userInfo && (
          <div className="profile-wrap">
            <ul className="nav">
              <li>
                <Link
                  to="/rdashboard/profile-view"
                  onClick={() => {
                    sessionStorage.removeItem('employerUserId');
                    toggleWebSideMenu(false);
                    toggleMobileSideMenu(false);
                  }}
                  className="profile-dropdown"
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-id-badge" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                  >
                    My Profile
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/rdashboard/profile-edit"
                  className="profile-dropdown"
                  onClick={() => {
                    toggleWebSideMenu(false);
                    toggleMobileSideMenu(false);
                  }}
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-wrench" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                  >
                    Edit Profile
                  </span>
                </Link>
              </li>
              <li>
                <a className="profile-dropdown status-employer mb-0">
                  <span className="sidebar-mini">
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                  >
                    Status:{' '}
                    <span className="dash_profile_percent">
                      {constantMapper(userStatus)}
                    </span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );

  const renderNav = () => (
    <ul className="nav recruiter-dash-navbar">
      <li className="nav-item">
        <Link
          to="/rdashboard"
          className="nav-link"
          onClick={() => {
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
          }}
        >
          <i className="fa fa-line-chart" />
          <p>Dashboard</p>
        </Link>
      </li>

      <li className="nav-item">
        <Link
          to="/rdashboard/job-post-listing"
          className="nav-link"
          onClick={() => {
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
          }}
        >
          <i className="fa fa-address-card-o" aria-hidden="true"></i>
          <p>Jobs Posted</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link"
          to="/rdashboard/company-view-details"
          onClick={() => {
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
          }}
        >
          <i className="fa fa-industry"></i>
          <p>Company Profile</p>
        </Link>
      </li>
      {/* <li className="nav-item">
        <Link
          className="nav-link"
          data-toggle="collapse"
          to="/rdashboard/notifications"
          onClick={() => {
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
          }}
        >
          <i
            className="fa fa-bell"
            style={{ float: showSideMenu || showMobileMenu ? 'left' : 'none' }}
          />
          <p>Notifications</p>
        </Link>
      </li> */}
      <li className="nav-item">
        <button
          className="nav-link"
          style={{ background: 'none' }}
          onClick={() => {
            window.open(
              'https://dufther.atlassian.net/servicedesk/customer/portal/1',
              '_blank',
              'noopener,noreferrer'
            );
          }}
        >
          <i
            className="fa fa-wrench"
            style={{ float: showSideMenu || showMobileMenu ? 'left' : 'none' }}
          />
          <p>Report Issues</p>
        </button>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link"
          data-toggle="collapse"
          to={'/rdashboard/empanelments'}
          onClick={() => {
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
          }}
        >
          <i className="fa fa-handshake-o"></i>
          <p>Empanelments</p>
        </Link>
      </li>
      {/* {userRoles?.includes('Candidate') &&
      userRoles?.includes('Employer') &&
      userRoles?.includes('Recruiter') ? null : (
        <li className="nav-item">
          <Link
            className="nav-link"
            data-toggle="collapse"
            to="/add-new-role"
            onClick={() => {
              toggleWebSideMenu(false);
              toggleMobileSideMenu(false);
            }}
          >
            <i className="fa fa-plus-square-o" />
            <p className="toggle add-role-text">Add New Role</p>
          </Link>
        </li>
      )} */}
    </ul>
  );

  const renderLogo = () => (
    <Fragment>
      <div className="logo">
        <Link to="/rdashboard" className="simple-text logo-mini">
          <img src={faviconImage} alt="duftherLogo" />
        </Link>
        <Link to="/rdashboard" className="simple-text logo-normal">
          <img src={duftherLogoName} alt="src" />
        </Link>
        <Link
          to="/rdashboard"
          className="simple-text logo-mini mt-globe-sidebar"
        >
          <i
            className="fa fa-globe"
            style={{ color: '#11527e' }}
            aria-hidden="true"
          ></i>
        </Link>
        <Link
          to="/rdashboard"
          className="last-logged-date simple-text logo-normal"
        >
          <span className="last-logged-label">Last Logged</span>
          <span> &nbsp;{userLastLogin}</span>
        </Link>
      </div>
    </Fragment>
  );

  return (
    <div
      className={`sidebar ${!showSideMenu ? 'sidebar-mini-slip' : ''} ${
        showMobileMenu ? 'revised-show-mob-menu' : 'revised-hide-mob-menu'
      }`}
      id="sidebar_dash"
    >
      <div
        className={`sidebar-wrapper ${
          !showSideMenu ? 'sidebar-mini-slip' : ''
        }`}
      >
        {userLastLogin && renderLogo()}
        {userRoleData && renderUser()}
        {renderNav()}
      </div>
    </div>
  );
};

RecruiterDashboardSidebar.propTypes = {
  globalStore: PropTypes.object.isRequired,
};

// select data from redux store
const mapStateToProps = createStructuredSelector({
  globalStore: makeSelectGlobalStore(),
  recDashboardStore: makeSelectRecruiterDashboard(),
});

// dispatch actions
const mapDispatchToProps = (dispatch) => ({
  getAvatar: (id) => dispatch(rDashboardActions.getAvatar(id)),
});

// connection of redux form with state & action dispatcher
const NewSidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecruiterDashboardSidebar);

export default compose(memo(NewSidebar));
