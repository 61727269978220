export const versionData = [
  {
    version: '2.2',
    release: 'Nov 19, 2022',
    description: 'Additional Enhancements, New Features',
  },
  {
    version: '2.1',
    release: 'Feb 26, 2022',
    description: 'Fixed Next JS changes and few bugs',
  },
  {
    version: '2.0',
    release: 'Dec 26, 2021',
    description: 'Added Skilling Hub module and few bug fixes',
  },
  {
    version: '1.9',
    release: 'Oct 31, 2021',
    description: 'Enhancements and Bug Fixes',
  },
  {
    version: '1.8',
    release: 'Oct 3, 2021',
    description:
      'Admin/Franchisee Screen Requirements, Enhancements and Bug Fixes',
  },
  {
    version: '1.7',
    release: 'Sept 2, 2021',
    description:
      'Domain Features, Education Fields, Infusion of Next JS, Bugs Fixing',
  },
  {
    version: '1.6',
    release: 'June 21, 2021',
    description: 'Bugs fixes & other improvements',
  },
  {
    version: '1.5',
    release: 'May 17, 2021',
    description: 'Academic Institute integration and Bug fixes',
  },
  {
    version: '1.4',
    release: 'May 07, 2021',
    description:
      'Servicing Agency Module, Home Page UI changes, Video Tutorials & Social Media Sharing',
  },
  {
    version: '1.3',
    release: 'Mar 27, 2021',
    description: 'Google Location Integrations, Invoicing Module',
  },
  {
    version: '1.2',
    release: 'Mar 12, 2021',
    description: 'Added Employer Specific Changes',
  },
  {
    version: '1.1',
    release: 'Feb 21, 2021',
    description: 'Revised with Minor Fixes in UI',
  },
  {
    version: '1.0',
    release: 'Feb 18, 2021',
    description: 'First Release',
  },
];
