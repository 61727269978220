import React, { memo } from 'react';
import { pathOr } from 'ramda';

function RefundPolicyTab({ match }) {
  return (
    <div
      className={
        // eslint-disable-next-line eqeqeq
        pathOr('', ['params', 'slug'], match) == 'Refund'
          ? 'tab-pane fade show active'
          : 'tab-pane fade'
      }
      id="help-logo"
      aria-labelledby="info-tab"
    >
      <div className="card">
        <div className="card-header">
          <h4 className="card-title ">Refund and Cancellation Policy</h4>
          <p className="card-category">Last updated: March 16, 2021</p>
        </div>
        <div className="card-body">
          <p>
            Thank you for visiting us here, and if you are an existing customer
            who has brought a Package, we Thank you for the Purchase.
          </p>
          <p>
            If, for any reason, You are not completely satisfied with a
            purchase, we invite You to review our policy on refunds and
            cancellations. This Refunds and Cancellation Policy has been created
            with the help of the{' '}
            <a
              href="https://www.privacypolicies.com/return-refund-policy-generator/"
              target="_blank"
            >
              Refunds and Cancellation Policy Generator.
            </a>
            .
          </p>
          <p>
            The following terms are applicable for any products that You have
            purchased from Us.
          </p>
          <h2>Interpretation and Definitions</h2>
          <h4>Interpretation</h4>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h4>Definitions</h4>
          <p>For the purposes of this Return and Cancellation Policy:</p>
          <ul>
            <li>
              <p>
                <strong>Company</strong> (referred to as either &quot;the
                Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
                in this Agreement) refers to Bluesparx Technologies Private
                Limited, Nerul West, Navi Mumbai 400706, Maharashtra.
              </p>
            </li>
            <li>
              <p>
                <strong>Goods</strong> refer to the items offered for sale on
                the Service.
              </p>
            </li>
            <li>
              <p>
                <strong>Orders</strong> mean a request by You to purchase Goods
                from Us.
              </p>
            </li>
            <li>
              <p>
                <strong>Service</strong> refers to the Website.
              </p>
            </li>
            <li>
              <p>
                <strong>Website</strong> refers to Dufther, accessible from{' '}
                <a
                  href="https://www.dufther.com"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  https://www.dufther.com
                </a>
              </p>
            </li>
            <li>
              <p>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </p>
            </li>
          </ul>
          <h2>Order Cancellation Policies</h2>
          <p>
            Once a Package has been purchased from us, We do not provide an
            option presently to refund the Order payment.
          </p>
          <p>
            If there are specific cases on a need basis, where the user has made
            payment but is unable to get the database due to system related
            issues or errors which are unforeseen, we may process a refund in
            such situations once we get a clear picture on the issue.
          </p>
          <p>
            Refund for the services offered by Dufther.com will be at the sole
            discretion of Dufther, Dufther offers no guarantees whatsoever for
            the accuracy or timeliness of the refunds reaching the Customers
            card/bank accounts.
          </p>
          <p>
            The deadline for cancelling an Order is 48 hours/2 days days from
            the date on which You received the Goods or on which a third party
            you have appointed, who is not the carrier, takes possession of the
            product delivered.
          </p>
          <p>
            Dufther reserves its right to amend / alter or change all or any
            disclaimers or terms of agreements at any time without any prior
            notice. All terms/disclaimers whether specifically mentioned or not
            shall be deemed to be included if any reference is made to them.
          </p>
          <p>
            In order to exercise Your right of cancellation, You must inform Us
            of your decision by means of a clear statement. You can inform us of
            your decision by: - By Email: support@dufther.com
          </p>
          <p>
            In ideal conditions, we should be able to refund you purchase values
            within 14 days from the day on which We have approve the refund
            request. We will use the same means of payment as You used for the
            Order, and You will not incur any fees for such reimbursement.
          </p>
          <h2>Refund Process</h2>
          <p>
            Presently we are usign Razorpay services for accepting the payments
            to the system. If any refund request is initiated, it will be routed
            through the same Razorpay gateways.
          </p>
          <p>
            You will be credited with full purchase value, based on the merit of
            the case
          </p>
          <p>
            Once the refund is approved from Dufther, the Admin will approve the
            payment gateways and from there, it may take upto 7 working days to
            get the amount credited to your account.
          </p>
          <h2>Conditions for Returns</h2>
          <p>
            In order for the Goods to be eligible for a return, please make sure
            that:
          </p>

          <ul>
            <li>The Services were purchased in the last 2 days</li>
          </ul>

          <p>
            We reserve the right to refuse returns if certain conditions are not
            getting met, and any cancellation requests will be processed by us
            at our sole discretion.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about our Refunds and Cancellation Policy,
            please contact us:
          </p>
          <ul>
            <li>
              <p>By Email: support@dufther.com</p>
            </li>
            <li>
              <p>By Phone number: +91 9930877972</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default memo(RefundPolicyTab);
