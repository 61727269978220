export const formatOptions = (arr) => {
  if (arr?.length) {
    return arr.map((a) => ({ value: a, label: a }));
  }
};

export const formatOptionsObject = (arr) => {
  if (arr?.length) {
    return arr.map((a) => ({ value: a.value, label: a.label }));
  }
};

export const formatApplicationStatus = (arr) => {
  if (arr?.length) {
    return arr.map((a) => ({ value: a.enumId, label: a.enumName }));
  }
};

export const formatGooglePlaces = (arr) => {
  const cities = arr.map((loc) => loc.description);
  return cities.map((a) => ({ value: a, label: a }));
};

export const formatRecruiterListOptions = (arr) => {
  if (arr?.length > 0) {
    return arr.map((a) => ({
      value: a.recruiterId ? a.recruiterId : a.employerId,
      label: a.firstName + ' ' + a.lastName,
    }));
  }
};

export const convertSelectOpt = (val) => {
  if (!val) {
    return undefined;
  }

  if (Array.isArray(val)) {
    return val.length ? val.map((v) => ({ value: v, label: v })) : '';
  }

  return {
    value: val && (val.value || val.value == false) ? val.value : val,
    label: val && val.label ? val.label : val,
  };
};

export const convertMultiSelectOpt = (val) => {
  return (
    val?.length > 0 &&
    val?.map((v) => ({
      value: v && v.value ? v.value : v,
      label: v && v.label ? v.label : v,
    }))
  );
};

export const formatJobListOptions = (arr) => {
  if (arr?.length > 0) {
    return arr.map((a) => ({
      value: a.jobId,
      label: a.title,
    }));
  }
};

export const formatJobList = (arr) => {
  if (arr?.length > 0) {
    return arr.map((a) => ({
      value: a.jobSummary.jobId,
      label: a.jobSummary.title,
    }));
  }
};

export const formatCompanyList = (arr) => {
  if (arr?.length > 0) {
    return arr.map((a) => ({
      value: a.companyId,
      label: a.companyName,
    }));
  }
};

export const formatCourseBatchList = (arr) => {
  if (arr?.length > 0) {
    return arr.map((a) => ({
      value: a.id,
      label: a.name,
    }));
  }
};

export const convertCourseBatchList = (arr) => {
  if (arr?.length > 0) {
    return arr.map((a) => ({
      value: a.id,
      label: a.name,
    }));
  }
};

export const convertCourseBatchOpt = (opt) => {
  return {
    value: opt?.id,
    label: opt?.name,
  };
};

export const formatFCompanyList = (companiesArr) => {
  if (companiesArr?.length > 0) {
    return companiesArr.map((company) => ({
      value: company.id,
      label: company.name,
    }));
  }
};

export const formatFranchiseeCompanyAssocList = (companiesArr) => {
  if (companiesArr?.length > 0) {
    return companiesArr.map((company) => ({
      value: company.code,
      label: company.name,
    }));
  }
};

export const formatCourseSelected = (val) => {
  if (!val) {
    return undefined;
  }

  return {
    value: val && val.id ? val.id : val,
    label: val && val.courseName ? val.courseName : val,
  };
};

export const formatFranchiseeLists = (list) => {
  if (list?.length > 0) {
    return list?.map((data) => ({
      value: data?.franchiseeCode,
      label: data?.name,
    }));
  }
};

export const formatListByField = (list, fieldLabel, fieldValue) => {
  if (list?.length > 0) {
    return list?.map((data) => ({
      value: data?.[fieldValue],
      label: data?.[fieldLabel],
    }));
  }
};

export const formatSelectedByField = (object, fieldLabel, fieldValue) => {
  if (Object.keys(object).length === 0) return;
  return {
    value: object?.[fieldValue],
    label: object?.[fieldLabel],
  };
};

export const formatProcessIdList = (list) => {
  if (list?.length > 0) {
    return list?.map((data) => ({
      value: data?.importProcessId,
      label: `${data?.fileName}_${data?.importProcessId}`,
    }));
  }
};

export const formatStatusOpt = (status) => {
  const statusOptions = {
    ACTIVE: 'Active',
    PASSED_OUT: 'Passed Out',
    TO_START: 'To Start',
  };

  return {
    label: statusOptions[status] || status,
    value: status,
  };
};
