import { useEffect } from 'react';

function useOutsideAlerter(ref, callback, params) {
  ref = Array.isArray(ref) ? ref : [ref];
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.every((ele) => ele.current && !ele.current.contains(event.target))) {
        //alert('You clicked outside of me!');
        callback(params);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      callback(params);
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [...ref, params]);
}

export default useOutsideAlerter;
