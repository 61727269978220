import { takeLatest, put, select, all, call } from 'redux-saga/effects';
import * as constants from './constants';
import * as actions from './actions';
import * as gService from '../../utility/GlobalServices';
import * as gConst from '../../utility/GlobalConstants';
import * as apiName from '../../config/apiUrl';
import * as gActions from '../App/actions';
import * as candidateAction from '../CandidateBasicAdvanceJobSearch/actions';
import * as selector from './selectors';
import { displayNotification } from '../../config/toastConfig';
import { push } from 'react-router-redux';

// ***** FUNCTION TO GET DATA ***** //
export function* getDashboardPageData() {
  const userId = localStorage.userId;
  const urls = [
    apiName.profileProgress,
    apiName.candidateAnalyticURL,
    apiName.candidateWorkURL + `/${userId}`,
    apiName.highestQualificationURL,
    apiName.educationalAreaURL,
    apiName.engSpecializationURL,
    apiName.dipSpecializationURL,
    apiName.itiSpecializationURL,
    apiName.medSpecializationURL,
    apiName.getCandidateInstituteConsentStatusURL,
    apiName.candidateEducationURL + `/${userId}`
  ];
  try {
    const [
      progressMark,
      config,
      workDetails,
      highestQualification,
      educationalArea,
      engSpecialization,
      dipSpecialization,
      itiSpecialization,
      medSpecialization,
      consentResponse,
      eduResponse,
    ] = yield all(urls.map((url) => gService.callApi(url)));
    const response = {
      progressMark,
      config,
      workDetails,
      highestQualification,
      educationalArea,
      engSpecialization,
      dipSpecialization,
      itiSpecialization,
      medSpecialization,
      consentResponse,
      eduResponse,
    };
    // const response = yield gService.callApi(apiName.candidateAnalyticURL);
    yield put(actions.setDashboardData(response));
  } catch (error) {}
}

// ***** FUNCTION TO SUBMIT NOTICE PERIOD AVAILABILITY ***** //
export function* submitNoticePeriodFormSaga({ data }) {
  try {
    yield gService.callApi(apiName.getAvailablityImmediatelyURL, data, 'POST');
    yield put(actions.noticePeriodFormResponse(data));
  } catch (error) {}
}

// ***** FUNCTION TO SUBMIT ISAVAILABLITY TO API ***** //
export function* submitAvaiabilityFormSaga({ data }) {
  const { isAvailableImmediately } = data;
  try {
    yield gService.callApi(apiName.getAvailablityImmediatelyURL, data, 'POST');
    yield put(actions.setAvailableResponse(data));
    isAvailableImmediately
      ? displayNotification(
          'Your availability is now marked as immediate for new jobs'
        )
      : yield put(actions.loadDashboardPageData());
  } catch (error) {}
}

// ***** FUNCTION TO SUBMIT ACTIVELY SEARCHING TO API ***** //
export function* submitActivelySearchingJobsSaga() {
  const { activelySearchingFormData } = yield select(
    selector.selectCandidateDashboardDomain
  );
  const activelySearchingJobsURL = `${apiName.activelySearchingURL}/${activelySearchingFormData}`;
  try {
    yield gService.callApi(activelySearchingJobsURL, null, 'POST');
    yield put(
      actions.activelySearchingFormResponse(
        'Actively searching for jobs has been set.'
      )
    );
    activelySearchingFormData &&
      displayNotification('You are marked as Actively searching for new jobs');
  } catch (error) {
    yield put(
      actions.activelySearchingFormResponse('Something went wrong! try again.')
    );
  }
}

// ***** GET CANDIDATE AVATAR ***** //
export function* getCandidateAvatar({ id }) {
  try {
    const blob = yield gService.callApi(
      apiName.imageUploadURL + `/${id}`,
      null,
      'GET',
      { fileResponse: true }
    );
    const image = URL.createObjectURL(blob);
    yield put(actions.setCandidateAvatar(image));
  } catch (e) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
}

// ***** FUNCTION TO GET DOMAIN DATA ***** //
export function* getDomainDropdownValueSaga() {
  try {
    const response = yield gService.callApi(`${apiName.domainURL}`);
    yield put(actions.setDomainDropdown(response));
  } catch (error) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
}

// ***** FUNCTION TO GET DOMAIN BASED SUBDATA ***** //
export function* getDomainBasedSubdataSaga({ data }) {
  try {
    const response = yield gService.callApi(
      `${apiName.configuraionApi}${gConst.domainMask[data]}`
    );
    yield put(actions.domainBasedSubdataResult(response));
  } catch (error) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
}

// ***** FUNCTION TO GET DOMAIN BASED SPECIALIZED SUBDATA ***** //
export function* getSpecializedSubdataSaga({ data }) {
  if (data) {
    try {
      const response = yield gService.callApi(`${apiName.erpProductsURL}`);
      yield put(actions.specializedSubdataResult(response));
    } catch (error) {
      displayNotification('Something went wrong! try again.', 'danger');
    }
  }
}

// ***** FUNCTION TO SUBMIT DOMAIN FORM ***** //
export function* submitDomainFormSaga({ data }) {
  yield put(gActions.showLoader());
  if (data) {
    try {
      yield gService.callApi(apiName.candidateDomainURL, data, 'POST');
      yield put(candidateAction.getMatchingJobs());
      yield put(push('cdashboard/candidate-basic-advance-job-search'));
    } catch (error) {
      displayNotification('Something went wrong! try again.', 'danger');
    }
  }
  yield put(gActions.hideLoader());
}

// ***** GET MATCHING JOBS SAGA ***** //
export function* approveConsentFormSaga({ instituteId, consentStatus }) {
  yield put(gActions.showLoader());
  try {
    yield gService.callApi(
      `${apiName.getCandidateInstituteBaseURL}/${instituteId}/consent/${consentStatus}`,
      null,
      'PUT'
    );
    const message = consentStatus ? 'provided' : 'declined';
    displayNotification(`Consent to share profile is ${message} successfully`);
    yield put(actions.setConsentModalStatus(false));
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

export function* verifyCandidateSaga({ userId }) {
  yield put(gActions.showLoader());
  try {
    const response = yield gService.callApi(
      `${apiName.verifyCandidateUrl}/${userId}`
    );
    yield put(actions.setVerifyCandidate(response));
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** BINDING ACTION & FUNCTION LOGIC ***** //
export default function* candidateDashboardSaga() {
  yield takeLatest(constants.LOAD_DASHBOARD_DATA, getDashboardPageData);
  yield takeLatest(
    constants.SUBMIT_AVAIABILITY_FORM,
    submitAvaiabilityFormSaga
  );
  yield takeLatest(
    constants.SUBMIT_ACTIVELY_SEARCHING_FORM,
    submitActivelySearchingJobsSaga
  );
  yield takeLatest(
    constants.SUBMIT_NOTICE_PERIOD_FORM,
    submitNoticePeriodFormSaga
  );
  yield takeLatest(constants.GET_CANDIDATE_IMG_URL, getCandidateAvatar);
  yield takeLatest(constants.DOMAIN_DROPDOWN, getDomainDropdownValueSaga);
  yield takeLatest(
    constants.GET_DOMAIN_BASED_SUBDATA,
    getDomainBasedSubdataSaga
  );
  yield takeLatest(
    constants.GET_SPECIALIZED_SUBDATA,
    getSpecializedSubdataSaga
  );
  yield takeLatest(constants.SUBMIT_DOMAIN_FORM, submitDomainFormSaga);
  yield takeLatest(constants.APPROVE_CONSENT_FORM, approveConsentFormSaga);
  yield takeLatest(constants.VERIFY_CANDIDATE, verifyCandidateSaga);
}
