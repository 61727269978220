/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import GearLoader from '../../components/LoadingIndicator/GearLoading';
import CDJobSearch from '../../components/common/CDJobSearch';
import CandidateDashboardSidebar from './sidebar/candidateDashboardSidebar';
import CandidateDashboardHeader from '../common/dashboardHeader/dashboardHeader';
import CDTranslator from '../common/translator/FrontTranslator';
import CandidateDashboardFooter from '../common/dashboardFooter/dashboardFooter';
import { Breadcrumb } from './Breadcrumb';
import saga from '../../containers/CandidateDashboard/saga';
import reducer from '../../containers/CandidateDashboard/reducer';
import makeSelectGlobalStore from 'containers/App/selectors';
import * as gConst from '../../utility/GlobalConstants';
import * as gActions from 'containers/App/actions';
import { createStructuredSelector } from 'reselect';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import { isUsernamePhoneNumber } from '../../utils/helper';
import '../css/dashboard-layout.css';
import './candidate-dashboard.css';

const CDashboardLayout = (props) => {
  useInjectReducer({ key: 'candidateDashboard', reducer });
  useInjectSaga({ key: 'candidateDashboard', saga });

  const history = useHistory();

  const { addEmailHandler, addMobileHandler, globalStore } = props;
  const { userRoleData, dashboardRole, userData } = globalStore || {};
  const { associatedInstituteCodes } = userData || {};
  const { isBackgroundVerified } = userRoleData || {};

  const createdBy = pathOr(null, ['userData', 'createdByRole'], globalStore);
  const userName = pathOr(
    localStorage.getItem('Mobile'),
    ['userRoleData', 'user', 'userName'],
    globalStore
  );
  const email = pathOr(
    localStorage.getItem('Email'),
    ['userRoleData', 'user', 'email'],
    globalStore
  );

  const isMob = useMediaQuery({ query: '(max-width: 667px)' });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  useEffect(() => {
    if (associatedInstituteCodes?.length > 0 && !email) {
      addEmailHandler(true);
    }
    if (!userName) {
      addMobileHandler(true);
    }
  }, [associatedInstituteCodes, email, userName]);

  const params = useLocation();
  const searchJobsLinkRef = useRef();

  const [webSideMenu, toggleWebSideMenu] = useState(false);
  const [mobileSideMenu, toggleMobileSideMenu] = useState(false);
  const [mobileDropDownMenu, toggleMobileDropDownMenu] = useState(false);

  const renderTranslateBanner = () => (
    <>
      <div className="candidate-dashboard">
        <GearLoader />
        <div className="container-fluid language-container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-4 col-md-4 search-candidates">
              <a
                href="#"
                className="togglebox-top"
                onClick={(e) => {
                  e.preventDefault();
                  toggleMobileDropDownMenu(!mobileDropDownMenu);
                }}
                ref={searchJobsLinkRef}
              >
                <span className="search-text-top">JOBS</span>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#fff' }}
                ></i>
              </a>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-12 text-right">
              <ul className="list-unstyled">
                <li>
                  <div id="ytWidget">
                    <CDTranslator />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {isMob && !userRoleData?.user?.email && (
        <div className="container-fluid">
          <div className="row mx-auto">
            <Button
              className="mt-3 btn btn-block"
              style={{ background: '#f7931e' }}
              onClick={() => addEmailHandler(true)}
            >
              <span className="emp-post-job-header">ADD EMAIL</span>
            </Button>
          </div>
        </div>
      )}
      {isMob &&
        !userRoleData?.user?.phoneNumber &&
        !isUsernamePhoneNumber(userName) && (
          <div className="container-fluid">
            <div className="row mx-auto">
              <Button
                className="mt-3 btn btn-block"
                style={{ background: '#f7931e' }}
                onClick={() => addMobileHandler(true)}
              >
                <span className="emp-post-job-header">ADD MOBILE</span>
              </Button>
            </div>
          </div>
        )}
      {isMob && !isBackgroundVerified && (
        <div className="container-fluid mt-2">
          <div className="row mx-auto">
            <Button
              className="btn btn-info btn-block"
              type="button"
              onClick={() => history.push('/cdashboard/verification')}
            >
              <span className="emp-post-job-header">VERIFY PROFILE</span> &nbsp;
            </Button>
          </div>
        </div>
      )}
    </>
  );

  const showMenuHandler = () => {
    toggleWebSideMenu(!webSideMenu);
  };

  const showMobileMenuHandler = () => {
    toggleMobileSideMenu(!mobileSideMenu);
  };

  return (
    <div className={`${!webSideMenu ? 'sidebar-mini' : ''}`}>
      <div className="wrapper candidate-dashboard">
        <CandidateDashboardSidebar
          showSideMenu={webSideMenu}
          showMobileMenu={mobileSideMenu}
          toggleMobileSideMenu={toggleMobileSideMenu}
        />
        <div className={`main-panel `}>
          <CandidateDashboardHeader
            showSideMenu={webSideMenu}
            showMobileMenu={mobileSideMenu}
            showMenuHandler={showMenuHandler}
            showMobileMenuHandler={showMobileMenuHandler}
            isEmployer={false}
            currentRoute={'Candidate'}
          />
          {renderTranslateBanner()}
          {(createdBy === 'Institute' || createdBy === 'Franchisee') &&
          globalStore?.userRoleData?.isHidden ? null : (
            <CDJobSearch
              toggleMobileDropDownMenu={toggleMobileDropDownMenu}
              mobileDropDownMenu={mobileDropDownMenu}
              searchJobsLinkRef={searchJobsLinkRef}
            />
          )}
          <div className="content">
            <Breadcrumb
              {...props}
              params={params}
              parentLinkAddress={`/cdashboard`}
              parentLinkName="Dashboard"
              childLinkName={gConst.pageNameByPath(
                params?.pathname,
                dashboardRole
              )}
              childLinkAddress={params?.pathname}
            />
            {props.children}
          </div>
          <CandidateDashboardFooter />
          <div
            className={`${mobileSideMenu ? 'close-layer visible' : ''}`}
            onClick={showMobileMenuHandler}
          ></div>
        </div>
      </div>
    </div>
  );
};

CDashboardLayout.propTypes = {
  children: PropTypes.any,
};

// select data from redux store
const mapStateToProps = createStructuredSelector({
  globalStore: makeSelectGlobalStore(),
});

// dispatch actions
const mapDispatchToProps = (dispatch) => ({
  addEmailHandler: (e) => dispatch(gActions.addEmailModalStatus(e)),
  addMobileHandler: (e) => dispatch(gActions.addMobileModalStatus(e)),
});

const CandidateDashboardLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(CDashboardLayout);

export default compose(memo(CandidateDashboardLayout));
