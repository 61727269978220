import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the CandidateBasicAdvanceJobSearch state domain
 */

const selectCandidateBasicAdvanceJobSearchDomain = (state) =>
  state.advanceBasicJobSearch || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by CandidateBasicAdvanceJobSearch
 */

const makeSelectCandidateBasicAdvanceJobSearch = () =>
  createSelector(
    selectCandidateBasicAdvanceJobSearchDomain,
    (substate) => substate
  );

export default makeSelectCandidateBasicAdvanceJobSearch;
export { selectCandidateBasicAdvanceJobSearchDomain };
