/*
 *
 * SetPin reducer
 *
 */
import produce from 'immer';
import { SUBMIT_SETPIN_FORM } from './constants';

export const initialState = {
  formData: null,
};

/* eslint-disable default-case, no-param-reassign */
const setPinReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SUBMIT_SETPIN_FORM:
        draft.formData = action.formData;
        break;
    }
  });

export default setPinReducer;
