/* eslint-disable default-case */
const mapper = (key) => {
  switch (key) {
    case 'PENDING_ACTIVATION':
      return 'Pending Activation';
    case 'ACTIVE':
      return 'Active';
    case 'INACTIVE':
      return 'Inactive';
  }
  return '';
};

export default mapper;
