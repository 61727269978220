import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const GooglePlacesAutocompleteComponent = ({selectProps}) => {
    const apiKey = window.location.host === "www.dufther.com" ? "AIzaSyDItvVjBfBn5jaGDGXlP9qqWDnZew9jFDc" : "AIzaSyBgs8uhprSrLwWCslE8jmiEj0ryPZ4l8Bo" ;
    return <GooglePlacesAutocomplete
        apiKey={apiKey}
        autocompletionRequest={{
            bounds: [
                { lat: 50, lng: 50 },
                { lat: 100, lng: 100 }
            ],
            componentRestrictions: {
            country: ['in']
            }}
        }
        selectProps={selectProps} />
    }

  export default GooglePlacesAutocompleteComponent;