export const validMobile = /^[6-9][0-9]{9}$|undefined|null/i;
export const validLandLineNumber = /^[0-9]{10}$|undefined|null/i;
export const charOnly = /^[a-zA-Z]*$/;
export const charAndSpaceOnly = /^[a-zA-Z ]+$/i;
export const onlyNumber = /^\d+$/i;
export const charsAndNumbers = /^[a-zA-Z0-9]+$/i;
export const alphaNumric = /^[a-zA-Z0-9]+$/;
export const validWebsite =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export const validEmail = /^\S+@\S+\.\S+$/;
export const emailValid =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const validGST =
  /^[0-9]{2}[a-zA-Z]{3}[abcfghljptfABCFGHLJPTF]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}Z[0-9a-zA-Z]{1}/;
