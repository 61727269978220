/* eslint-disable jsx-a11y/anchor-is-valid */
import { pathOr } from 'ramda';
import React, { memo } from 'react';

function ContactUsTab({ match }) {
  return (
    <div
      className={
        // eslint-disable-next-line eqeqeq
        pathOr('', ['params', 'slug'], match) == 'contact'
          ? 'tab-pane fade show active'
          : 'tab-pane fade'
      }
      id="help-logo1"
      aria-labelledby="info-tab"
    >
      <div className="card">
        <div className="card-body">
          <div className="text-align mb-huge-text">
            <span className="huge-text">support@dufther.com</span>
          </div>
          <div
            className="contact-social pos-a js-contact-social"
            target="_blank"
          >
            <h3>
              <span className="not-awkward social-text ">Social</span>
            </h3>
            <ul className="social-list">
              <li>
                <a
                  className
                  href="https://twitter.com/dufther"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-twitter" />
                </a>
              </li>
              <li>
                <a
                  className
                  href="https://www.facebook.com/Dufther-116505893077155"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-facebook" />
                </a>
              </li>
              <li>
                <a
                  className
                  href="https://www.linkedin.com/in/dufther-job-portal-5805b01b4/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-linkedin" />{' '}
                </a>
              </li>
              <li>
                <a
                  className
                  href="https://www.youtube.com/channel/UCkMtaYSgsNNlD5V2gpF5wQg/?guided_help_flow=5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-youtube" />{' '}
                </a>
              </li>
            </ul>
            <p className="mb-0 com-address-contact text-center">
              Bluesparx Technologies Private Limited, Registered Office:
              F117/E109, 1st Floor, Nerul Railway Station Complex, Sector 20,
              Nerul West, Navi Mumbai 400706, Maharashtra, India
            </p>
            <p className="text-center">
              <a href="tel:+919930877972" className="nav-link p-0">
                <span className="btn-inner--icon">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                </span>
                &nbsp;
                <span className="btn-inner--text">+91 9930877972</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ContactUsTab);
