/*
 *
 * Set Pin actions
 *
 */

import { SUBMIT_SETPIN_FORM } from './constants';

export function submitSetPinForm(formData) {
  return {
    formData,
    type: SUBMIT_SETPIN_FORM,
  };
}
