/**
 *
 * Asynchronously loads the component for Login
 *
 */

import React from 'react';
import loadable from '../../utils/loadable';
import LoadingIndicator from '../../components/LoadingIndicator';

export default loadable(() => import('./index'), {
  fallback: (
    <div className='limiter center-content'>
      <div className='container-login'>
        <div className='wrap-login p-l-36 p-r-36 p-t-55 p-b-55 mt-0'>
          <LoadingIndicator />
        </div>
      </div>
    </div>
  ),
});