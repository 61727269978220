import React, { memo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'components/common/Input';
import PopupModal from 'components/common/PopupModal';
import * as pattern from 'components/common/Patterns';
import { allowOnlyNums } from 'components/common/FormFields/formValidations';
import 'components/CandidateViewProfileDetails/candidate-view-profile-details.css';
import { displayNotification } from 'config/toastConfig';

const AddMobileToAccount = (props) => {
  const {
    globalStore,
    addMobileHandler,
    submitMobileForOtp,
    submitMobileChange,
    resetOtpFieldValue,
    triggerOtp,
  } = props;
  const { mobileModalStatus, mobileIsSent, resetOtpField, userData } = {
    ...globalStore,
  };
  const { associatedInstituteCodes } = userData || {};
  const otpRef = useRef(null);

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    setError,
    getValues,
  } = useForm({
    mode: 'all',
  });

  React.useEffect(() => {
    if (mobileIsSent && otpRef && otpRef.current) {
      otpRef.current.focus();
    }
  }, [mobileIsSent]);

  React.useEffect(() => {
    if (resetOtpField && otpRef && otpRef.current) {
      setValue('code', undefined);
      otpRef.current.focus();
      setTimeout(() => {
        resetOtpFieldValue(null);
      }, 500);
    }
  }, [resetOtpField, resetOtpFieldValue, setValue]);

  const mobileOtpHandler = () => {
    const { phoneNumber } = watch();
    if (!phoneNumber) {
      return setError('mobile', {
        message: 'This field is mandatory',
      });
    }
    submitMobileForOtp({ phoneNumber });
  };

  const onCancel = () => {
    displayNotification(
      'Please add and validate your mobile number using the Add Mobile button in the top panel (preferably Whatsapp enabled number) before proceeding further',
      'warning'
    );
    addMobileHandler(false);
  };

  const handleOtpChange = (e) => {
    if (e.target.value.length >= 6) {
      const { phoneNumber } = watch();
      const formData = {
        phoneNumber,
        code: e.target.value,
      };
      submitMobileChange(formData);
    }
  };

  const onSubmit = (data) => {
    submitMobileChange(data);
  };
  return (
    <PopupModal
      setLocalModalStatus={addMobileHandler}
      modalStatus={mobileModalStatus}
      noModalHeader
      modalName="basicModal"
      headerChildren="Add Mobile Number"
      className="welcome-modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6 style={{ color: 'grey', textTransform: 'none' }} className="pb-3">
          Add Mobile Number
        </h6>
        <Input
          name="phoneNumber"
          type="tel"
          maxLength={10}
          autoFocus
          autoComplete="off"
          placeholder="Enter Your Mobile Number"
          className={(errors.phoneNumber ? 'error ' : '') + 'form-control'}
          onKeyPress={allowOnlyNums}
          ref={register({
            required: true,
            pattern: {
              value: /^[6-9][0-9]{9}$/i,
            },
            maxLength: 10,
          })}
        />
        {errors.phoneNumber && (
          <span className="form-control-error">
            {errors.phoneNumber?.message}
          </span>
        )}

        {mobileIsSent && (
          <>
            <div className="otp-input-outer-container">
              <div className="otp-input-inner-container">
                <Input
                  inputMode="numeric"
                  title="Non-negative integral number"
                  pattern="[0-9]*"
                  required
                  ref={(e) => {
                    register(e, {
                      required: true,
                      maxLength: 6,
                      pattern: pattern.onlyNumber,
                      minLength: 6,
                    });
                    otpRef.current = e;
                  }}
                  onKeyPress={allowOnlyNums}
                  errors={errors}
                  type="number"
                  className="otp-input"
                  name="code"
                  maxLength={6}
                  label="Enter OTP"
                  // helptext={<small>Enter OTP Recieved on E-Mail</small>}
                  helptextClass="text-secondary"
                  handleChange={handleOtpChange}
                />
              </div>
            </div>
            <p className="font-size-12">
              {mobileIsSent}
              <label
                className="ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  mobileOtpHandler();
                }}
                style={{ cursor: 'pointer' }}
              >
                <span className={'text-info text-capitalize'}>
                  <u>Resend OTP</u>
                </span>
              </label>
            </p>
          </>
        )}

        <div className="modal-footer1">
          {!mobileIsSent ? (
            <button
              type="button"
              className="btn btn-info mb-1"
              data-dismiss="modal"
              onClick={mobileOtpHandler}
            >
              Verify
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-info mb-1"
              data-dismiss="modal"
            >
              Submit
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger ml-3"
            data-dismiss="modal"
            onClick={() => onCancel()}
          >
            Cancel
          </button>
        </div>
      </form>
    </PopupModal>
  );
};

export default memo(AddMobileToAccount);
