import React, { memo } from 'react';
import { pathOr } from 'ramda';

function PrivacyPolicyTab({ match }) {
  return (
    <div
      className={
        // eslint-disable-next-line eqeqeq
        pathOr('', ['params', 'slug'], match) == 'privacy'
          ? 'tab-pane fade show active'
          : 'tab-pane fade'
      }
      id="legal-logo"
      aria-labelledby="info-tab"
    >
      <div className="card">
        <div className="card-header">
          <h4 className="card-title ">Privacy Policy</h4>
          <p className="card-category">Effective date: February 10, 2021</p>
        </div>
        <div className="card-body">
          <p>
            Bluesparx Technologies Private Limited ("us", "we", or "our")
            operates the http://www.dufther.com website and the Dufther mobile
            application (hereinafter referred to as the "Service").
          </p>
          <p>
            This page informs you of our policies regarding the collection, use,
            and disclosure of personal data when you use our Service and the
            choices you have associated with that data. Our Privacy Policy for
            Bluesparx Technologies Private Limited is managed with the help of{' '}
            <a
              href="https://privacypolicies.com/free-privacy-policy-generator/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policies
            </a>
            .
          </p>
          <p>
            We use your data to provide and improve the Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, the terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions.
          </p>
          <h3>Information Collection And Use</h3>
          <p>
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>
          <h3>Types of Data Collected</h3>
          <h4>Personal Data</h4>
          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
          </p>
          <ul>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Email address</li>
            <li>Demographics</li>
            <li>Location details</li>
            <li>Education details</li>
            <li>Work information</li>
            <li>Cookies and Usage Data</li>
          </ul>
          <h4>Usage Data</h4>
          <p>
            We may also collect information that your browser sends whenever you
            visit our Service or when you access the Service by or through a
            mobile device ("Usage Data").
          </p>
          <p>
            This Usage Data may include information such as your computer's
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that you visit, the time and date
            of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>
          <p>
            When you access the Service with a mobile device, this Usage Data
            may include information such as the type of mobile device you use,
            your mobile device unique ID, the IP address of your mobile device,
            your mobile operating system, the type of mobile Internet browser
            you use, unique device identifiers and other diagnostic data.
          </p>
          <h4>Tracking &amp; Cookies Data</h4>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and hold certain information.
          </p>
          <p>
            Cookies are files with small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Tracking technologies also used
            are beacons, tags, and scripts to collect and track information and
            to improve and analyze our Service.
          </p>
          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>
          <p>Examples of Cookies we use:</p>
          <ul>
            <li>
              <strong>Session Cookies.</strong> We use Session Cookies to
              operate our Service.
            </li>
            <li>
              <strong>Preference Cookies.</strong> We use Preference Cookies to
              remember your preferences and various settings.
            </li>
            <li>
              <strong>Security Cookies.</strong> We use Security Cookies for
              security purposes.
            </li>
          </ul>
          <h3>Use of Data</h3>
          <p>
            Bluesparx Technologies Private Limited uses the collected data for
            various purposes:
          </p>
          <ul>
            <li>To provide and maintain the Service</li>
            <li>To notify you about changes to our Service</li>
            <li>
              To allow you to participate in interactive features of our Service
              when you choose to do so
            </li>
            <li>To provide customer care and support</li>
            <li>
              To provide analysis or valuable information so that we can improve
              the Service
            </li>
            <li>To monitor the usage of the Service</li>
            <li>To detect, prevent and address technical issues</li>
          </ul>
          <h3>Transfer Of Data</h3>
          <p>
            Your information, including Personal Data, may be transferred to and
            maintained on computers located outside of your state, province,
            country or other governmental jurisdiction where the data protection
            laws may differ than those from your jurisdiction.
          </p>
          <p>
            If you are located outside India and choose to provide information
            to us, please note that we transfer the data, including Personal
            Data, to India and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            Bluesparx Technologies Private Limited will take all steps
            reasonably necessary to ensure that your data is treated securely
            and in accordance with this Privacy Policy and no transfer of your
            Personal Data will take place to an organization or a country unless
            there are adequate controls in place including the security of your
            data and other personal information.
          </p>
          <h3>Disclosure Of Data</h3>
          <h4>Legal Requirements</h4>
          <p>
            Bluesparx Technologies Private Limited may disclose your Personal
            Data in the good faith belief that such action is necessary to:
          </p>
          <ul>
            <li>To comply with a legal obligation</li>
            <li>
              To protect and defend the rights or property of Bluesparx
              Technologies Private Limited
            </li>
            <li>
              To prevent or investigate possible wrongdoing in connection with
              the Service
            </li>
            <li>
              To protect the personal safety of users of the Service or the
              public
            </li>
            <li>To protect against legal liability</li>
          </ul>
          <h3>Security Of Data</h3>
          <p>
            The security of your data is important to us, but remember that no
            method of transmission over the Internet, or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
          <h3>Data Deletion Policy</h3>
          <p>
            Dufther is committed to protecting the privacy and security of our
            users' personal information. This Data Deletion Policy outlines our
            practices regarding the deletion of user data and provides
            transparency into how we handle and manage data deletion requests.
          </p>
          <ul>
            <li>
              <p>
                <strong>User-Controlled Data Deletion</strong>
              </p>
              <p>
                Users have full control over their data and can delete their
                information at any time through the app's settings. Upon
                deletion, all personal data associated with the user account
                will be permanently removed from our servers. Once logged in,
                Please go to the account settings on the top right corner and
                click on Profile -> Delete.
              </p>
            </li>
            <li>
              <p>
                <strong>Inactive Account Data Deletion</strong>
              </p>
              <p>
                To ensure the efficient use of resources and maintain the
                security of our platform, Dufther may automatically delete data
                associated with inactive accounts after a period of inactivity.
                Typically, data from inactive accounts will be deleted after 12
                months of no activity.
              </p>
            </li>
            <li>
              <p>
                <strong>Data Retention Periods</strong>
              </p>
              <p>
                We retain user data only for as long as necessary to fulfill the
                purposes outlined in our Privacy Policy. Different types of data
                may have different retention periods, and we adhere to industry
                best practices and legal requirements when determining these
                periods.
              </p>
              <p>
                User-initiated Deletion: Users have the option to delete their
                data after login to the App.
              </p>
            </li>
            <li>
              <p>
                <strong>Data Deletion upon Account Closure</strong>
              </p>
              <p>
                When a user closes their account, all associated data will be
                promptly and permanently deleted from our servers. This includes
                personal information, preferences, usage data, and any other
                data collected during the user's interaction with our app.
              </p>
            </li>
            <li>
              <p>
                <strong>Opt-out Mechanism</strong>
              </p>
              <p>
                Users have the option to opt out of certain data collection
                practices or choose not to provide specific types of
                information. However, please note that opting out of certain
                data collection may limit the functionality and features
                available within the app.
              </p>
            </li>
            <li>
              <p>
                <strong>Data Deletion upon Request</strong>
              </p>
              <p>
                Users can request the deletion of their data at any time by
                contacting our support team or by using the designated data
                deletion feature within the app. We will promptly process such
                requests in accordance with our internal procedures and legal
                obligations.
              </p>
            </li>
            <li>
              <p>
                <strong>Revision of Data Deletion Policy</strong>
              </p>
              <p>
                We reserve the right to update or revise our Data Deletion
                Policy as necessary to reflect changes in our data practices or
                legal requirements. Any updates to this policy will be
                communicated to users through the app or our website.
              </p>
            </li>
          </ul>
          <p>For more information about how we collect, use, and protect your data, please refer to our Privacy Policy.</p>
          <p>If you have any questions or concerns about our Data Deletion Policy or data handling practices, please contact us at support@dufther.com.</p>
          <h3>Service Providers</h3>
          <p>
            We may employ third party companies and individuals to facilitate
            our Service ("Service Providers"), to provide the Service on our
            behalf, to perform Service-related services or to assist us in
            analyzing how our Service is used.
          </p>
          <p>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
          <h3>Analytics</h3>
          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>
          <ul>
            <li>
              <p>
                <strong>Google Analytics</strong>
              </p>
              <p>
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualize and personalize the ads of its
                own advertising network.
              </p>
              <p>
                For more information on the privacy practices of Google, please
                visit the Google Privacy &amp; Terms web page:{' '}
                <a
                  href="https://policies.google.com/privacy?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <u>Policies</u>
                </a>
              </p>
            </li>
          </ul>
          <h3>Links To Other Sites</h3>
          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click on a third party link, you will be directed to
            that third party's site. We strongly advise you to review the
            Privacy Policy of every site you visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <h3>Children's Privacy</h3>
          <p>
            Our Service does not address anyone under the age of 18
            ("Children").
          </p>
          <p>
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Children has provided us with Personal Data,
            please contact us. If we become aware that we have collected
            Personal Data from children without verification of parental
            consent, we take steps to remove that information from our servers.
          </p>
          <h3>Changes To This Privacy Policy</h3>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            "effective date" at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h3>Contact Us</h3>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us:
          </p>
          <ul>
            <li>
              By Mail:{' '}
              <span className="mail-id-support">support@dufther.com</span>
            </li>
            <li>By Phone: 1800 222 550</li>
            <li>
              By Post: E109/F117, Nerul Railway Station Complex, Nerul West,
              Maharashtra state, India 400706
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default memo(PrivacyPolicyTab);
