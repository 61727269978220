import {
  LOAD_ANALYTICS_DATA,
  SET_ANALYTICS_DATA,
  LOAD_APPLICATION_DATA,
  SET_APPLICATION_DATA,
  SET_PAGE_NUMBER_TO_STORE,
  SET_PAGE_PAGE_ITEMS_TO_STORE,
  FETCH_APPLICATION_JOBID,
  SET_APPLICATIONS_TO_STORE,
  UPDATE_APPLICATION_STATUS,
  SET_JOB_STATUS_TO_STORE,
  GET_CANDIDATE_CONTACT,
  SET_CANDIDATE_CONTACT,
  DOWNLOAD_RESUME,
  GET_AVATAR,
  SET_AVATAR,
  INITIALIZE_REC_STORE,
  SET_EMP_ACTION_WAY,
  GET_APPLICATION_STATUS,
  SET_APPLICATION_STATUS,
  GET_STAGE_STATUS,
  SET_STAGE_STATUS,
} from './constants';

export const loadAnalyticsDataAction = (companyId) => ({
  companyId,
  type: LOAD_ANALYTICS_DATA,
});

export const setAnalyticsDataAction = (data) => ({
  data,
  type: SET_ANALYTICS_DATA,
});

export const loadApplicationDataAction = (id) => ({
  id,
  type: LOAD_APPLICATION_DATA,
});

export const setApplicationDataAction = (data) => ({
  data,
  type: SET_APPLICATION_DATA,
});

export const setPageNumberToStore = (pageNum) => ({
  pageNum,
  type: SET_PAGE_NUMBER_TO_STORE,
});

export const setPerPageItemsToStore = (perPage) => ({
  perPage,
  type: SET_PAGE_PAGE_ITEMS_TO_STORE,
});

export const fetchApplicationsOfJobId = (jobId) => ({
  jobId,
  type: FETCH_APPLICATION_JOBID,
});

export const setApplicationsOfJobToStore = (data) => ({
  data,
  type: SET_APPLICATIONS_TO_STORE,
});

export const setJobStatusToStore = (jobStatus) => ({
  jobStatus,
  type: SET_JOB_STATUS_TO_STORE,
});

export const updateApplicationStatusAction = (data) => ({
  data,
  type: UPDATE_APPLICATION_STATUS,
});

export const getCandidateContactDetail = (candidateUserId) => ({
  candidateUserId,
  type: GET_CANDIDATE_CONTACT,
});

export const setCandidateContactDetail = (data) => ({
  data,
  type: SET_CANDIDATE_CONTACT,
});

export const downloadResumeAction = (data) => ({
  data,
  type: DOWNLOAD_RESUME,
});

export const getAvatar = (id) => ({
  id,
  type: GET_AVATAR,
});

export const setAvatar = (data) => ({
  data,
  type: SET_AVATAR,
});

export const initializeRecStore = () => ({
  type: INITIALIZE_REC_STORE,
});

export const setEmpActionWay = (actionWay, id) => ({
  actionWay,
  id,
  type: SET_EMP_ACTION_WAY,
});

export const getApplicationStatus = () => ({
  type: GET_APPLICATION_STATUS,
});

export const setApplicationStatus = (data) => ({
  data,
  type: SET_APPLICATION_STATUS,
});

export const getStageStatus = (status) => ({
  status,
  type: GET_STAGE_STATUS,
});

export const setStageStatus = (data) => ({
  data,
  type: SET_STAGE_STATUS,
});
