const routing = {
  USER_PIN: '/setpin',
  USER_SIGNUP: '/register',
  CANDIDATE_JOB_PREFERENCE: '/job-preference',
  EMPLOYER_COMPANY_INFO: '/company-profile',
  EMPLOYER_BASIC: '/employer-admin-profile-registration',
  EMPLOYER_WORK: '/employer-admin-profile-registration',
  EMPLOYER_EDUCATION: '/employer-admin-profile-registration',
  RECRUITER_BASIC: '/recruiter-profile',
  RECRUITER_WORK: '/recruiter-profile',
};
export default function presentPage(userDetails, latestRole, screenName) {
  if (screenName === 'CANDIDATE_JOB_PREFERENCE' && routing[screenName]) {
    return {
      pathname: routing[screenName],
      role: 'candidate',
    };
  }
  if (screenName === 'EMPLOYER_COMPANY_INFO' && routing[screenName]) {
    return {
      pathname: routing[screenName],
      role: 'employer',
    };
  }
  if (screenName === 'EMPLOYER_BASIC' && routing[screenName]) {
    return {
      pathname: routing[screenName],
      role: 'employer',
    };
  }
  if (screenName === 'RECRUITER_BASIC' && routing[screenName]) {
    return {
      pathname: routing[screenName],
      role: 'recruiter',
    };
  }

  if (screenName !== 'SUCCESS' && routing[screenName]) {
    return {
      pathname: routing[screenName],
    };
  }
  const pathname = window.location.pathname;
  const path = window.location.pathname.split('/')[1];
  if (path === 'edashboard' && userDetails.roles.indexOf('Employer') > -1) {
    return {
      pathname,
      role: 'employer',
    };
  }

  if (path === 'cdashboard' && userDetails.roles.indexOf('Candidate') > -1) {
    return {
      pathname,
      role: 'candidate',
    };
  }

  if (path === 'rdashboard' && userDetails.roles.indexOf('Recruiter') > -1) {
    return {
      pathname,
      role: 'recruiter',
    };
  }

  if (path === 'fdashboard' && userDetails.roles.indexOf('Franchisee') > -1) {
    return {
      pathname,
      role: 'franchisee',
    };
  }

  if (path === 'idashboard' && userDetails.roles.indexOf('Institute') > -1) {
    return {
      pathname,
      role: 'institute',
    };
  }

  if (path === '/dashboard-admin' && userDetails.roles.indexOf('Admin') > -1) {
    return {
      pathname,
      role: 'admin',
    };
  }

  if (
    path === 'login' &&
    userDetails.roles.indexOf('Candidate') > -1 &&
    latestRole === 'candidate' &&
    userDetails?.associatedFranchiseeCodes?.length > 0 &&
    userDetails?.createdByRole === 'Franchisee'
  ) {
    return {
      pathname: 'cdashboard/profile-menu',
      role: 'candidate',
    };
  } else if (
    path === 'login' &&
    userDetails.roles.indexOf('Candidate') > -1 &&
    latestRole === 'candidate'
  ) {
    return {
      pathname: 'cdashboard/candidate-basic-advance-job-search',
      role: 'candidate',
    };
  }

  return {
    pathname:
      latestRole?.toLowerCase() === 'admin'
        ? '/dashboard-admin'
        : `/${latestRole[0]}dashboard`,
    role: latestRole,
  };
}
