import React from 'react';
import importScript from '../../../components/common/Script';

import './front-translator.css';

function FrontTranslator() {
  importScript(
    'https://translate.yandex.net/website-widget/v1/widget.js?widgetId=ytWidget&pageLang=en&widgetTheme=light&autoMode=false'
  );
  return (
    <div className="language-container">
      <div id="ytWidget" className="language-widget container"></div>
    </div>
  );
}

export default FrontTranslator;
