/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Breadcrumb } from '../candidateDashboardLayout/Breadcrumb';
import GearLoader from 'components/LoadingIndicator/GearLoading';
import InstituteDashboardSidebar from './sidebar/instituteDashboardSidebar';
import InstituteDashboardHeader from '../common/dashboardHeader/dashboardHeader';
import CDTranslator from '../common/translator/FrontTranslator';
import AdminDashboardFooter from '../common/dashboardFooter/dashboardFooter';
import makeSelectGlobalStore from 'containers/App/selectors';
import * as gConst from '../../utility/GlobalConstants';
import * as gActions from 'containers/App/actions';
import { createStructuredSelector } from 'reselect';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isUsernamePhoneNumber } from '../../utils/helper';
import SpecializationEducationBox from 'components/SpecializationEducationBox';
import SlidingPane from 'react-sliding-pane';
import InstituteCandidateTermsCondition from 'containers/InstituteCandidateTermsCondition/Loadable';

import '../css/dashboard-layout.css';
import './institute-dashboard.css';
import { pathOr } from 'ramda';

const InstituteDashboardLayout = (props) => {
  const {
    addEmailHandler,
    addMobileHandler,
    globalStore,
    setRedirectCallback,
  } = props;
  const { userRoleData, dashboardRole } = globalStore || {};

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  const params = useLocation();

  const [webSideMenu, toggleWebSideMenu] = useState(false);
  const [mobileSideMenu, toggleMobileSideMenu] = useState(false);
  const userName = pathOr(
    localStorage.getItem('Mobile'),
    ['userRoleData', 'user', 'userName'],
    globalStore
  );
  const email = pathOr(
    localStorage.getItem('Email'),
    ['userRoleData', 'user', 'email'],
    globalStore
  );

  useEffect(() => {
    if (!email) {
      addEmailHandler(true);
    }
    if (!userName) {
      addMobileHandler(true);
    }
  }, [email, userName]);

  const renderTranslateBanner = () => (
    <>
      <div className="candidate-dashboard">
        <GearLoader />
        <div className="container-fluid language-container">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-sm-4 col-md-4 search-candidates"></div>
            <div className="col-sm-8 col-md-8 col-lg-12 text-right">
              <ul className="list-unstyled">
                <li>
                  <div id="ytWidget">
                    <CDTranslator />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {isMob && !userRoleData?.user?.email && (
        <div className="container-fluid">
          <div className="row mx-auto">
            <Button
              className="mt-3 btn btn-block"
              style={{ background: '#f7931e' }}
              onClick={() => addEmailHandler(true)}
            >
              Add Email
            </Button>
          </div>
        </div>
      )}
      {isMob &&
        !userRoleData?.user?.phoneNumber &&
        !isUsernamePhoneNumber(userRoleData?.user?.userName) && (
          <div className="container-fluid">
            <div className="row mx-auto">
              <Button
                className="mt-3 btn btn-block"
                style={{ background: '#f7931e' }}
                onClick={() => addMobileHandler(true)}
              >
                Add Mobile
              </Button>
            </div>
          </div>
        )}
    </>
  );

  const showMenuHandler = () => {
    toggleWebSideMenu(!webSideMenu);
  };

  const showMobileMenuHandler = () => {
    toggleMobileSideMenu(!mobileSideMenu);
  };

  function SpecializationComponent() {
    const location = useLocation();
    const excludedPaths = [
      '/idashboard',
      '/idashboard/profile-edit',
      '/idashboard/profile-view',
      '/idashboard/add-candidate',
      '/idashboard/manage-institute-users',
      '/idashboard/manage-batch',
      '/idashboard/empanelment',
      '/idashboard/empanelment-requests-management',
      '/idashboard/company-association',
      '/idashboard/empanelment-request-institute-to-company',
      '/idashboard/specialization',
      '/idashboard/course-catalogues',
      '/idashboard/company-list',
      '/idashboard/post-job/new',
      '/idashboard/job-central',
      '/idashboard/manage-placement',
      '/idashboard/internal-company',
      '/idashboard/job-posts',
      '/idashboard/manage-job-post',
      '/idashboard/edit-job-post',
      '/idashboard/programmes',
      '/idashboard/change-mobile',
      '/idashboard/bulk-users',
      '/idashboard/create-bulk-candidates',
      '/idashboard/list-bulk-candidates',
      '/idashboard/institute-view-single-log',
      '/idashboard/search-candidate-by-process',
      '/idashboard/search-candidate-user-listing',
      '/idashboard/createprofiles',
      '/idashboard/create-profiles',
      '/idashboard/manage-students',
      '/idashboard/create-employer',
      '/idashboard/edit-application',
      '/idashboard/idashboard-associated',
      '/idashboard/change-pin',
      '/idashboard/create-batch',
      '/idashboard/dashboard-settings',
      '/idashboard/broadcast-notice',
      '/idashboard/consent-form',
      '/idashboard/job-central/companies-by-tier',
    ];

    if (excludedPaths.includes(location.pathname)) {
      return null; // Return null to hide the component
    }

    return <SpecializationEducationBox />;
  }

  const isMob = useMediaQuery({ query: '(max-width: 667px)' });
  const isTab = useMediaQuery({ query: '(max-width: 1024px)' });
  const renderConsentFormModal = (modalStatus, setModalStatus) =>
    modalStatus && (
      <SlidingPane
        isOpen={modalStatus}
        onRequestClose={() => {
          setModalStatus({ consentFormModal: false });
        }}
        width={isMob || isTab ? '100%' : '50%'}
        closeIcon={
          isMob && (
            <button type="button" className="slider-pane-close-button">
              Close
            </button>
          )
        }
      >
        <InstituteCandidateTermsCondition
          closeSliderOnSuccess={(modStatus) =>
            setModalStatus({ consentFormModal: modStatus })
          }
          calledBy='root'
        />
      </SlidingPane>
    );

  return (
    <div className={`${!webSideMenu ? 'sidebar-mini' : ''}`}>
      {renderConsentFormModal(
        globalStore?.redirectCallBack?.consentFormModal,
        setRedirectCallback
      )}
      <div className="wrapper candidate-dashboard">
        <InstituteDashboardSidebar
          showSideMenu={webSideMenu}
          showMobileMenu={mobileSideMenu}
          toggleMobileSideMenu={toggleMobileSideMenu}
          toggleWebSideMenu={toggleWebSideMenu}
          setRedirectCallback={setRedirectCallback}
        />
        <div className={`main-panel `}>
          <InstituteDashboardHeader
            showSideMenu={webSideMenu}
            showMobileMenu={mobileSideMenu}
            showMenuHandler={showMenuHandler}
            showMobileMenuHandler={showMobileMenuHandler}
            isEmployer={true}
            currentRoute={'Institute'}
          />
          {renderTranslateBanner()}
          <div className="content">
            <Breadcrumb
              {...props}
              params={params}
              parentLinkAddress={`/idashboard`}
              parentLinkName="Dashboard"
              childLinkName={gConst.pageNameByPath(
                params?.pathname,
                dashboardRole
              )}
              childLinkAddress={params?.pathname}
            />
            <SpecializationComponent />
            {props.children}
          </div>
          <GearLoader />
          <AdminDashboardFooter />
          <div
            className={`${mobileSideMenu ? 'close-layer visible' : ''}`}
            onClick={showMobileMenuHandler}
          ></div>
        </div>
      </div>
    </div>
  );
};

InstituteDashboardLayout.propTypes = {
  children: PropTypes.any,
};

// select data from redux store
const mapStateToProps = createStructuredSelector({
  globalStore: makeSelectGlobalStore(),
});

// dispatch actions
const mapDispatchToProps = (dispatch) => ({
  addEmailHandler: (e) => dispatch(gActions.addEmailModalStatus(e)),
  addMobileHandler: (e) => dispatch(gActions.addMobileModalStatus(e)),
  setRedirectCallback: (callback) => {
    dispatch(gActions.setRedirectCallback(callback));
  },
});

const NewInstituteDashboardLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(InstituteDashboardLayout);

export default compose(memo(NewInstituteDashboardLayout));
