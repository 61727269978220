/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseTEXT(response) {
  if (response === null || response === '') {
    return null;
  }
  try {
    return JSON.parse(response);
  } catch {
    return response;
  }
}

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.text();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 403) {
    localStorage.clear('userId');
    localStorage.removeItem('Bearer');
    localStorage.removeItem('Mobile');
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options, fileResponse = false) {
  const token = window.localStorage.getItem('Bearer');
  const header = {
    accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (token) {
    header.Authorization = `Bearer ${token}`;
  }
  const newOptions = { ...options, headers: header };

  if (fileResponse) {
    return fetch(url, newOptions).then((res) => res.blob());
  } else {
    return fetch(url, newOptions)
      .then(checkStatus)
      .then(parseJSON)
      .then(parseTEXT);
  }
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function upload(url, options) {
  const token = window.localStorage.getItem('Bearer');
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  // eslint-disable-next-line no-param-reassign
  options.headers = headers;
  return fetch(url, options).then(checkStatus).then(parseJSON).then(parseTEXT);
}
