import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the globalStore state domain
 */

const selectGlobalStorerDomain = state => state.globalStore || initialState;

/**
 * Other specific selectors
 */

const selectRouter = state => state.router;

/**
 * Default selector used by globalStore
 */

const makeSelectGlobalStore = () =>
  createSelector(
    selectGlobalStorerDomain,
    substate => substate,
  );

const makeSelectLocation = () =>
  createSelector(
    selectRouter,
    routerState => routerState.location,
  );

export default makeSelectGlobalStore;
export { selectGlobalStorerDomain, makeSelectLocation };
