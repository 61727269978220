import { takeLatest, put, select, delay } from 'redux-saga/effects';
import * as constants from './constants';
import * as selectors from './selectors';
import * as apiName from '../../config/apiUrl';
import * as gService from '../../utility/GlobalServices';
import * as gActions from '../App/actions';
import { displayNotification } from '../../config/toastConfig';
import history from '../../utils/history';

// ***** FUNCTION TO SUBMIT SET PIN FORM DATA ***** //
export function* submitSetPinFormSaga() {
  yield put(gActions.showLoader());
  const { formData } = yield select(selectors.selectSetPinDomain);
  try {
    yield gService.callApi(apiName.setDashboardPinURL, formData, 'POST');
    displayNotification(`PIN has been created successfully`);
    yield delay(3000);
    history.push('/register');
    yield put(gActions.loadGlobalStoreData());
  } catch (error) {
    displayNotification(`Something went wrong try again`, 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** BINDING ACTION & FUNCTION LOGIC ***** //
export default function* setPinSaga() {
  yield takeLatest(constants.SUBMIT_SETPIN_FORM, submitSetPinFormSaga);
}
