import React from 'react';
import { Controller } from 'react-hook-form';
import GooglePlaces from '../GooglePlaces';
import { components } from 'react-select';

import Error from './Error';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: '12px',
  }),
};

const getError = (name, errors) => {
  let error = errors;
  if (name.includes('.')) {
    name.split('.').forEach((x) => {
      if (error) {
        error = error[x];
      }
    });
  } else {
    error = errors[name];
  }

  return error;
};

export default function SelectBox({
  name,
  label,
  rules = {},
  errors,
  labelClass,
  wrapperClass,
  control,
  validation,
  isCreateable,
  defaultValue = [],
  disabled,
  maxLimit = 99,
  showStar = true,
  handleChange = () => {},
  setValue,
  ...props
}) {
  const error = getError(name, errors);
  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < maxLimit;
  const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0;
    return (
      <components.Menu {...props}>
        {optionSelectedLength < maxLimit ? (
          props.children
        ) : (
          <div style={{ margin: 15, fontSize: '12px' }}>Max limit achieved</div>
        )}
      </components.Menu>
    );
  };

  defaultValue =
    defaultValue && defaultValue.length
      ? defaultValue.map((place) => ({
          label: place.label,
          value: {
            place_id: place.value,
            structured_formatting: {
              main_text: place.value,
            },
          },
        }))
      : defaultValue;

  return (
    <div className={`form-group ${wrapperClass || ''}`}>
      {!!label ? (
        <label className={labelClass || 'lbl_info'}>
          {label}
          {rules.required && showStar && <star>*</star>}
        </label>
      ) : null}
      <Controller
        name={name}
        rules={rules}
        required={rules.required}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value }) => (
          <GooglePlaces
            selectProps={{
              ...props,
              onKeyDown: (e) => {
                if (e.keyCode === 9) e.preventDefault();
              },
              onChange: (e) => {
                handleChange(e);
                onChange(e);
              },
              maxMenuHeight: 120,
              components: { Menu },
              styles: customStyles,
              isDisabled: disabled,
              defaultValue: defaultValue,
              setValue: setValue,
              isValidNewOption: isValidNewOption,
            }}
          />
        )}
      />
      {error && <Error {...error} />}
    </div>
  );
}
