import React, { memo, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import RecruiterCompanyDropdown from '../../components/RecruiterCompanyDropdown';
import * as gActions from './../App/actions';

export function RecruiterCompanyDropdownContainer(props) {
  return (
    <Fragment>
      <RecruiterCompanyDropdown {...props} />
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  changeCompanyDropdown: (e) => dispatch(gActions.selectRecruiterCompany(e)),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(RecruiterCompanyDropdownContainer);
