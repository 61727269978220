export const prepareData = (FilterConfigData, searchPayload) => {
  const {
    postingDate,
    worktype,
    locationTypes,
    hired,
    empType,
    workingType,
    workDuration,
    experience,
    salaryMode,
    salRange,
    ageRange,
    gender,
    physicalStatus,
    associatedIndustries,
    rolesPerformed,
    skills,
    jobAllowance,
    timeToFillPost,
    distanceKms,
    companyNameList,
    domain,
  } = FilterConfigData;
  const newDistanceInKm = distanceKms.filter((dis) => dis <= 250);
  return [
    {
      name: 'Posting Date',
      data: postingDate,
      targetKey: 'postingDate',
      defaultValue: searchPayload.postingDate,
      element: 'checkbox',
      expand: !!searchPayload?.postingDate?.length,
    },
    {
      name: 'Location (Kms)',
      data: distanceKms.length ? newDistanceInKm.map((dis) => `0-${dis}`) : [],
      targetKey: 'distanctInKm',
      defaultValue: searchPayload.distanctInKm,
      element: 'radio',
      expand: !!searchPayload.distanctInKm,
    },
    {
      name: 'Location Type',
      data: locationTypes,
      targetKey: 'locationType',
      defaultValue: searchPayload.locationType,
      element: 'checkbox',
      expand: !!searchPayload?.locationType?.length,
    },
    {
      name: 'Work from home',
      data: ['Available'],
      targetKey: 'workFromHome',
      defaultValue: searchPayload.workFromHome,
      element: 'checkbox',
      expand: !!searchPayload.workFromHome,
    },
    {
      name: 'Domain',
      data: domain,
      targetKey: 'domain',
      defaultValue: searchPayload.domain,
      element: 'dropdown',
      type: 'single',
    },
    {
      name: 'Industry',
      data: associatedIndustries,
      targetKey: 'industry',
      defaultValue: searchPayload.industry,
      element: 'dropdown',
      type: 'multi',
    },
    {
      name: 'Roles Performed',
      data: rolesPerformed,
      targetKey: 'role',
      defaultValue: searchPayload.role,
      element: 'dropdown',
      type: 'multi',
    },
    {
      name: 'Skillset',
      data: skills,
      targetKey: 'skillSet',
      defaultValue: searchPayload.skillSet,
      element: 'dropdown',
      type: 'multi',
    },
    {
      name: 'Work Type',
      data: worktype,
      targetKey: 'workType',
      defaultValue: searchPayload.workType,
      element: 'checkbox',
      expand: !!searchPayload.workType.length,
    },
    {
      name: 'Hiring Preference',
      data: hired,
      targetKey: 'hiringType',
      defaultValue: searchPayload.hiringType,
      element: 'checkbox',
      expand: !!searchPayload.hiringType.length,
    },
    {
      name: 'Employer Type',
      data: empType,
      targetKey: 'employerType',
      defaultValue: searchPayload.employerType,
      element: 'checkbox',
      expand: !!searchPayload.employerType.length,
    },
    {
      name: 'Working Type',
      data: workingType,
      targetKey: 'workingTime',
      defaultValue: searchPayload.workingTime,
      element: 'checkbox',
      expand: !!searchPayload.workingTime.length,
    },
    {
      name: 'Duration of Work',
      data: workDuration,
      targetKey: 'durationOfWork',
      defaultValue: searchPayload.durationOfWork,
      element: 'checkbox',
      expand: !!searchPayload.durationOfWork.length,
    },
    {
      name: 'Experience',
      data: experience,
      targetKey: 'experience',
      defaultValue: searchPayload.experience,
      element: 'checkbox',
      expand: !!searchPayload.experience.length,
    },
    {
      name: 'Salary Payment type',
      data: salaryMode,
      targetKey: 'salaryMode',
      defaultValue: searchPayload.salaryMode,
      element: 'checkbox',
      expand: !!searchPayload.salaryMode.length,
    },
    {
      name: 'Annual Salary',
      data: salRange,
      targetKey: 'annualSalary',
      defaultValue: searchPayload.annualSalary,
      element: 'radio',
      expand: !!searchPayload.annualSalary,
    },
    {
      name: 'Age Group',
      data: ageRange,
      targetKey: 'age',
      defaultValue: searchPayload.age,
      element: 'radio',
      expand: !!searchPayload.age,
    },
    {
      name: 'Gender',
      data: gender,
      targetKey: 'gender',
      defaultValue: searchPayload.gender,
      element: 'checkbox',
      expand: !!searchPayload.gender.length,
    },
    {
      name: 'Physical Status',
      data: physicalStatus,
      targetKey: 'physicalStatus',
      defaultValue: searchPayload.physicalStatus,
      element: 'checkbox',
      expand: !!searchPayload.physicalStatus.length,
    },
    {
      name: 'Allowances Available',
      data: jobAllowance,
      targetKey: 'allowanceProvision',
      defaultValue: searchPayload.allowanceProvision,
      element: 'checkbox',
      expand: !!searchPayload.allowanceProvision.length,
    },
    {
      name: 'Time to fill Vacancy',
      data: timeToFillPost,
      targetKey: 'requirementUrgency',
      defaultValue: searchPayload.requirementUrgency,
      element: 'checkbox',
      expand: !!searchPayload.requirementUrgency.length,
    },
    {
      name: 'Company Name',
      data: companyNameList,
      targetKey: 'companyName',
      defaultValue: searchPayload.companyName,
      element: 'dropdown',
      type: 'multi',
      expand: !!searchPayload.companyName.length,
    },
  ];
};

export const initialState = (locations, keywords) => ({
  age: '',
  allowanceProvision: [],
  annualSalary: '',
  distanctInKm: 0,
  durationOfWork: [],
  employerType: [],
  experience: [],
  gender: [],
  hiringType: [],
  keywords,
  industry: [],
  locations,
  locationTypes: [],
  page: 0,
  salaryMode: [],
  physicalStatus: [],
  postingDate: [],
  requirementUrgency: [],
  role: [],
  size: 10,
  skillSet: [],
  sortBy: '',
  workFromHome: '',
  workType: [],
  workingTime: [],
  companyName: [],
  domain: [],
});

export const targetKeys = [
  'postingDate',
  'locations',
  'locationType',
  'workType',
  'hiringType',
  'employerType',
  'workingTime',
  'workFromHome',
  'durationOfWork',
  'experience',
  'salaryMode',
  'annualSalary',
  'age',
  'gender',
  'physicalStatus',
  'industry',
  'role',
  'skillSet',
  'allowanceProvision',
  'requirementUrgency',
  'distanctInKm',
  'companyName',
  'domain',
];
