import React, { Fragment, memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import { createStructuredSelector } from 'reselect';
import reducer from 'containers/FranchiseViewDetails/reducer';
import saga from 'containers/FranchiseViewDetails/saga';
import makeSelectFranchiseDetails from 'containers/FranchiseViewDetails/selectors';
import { loadFranchiseeDetails } from 'containers/FranchiseViewDetails/actions';
import makeSelectGlobalStore from 'containers/App/selectors';
import EditTermsAndConditions from 'components/EditTermsAndConditions';
import * as actions from './actions';

export const EditTermsAndConditionsView = (props) => {
  useInjectReducer({ key: 'franchiseDetail', reducer });
  useInjectSaga({ key: 'franchiseDetail', saga });

  const { loadData } = props;

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Fragment>
      <Helmet>
        <title>Terms and Conditions</title>
        <meta
          name="description"
          content="Description of Terms and Conditions"
        />
      </Helmet>
      <EditTermsAndConditions {...props} />
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  franchiseDetailStore: makeSelectFranchiseDetails(),
  globalStore: makeSelectGlobalStore(),
});

const mapDispatchToProps = (dispatch) => ({
  saveFranchiseeData: (e) => dispatch(actions.saveFranchiseeData(e)),
  loadData: () => dispatch(loadFranchiseeDetails()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(EditTermsAndConditionsView);
