/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, Fragment } from 'react';
import { pathOr } from 'ramda';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import makeSelectGlobalStore from '../../../containers/App/selectors';
import faviconImage from '../../../images/FAVICON.png';
import userLogo from '../../../images/PROFILE_COMMON_HEADER.png';
import duftherLogoName from '../../../images/DUFTHER_NAME_LOGO.png';
import * as eDashboardActions from '../../../containers/EmployerDashboard/actions';
import { displayNotification } from 'config/toastConfig';

import './franchise-dashboard-sidebar.css';

let FranchiseDashboardSidebar = (props) => {
  const {
    globalStore,
    showSideMenu,
    showMobileMenu,
    toggleMobileSideMenu,
    toggleWebSideMenu,
    toggleSearchMenu,
    closeSearchMenu,
  } = props;

  const { dashboardRole } = globalStore;
  const userRoleData = pathOr('', ['userRoleData'], globalStore);
  const userLastLogin = pathOr('', ['userData', 'lastLogin'], globalStore);
  const userStatus = pathOr('', ['userRoleData', 'status'], globalStore);
  let userNewName = `${userRoleData?.firstName} ${userRoleData?.lastName}`;

  const handleInactiveMessage = () => {
    if (
      dashboardRole === 'Franchisee' &&
      !globalStore?.userRoleData?.isSOActive
    ) {
      displayNotification(
        'Your Contract is Expired and SO ID is no longer active. Kindly contact the admin at the earliest.',
        'warning',
        2000
      );
    }
  };

  const renderUser = () => (
    <div className="user">
      <div className="photo">
        <img src={userLogo} alt="img" />
      </div>
      <div className="info " style={{ cursor: 'pointer' }}>
        <a data-toggle="collapse" className="collapsed">
          <OverlayTrigger
            key=""
            placement="bottom"
            overlay={<Tooltip>{userNewName}</Tooltip>}
          >
            <span className="profile-name">
              {userNewName?.length < 20
                ? userNewName
                : userNewName?.substring(0, 20) + ' ...'}
              <b className="caret"></b>
            </span>
          </OverlayTrigger>
        </a>
      </div>
    </div>
  );

  const renderContract = () => (
    <ul className="user nav pb-1 employer-dashboard-navbar">
      <li className="nav-item">
        <Link
          to={
            userStatus === 'INACTIVE'
              ? '/fdashboard'
              : '/fdashboard/commercials'
          }
          className="nav-link mt-0"
          data-toggle="collapse"
          onClick={() => {
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
            closeSearchMenu();
          }}
        >
          <i className="fa fa-money" aria-hidden="true"></i>
          <p>Commercials</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={'/fdashboard/contract-information'}
          className="nav-link mt-0"
          data-toggle="collapse"
          onClick={() => {
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
            closeSearchMenu();
          }}
        >
          <i className="fa fa-flag-o" aria-hidden="true"></i>
          <p>Plan Details</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={
            userStatus === 'INACTIVE'
              ? '/fdashboard'
              : '/fdashboard/associations'
          }
          className="nav-link mt-0"
          data-toggle="collapse"
          onClick={() => {
            handleInactiveMessage();
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
            closeSearchMenu();
          }}
        >
          <i className="fa fa-plug" aria-hidden="true"></i>
          <p>Associations</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={
            userStatus === 'INACTIVE' ? '/fdashboard' : '/fdashboard/bulk-users'
          }
          className="nav-link mt-0"
          data-toggle="collapse"
          onClick={() => {
            handleInactiveMessage();
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
            closeSearchMenu();
          }}
        >
          <i className="fa fa-cloud-upload" aria-hidden="true"></i>
          <p>Bulk Users</p>
        </Link>
      </li>
    </ul>
  );

  const renderCreateRole = () => (
    <ul className="user nav pb-1 employer-dashboard-navbar">
      <li className="nav-item">
        <Link
          to="/fdashboard"
          className="nav-link mt-0"
          data-toggle="collapse"
          onClick={() => {
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
            toggleSearchMenu();
          }}
        >
          <i className="fa fa-search" aria-hidden="true"></i>
          <p>Search Candidates</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={
            userStatus === 'INACTIVE'
              ? '/fdashboard'
              : '/fdashboard/createprofiles'
          }
          className="nav-link mt-0"
          data-toggle="collapse"
          onClick={() => {
            handleInactiveMessage();
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
            closeSearchMenu();
          }}
        >
          <i className="fa fa-plus-circle" aria-hidden="true" />
          <p>Create Users</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={
            userStatus === 'INACTIVE'
              ? '/fdashboard'
              : '/fdashboard/empanelment-request-franch-to-company'
          }
          className="nav-link mt-0"
          data-toggle="collapse"
          onClick={() => {
            handleInactiveMessage();
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
            closeSearchMenu();
          }}
        >
          <i className="fa fa-file-word-o" aria-hidden="true"></i>
          <p>Create Contract</p>
        </Link>
      </li>

      <li className="nav-item">
        <Link
          to={
            userStatus === 'INACTIVE'
              ? '/fdashboard'
              : '/fdashboard/fdashboard-associated'
          }
          className="nav-link mt-0"
          data-toggle="collapse"
          onClick={() => {
            handleInactiveMessage();
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
            closeSearchMenu();
          }}
        >
          <i className="fa fa-link" aria-hidden="true" />
          <p>Map to Jobs</p>
        </Link>
      </li>
      <li className="nav-item">
        <button
          className="nav-link"
          style={{ background: 'none' }}
          onClick={() => {
            window.open(
              'https://dufther.atlassian.net/servicedesk/customer/portal/1',
              '_blank',
              'noopener,noreferrer'
            );
          }}
        >
          <i
            className="fa fa-wrench"
            style={{ float: showSideMenu || showMobileMenu ? 'left' : 'none' }}
          />
          <p>Report Issues</p>
        </button>
      </li>
    </ul>
  );

  const renderNav = () => (
    <ul className="nav user pb-1 employer-dashboard-navbar">
      <li className="nav-item">
        <Link
          to="/fdashboard"
          className="nav-link mt-0"
          data-toggle="collapse"
          onClick={() => {
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
            closeSearchMenu();
          }}
        >
          <i className="fa fa-address-card-o" aria-hidden="true"></i>
          <p>
            Dashboard <span className="dash_profile_percent"></span>
          </p>
        </Link>
      </li>

      <li className="nav-item">
        <Link
          to="/fdashboard/fdashboard-details"
          className="nav-link mt-0 p-0"
          data-toggle="collapse"
          onClick={() => {
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
            closeSearchMenu();
          }}
        >
          <i className="fa fa-user-circle" aria-hidden="true"></i>
          <p>My Profile</p>
        </Link>
      </li>

      <li className="nav-item">
        <Link
          to="/fdashboard/service-offerings"
          className="nav-link mt-0 p-0"
          data-toggle="collapse"
          onClick={() => {
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
            closeSearchMenu();
          }}
        >
          <i className="fa fa-truck" aria-hidden="true"></i>
          <p>Service Offerings</p>
        </Link>
      </li>
    </ul>
  );

  const renderLogo = () => (
    <Fragment>
      <div className="logo">
        <Link className="simple-text logo-mini" to="/fdashboard">
          <img src={faviconImage} alt="duftherLogo" />
        </Link>
        <Link className="simple-text logo-normal" to="/fdashboard">
          <img src={duftherLogoName} alt="img" />
        </Link>
        <Link
          className="simple-text logo-mini mt-globe-sidebar padding-globe-top"
          to="/fdashboard"
        >
          <i
            className="fa fa-globe"
            aria-hidden="true"
            style={{ color: '#11527e' }}
          ></i>
        </Link>
        <Link
          className="last-logged-date simple-text logo-normal padding-globe-top"
          to="/fdashboard"
        >
          <span className="last-logged-label">Last Logged</span>
          <span> &nbsp;{userLastLogin}</span>
        </Link>
      </div>
    </Fragment>
  );

  return (
    <div
      className={`sidebar ${!showSideMenu ? 'sidebar-mini-slip' : ''} ${
        showMobileMenu ? 'revised-show-mob-menu' : 'revised-hide-mob-menu'
      }`}
      id="sidebar_dash"
    >
      <div
        className={`sidebar-wrapper ${
          !showSideMenu ? 'sidebar-mini-slip' : ''
        }`}
      >
        {renderLogo()}
        {userRoleData && renderUser()}
        {renderNav()}
        {renderContract()}
        {renderCreateRole()}
      </div>
    </div>
  );
};

FranchiseDashboardSidebar.propTypes = {
  globalStore: PropTypes.object.isRequired,
};

// select data from redux store
const mapStateToProps = createStructuredSelector({
  globalStore: makeSelectGlobalStore(),
});

// dispatch actions
const mapDispatchToProps = (dispatch) => ({
  getAvatar: (id) => dispatch(eDashboardActions.getAvatar(id)),
});

// connection of redux form with state & action dispatcher
const NewSidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(FranchiseDashboardSidebar);

export default compose(memo(NewSidebar));
