/*
 *
 * CdChangeMobile constants
 *
 */

export const GET_CONFIG_DATA = 'app/EmpCandidateSearch/GET_CONFIG_DATA';
export const SET_CONFIG_DATA = 'app/EmpCandidateSearch/SET_CONFIG_DATA';
export const SEARCH_CANDIDATE = 'app/EmpCandidateSearch/SEARCH_CANDIDATE';
export const SET_SEARCH_RESULT = 'app/EmpCandidateSearch/SET_SEARCH_RESULT';
export const SET_SEARCH_RESULT_ERROR =
  'app/EmpCandidateSearch/SET_SEARCH_RESULT_ERROR';
export const SET_PAYLOAD_DATA = 'app/EmpCandidateSearch/SET_PAYLOAD_DATA';
export const SET_NOTIFICATION_ON = 'app/EmpCandidateSearch/SET_NOTIFICATION_ON';
export const SET_NOTIFICATION_OFF =
  'app/EmpCandidateSearch/SET_NOTIFICATION_OFF';
export const SAVE_JOB = 'app/EmpCandidateSearch/SAVE_JOB';
export const DELETE_JOB = 'app/EmpCandidateSearch/DELETE_JOB';
export const SET_PAGE_NUMBER_TO_STORE =
  'app/EmpCandidateSearch/SET_PAGE_NUMBER_TO_STORE';
export const APPLY_JOB = 'app/EmpCandidateSearch/APPLY_JOB';
export const GET_NEAREST_LOCATION =
  'app/EmpCandidateSearch/GET_NEAREST_LOCATION';
export const SET_NEAREST_LOCATION =
  'app/EmpCandidateSearch/SET_NEAREST_LOCATION';
export const GET_COMPANY_ID = 'app/EmpCandidateSearch/GET_COMPANY_ID';
export const SET_COMPANY_ID = 'app/EmpCandidateSearch/SET_COMPANY_ID';
export const GET_JOB_LIST = `app/EmpCandidateSearch/GET_JOB_LIST`;
export const SET_JOB_LIST = `app/EmpCandidateSearch/SET_JOB_LIST`;
export const GET_CONTACT_DATA = `app/EmpCandidateSearch/GET_CONTACT_DATA`;
export const SET_CONTACT_DATA = `app/EmpCandidateSearch/SET_CONTACT_DATA`;
export const SEND_CANDIDATE_INVITE = `app/EmpCandidateSearch/SEND_CANDIDATE_INVITE`;
export const CANDIDATE_FRANCHISEE_SHORTLISTING = `app/EmpCandidateSearch/CANDIDATE_FRANCHISEE_SHORTLISTING`;
export const SELECT_JOB_ID = `app/EmpCandidateSearch/SELECT_JOB_ID`;
export const DOWNLOAD_RESUME = `app/EmpCandidateSearch/DOWNLOAD_RESUME`;
export const VIEW_PROFILE = `app/EmpCandidateSearch/VIEW_PROFILE`;

export const GET_PRICING_FIREWALL_CHECK =
  'app/EmpCandidateSearch/GET_PRICING_FIREWALL_CHECK';
export const SET_PRICING_FIREWALL_CHECK =
  'app/EmpCandidateSearch/SET_PRICING_FIREWALL_CHECK';

export const GET_MANAGE_CANDIDATE =
  'app/EmpCandidateSearch/GET_MANAGE_CANDIDATE';

export const SET_MANAGE_CANDIDATE =
  'app/EmpCandidateSearch/SET_MANAGE_CANDIDATE';

export const SET_MANAGE_CANDIDATE_DATA =
  'app/EmpCandidateSearch/SET_MANAGE_CANDIDATE_DATA';

export const RESET_MANAGE_CANDIDATE =
  'app/EmpCandidateSearch/RESET_MANAGE_CANDIDATE';
export const SET_FIREWALL_MESSAGE =
  'app/EmpCandidateSearch/SET_FIREWALL_MESSAGE';
