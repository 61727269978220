import React from 'react';

import Error from './Error';

const getError = (name, errors) => {
  let error = errors;
  if (name?.includes('.')) {
    name?.split('.')?.forEach((x) => {
      if (error) {
        error = error[x];
      }
    });
  } else {
    error = errors[name];
  }

  return error;
};

const getErrorFromUseFieldArray = (id, name, errors) => {
  const arrayName = name?.includes('[') ? name.split('[')[0] : '';
  const fieldName = name?.includes('.') ? name.split('.')[1] : '';
  return errors[arrayName][id] ? errors[arrayName]?.[id]?.[fieldName] : {};
};

const Input = React.forwardRef(
  (
    {
      name,
      label,
      errors = null,
      id,
      labelClass,
      className,
      required,
      wrapperClass,
      type = 'text',
      defaultValue,
      fromUseFieldArray = false,
      helptext,
      helptextClass,
      newError,
      handleChange = (e) => {
        if (name === 'firstName' || name === 'lastName') {
          const capitalizedValue =
            e.target.value.charAt(0).toUpperCase() +
            e.target.value.slice(1).toLowerCase();
          e.target.value = capitalizedValue;
        }
      },
      onChange = (e) => {
        if (name === 'firstName' || name === 'lastName') {
          const capitalizedValue =
            e.target.value.charAt(0).toUpperCase() +
            e.target.value.slice(1).toLowerCase();
          e.target.value = capitalizedValue;
        }
      },
      onBlur,
      ...props
    },
    ref
  ) => {
    let error = {};
    if (errors && Object.keys(errors)?.length && fromUseFieldArray) {
      error = getErrorFromUseFieldArray(id, name, errors);
    } else if (errors && Object.keys(errors)?.length) {
      error = getError(name, errors);
    }

    return (
      <>
        <div className={`form-group ${wrapperClass || ''}`}>
          {label && required ? (
            <label htmlFor={id} className={labelClass || 'lbl_info'}>
              {label}
              <star>*</star>
            </label>
          ) : !label && required ? (
            <star>*</star>
          ) : label && !required ? (
            <label htmlFor={id} className={labelClass || 'lbl_info'}>
              {label}
            </label>
          ) : null}
          {name === 'docketId' && (
            <div
              style={{ color: 'grey', fontSize: '10px', fontStyle: 'italic' }}
            >
              Please contact support@dufther.com to generate your docket ID
            </div>
          )}
          <input
            ref={ref}
            {...props}
            name={name}
            id={id}
            className={className || 'form-control txt_animation'}
            type={type}
            defaultValue={defaultValue}
            onChange={onChange || handleChange}
            onBlur={onBlur}
          />
          {newError ? (
            <Error {...newError} />
          ) : error && Object.keys(error).length ? (
            <Error {...error} />
          ) : (
            ''
          )}
          <div className={'txt_age_msg font-italic ' + helptextClass}>
            {helptext}
          </div>
        </div>
      </>
    );
  }
);

export default Input;
