import { displayNotification } from 'config/toastConfig';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

export const handleViewLogs = () => {
  displayNotification(
    'We are presently working on enabling this feature, we appreciate your patience',
    'info'
  );
};

export const isUsernamePhoneNumber = (username) => {
  return username?.length === 10 && /^\d+$/.test(username);
};