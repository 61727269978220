/* eslint-disable eqeqeq */
import { call } from 'redux-saga/effects';
import moment from 'moment';
import history from '../utils/history';
import * as apiName from '../config/apiUrl';
import request, { upload } from '../utils/request';
import {
  baseUrl,
  googleMapProdApiKey,
  // googleMapDevApiKey,
} from '../config/config';
import {
  geocodeByPlaceId,
  geocodeByLatLng,
} from 'react-google-places-autocomplete';
// import { convertSelectOpt } from '../utils/form';

// check if user mobile exists
export const checkMobilePresent = () => {
  const number = window.localStorage.getItem('Mobile');
  if (number) {
    return true;
  }
  return history.push('/');
};
// check if user mobile or email exists
export const checkMobileOrEmailPresent = () => {
  const number = window.localStorage.getItem('Mobile');
  const email = window.localStorage.getItem('Email');
  if (number || email) {
    return true;
  }
  return history.push('/');
};

// check if user is logged in
export const checkIsLoggedIn = () => {
  const Bearer = window.localStorage.getItem('Bearer');
  const userId = window.localStorage.getItem('userId');
  const mobile = window.localStorage.getItem('Mobile');
  const email = window.localStorage.getItem('Email');
  if (Bearer && userId && (mobile || email)) {
    return true;
  }
  return false;
};

// function to check user authentication
export const isAuthenticated = () => {
  const Bearer = window.localStorage.getItem('Bearer');
  const userId = window.localStorage.getItem('userId');
  const mobile = window.localStorage.getItem('Mobile');
  const email = window.localStorage.getItem('Email');
  if (Bearer && userId && (mobile || email)) {
    return true;
  }
  return history.push('/');
};

// user logout script
export const logUserOut = () => {
  localStorage.clear();
  sessionStorage.clear();

  //removed hard coded redirection path for logout.
  window.location.href = window.location.href
    .substring(0, window.location.href.indexOf('dashboard') - 1)
    ?.replace('portal.', '');
};

// global function to call all types of api
export const callApi = (
  url,
  data = null,
  type = 'GET',
  fileOpr = { fileUpload: false, fileResponse: false }
) => {
  let response;
  try {
    const requestformdata = {
      method: type,
    };
    if (data) {
      if (fileOpr.fileUpload) {
        requestformdata.body = data;
      } else {
        requestformdata.body = JSON.stringify(data);
      }
    }
    const apiUrl = baseUrl + url;
    response = call(
      fileOpr.fileUpload ? upload : request,
      apiUrl,
      requestformdata,
      fileOpr.fileResponse
    );
  } catch (error) {
    return false;
  }
  return response;
};

// get time difference bentween to dates
export const getTimeDifference = (oldDate) => {
  const now = moment(new Date());
  const last = moment(oldDate);
  const duration = moment.duration(now.diff(last));
  const timeDifference = duration.asSeconds();
  return Math.floor(timeDifference);
};

// function to scroll page towards field error
export const scrollToInvalid = (errors) => {
  if (errors) {
    const val = Object.keys(errors);
    const val1 = val[0];
    const key = errors[val1];
    let invalidInput = val1;
    if (typeof key === 'object') {
      const val2 = Object.keys(errors[val1]);
      const val3 = `${val1}.${val2[0]}`;
      invalidInput = val3;
    }
    // const invalidInput = _.findKey(errors, key => key !== undefined);
    const field = document
      .getElementsByName(invalidInput)[0]
      .getBoundingClientRect().top;
    window.scrollTo(0, window.pageYOffset + (field - 200));
  }
};

// Fetch location/cities from api
export const fetchLocations = async (data, method, loadingStatus) => {
  loadingStatus(true);
  const Bearer = window.localStorage.getItem('Bearer');
  const locationURL = `${baseUrl}/api/city/search?keyword=${data}`;
  return fetch(`${locationURL}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Bearer}`,
    },
  })
    .then((response) => response.json())
    .then((result) => {
      loadingStatus(false);
      method(result);
    });
};

// Fetch delete reasons from api
export const fetchDeleteReasons = async (method) => {
  const Bearer = window.localStorage.getItem('Bearer');
  const deleteURL = `${baseUrl}${apiName.deleteReasonURL}`;
  return fetch(`${deleteURL}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Bearer}`,
    },
  })
    .then((response) => response.json())
    .then((result) => {
      method(result);
    });
};

// Fetch location/cities from api
export const fetchNewLocations = async (data) => {
  const Bearer = window.localStorage.getItem('Bearer');
  const locationURL = `${baseUrl}/api/city/search?keyword=${data}`;
  return fetch(`${locationURL}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Bearer}`,
    },
  }).then((response) => response.json());
};

// Fetch cities from google api
export const fetchGooglePlaces = async (searchTerm) => {
  const locationURL = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${searchTerm}&key=${googleMapProdApiKey}`;
  const resp = await fetch(`${locationURL}`, {
    method: 'GET',
  });
  const locationsResp = await resp.json();
  return locationsResp.status === 'OK' ? locationsResp.predictions : [];
};

// get coordinates
export const setCoordinates = (method) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude.toFixed(6);
        const longitude = position.coords.longitude.toFixed(6);
        const distanctInKm = 100;
        const dummyQuery = `${baseUrl}/api/city/nearest?distanctInKm=${distanctInKm}&latitude=${latitude}&longitude=${longitude}`;
        fetch(dummyQuery, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.localStorage.getItem('Bearer')}`,
          },
        })
          .then((res) => res.text())
          .then((data) => method(data));
      },
      function error(msg) {
        // eslint-disable-next-line
        alert('Please enable your GPS position feature.', msg);
      },
      { timeout: 5000, enableHighAccuracy: true }
    );
  } else {
    // eslint-disable-next-line
    console.log('no location found');
  }
};

// Fetch uploaded image & display
export const fetchImageById = async (profileId, setMethod) => {
  const token = window.localStorage.getItem('Bearer');
  await fetch(`${baseUrl}/api/documents/${profileId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((result) => result.blob())
    .then((blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64Data = reader.result;
        setMethod(base64Data);
        return true;
      };
    });
};

// convert timestamp format to dd/mm/yyyy
export const convertTimeStampToDate = (str) => {
  let date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join('-');
};

// convert timestamp to dd/mm/yyyy hh:mm
export const convertTimeStampToFormatDate = (str) => {
  return moment(str).format('MMM DD,YYYY HH:mm a');
};

// Fetch document
export const fetchDocument = async (documentId) => {
  const token = window.localStorage.getItem('Bearer');
  return fetch(`${baseUrl}/api/documents/${documentId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((result) => result.blob())
    .then((blob) => window.URL.createObjectURL(blob));
};

//delete document
export const deleteDocument = async (documentId) => {
  const token = window.localStorage.getItem('Bearer');
  return fetch(`${baseUrl}/api/documents/${documentId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((result) => result.json())
    .then((res) => res);
};

// get age difference in years from current date
export const getAgeYearsDifference = (dobDate) => {
  if (dobDate) {
    const currentBirth = dobDate.split('/');
    const birthDateTime = new Date(
      currentBirth[2],
      currentBirth[1] - 1,
      currentBirth[0]
    );
    const currentDate = new Date();
    const yearsDifference = currentDate - birthDateTime;
    // time difference in milliseconds
    const currentAge = Math.floor(yearsDifference / 31557600000);
    return currentAge;
  }
};
// convert month to number
export const getMonthFromString = (mon) => {
  const d = Date.parse(mon + '1, 2012');
  if (!isNaN(d)) {
    return new Date(d).getMonth() + 1;
  }
  return -1;
};

export const getDateDDMMYYY = (day, mon, year) => {
  return day && mon && year ? `${day}/${getMonthFromString(mon)}/${year}` : '';
};

export const getStringFromMonth = (mon) => {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][Number(mon) - 1];
};

// capitalize first letter of each word in string
export const capitalizeFirstLetter = (s) => {
  if (typeof s !== 'string') return '';
  var splitStr = s.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const sortYearInDescending = (data) => {
  const newData = [...data];

  return newData && newData.length
    ? newData.sort((a, b) => a.substring(0, 4) > b.substring(0, 4)).reverse()
    : [];
};

// Fetch cities
export const fetchCitiesByStates = async (stateName, method) => {
  const citiesURL = `${baseUrl}${apiName.citySearchURL}INDIA_${escape(
    stateName
  )}_CITIES`;
  const response = await fetch(`${citiesURL}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('Bearer')}`,
    },
  });
  const result = await response.json();
  return method(result);
};

export const addGoogleCity = async (mapObj) => {
  mapObj = Array.isArray(mapObj) ? mapObj : [mapObj];

  const citiesWithPlaceId = mapObj?.filter((obj) => obj?.place_id);
  if (citiesWithPlaceId.length === 0) {
    return;
  }

  const reqPromises = citiesWithPlaceId.map((objs) =>
    geocodeByPlaceId(objs.place_id)
  );
  const citiesGeoCodes = await Promise.all(reqPromises);

  const body = citiesGeoCodes.map((cityGeoCode) => {
    return {
      latitude: cityGeoCode[0].geometry?.location?.lat() || '',
      longitude: cityGeoCode[0].geometry?.location?.lng() || 0,
      location: cityGeoCode[0].address_components[0]?.long_name || '',
    };
  });
  fetch(`${baseUrl}/api/city/addAll`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('Bearer')}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((result) => {});
};

export const addGoogleCityJobPreferences = async (mapObj) => {
  mapObj = Array.isArray(mapObj) ? mapObj : [mapObj];
  const newPlaceId = mapObj?.filter((obj) => obj?.value?.place_id);
  const citiesWithPlaceId = newPlaceId?.filter(
    (data) => data?.value?.place_id != data?.label
  );
  if (citiesWithPlaceId.length === 0) {
    return;
  }
  const reqPromises = citiesWithPlaceId.map((objs) =>
    geocodeByPlaceId(objs?.value?.place_id)
  );

  const citiesGeoCodes = await Promise.all(reqPromises);

  const body = citiesGeoCodes.map((cityGeoCode) => {
    return {
      latitude: cityGeoCode[0].geometry?.location?.lat() || '',
      longitude: cityGeoCode[0].geometry?.location?.lng() || 0,
      location: cityGeoCode[0].address_components[0]?.long_name || '',
    };
  });
  fetch(`${baseUrl}/api/city/addAll`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('Bearer')}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((result) => {});
};

export const getGeocodeByPlaceId = async (placeObjs) => {
  const reqPromises =
    placeObjs && placeObjs.length
      ? placeObjs.map((loc) => geocodeByPlaceId(loc.place_id))
      : [];
  const geoCodesResponse = await Promise.all(reqPromises);

  if (geoCodesResponse.length === 0) {
    return;
  }

  return geoCodesResponse.map((cityGeoCode, i) => {
    const labelObj = cityGeoCode[0].address_components.filter(
      (locObj) =>
        locObj.types.includes('premise') ||
        locObj.types.includes('sublocality_level_3') ||
        locObj.types.includes('sublocality_level_2') ||
        locObj.types.includes('sublocality_level_1') ||
        locObj.types.includes('locality') ||
        locObj.types.includes('administrative_area_level_2')
    );
    let final_local_address = '';
    let separator = '';
    for (let obj of labelObj) {
      final_local_address += separator + obj.long_name;
      separator = ', ';
    }
    return {
      latitude: cityGeoCode[0].geometry?.location?.lat() || '',
      longitude: cityGeoCode[0].geometry?.location?.lng() || 0,
      location: cityGeoCode[0].formatted_address,
    };
  });
};

export const getGeocodeByLatLng = async (lat, lng, callback) => {
  const results = await geocodeByLatLng({ lat, lng });
  const firstLoc = results[0];

  const cityObj = firstLoc.address_components.filter(
    (locObj) =>
      locObj.types.includes('administrative_area_level_2') ||
      locObj.types.includes('administrative_area_level_1') ||
      locObj.types.includes('country')
  );

  /* formatting detailed address */
  const labelObj = firstLoc.address_components.filter(
    (locObj) =>
      locObj.types.includes('premise') ||
      locObj.types.includes('sublocality_level_3') ||
      locObj.types.includes('sublocality_level_2') ||
      locObj.types.includes('sublocality_level_1') ||
      locObj.types.includes('locality') ||
      locObj.types.includes('administrative_area_level_2')
  );

  const shortAddrObj = firstLoc.address_components.filter(
    (locObj) =>
      locObj.types.includes('premise') ||
      locObj.types.includes('sublocality_level_3') ||
      locObj.types.includes('sublocality_level_2') ||
      locObj.types.includes('sublocality_level_1') ||
      locObj.types.includes('locality') ||
      locObj.types.includes('administrative_area_level_2')
  );
  // const labels = cityObj.map((locObj) => {
  //   return locObj.long_name;
  // });
  let final_local_address = '';
  let separator = '';
  for (let obj of shortAddrObj) {
    final_local_address += separator + obj.long_name;
    separator = ', ';
  }
  // callback({
  //   place_id: firstLoc.place_id,
  //   label: final_local_address,
  //   value: cityObj[0].long_name,
  // });

  callback({
    place_id: firstLoc.place_id,
    label: results[0].formatted_address,
    short_address: final_local_address,
    value: cityObj[0].long_name,
  });
};

// return recruiter name & id based on data
export const recruiterNameOnId = (data) => {
  return {
    label: data[0]?.firstName + ' ' + data[0]?.lastName,
    value: data[0]?.recruiterId,
  };
};

// check if file extension is in array
export const fileTypeEndsWith = (fileName, fileTypes) => {
  let value = false;
  value = fileTypes.some((element) => {
    return fileName.endsWith(element);
  });
  return value;
};

export const serializeURLWithParams = (url, params) => {
  for (const [key, value] of Object.entries(params)) {
    url.searchParams.append(key, value);
  }
  return url;
};

// convert camel case to pascal case
export const convertCamelToPascal = (data) => {
  const caseData = data.replace(/([A-Z])/g, ' $1');
  return caseData.charAt(0).toUpperCase() + caseData.slice(1);
};

// convert CAPITAL_LETTER to Capital Letter format
export const convertCapitalUpperToPascal = (str) => {
  let i,
    frags = str?.split('_');
  if (str) {
    for (i = 0; i < frags.length; i++) {
      frags[i] =
        frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase();
    }
  }
  return frags?.join(' ');
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

// function to convert number to words
var a = [
  '',
  'One ',
  'Two ',
  'Three ',
  'Four ',
  'Five ',
  'Six ',
  'Seven ',
  'Eight ',
  'Nine ',
  'Ten ',
  'Eleven ',
  'Twelve ',
  'Thirteen ',
  'Fourteen ',
  'Fifteen ',
  'Sixteen ',
  'Seventeen ',
  'Eighteen ',
  'Nineteen ',
];
var b = [
  '',
  '',
  'Twenty',
  'Thirty',
  'Forty',
  'Fifty',
  'Sixty',
  'Seventy',
  'Eighty',
  'Ninety',
];
//  prettier-ignore
export const convertNumberToWords = (num) => {
  if ((num = num?.toString())?.length > 9) return 'overflow';
  let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return; var str = '';
  str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
  str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
  str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
  str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
  str += (n[5] != 0) ? ((str != '') ? 'And ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'Only ' : '';
  return str;
};

// convert dd/mm/yyyy to yyyy-mm-dd
export const convertDateFormat = (date) => {
  return date.split('/').reverse().join('-');
};

export const createParamToUrl = (data) => {
  let urlParam = [];
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      urlParam.push(`${key}=${data[key]}`);
    }
  }
  return urlParam;
};

export function createUrlParams(urlData) {
  let datasize = Object.keys(urlData)?.length;
  const keys = Object.keys(urlData);
  let search = '';
  if (datasize) {
    keys.forEach((key) => {
      if (
        urlData[key] !== null &&
        urlData[key] !== '' &&
        urlData[key] !== undefined
      ) {
        search += `${key}=${urlData[key]}&`;
      }
    });
    return search?.substring(0, search.length - 1);
  }
}

export const getValidityInDays = (endDate) => {
  if (endDate) {
    const end = moment(endDate, 'DD/MM/YYYY'); // Parse the endDate with the correct format
    const current = moment(); // No need to format here
    return end.diff(current, 'days'); // Swap end and current in the diff function
  }
  return '';
};

// get array of next 10 years from current
export const getYearsArray = () => {
  const startYear = 2020;
  const endYear = 2040;
  const yearsArray = Array.from({ length: endYear - startYear + 1 }, (_, i) =>
    String(startYear + i)
  );
  return yearsArray;
};

// convert pascal to upper case
export const pascalToUpperCase = (input, symbol = '_') => {
  const words = input?.split(' ');
  const joinedWords = words.map((word) => word.toUpperCase()).join(symbol);
  return joinedWords;
};

// break long string after certain length
export const breakLongString = (string, maxLength = 20) => {
  if (string.length <= maxLength) {
    return string;
  } else {
    return `${string.slice(0, maxLength)}...`;
  }
};

// compare passed date (DD/MM/YYYY) with todays date
export const isDatePassed = (date) => {
  if (!date) return;
  const newValue = date.split('/');
  const contractEnd = new Date(newValue[2], newValue[1] - 1, newValue[0]);
  const today = new Date();

  // Compare the dates
  return today >= contractEnd;
};

export const localStorageActions = {
  // Set a key-value pair in local storage
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch (error) {
      console.error('Error setting item in local storage:', error);
      return false;
    }
  },

  // Remove a key from local storage
  removeItem: (key) => {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (error) {
      console.error('Error removing item from local storage:', error);
      return false;
    }
  },

  // Get a value from local storage and parse it
  getItem: (key) => {
    try {
      const item = localStorage.getItem(key);
      if (item !== null) {
        return JSON.parse(item);
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error getting item from local storage:', error);
      return null;
    }
  },
};
