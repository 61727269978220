/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import EmployerSearch from '../../components/common/EmployerSearchBox';
import RecruiterDashboardSidebar from './sidebar/recruiterDashboardSidebar';
import EmployerDashboardHeader from '../common/dashboardHeader/dashboardHeader';
import CDTranslator from '../common/translator/FrontTranslator';
import EmployerDashboardFooter from '../common/dashboardFooter/dashboardFooter';
import { Breadcrumb } from '../candidateDashboardLayout/Breadcrumb';
import GearLoader from '../../components/LoadingIndicator/GearLoading';
import makeSelectGlobalStore from 'containers/App/selectors';
import * as gConst from '../../utility/GlobalConstants';
import * as gActions from 'containers/App/actions';
import { createStructuredSelector } from 'reselect';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';

import '../css/dashboard-layout.css';
import './recruiter-dashboard.css';

const CDashboardLayout = (props) => {
  const { addEmailHandler, addMobileHandler, globalStore } = props;
  const { userRoleData, dashboardRole } = globalStore || {};
  const isMob = useMediaQuery({ query: '(max-width: 667px)' });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  const params = useLocation();

  const [webSideMenu, toggleWebSideMenu] = useState(false);
  const [mobileSideMenu, toggleMobileSideMenu] = useState(false);
  const [mobileDropDownMenu, toggleMobileDropDownMenu] = useState(false);

  const renderTranslateBanner = () => (
    <>
      <div className="candidate-dashboard">
        <div className="container-fluid language-container">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-sm-4 col-md-4 search-candidates">
              <a
                href="#"
                className="togglebox-top"
                onClick={(e) => {
                  e.preventDefault();
                  toggleMobileDropDownMenu(!mobileDropDownMenu);
                }}
              >
                <span className="search-text-top">Search</span>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#fff' }}
                ></i>
              </a>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-12 text-right">
              <ul className="list-unstyled">
                <li>
                  <div id="ytWidget">
                    <CDTranslator />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {isMob && !userRoleData?.user?.email && (
        <div className="container-fluid">
          <div className="row mx-auto">
            <Button
              className="mt-3 btn btn-block"
              style={{ background: '#f7931e' }}
              onClick={() => addEmailHandler(true)}
            >
              Add Email
            </Button>
          </div>
        </div>
      )}
      {isMob && !userRoleData?.user?.userName && (
        <div className="container-fluid">
          <div className="row mx-auto">
            <Button
              className="mt-3 btn btn-block"
              style={{ background: '#f7931e' }}
              onClick={() => addMobileHandler(true)}
            >
              Add Mobile
            </Button>
          </div>
        </div>
      )}
    </>
  );

  const showMenuHandler = () => {
    toggleWebSideMenu(!webSideMenu);
  };

  const showMobileMenuHandler = () => {
    toggleMobileSideMenu(!mobileSideMenu);
  };

  return (
    <div className={`${!webSideMenu ? 'sidebar-mini' : ''}`}>
      <div className="wrapper candidate-dashboard">
        <RecruiterDashboardSidebar
          showSideMenu={webSideMenu}
          showMobileMenu={mobileSideMenu}
          toggleWebSideMenu={toggleWebSideMenu}
          toggleMobileSideMenu={toggleMobileSideMenu}
        />
        <div className={`main-panel `}>
          <EmployerDashboardHeader
            showSideMenu={webSideMenu}
            showMobileMenu={mobileSideMenu}
            showMenuHandler={showMenuHandler}
            showMobileMenuHandler={showMobileMenuHandler}
            isEmployer={true}
            currentRoute={'Recruiter'}
          />
          {renderTranslateBanner()}
          <EmployerSearch mobileDropDownMenu={mobileDropDownMenu} />
          <div className="content">
            <Breadcrumb
              {...props}
              params={params}
              parentLinkAddress={`/rdashboard`}
              parentLinkName="Dashboard"
              childLinkName={gConst.pageNameByPath(
                params?.pathname,
                dashboardRole
              )}
              childLinkAddress={params?.pathname}
            />
            {props.children}
          </div>
          <GearLoader />
          <EmployerDashboardFooter />
          <div
            className={`${mobileSideMenu ? 'close-layer visible' : ''}`}
            onClick={showMobileMenuHandler}
          ></div>
        </div>
      </div>
    </div>
  );
};

CDashboardLayout.propTypes = {
  children: PropTypes.any,
};

// select data from redux store
const mapStateToProps = createStructuredSelector({
  globalStore: makeSelectGlobalStore(),
});

// dispatch actions
const mapDispatchToProps = (dispatch) => ({
  addEmailHandler: (e) => dispatch(gActions.addEmailModalStatus(e)),
  addMobileHandler: (e) => dispatch(gActions.addMobileModalStatus(e)),
});

const RecruiterDashboardLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(CDashboardLayout);

export default compose(memo(RecruiterDashboardLayout));
