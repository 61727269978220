/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, Fragment, memo, useRef } from 'react';
import { pathOr } from 'ramda';
import { createStructuredSelector } from 'reselect';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button } from 'react-bootstrap';
import { setRoleForChannel } from 'containers/App/websocketSagaUtility';
import * as gActions from 'containers/App/actions';
import * as gService from 'utility/GlobalServices';
import * as candidateAction from 'containers/CandidateBasicAdvanceJobSearch/actions';
import makeSelectGlobalStore from 'containers/App/selectors';
import useOutSideCallback from 'hooks/useOutsideCallback.hook';
import RecruiterCompanyDropdown from 'containers/RecruiterCompanyDropdown';
import makeSelectCandidateDashboard from 'containers/CandidateDashboard/selectors';
import useToggle from 'hooks/toggleHook';
import InstituteNameDropdown from 'components/InstituteNameDropdown';
import AddEmailToAccount from 'components/AddEmailToAccount';
import AddMobileToAccount from 'components/AddMobileToAccount';
import { getDashboardPath } from 'utility/GlobalConstants';
import { displayNotification } from 'config/toastConfig';
import { useMediaQuery } from 'react-responsive';
import hubLogo from 'images/institute_placeholder.png';
import './dashboard-header.css';
import moment from 'moment';
import makeSelectEmployerDashboard from 'containers/EmployerDashboard/selectors';
import makeSelectEmployerPricingModel from 'containers/EmployerPricingModel/selectors';

function DashboardHeader({
  showSideMenu,
  showMenuHandler,
  showMobileMenuHandler,
  isEmployer,
  setDashboardRole,
  globalStore,
  currentRoute,
  setDashboardRoleUserData,
  getMatchingJobs,
  candidateStore,
  addEmailHandler,
  submitEmailForOtp,
  submitEmailChange,
  addMobileHandler,
  submitMobileForOtp,
  submitMobileChange,
  resetOtpFieldValue,
  loadEmpPricingModelData,
  employerDashboardStore,
  triggerOtp,
  setVerifyPanel,
  empPricingDataStore,
}) {
  const { empPricingModelData } = empPricingDataStore || {};
  const showRoleRef = useRef();
  const showSettingRef = useRef();
  const showActionCenterRef = useRef();
  const showDropDownRef = useRef();
  const showDropDownBtnRef = useRef();
  const roles = pathOr([], ['userRoleData', 'user', 'roles'], globalStore);
  const email = pathOr(
    localStorage.getItem('Email'),
    ['userRoleData', 'user', 'email'],
    globalStore
  );
  const mobile = pathOr(
    localStorage.getItem('Mobile'),
    ['userRoleData', 'user', 'userName'],
    globalStore
  );
  const status = pathOr(null, ['userRoleData', 'status'], globalStore);
  const contractEndDate = pathOr(
    null,
    ['userRoleData', 'contractEndDate'],
    globalStore
  );
  const agencyCompanyId = pathOr(
    null,
    ['userRoleData', 'company', 'companyId'],
    globalStore
  );
  const empCompanyId = pathOr(null, ['userRoleData', 'companyId'], globalStore);
  const { candidatePlan = {}, franchiseeProfilePlan = {} } = pathOr(
    {},
    ['empPricingModelData'],
    globalStore
  );
  const userRoleData = pathOr('', ['userRoleData'], globalStore);
  const userData = pathOr('', ['userData'], globalStore);
  const { isBackgroundVerified } = userRoleData || {};
  const institutePicId = pathOr(null, ['logoDocument', 'id'], userRoleData);
  const createdBy = pathOr(null, ['userData', 'createdByRole'], globalStore);
  const [userRoles, setUserRoles] = useState(roles);
  const [showActionCenter, setShowActionCenter] = useState(false);
  const [showSettings, setSettings] = useState(false);
  const [showRole, setShowRole] = useState(false);
  const [showDropdown, toggleShowDropdown, setShowDropdown] = useToggle();
  const history = useHistory();
  const params = useLocation();
  const [userRole, setUserRole] = useState(null);
  const { companyDetail } = employerDashboardStore || {};
  const { basicDetail } = companyDetail || {};
  const { verificationStatus, status: companyStatus } = basicDetail || {};
  useOutSideCallback(showRoleRef, () => setShowRole(false));
  const isMob = useMediaQuery({ query: '(max-width: 667px)' });
  useOutSideCallback(showActionCenterRef, () => setShowActionCenter(false));
  useOutSideCallback(showSettingRef, () => setSettings(false));
  useOutSideCallback([showDropDownRef, showDropDownBtnRef], () =>
    setShowDropdown(false)
  );
  const [institutePic, setInstitutePic] = useState(null);
  const endDate = pathOr('', ['candidatePlan', 'endDate'], empPricingModelData);
  const type = pathOr('FREE', ['candidatePlan', 'type'], empPricingModelData);
  const profiles = pathOr(
    0,
    ['candidatePlan', 'profiles'],
    empPricingModelData
  );
  const validity = pathOr(
    null,
    ['candidatePlan', 'validity'],
    empPricingModelData
  );

  useEffect(() => {
    gService.isAuthenticated();
  }, []);

  useEffect(() => {
    if (agencyCompanyId || empCompanyId) {
      loadEmpPricingModelData();
    }
  }, [agencyCompanyId, loadEmpPricingModelData]);

  useEffect(() => {
    const currentRoles = roles.filter((item) => item !== currentRoute);
    currentRoles.length && setUserRoles(currentRoles);
  }, [currentRoute, roles, userRole]);

  const [isContractExpired, setIsContractExpired] = useState(false);
  useEffect(() => {
    if (
      (contractEndDate && gService.getValidityInDays(contractEndDate) <= 0) ||
      status === 'INACTIVE'
    ) {
      setIsContractExpired(true);
    }
  }, [contractEndDate, status]);

  useEffect(() => {
    if (params.pathname === '/cdashboard') {
      setRoleForChannel('Candidate');
    } else if (params.pathname === '/edashboard') {
      setRoleForChannel('Employer');
    }
  }, [params.pathname]);

  useEffect(() => {
    setTimeout(() => {
      setDashboardRole(currentRoute);
    }, 0);
  }, [currentRoute, setDashboardRole]);

  useEffect(() => {
    if (institutePicId) {
      gService.fetchImageById(institutePicId, setInstitutePic);
    } else {
      setInstitutePic(null);
    }
  }, [institutePicId]);
  const userLength = userRoles.length;

  const settingsToggleHandler = (e) => {
    e.preventDefault();
    setSettings(!showSettings);
    setShowRole(false);
    setShowActionCenter(false);
  };

  const actionCenterToggleHandler = (e) => {
    e.preventDefault();
    setSettings(false);
    setShowRole(false);
    setShowActionCenter(!showActionCenter);
  };

  const roleToggleHandler = (evt) => {
    evt.preventDefault();
    setSettings(false);
    setShowActionCenter(false);
    setShowRole(!showRole);
  };

  const showRoleHandler = (evt, role) => {
    evt.preventDefault();
    setShowRole(!showRole);
    const userData = globalStore.userRolesData.find(
      (userRoleData) => userRoleData.role === role.toLowerCase()
    );
    setDashboardRoleUserData({
      role,
      userData: userData.data,
    });
    role === 'Employer' && history.push('/edashboard');
    role === 'Recruiter' && history.push('/rdashboard');
    if (
      role === 'Candidate' &&
      globalStore?.userData?.associatedFranchiseeCodes?.length > 0
    ) {
      history.push('/cdashboard/candidate-basic-advance-job-search');
      setTimeout(() => {
        getMatchingJobs();
      }, 10);
    } else if (
      role === 'Candidate' &&
      globalStore?.userData?.associatedFranchiseeCodes?.length === 0
    ) {
      history.push('/cdashboard/profile-menu');
    }
  };

  const { dashboardRole } = globalStore;

  useEffect(() => {
    setUserRole(getDashboardPath(dashboardRole));
  }, [dashboardRole]);

  const handleBuyPlanEvent = () => {
    switch (dashboardRole) {
      case 'Employer':
        history.push('/edashboard/commercials/emp-plan');
        break;
      case 'Franchisee':
        history.push('/fdashboard/franchisee-plan');
        break;
      default:
        break;
    }
  };

  const getBroadcastNoticeURL = () => {
    switch (dashboardRole) {
      case 'Employer':
        history.push('/edashboard/broadcast-notice');
        break;
      case 'Institute':
        history.push('/idashboard/broadcast-notice');
        break;
      case 'Recruiter':
        history.push('/rdashboard/broadcast-notice');
        break;
      default:
        break;
    }
  };

  const getValidity = (date) => {
    if (date) {
      const end = moment(date);
      const current = moment(new Date()).format('YYYY-MM-DD');
      const start = moment(current);
      return end.diff(start, 'days') + 1;
    }
    return '';
  };

  const {
    validity: franchiseeValidity,
    endDate: franchiseeEndDate,
    profiles: franchiseeProfiles,
  } = franchiseeProfilePlan;
  const {
    type: candidatePlanType,
    validity: candidatePlanValidity,
    profiles: candidatePlanProfiles,
    endDate: candidatePlanEndDate,
  } = candidatePlan;

  const renderLogout = () => (
    <>
      <li
        className={`nav-item dropdown mb-dropdown-sm ${
          showSettings ? 'show' : ''
        }`}
        ref={showSettingRef}
      >
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdownMenuLink"
          onClick={settingsToggleHandler}
        >
          <i className="fa fa-gear mr-2"></i>
          <span className="d-lg-none">Account</span>
        </a>
        <div
          className={`dropdown-menu dropdown-menu-right ${
            showSettings ? 'show' : ''
          }`}
          aria-labelledby="navbarDropdownMenuLink"
        >
          <a
            className="dropdown-item sign-out-web"
            style={{ color: '#fb0d64' }}
            href="#"
            onClick={() => gService.logUserOut()}
          >
            <i className="fa fa-sign-out" aria-hidden="true"></i>{' '}
            <span>Logout</span>
          </a>
        </div>
      </li>
      <li className="nav-item sign-out-mobile">
        <a
          className="nav-link signout-mob"
          style={{ color: '#fb0d64 !important', fontSize: '13px' }}
          onClick={() => gService.logUserOut()}
          id="navbarDropdownMenuLink"
        >
          <i className="fa fa-sign-out mr-2" aria-hidden="true"></i>
          <span style={{ color: '#fb0d64 !important', fontSize: '13px' }}>
            Logout
          </span>
        </a>
      </li>
    </>
  );

  const renderSettings = () => (
    <Fragment>
      {dashboardRole === 'Franchisee' ||
      dashboardRole === 'Institute' ||
      dashboardRole === 'Employer' ||
      (roles.includes('Candidate') &&
        roles.includes('Employer') &&
        roles.includes('Recruiter')) ? null : (
        <>
          {/* {createdBy === 'Franchisee' || createdBy === 'Institute' ? null : (
            <li
              className={`dropdown nav-item mb-dropdown-sm for-mobile`}
              style={isMob ? { display: 'block' } : { display: 'none' }}
            >
              <a
                href="#"
                onClick={(e) => {
                  settingsToggleHandler(e);
                  toggleShowDropdown();
                  history.push(`/add-new-role`);
                }}
                className="nav-link"
              >
                <i className="fa fa-plus mr-2" style={{ fontSize: '14px' }} />
                <span className="orange-text" style={{ fontSize: '14px' }}>
                  Add New Role
                </span>
              </a>
            </li>
          )} */}
          {dashboardRole === 'Franchisee' ||
          dashboardRole === 'Institute' ? null : (
            <li
              className={`dropdown nav-item mb-dropdown-sm for-mobile`}
              style={isMob ? { display: 'block' } : { display: 'none' }}
            >
              <a
                href="#"
                onClick={(e) => {
                  settingsToggleHandler(e);
                  toggleShowDropdown();
                  history.push(`/${userRole}/notifications`);
                }}
                className="nav-link"
              >
                <i className="fa fa-bell mr-2" style={{ fontSize: '13px' }} />
                <span className="d-lg-none">Notifications</span>
              </a>
            </li>
          )}
        </>
      )}
      <li
        className={`nav-item dropdown mb-2 ${showSettings ? 'show' : ''}`}
        ref={showSettingRef}
      >
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdownMenuLink"
          onClick={settingsToggleHandler}
        >
          <i className="fa fa-gear mr-2"></i>
          <span className="d-lg-none">Account</span>
        </a>
        <div
          className={`dropdown-menu dropdown-menu-right ${
            showSettings ? 'show' : ''
          }`}
          aria-labelledby="navbarDropdownMenuLink"
        >
          {/* {dashboardRole === 'Franchisee' ||
          dashboardRole === 'Institute' ||
          dashboardRole === 'Employer' ||
          (roles.includes('Candidate') &&
            roles.includes('Employer') &&
            roles.includes('Recruiter')) ||
          isMob ? null : (
            <>
              {createdBy === 'Franchisee' ||
              createdBy === 'Institute' ? null : (
                <button
                  type="button"
                  onClick={(e) => {
                    settingsToggleHandler(e);
                    toggleShowDropdown();
                    history.push(`/add-new-role`);
                  }}
                  className="dropdown-item for-web mt-2"
                  style={{ fontSize: '13px' }}
                >
                  <i className="fa fa-plus" style={{ fontSize: '13px' }} />
                  <span className="orange-text">Add New Role</span>
                </button>
              )}
            </>
          )} */}

          <button
            type="button"
            onClick={(e) => {
              settingsToggleHandler(e);
              toggleShowDropdown();
              history.push(`/${userRole}/login_info`);
            }}
            className="dropdown-item for-web"
            style={
              isMob
                ? { display: 'block' }
                : { display: 'block', fontSize: '13px' }
            }
          >
            <i className="fa fa-sign-in" style={{ fontSize: '13px' }} />
            <span>Login Details</span>
          </button>

          {dashboardRole === 'Institute' && (
            <>
              <button
                type="button"
                onClick={(e) => {
                  settingsToggleHandler(e);
                  toggleShowDropdown();
                  history.push(`/${userRole}/course-catalogues`);
                }}
                className="dropdown-item for-web"
                style={
                  isMob
                    ? { display: 'none' }
                    : { display: 'block', fontSize: '13px' }
                }
              >
                <i className="fa fa-list-ul" style={{ fontSize: '13px' }} />
                <span>Course Catalogue</span>
              </button>
              <button
                type="button"
                onClick={(e) => {
                  settingsToggleHandler(e);
                  toggleShowDropdown();
                  history.push(`/${userRole}/specialization`);
                }}
                className="dropdown-item for-web"
                style={
                  isMob
                    ? { display: 'none' }
                    : { display: 'block', fontSize: '13px' }
                }
              >
                <i className="fa fa-wrench" style={{ fontSize: '13px' }} />
                <span>Specialization</span>
              </button>
            </>
          )}

          {dashboardRole === 'Franchisee' ||
          dashboardRole === 'Institute' ? null : (
            <button
              type="button"
              onClick={(e) => {
                settingsToggleHandler(e);
                toggleShowDropdown();
                history.push(`/${userRole}/notifications`);
              }}
              className="dropdown-item for-web"
              style={
                isMob
                  ? { display: 'none' }
                  : { display: 'block', fontSize: '13px' }
              }
            >
              <i className="fa fa-bell" style={{ fontSize: '13px' }} />
              <span>Notifications</span>
            </button>
          )}

          {dashboardRole === 'Employer' &&
            verificationStatus === 'VERIFIED' && (
              <button
                type="button"
                onClick={() => setVerifyPanel(true)}
                className="dropdown-item for-web"
                style={
                  isMob
                    ? { display: 'none' }
                    : { display: 'block', fontSize: '13px' }
                }
              >
                <i className="fa fa-file-pdf-o" style={{ fontSize: '13px' }} />
                <span>View Company Docs</span>
              </button>
            )}

          <button
            type="button"
            onClick={(e) => {
              settingsToggleHandler(e);
              toggleShowDropdown();
              history.push(`/${userRole}/dashboard-settings`);
            }}
            className="dropdown-item"
            style={{ fontSize: '13px' }}
          >
            <i
              className="fa fa-flask"
              style={{ fontSize: '13px' }}
              aria-hidden="true"
            ></i>
            Settings
          </button>
          {(currentRoute !== 'Franchisee' || currentRoute !== 'Institute') && (
            <button
              type="button"
              onClick={(e) => {
                if (status !== 'INACTIVE') {
                  settingsToggleHandler(e);
                  toggleShowDropdown();
                  history.push(`/${userRole}/change-mobile`);
                } else {
                  displayNotification(
                    'Kindly activate your profile to change the Mobile / Email.',
                    'warning',
                    2000
                  );
                }
              }}
              className="dropdown-item"
              style={{ fontSize: '13px' }}
            >
              <i
                className="fa fa-tablet pt-position-rel"
                aria-hidden="true"
                style={{ fontSize: '14px' }}
              ></i>
              Change Mobile/Email
            </button>
          )}
          <button
            type="button"
            onClick={(e) => {
              settingsToggleHandler(e);
              toggleShowDropdown();
              history.push(`/${userRole}/change-pin`);
            }}
            className="dropdown-item change-pin-mobview"
            style={{ fontSize: '13px' }}
          >
            <i
              className="fa fa-key"
              style={{ fontSize: '13px' }}
              aria-hidden="true"
            ></i>
            Change PIN
          </button>
          <a
            className="dropdown-item sign-out-web"
            style={{ color: '#fb0d64', fontSize: '13px' }}
            href="#"
            onClick={() => gService.logUserOut()}
          >
            <i
              className="fa fa-sign-out"
              aria-hidden="true"
              style={{ fontSize: '13px' }}
            ></i>{' '}
            <span>Logout</span>
          </a>
        </div>
      </li>
      <li className="nav-item sign-out-mobile">
        <a
          className="nav-link signout-mob"
          style={{ color: '#fb0d64 !important', fontSize: '13px' }}
          onClick={() => gService.logUserOut()}
          id="navbarDropdownMenuLink"
        >
          <i className="fa fa-sign-out mr-2" aria-hidden="true"></i>
          <span style={{ color: '#fb0d64 !important', fontSize: '13px' }}>
            Logout
          </span>
        </a>
      </li>
    </Fragment>
  );

  const renderCandidateDropDown = () => (
    <li
      className={`dropdown nav-item mb-2 ${showRole ? 'show' : ''}`}
      ref={showRoleRef}
    >
      <a
        href="#"
        className="dropdown-toggle nav-link"
        onClick={(evt) => roleToggleHandler(evt)}
        data-toggle="dropdown"
      >
        <i className="fa fa-refresh mr-2"></i>
        <span className="role-change-color"> {currentRoute}</span>
      </a>

      {userRoles && userRoles.length > 0 && (
        <ul
          className={`dropdown-menu dropdown-menu-right ${
            showRole ? 'show' : ''
          }`}
        >
          {userRoles.map((role, index) => (
            <a
              href="#"
              className={
                userLength === index + 1
                  ? 'dropdown-item pb-2'
                  : 'dropdown-item'
              }
              key={role}
              onClick={(evt) => {
                sessionStorage.clear();
                showRoleHandler(evt, role);
              }}
            >
              {role}
            </a>
          ))}
        </ul>
      )}
    </li>
  );

  const renderNoticeCenter = () => (
    <Fragment>
      <li className="nav-item mb-2" ref={showActionCenterRef}>
        <a
          className="nav-link"
          id="navbarDropdownMenuLink"
          onClick={() => history.push(getBroadcastNoticeURL())}
        >
          <i className="fa fa-bell-o mr-2"></i>
          <span className="role-change-color">Broadcast Notices</span>
        </a>
      </li>
    </Fragment>
  );

  const renderActionCenter = () => (
    <Fragment>
      <li className={`dropdown nav-item mb-2`}>
        <label style={{ fontSize: '14px' }} className="nav-link">
          {/* Placement Status: */}
          <i className="fa fa-briefcase mr-2"></i>
          <span style={{ fontSize: '14px', color: 'gray' }}>
            {userRoleData?.isPlaced ? ' Placed' : ' Not Placed'}
          </span>
        </label>
      </li>
      <li
        className={`dropdown nav-item mb-2 ${showActionCenter ? 'show' : ''}`}
        ref={showActionCenterRef}
      >
        <a
          className="dropdown-toggle nav-link"
          href="#"
          id="navbarDropdownMenuLink"
          onClick={actionCenterToggleHandler}
        >
          <i className="fa fa-bell-o mr-2"></i>
          <span className="role-change-color">Action Center</span>
        </a>

        <div
          className={`dropdown-menu dropdown-menu-right pb-2 ${
            showActionCenter ? 'show' : ''
          }`}
          aria-labelledby="navbarDropdownMenuLink"
        >
          {/* {!candidateStore?.adharVerified && (
            <button
              type="button"
              className="dropdown-item for-web mt-2"
              style={{ fontSize: '13px' }}
              onClick={(e) => {
                actionCenterToggleHandler(e);
                toggleShowDropdown();
                history.push(`/cdashboard/aadhaar-verification`);
              }}
            >
              Verify Your Aadhar
            </button>
          )} */}
          <button
            type="button"
            className="dropdown-item for-web mt-2"
            style={{ fontSize: '13px' }}
            onClick={(e) => {
              actionCenterToggleHandler(e);
              toggleShowDropdown();
              history.push(`/cdashboard/cd-edit-profile`);
            }}
          >
            Complete Profile{' '}
            <span className="candidate-profile-completion">
              {candidateStore.profileScore}%
            </span>
          </button>
          <button
            type="button"
            className="dropdown-item for-web mt-2"
            style={{ fontSize: '13px' }}
            onClick={(e) => {
              actionCenterToggleHandler(e);
              toggleShowDropdown();
              history.push(`/cdashboard/candidate-update-resume`);
            }}
          >
            Upload Resume
          </button>
          <button
            type="button"
            className="dropdown-item for-web mt-2"
            style={{ fontSize: '13px' }}
            onClick={(e) => {
              actionCenterToggleHandler(e);
              toggleShowDropdown();
              history.push(`/cdashboard/verification`);
            }}
          >
            Background Documents
          </button>
          <button
            type="button"
            className="dropdown-item for-web mt-2 d-none"
            style={{ fontSize: '13px' }}
          >
            Premium User Pack
          </button>
        </div>
      </li>
    </Fragment>
  );

  const renderNavBarButton = () => (
    <Fragment>
      <div className="navbar-wrapper">
        <div className={!isMob ? 'navbar-minimize' : ''}>
          <button
            id="headerMinimizeSidebar"
            className="btn btn-warning btn-fill btn-round btn-icon d-none d-lg-block"
            onClick={showMenuHandler}
          >
            {showSideMenu ? (
              <i className="fa fa-ellipsis-v visible-on-sidebar-regular"></i>
            ) : (
              <i className="fa fa-navicon "></i>
            )}
          </button>
          <button
            className="navbar-toggler navbar-toggler-right ml-3 btn bg-primary mobile-menu-button"
            type="button"
            data-toggle="collapse"
            aria-controls="navigation-index"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={showMobileMenuHandler}
          >
            MENU
          </button>
        </div>
      </div>
    </Fragment>
  );

  const renderRightContents = () => (
    <Fragment>
      {/* mobile view rigth top */}
      <button
        id="mobileHeaderMinimizeSidebar"
        className="btn btn-warning btn-fill btn-round btn-icon d-lg-none mt-mb-sm-0 mr-3"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-expanded="false"
        aria-label="Toggle navigation"
        ref={showDropDownBtnRef}
        onClick={toggleShowDropdown}
      >
        <i className="fa fa-ellipsis-v visible-on-sidebar-regular"></i>
        <i className="fa fa-navicon visible-on-sidebar-mini"></i>
      </button>
      <div
        className={`collapse navbar-collapse justify-content-end c-dashboard ${
          showDropdown ? 'show' : ''
        }`}
        id="navigation"
        ref={showDropDownRef}
      >
        <ul className="navbar-nav">
          {/* {currentRoute === 'Recruiter' && (
            <RecruiterCompanyDropdown globalStore={globalStore} />
          )} */}
          {currentRoute === 'Candidate' ? renderActionCenter() : null}
          {currentRoute === 'Institute' ||
          currentRoute === 'Employer' ||
          currentRoute === 'Recruiter'
            ? renderNoticeCenter()
            : null}
          {currentRoute === 'Franchisee' ||
          currentRoute === 'Admin' ||
          currentRoute === 'Institute'
            ? null
            : renderCandidateDropDown()}
          {currentRoute === 'Admin' ? renderLogout() : renderSettings()}
        </ul>
      </div>
    </Fragment>
  );

  const renderPostJobLogo = () => {
    const category = globalStore?.userRoleData?.company
      ? globalStore?.userRoleData?.company.companyCategory
      : globalStore?.userRoleData?.companies
      ? globalStore.userRoleData?.companies[0].companyCategory
      : null;

    const handlePostJob = () => {
      if (category === 'CORP' || category == null) {
        if (companyStatus === 'INACTIVE') {
          return displayNotification(
            'The Company status is presently Inactive, Please complete the verification Process or contact the admin.',
            'warning',
            2000
          );
        } else if (
          roles?.includes('Employer') &&
          (!verificationStatus ||
            verificationStatus === 'PENDING' ||
            verificationStatus === 'REJECTED')
        ) {
          return displayNotification(
            'Please complete the verification process to continue using the portal',
            'warning',
            2000
          );
        } else {
          history.push(`/${userRole}/create-job-post`);
        }
      } else if (
        dashboardRole === 'Franchisee' &&
        globalStore?.userRoleData?.isSOActive
      ) {
        history.push(`/${userRole}/post-job/new`);
      } else if (
        dashboardRole === 'Franchisee' &&
        !globalStore?.userRoleData?.isSOActive
      ) {
        displayNotification(
          'Your Contract is Expired and SO ID is no longer active. Kindly contact the admin at the earliest.',
          'warning',
          2000
        );
      }
    };
    return (
      <>
        <Link
          className="btn btn-post-job mr-2"
          type="button"
          to="#"
          onClick={handlePostJob}
        >
          <span className="btn-inner--icon">
            <i
              className="fa fa-thumb-tack fa-iconthumtack"
              data-fa-transform="rotate-330"
              aria-hidden="true"
            ></i>
          </span>
          <span className="emp-post-job-header"> POST JOB</span> &nbsp;
        </Link>
      </>
    );
  };

  const renderBuyPlan = () => {
    if (roles.includes('Franchisee')) {
      if (
        !franchiseeValidity ||
        franchiseeProfiles <= 0 ||
        getValidity(franchiseeEndDate) <= 0 ||
        (candidatePlanType === 'PAY_AS_USE' &&
          !candidatePlanValidity &&
          candidatePlanProfiles <= 0) ||
        (candidatePlanType !== 'PAY_AS_USE' && !candidatePlanValidity) ||
        (candidatePlanType !== 'PAY_AS_USE' && !candidatePlanProfiles) ||
        (candidatePlanType !== 'PAY_AS_USE' &&
          getValidity(candidatePlanEndDate) <= 0)
      ) {
        return (
          <Button className="btn btn-danger mr-2" onClick={handleBuyPlanEvent}>
            Buy Plan
          </Button>
        );
      }
      return null;
    } else if (roles.includes('Employer')) {
      if (
        (type === 'PAY_AS_USE' && !validity && profiles <= 0) ||
        (type !== 'PAY_AS_USE' && !validity) ||
        (type !== 'PAY_AS_USE' && !profiles) ||
        (type !== 'PAY_AS_USE' && getValidity(endDate) <= 0)
      ) {
        return (
          <Button className="btn btn-warning mr-2" onClick={handleBuyPlanEvent}>
            Buy Plan
          </Button>
        );
      }
    }
    return null;
  };

  const renderAddEmail = () => {
    return (
      <Button
        className="btn btn-info mr-2"
        onClick={() => addEmailHandler(true)}
      >
        <span className="emp-post-job-header">ADD EMAIL</span>
      </Button>
    );
  };

  const renderVerifyProfile = () => {
    return (
      <Button
        className="btn btn-info mr-2"
        type="button"
        onClick={() =>
          roles?.includes('Candidate')
            ? history.push('/cdashboard/verification')
            : setVerifyPanel(true)
        }
      >
        <span className="emp-post-job-header">VERIFY PROFILE</span> &nbsp;
      </Button>
    );
  };

  const renderAddMobile = () => {
    return (
      <Button
        className="btn btn-info mr-2"
        onClick={() => addMobileHandler(true)}
      >
        <span className="emp-post-job-header">ADD MOBILE</span>
      </Button>
    );
  };

  return (
    <nav className="navbar navbar-expand-lg " data-toggle="sticky-onscroll">
      <div className="container-fluid">
        {renderNavBarButton()}
        {!isMob && renderBuyPlan()}
        {!isMob && !email && renderAddEmail()}
        {!isMob && !mobile && renderAddMobile()}
        {isEmployer &&
          currentRoute !== 'Admin' &&
          currentRoute !== 'Institute' &&
          renderPostJobLogo()}
        {currentRoute === 'Recruiter' && !isMob && (
          <span className="role-change-color">
            {globalStore?.userRoleData?.companies?.[0]?.companyName}
          </span>
        )}
        {!isMob &&
          roles?.includes('Employer') &&
          (!verificationStatus ||
            verificationStatus === 'PENDING' ||
            verificationStatus === 'REJECTED') &&
          renderVerifyProfile()}
        {!isMob &&
          roles?.includes('Candidate') &&
          !isBackgroundVerified &&
          !createdBy &&
          !userData?.associatedInstituteCodes?.length > 0 &&
          renderVerifyProfile()}
        {(currentRoute === 'Institute' || currentRoute === 'Franchisee') && (
          <>
            <InstituteNameDropdown globalStore={globalStore} />
            {institutePic && (
              <img
                src={institutePic || hubLogo}
                alt="img"
                className="image-flud border p-1 ml-lg-3"
                style={{
                  height: '2.5rem',
                  width: '2.5rem',
                  borderRadius: '50%',
                }}
              />
            )}
          </>
        )}
        {renderRightContents()}
        <AddEmailToAccount
          {...{
            globalStore,
            addEmailHandler,
            submitEmailForOtp,
            submitEmailChange,
            resetOtpFieldValue,
            triggerOtp,
          }}
        />
        <AddMobileToAccount
          {...{
            globalStore,
            addMobileHandler,
            submitMobileForOtp,
            submitMobileChange,
            resetOtpFieldValue,
            triggerOtp,
          }}
        />
      </div>
    </nav>
  );
}

// select data from redux store
const mapStateToProps = createStructuredSelector({
  globalStore: makeSelectGlobalStore(),
  candidateStore: makeSelectCandidateDashboard(),
  employerDashboardStore: makeSelectEmployerDashboard(),
  empPricingDataStore: makeSelectEmployerPricingModel(),
});

// dispatch actions
const mapDispatchToProps = (dispatch) => ({
  setDashboardRole: (e) => dispatch(gActions.setDashboardRole(e)),
  setDashboardRoleUserData: (e) =>
    dispatch(gActions.setDashboardRoleUserData(e)),
  getMatchingJobs: () => dispatch(candidateAction.getMatchingJobs()),
  addEmailHandler: (e) => dispatch(gActions.addEmailModalStatus(e)),
  submitEmailForOtp: (e) => dispatch(gActions.submitEmailForOtp(e)),
  submitEmailChange: (e) => dispatch(gActions.submitEmailChange(e)),
  addMobileHandler: (e) => dispatch(gActions.addMobileModalStatus(e)),
  submitMobileForOtp: (e) => dispatch(gActions.submitMobileForOtp(e)),
  submitMobileChange: (e) => dispatch(gActions.submitMobileChange(e)),
  resetOtpFieldValue: (e) => dispatch(gActions.resetOtpField(e)),
  loadEmpPricingModelData: () => dispatch(gActions.loadEmpPricingModelData()),
  triggerOtp: (data, type) => {
    dispatch(gActions.showLoader());
    dispatch(gActions.resendOtpAfterLogin(data, type));
  },
});

const DashboardHeaderComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardHeader);

export default compose(memo(DashboardHeaderComponent));
