/**
 *
 * Asynchronously loads the component for JobPreference
 *
 */

import React from 'react';
import loadable from '../../utils/loadable';
import LoadingIndicator from '../../components/LoadingIndicator';

export default loadable(() => import('./index'), {
  fallback: (
    <section
      role='main'
      className='job-preference-section pdtop nopd-lr pb-1'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 col-md-12 '>
            <div className='page-content margin-top-profile mt-0'>
              <div className='wizard-v1-content'>
                <div className='wizard-form'>
                  <LoadingIndicator />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ),
});
