import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import duftherLogoName from 'images/DUFTHER_NAME_LOGO.png';
import faviconImage from 'images/FAVICON.png';
import makeSelectGlobalStore from 'containers/App/selectors';

import './admin-dashboard-sidebar.css';

let AdminDashboardSidebar = (props) => {
  const {
    globalStore,
    showSideMenu,
    showMobileMenu,
    toggleMobileSideMenu,
    toggleWebSideMenu,
  } = props;

  const renderNav = () => (
    <ul className="nav employer-dashboard-navbar">
      <li className="nav-item mt-3">
        <Link
          to="/dashboard-admin"
          className="nav-link"
          onClick={() => {
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
          }}
        >
          <i className="fa fa-line-chart" />
          <p>Dashboard Analytics</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/dashboard-admin/employers-list" className="nav-link">
          <i
            className="fa fa-user-o"
            style={{ float: showSideMenu || showMobileMenu ? 'left' : 'none' }}
          />
          <p>List Employers</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="/dashboard-admin/jobs-by-companies-listing"
          className="nav-link"
        >
          <i
            className="fa fa-user-o"
            style={{ float: showSideMenu || showMobileMenu ? 'left' : 'none' }}
          />
          <p>List Companies</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/dashboard-admin/agencies-listing" className="nav-link">
          <i
            className="fa fa-user-o"
            style={{ float: showSideMenu || showMobileMenu ? 'left' : 'none' }}
          />
          <p>Agencies Listing</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/dashboard-admin/candidates-list" className="nav-link">
          <i
            className="fa fa-user-o"
            style={{ float: showSideMenu || showMobileMenu ? 'left' : 'none' }}
          />
          <p>List Candidates</p>
        </Link>
      </li>
    </ul>
  );

  const renderLogo = () => (
    <Fragment>
      <div className="logo">
        <Link className="simple-text logo-mini" to="/edashboard">
          <img src={faviconImage} alt="duftherLogo" />
        </Link>
        <Link className="simple-text logo-normal" to="/edashboard">
          <img src={duftherLogoName} alt="img" />
        </Link>
        <Link
          className="simple-text logo-mini mt-globe-sidebar padding-globe-top"
          to="/dashboard-admin"
        >
          <i
            className="fa fa-globe"
            aria-hidden="true"
            style={{ color: '#11527e' }}
          ></i>
        </Link>
      </div>
    </Fragment>
  );

  return (
    <div
      className={`sidebar ${!showSideMenu ? 'sidebar-mini-slip' : ''} ${
        showMobileMenu ? 'revised-show-mob-menu' : 'revised-hide-mob-menu'
      }`}
      id="sidebar_dash"
    >
      <div
        className={`sidebar-wrapper ${
          !showSideMenu ? 'sidebar-mini-slip' : ''
        }`}
      >
        {renderLogo()}
        {renderNav()}
      </div>
    </div>
  );
};

AdminDashboardSidebar.propTypes = {
  showSideMenu: PropTypes.bool.isRequired,
  showMobileMenu: PropTypes.bool.isRequired,
  toggleMobileSideMenu: PropTypes.func.isRequired,
  toggleWebSideMenu: PropTypes.func.isRequired,
};

AdminDashboardSidebar.defaultProps = {
  showSideMenu: false,
  showMobileMenu: false,
  toggleMobileSideMenu: () => {},
  toggleWebSideMenu: () => {},
};

// select data from redux store
const mapStateToProps = createStructuredSelector({
  globalStore: makeSelectGlobalStore(),
});

// connection of redux form with state & action dispatcher
const NewSidebar = connect(mapStateToProps, null)(AdminDashboardSidebar);

export default compose(memo(NewSidebar));
