/*
 *
 * RecruiterFormPage constants
 *
 */


export const GOTO_RECRUITER_PAGE = 'app/RecruiterFormPage/GOTO';
export const SET_RECRUITER_PAGE_DATA = 'app/RecruiterFormPage/SET';
export const LOAD_RECRUITER_PAGE_DATA = 'app/RecruiterFormPage/LOAD';
export const FAILED_RECRUITER_PAGE_DATA = 'app/RecruiterFormPage/FAILED';
export const SUBMIT_RECRUITER_PAGE_FORM = 'app/RecruiterFormPage/SUBMIT';
export const SET_MODAL_STATUS = 'app/RecruiterFormPage/SET_MODAL_STATUS';
export const SET_RECRUITER_PROFILE =
  'app/RecruiterFormPage/SET_RECRUITER_PROFILE';

export const UPLOAD_RECRUITER_LOGO = 'app/RecruiterFormPage/UPLOAD_LOGO';
export const GET_RECRUITER_LOGO = 'app/RecruiterFormPage/GET_RECRUITER_LOGO';
export const SET_RECRUITER_LOGO = 'app/RecruiterFormPage/SET_RECRUITER_LOGO';
export const REMOVE_RECRUITER_LOGO =
  'app/RecruiterFormPage/REMOVE_RECRUITER_LOGO';
export const UPDATE_RECRUITER_LOGO_INFO =
  'app/RecruiterFormPage/UPDATE_RECRUITER_LOGO_INFO';
