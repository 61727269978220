import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

// var countDownDate = new Date('Jan 26, 2021 11:45:00').getTime();

// var now = new Date().getTime();

// Find the distance between now an the count down date
// var distance = countDownDate - now;

// If the count down is over, write some text
const render = () =>
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
    document.getElementById('root').addEventListener("contextmenu", (event) => {
      event.preventDefault();
    })
  );



render();
// if (distance < 0) {
//   render();
// } else {
//   const newLink = `${window.location.origin}/welcome.html`;
//   window.location.replace(newLink);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
