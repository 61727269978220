/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import headerLogo from '../../../images/Logo-Home.png';
import usePathname from '../../../hooks/usePathname';
import OutsideAlerter from '../../../components/common/OutsideAlerter';
import * as gService from '../../../utility/GlobalServices';

import './predashboard-header.css';
import { useMediaQuery } from 'react-responsive';

function LandingHeader({ userRoleData, userData }) {
  const isTab = useMediaQuery({ query: '(max-width: 990px)' });

  const [loginStatus, setLoginStatus] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const [dashboardURL, setDashboardURL] = useState('');
  const history = useHistory().location.pathname;
  const pathname = usePathname();
  useEffect(() => {
    const checkMe = gService.checkIsLoggedIn();
    if (checkMe) {
      setLoginStatus(true);
    }
  }, []);

  useEffect(() => {
    const accountType = userData?.roles ? userData.roles[0] : '';
    if (accountType === 'Employer') {
      setDashboardURL('/edashboard');
    } else if (accountType === 'Candidate') {
      setDashboardURL('/cdashboard');
    }
  }, [userData]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const hideMenu = () => {
    setMenuOpen(false);
    setUserMenuOpen(false);
  };

  const toggleUserMenu = () => {
    setUserMenuOpen(!isUserMenuOpen);
  };

  return (
    <OutsideAlerter trigger={hideMenu}>
      <div className="navbar-light container pre-dashboard-header navbar-expand-md">
        <a
          href={
            loginStatus && history === '/'
              ? '/cdashboard'
              : 'https://dufther.com/'
          }
          className="navbar-brand mr-lg-5"
        >
          <img src={headerLogo} alt="header_logo" />
        </a>
        {isTab && (
          <button className="btn btn-info border" onClick={toggleMenu}>
            Menu
          </button>
        )}
        {/* <button className="navbar-toggler" type="button" onClick={toggleMenu}>
          <span className="navbar-toggler-icon" />
        </button> */}
        <div
          className={
            isMenuOpen
              ? 'navbar-collapse collapse show'
              : 'navbar-collapse collapse'
          }
        >
          <div className="navbar-collapse-header">
            <div className="row">
              <div className="col-12 collapse-close">
                <button
                  type="button"
                  className="navbar-toggler"
                  onClick={toggleMenu}
                >
                  <span />
                  <span />
                </button>
              </div>
            </div>
          </div>
          <ul className="ml-auto navbar-nav navbar-nav-hover align-items-lg-center">
            {/* <li className="nav-item">
              <a href="tel:+91 9930877972" className="nav-link p-0">
                <span className="nav-link-inner--text">
                  <button className="btn btn-outline-secondary" type="button">
                    <span className="btn-inner--icon">
                      <i className="fa fa-phone" aria-hidden="true"></i>
                    </span>
                    &nbsp;
                    <span className="btn-inner--text">+91 9930877972</span>
                  </button>
                </span>
              </a>
            </li> */}
            {userRoleData ? (
              <>
                {(pathname.startsWith('/add-new-role') ||
                  pathname.startsWith('/user-agreement')) &&
                  dashboardURL && (
                    <li className="nav-item">
                      <Link to={dashboardURL} className="nav-link">
                        <span className="nav-link-inner--text">
                          <button type="button" className="btn btn-default">
                            <span className="btn-inner--text">Dashboard</span>
                          </button>
                        </span>
                      </Link>
                    </li>
                  )}
                <li
                  className={
                    'dropdown nav-item mb-dropdown-sm' +
                    (isUserMenuOpen ? ' show' : '')
                  }
                >
                  <div className="nav-link user-link">
                    <span className="login-name">
                      {userRoleData?.firstName
                        ? userRoleData?.firstName + ' ' + userRoleData?.lastName
                        : 'Guest User'}
                    </span>
                    <div className="clearfix"></div>
                    <span className="login-time">
                      {userData && userData?.lastLogin}
                      <span
                        className="logout-link logout-sm-none"
                        onClick={() => gService.logUserOut()}
                      >
                        <u>Logout</u>
                      </span>
                    </span>
                  </div>
                  <a
                    href="#"
                    className="nav-link d-lg-none pt-0"
                    onClick={(evt) => {
                      gService.logUserOut();
                      evt.preventDefault();
                    }}
                  >
                    <span className="nav-link-inner--text">
                      <button
                        className="btn btn-outline-secondary logout"
                        type="button"
                      >
                        <span className="btn-inner--text logout-text">
                          Logout
                        </span>
                      </button>
                    </span>
                  </a>
                </li>
              </>
            ) : history === '/login' ||
              history === '/createpin' ||
              history === '/forgotpin' ? (
              <li className="nav-item">
                <a href="https://dufther.com/" className="nav-link">
                  <span className="nav-link-inner--text">
                    <button type="button" className="btn btn-default">
                      <span className="btn-inner--text">Home</span>
                    </button>
                  </span>
                </a>
              </li>
            ) : (
              <li
                className={history === '/' ? 'nav-item m-sm-none' : 'nav-item'}
              >
                <Link to="/login" className="nav-link">
                  <span className="nav-link-inner--text">
                    <button type="button" className="btn btn-default">
                      <span className="btn-inner--icon">
                        <i className="fa fa-user-circle" />
                      </span>
                      <span className="btn-inner--text">Login / Register</span>
                    </button>
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </OutsideAlerter>
  );
}

const mapStatetoProps = (state) => state.globalStore || {};

export default connect(mapStatetoProps, null)(LandingHeader);
