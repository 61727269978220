/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { useEffect } from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import { useInjectSaga } from '../../utils/injectSaga';
import { connect } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { useInjectReducer } from '../../utils/injectReducer';
import PropTypes from 'prop-types';
import history from '../../utils/history';
import useOnLoadHistory from 'hooks/useOnLoadHistory';
import useOnBackHistory from 'hooks/useOnBackHistory';
// import WhatsAppWidget from 'react-whatsapp-widget';
// import 'react-whatsapp-widget/dist/index.css';
/* library css files */
import '../../layouts/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../layouts/css/paper-dashboard.css?v=2.0.0';
import '../../layouts/css/global.css';

/* layout css files */

/*
 * import all pages/containers/components
 */
import Login from '../Login/Loadable';
import UserSignup from '../UserSignup/Loadable';
import JobPreference from '../JobPreference/Loadable';
import IndustiesList from '../IndustriesList/Loadable';
import EmployerAddUser from '../EmployerAddUser/Loadable';
import ForgotCreatePin from '../ForgotCreatePin/Loadable';
import CompanyProfileRegistration from '../CompanyProfileRegistration/Loadable';
import RecruiterProfile from '../RecruiterProfile/Loadable';
import EmployerAdminProfileRegistration from '../EmployerAdminProfileRegistration/Loadable';
import SetPin from '../SetPin';
import CandidateProfileRegistration from '../CandidateProfileRegistration/Loadable';
import CandidateEditProfile from '../CandidateEditProfile/Loadable';
import UserAgreement from '../../components/UserAgreement';
import JobPostForIndividual from '../JobPostForIndividual/Loadable';
import FranchiseRegistration from '../FranchiseRegistration/Loadable';
import InstituteRegister from '../../containers/InstituteRegister/Loadable';

// candidate dashbaord screens
import CandidateDashbaord from '../CandidateDashboard/Loadable';
import CandidateEditJobPreference from '../CandidateEditJobPreference/Loadable';
import CandidateEmployerRecruiterDetails from '../CandidateEmployerRecruiterDetails/Loadable';
import CandidateEmployerCompanyDetails from '../CandidateEmployerCompanyDetails/Loadable';
import CandidateViewProfileDetails from '../CandidateViewProfileDetails/Loadable';
import CandidateViewAppliedApp from '../CandidateViewAppliedJob/Loadable';
import CandidateViewActiveJobRecruiter from '../CandidateViewActiveJobRecruiter/Loadable';
import CandidateDashboardActivitiesStatus from '../CandidateDashboardActivitiesStatus/Loadable';
import ViewJobPostFullDetail from '../ViewJobPostFullDetail/Loadable';
import CandidateBasicAdvanceJobSearch from '../CandidateBasicAdvanceJobSearch/Loadable';
import CandidateUpdateResume from '../CandidateUpdateResume/Loadable';
import CandidateEmployerActionActivities from '../CandidateEmployerActionActivities/Loadable';
import CandidateAppliedJobListing from '../CandidateAppliedJobListing/Loadable';
import CandidateSavedJobListing from '../CandidateSavedJobListing/Loadable';

import EmployerDashboard from '../EmployerDashboard/Loadable';
import RecruiterDashboard from '../RecruiterDashboard/Loadable';
import FranchiseDashboard from '../FranchiseDashboard/Loadable';
import InstituteDashboard from '../InstituteDashboard/Loadable';

// admin/internal user screens
import AdminLogin from '../AdminLogin/Loadable';
import AdminDashboard from '../AdminDashboard/Loadable';

// not found page container
import NotFoundPage from '../NotFoundPage/Loadable';

/* import all layouts to render page */
import PredashboardLayout from '../../layouts/predashboardLayout/PredashboardLayout';
import LandingLayout from '../../layouts/landingLayout/LandingLayout';
import CandidateDashboardLayout from '../../layouts/candidateDashboardLayout/candidateDashboardLayout';
import EmployerDashboardLayout from '../../layouts/employerDashboardLayout/employerDashboardLayout';
import RecruiterDashboardLayout from '../../layouts/recruiterDashboardLayout/recruiterDashboardLayout';
import FranchiseDashboardLayout from '../../layouts/FranchiseDashboardLayout/FranchiseDashboardLayout';
import AdminDashboardLayout from '../../layouts/adminDashboardLayout/adminDashboardLayout';
import InstituteDashboardLayout from '../../layouts/instituteDashboardLayout/instituteDashboardLayout';

import PrivateRoute from './PrivateRoute';

/* import root actions, reducer & saga */
import * as gActions from './actions';
import reducer from './reducer';
import saga from './saga';
import EditTermsConditions from 'containers/EditTermsConditions';

const App = ({ loadGlobalStore, initWebSocket }) => {
  // injecting global reducer & saga for common operation
  useInjectReducer({ key: 'globalStore', reducer });
  useInjectSaga({ key: 'globalSaga', saga });
  // adding history manually for potential fix for PWA on Mobile
  useOnLoadHistory();
  useOnBackHistory();

  useEffect(() => {
    loadGlobalStore();
  }, [loadGlobalStore]);

  useEffect(() => {
    window.location?.pathname?.includes('/cdashboard') &&
      initWebSocket('Candidate');
    window.location?.pathname?.includes('/edashboard') &&
      initWebSocket('Employer');
  }, [initWebSocket]);

  // function to render layouts of different pattern
  const RouteWithLayout = ({
    component: Component,
    layout: Layout,
    ...rest
  }) => (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );

  RouteWithLayout.propTypes = {
    layout: PropTypes.any,
    component: PropTypes.any,
  };
  return (
    <Router history={history}>
      {/* <WhatsAppWidget
        phoneNumber="919930877972"
        sendButton="Start Chat"
        companyName="Dufther"
        message="Hello, Please let us know how we can help you?"
        textReplyTime="Typically replies within a day"
      /> */}
      <HelmetProvider>
        <Switch>
          {/* <Redirect from="/" to="/login" exact /> */}
          {/* Admin/internal user pages */}
          <RouteWithLayout
            exact
            layout={PredashboardLayout}
            path="/internal-user"
            component={AdminLogin}
          />
          <PrivateRoute
            layout={AdminDashboardLayout}
            path="/dashboard-admin"
            component={AdminDashboard}
          />
          {/* Frontend-pages */}
          <RouteWithLayout
            exact
            layout={PredashboardLayout}
            path="/"
            component={Login}
          />
          <RouteWithLayout
            exact
            layout={LandingLayout}
            path="/industries"
            component={IndustiesList}
          />
          <RouteWithLayout
            exact
            layout={PredashboardLayout}
            path="/login"
            component={Login}
          />
          <RouteWithLayout
            exact
            layout={PredashboardLayout}
            path="/createpin"
            component={ForgotCreatePin}
          />
          <RouteWithLayout
            exact
            layout={PredashboardLayout}
            path="/forgotpin"
            component={ForgotCreatePin}
          />
          <RouteWithLayout
            exact
            layout={PredashboardLayout}
            path="/register"
            component={UserSignup}
          />
          <PrivateRoute
            exact
            layout={PredashboardLayout}
            path="/job-preference"
            component={JobPreference}
          />
          <PrivateRoute
            exact
            layout={PredashboardLayout}
            path="/company-profile"
            component={CompanyProfileRegistration}
          />
          <PrivateRoute
            exact
            layout={PredashboardLayout}
            path="/employer-admin-profile-registration"
            component={EmployerAdminProfileRegistration}
          />
          <PrivateRoute
            exact
            layout={PredashboardLayout}
            path="/employer-add-user"
            component={EmployerAddUser}
          />
          <RouteWithLayout
            exact
            layout={PredashboardLayout}
            path="/setpin"
            component={SetPin}
          />
          <PrivateRoute
            exact
            layout={PredashboardLayout}
            path="/add-new-role"
            component={UserSignup}
          />
          <PrivateRoute
            exact
            layout={PredashboardLayout}
            path="/post-new-job"
            component={JobPostForIndividual}
          />
          <PrivateRoute
            exact
            layout={PredashboardLayout}
            path="/recruiter-profile"
            component={RecruiterProfile}
          />
          <RouteWithLayout
            exact
            layout={PredashboardLayout}
            path="/user-agreement/:slug"
            component={UserAgreement}
          />
          <RouteWithLayout
            exact
            layout={PredashboardLayout}
            path="/franchise-signup"
            component={FranchiseRegistration}
          />
          <RouteWithLayout
            exact
            layout={PredashboardLayout}
            path="/institute-signup"
            component={InstituteRegister}
          />
          {/* CandidateDashboardLayout */}
          <RouteWithLayout
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/edit-job-preference"
            component={CandidateEditJobPreference}
          />
          <RouteWithLayout
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/view-company"
            component={CandidateEmployerCompanyDetails}
          />
          <RouteWithLayout
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/view-recruiter"
            component={CandidateEmployerRecruiterDetails}
          />
          <PrivateRoute
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/candidate-view-profile-details"
            component={CandidateViewProfileDetails}
          />
          <RouteWithLayout
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/candidate-view-applied-job"
            component={CandidateViewAppliedApp}
          />
          <RouteWithLayout
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/candidate-view-active-jobs"
            component={CandidateViewActiveJobRecruiter}
          />
          <RouteWithLayout
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/candidate-activites-status/:slug"
            component={CandidateDashboardActivitiesStatus}
          />
          <RouteWithLayout
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/cd-profile-reg"
            component={CandidateProfileRegistration}
          />
          <RouteWithLayout
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/cd-edit-profile"
            component={CandidateEditProfile}
          />
          <RouteWithLayout
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/candidate-view-job-post-full-details"
            component={ViewJobPostFullDetail}
          />
          <RouteWithLayout
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/candidate-basic-advance-job-search"
            component={CandidateBasicAdvanceJobSearch}
          />
          <RouteWithLayout
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/candidate-update-resume"
            component={CandidateUpdateResume}
          />
          <RouteWithLayout
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/candidate-employer-action/:slug"
            component={CandidateEmployerActionActivities}
          />
          <RouteWithLayout
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/applied-job-list"
            component={CandidateAppliedJobListing}
          />
          <RouteWithLayout
            exact
            layout={CandidateDashboardLayout}
            path="/cdashboard/saved-job-list"
            component={CandidateSavedJobListing}
          />

          <PrivateRoute
            layout={CandidateDashboardLayout}
            path="/cdashboard"
            component={CandidateDashbaord}
          />

          {/* EmployerDashboardLayout */}
          <PrivateRoute
            layout={EmployerDashboardLayout}
            path="/edashboard"
            component={EmployerDashboard}
          />

          {/* RecruiterDashboardLayout */}
          <PrivateRoute
            layout={RecruiterDashboardLayout}
            path="/rdashboard"
            component={RecruiterDashboard}
          />

          {/* FranchiseDashboardLayout */}
          <PrivateRoute
            layout={FranchiseDashboardLayout}
            path="/fdashboard"
            component={FranchiseDashboard}
          />
          <PrivateRoute
            layout={FranchiseDashboardLayout}
            path="/fdashboard/edit-terms-conditions"
            component={EditTermsConditions}
          />
          {/* InstituteDashboardLayout */}
          <PrivateRoute
            layout={InstituteDashboardLayout}
            path="/idashboard"
            component={InstituteDashboard}
          />

          {/* No-page found */}
          <RouteWithLayout
            layout={PredashboardLayout}
            component={NotFoundPage}
          />
        </Switch>
      </HelmetProvider>
    </Router>
  );
};

App.propTypes = {
  loadGlobalStore: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  loadGlobalStore: () => {
    dispatch(gActions.showLoader());
    dispatch(gActions.loadGlobalStoreData());
  },
  initWebSocket: (role) => {
    dispatch(gActions.initWebSocket(role));
  },
});

export default connect(null, mapDispatchToProps)(App);
