import produce from 'immer';
import { SET_FRANCHISEE_DETAIL, SET_FRANCHISEE_DATA } from './constants';

export const initialState = {
  details: null,
  franchiseTypeArr: [],
  educationArr: [],
  entityArr: [],
  domainData: [],
};

/* eslint-disable default-case, no-param-reassign */
const franchiseDetailsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_FRANCHISEE_DETAIL:
        draft.details = action.data;
        break;
      case SET_FRANCHISEE_DATA:
        const payload = action.data;
        draft.franchiseTypeArr = payload.franchiseTypeArr;
        draft.educationArr = payload.educationArr;
        draft.entityArr = payload.entityArr;
        draft.domainData = payload.domainData;
        break;
    }
  });

export default franchiseDetailsReducer;
