import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the recruiter dashboard state domain
 */

const selectRecruiterDashboardDomain = (state) =>
  state.recruiterDashboard || initialState;

/**
 * Default selector used by Recruiter Dashboard
 */
const makeSelectRecruiterDashboard = () =>
  createSelector(selectRecruiterDashboardDomain, (substate) => substate);

export default makeSelectRecruiterDashboard;
export { selectRecruiterDashboardDomain };
