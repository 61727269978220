import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the CandidateBasicAdvanceJobSearch state domain
 */

const selectEmployerCandidateSearchDomain = (state) =>
  state.empCandidateSearch || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by CandidateBasicAdvanceJobSearch
 */

const makeSelectEmployerCandidateSearch = () =>
  createSelector(selectEmployerCandidateSearchDomain, (substate) => substate);

export default makeSelectEmployerCandidateSearch;
export { selectEmployerCandidateSearchDomain };
