/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { allowOnlyNums } from '../../../components/common/FormFields/formValidations';

function Noticebox(props) {
  const {
    availableCheck,
    isAvailableSubmit,
    noticeBox,
    isNoticePeriodInDays,
    toggleNoticeBox,
    noticePeriodFunc,
    setNoticePeriodValue,
    noticePeriodDays,
    setReset,
  } = props;

  useEffect(() => {
    availableCheck && toggleNoticeBox(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCheck]);

  const saveNoticePeriodDays = (e) => {
    e.preventDefault();
    noticePeriodFunc(isNoticePeriodInDays);
    toggleNoticeBox(false);
  };

  const editNoticePeriodDays = (e) => {
    e.preventDefault();
    !availableCheck && toggleNoticeBox(!noticeBox);
    !availableCheck && setReset(true);
  };

  const onNoticePeriodValueChange = (e) => {
    if (e.target.value < 0) e.target.value = 0;
    if (e.target.value >= 100) e.target.value = 99;

    setNoticePeriodValue(e.target.value);
  };

  return (
    <Fragment>
      <li className="nav-item">
        <span className="nav-link">
          <label className="switch toggle-switch">
            <input
              type="checkbox"
              name="isAvailableImmediately"
              id="chkBox"
              checked={!!availableCheck}
              onChange={(event) => isAvailableSubmit(event)}
            />
            <span className="slider round"></span>
          </label>
          <span className="toggle switch-lable-sidebar zero-opacity">
            Available Immediately
          </span>
        </span>
      </li>

      <li className="nav-item">
        <span className="nav-link">
          {noticeBox === true ? (
            <input
              type="tel"
              inputMode="numeric"
              pattern="[0-9]*"
              onKeyPress={allowOnlyNums}
              required
              maxLength={2}
              name="noticePeriod"
              style={{ padding: '0 5px' }}
              className="form-control notice-peroid-input"
              defaultValue={noticePeriodDays > -1 ? '' + noticePeriodDays : ''}
              onChange={onNoticePeriodValueChange}
            />
          ) : (
            <Fragment>
              <div className="notice-days-left notice-days-label">
                {noticePeriodDays || '0'}
              </div>
            </Fragment>
          )}

          <span className="zero-opacity">
            <span className="toggle days-notice-sidebar">Days Notice Period</span>
            {noticeBox === false ? (
              !availableCheck ? (
                <a href="#" onClick={editNoticePeriodDays}>
                  <span>
                    <u className="font-italic ml-1" id="notice-period-edit">
                      Edit
                    </u>
                  </span>
                </a>
              ) : (
                ''
              )
            ) : (
              <a href="#">
                <span>
                  <u
                    className="font-italic ml-1 position-save-icons"
                    id="notice-period-save"
                  >
                    <button className="noticebox-button btn-info ml-1">
                      <i
                        onClick={saveNoticePeriodDays}
                        className="fa fa-check position-check-change-pin"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <button className="noticebox-button btn-info ml-1">
                      <i
                        onClick={() => toggleNoticeBox(false)}
                        className="fa fa-times"
                      ></i>
                    </button>
                  </u>
                </span>
              </a>
            )}
          </span>
        </span>
      </li>
    </Fragment>
  );
}

Noticebox.propTypes = {
  availableCheck: PropTypes.any,
  isAvailableSubmit: PropTypes.any,
  noticeBox: PropTypes.any,
  availableImmediately: PropTypes.any,
  toggleNoticeBox: PropTypes.any,
  noticePeriodFunc: PropTypes.any,
};

export default Noticebox;
