/*
 *
 * EmployerFormPage constants
 *
 */

export const GOTO_EMPLOYER_PAGE = 'app/EmployerFormPage/GOTO';
export const SET_EMPLOYER_PAGE_DATA = 'app/EmployerFormPage/SET';
export const LOAD_EMPLOYER_PAGE_DATA = 'app/EmployerFormPage/LOAD';
export const FAILED_EMPLOYER_PAGE_DATA = 'app/EmployerFormPage/FAILED';
export const SUBMIT_EMPLOYER_PAGE_FORM = 'app/EmployerFormPage/SUBMIT';
export const SHOW_CONFIRM_MODAL = 'app/EmployerFormPage/GOTO_ADD_RECRUITER';
export const SET_EMPLOYER_ADMIN_PROFILE =
  'app/EmployerFormPage/SET_EMPLOYER_ADMIN_PROFILE';

export const UPLOAD_EMPLOYER_LOGO = 'app/EmployerFormPage/UPLOAD_LOGO';
export const GET_EMPLOYER_LOGO = 'app/EmployerFormPage/GET_EMPLOYER_LOGO';
export const SET_EMPLOYER_LOGO = 'app/EmployerFormPage/SET_EMPLOYER_LOGO';
export const REMOVE_EMPLOYER_LOGO = 'app/EmployerFormPage/REMOVE_EMPLOYER_LOGO';
export const UPDATE_EMPLOYER_LOGO_INFO =
  'app/EmployerFormPage/UPDATE_EMPLOYER_LOGO_INFO';
