// Global server url for all api's
export const baseUrl = process.env.REACT_APP_ENV
  ? 'https://dev.api.dufther.com:8084'
  : 'https://dev.api.dufther.com:8084';

export const baseWebsocketUrl = process.env.REACT_APP_ENV
  ? 'https://dev.api.dufther.com:8090'
  : 'https://dev.api.dufther.com:8090';

export const baseGoogleMapApiUrl =
  'https://maps.googleapis.com/maps/api/geocode/json?latlng';

export const googleMapDevApiKey = 'AIzaSyBgs8uhprSrLwWCslE8jmiEj0ryPZ4l8Bo';

export const googleMapProdApiKey = 'AIzaSyDItvVjBfBn5jaGDGXlP9qqWDnZew9jFDc';

//export const razorPayIdTest = 'rzp_test_ydm1NB0g7FuyAE';

export const razorPayIdLive = 'rzp_live_T5DHDVD1mVTBKA';

export const razorPayKeyId =
  process.env.REACT_APP_ENV === 'development' ? razorPayIdLive : razorPayIdLive;
