import React, { memo } from 'react';
import { pathOr } from 'ramda';
import { Link } from 'react-router-dom';

function NavigationMenu({ match }) {
  return (
    <div className="user-agreement">
      <div className="nav-container ">
        <ul
          className="nav nav-icons justify-content-center scrollbar"
          id="style-4"
          role="tablist"
        >
          {/* <li className="nav-item show">
            <Link
              to="/user-agreement/about"
              className={
                // eslint-disable-next-line eqeqeq
                pathOr('', ['params', 'slug'], match) == 'about'
                  ? 'nav-link  active'
                  : 'nav-link'
              }
              id="description-tab"
              role="tab"
              data-toggle="tab"
            >
              About Us
            </Link>
          </li> */}
          <li className="nav-item">
            <Link
              to="/user-agreement/terms"
              className={
                // eslint-disable-next-line eqeqeq
                pathOr('', ['params', 'slug'], match) == 'terms'
                  ? 'nav-link  active'
                  : 'nav-link'
              }
              id="location-tab"
              role="tab"
              data-toggle="tab"
            >
              Terms
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/user-agreement/privacy"
              className={
                // eslint-disable-next-line eqeqeq
                pathOr('', ['params', 'slug'], match) == 'privacy'
                  ? 'nav-link  active'
                  : 'nav-link'
              }
              id="legal-info-tab"
              role="tab"
              data-toggle="tab"
            >
              Privacy Policy
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/user-agreement/cookie"
              className={
                // eslint-disable-next-line eqeqeq
                pathOr('', ['params', 'slug'], match) == 'cookie'
                  ? 'nav-link  active'
                  : 'nav-link'
              }
              id="help-tab"
              role="tab"
              data-toggle="tab"
            >
              Cookies Policy
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/user-agreement/Refund"
              className={
                // eslint-disable-next-line eqeqeq
                pathOr('', ['params', 'slug'], match) == 'Refund'
                  ? 'nav-link  active'
                  : 'nav-link'
              }
              id="help-tab"
              role="tab"
              data-toggle="tab"
            >
              Refund Policy
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/user-agreement/contact"
              className={
                // eslint-disable-next-line eqeqeq
                pathOr('', ['params', 'slug'], match) == 'contact'
                  ? 'nav-link  active'
                  : 'nav-link'
              }
              id="help-tab"
              role="tab"
              data-toggle="tab"
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default memo(NavigationMenu);
