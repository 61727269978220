import {
  takeLatest,
  put,
  all,
  call,
  take,
  takeEvery,
  select,
} from 'redux-saga/effects';
import moment from 'moment';
import * as selectors from './selectors';
import * as gService from '../../utility/GlobalServices';
import * as apiName from '../../config/apiUrl';
import presentPage from '../../config/progress';
import * as gConstants from './constants';
import * as gActions from './actions';
import * as employerActions from '../EmployerAdminProfileRegistration/actions';
import * as recruiterProfileActions from '../RecruiterProfile/actions';
import * as candidateAction from '../CandidateBasicAdvanceJobSearch/actions';
import * as franchiseeActions from '../FranchiseViewDetails/actions';
import { initWebsocket } from './websocketSagaUtility';
import { push } from 'react-router-redux';
import { displayNotification } from 'config/toastConfig';

// ***** FUNCTION TO GET DATA FROM API FOR GLOBAL USAGE ***** //
export function* getDataFromApiToGlobalStoreSaga() {
  let userRolesDataResp, userRolesData, userRoleData;
  const userId = localStorage.getItem('userId');
  const allUrls = [
    apiName.userRolesURL,
    apiName.profileProgress,
    apiName.latestRole,
  ];
  if (userId) {
    const userRolesUrls = {
      candidateInfoURL: `${apiName.candidateInfoURL}/${userId}`,
      employerInfoURL: `${apiName.employerInfoURL}/${userId}`,
      recruiterInfoURL: `${apiName.recruiterInfoURL}/${userId}`,
      franchiseeInfoURL: `${apiName.getFranchiseURL}`,
      instituteInfoURL: `${apiName.instituteURL}/${userId}`,
    };
    try {
      // calling multiple api at a time
      const [userDataResponse, progress, latestRole] = yield all(
        allUrls.map((url) => gService.callApi(url))
      );
      const role = latestRole && latestRole.toLowerCase();
      const userRoles = userDataResponse.roles;
      const redirectConfig = presentPage(userDataResponse, role, progress);
      if (role !== 'admin') {
        userRolesDataResp = yield all(
          userRoles.map((role) =>
            gService.callApi(userRolesUrls[`${role.toLowerCase()}InfoURL`])
          )
        );
        userRolesData = userRoles.map((role, index) => {
          return {
            role: role.toLowerCase(),
            data: userRolesDataResp[index],
          };
        });
        userRoleData =
          userRolesData.find((roleDate) => {
            return roleDate.role === redirectConfig.role;
          }) || {};
        if (redirectConfig.role == 'franchisee') {
          const isSOActive = yield gService.callApi(
            `${apiName.isSoAtiveURL}/${userRoleData?.data?.serviceCode}`
          );
          yield put(
            gActions.setGlobalStoredData({
              userRolesData: userRolesData,
              userRoleData: { ...userRoleData.data, isSOActive } || {},
              userDataResponse,
              role,
            })
          );
        } else {
          yield put(
            gActions.setGlobalStoredData({
              userRolesData: userRolesData,
              userRoleData: userRoleData.data || {},
              userDataResponse,
              role,
            })
          );
        }
      } else {
        yield put(
          gActions.setGlobalStoredData({
            userRolesData: { user: userDataResponse },
            userRoleData: { user: userDataResponse } || {},
            userDataResponse,
            role,
          })
        );
      }
      yield put(push(redirectConfig.pathname));
      if (redirectConfig.role == 'employer') {
        yield put(
          employerActions.setEmployerAdminProfile({
            firstName: userRoleData.data.firstName,
            lastName: userRoleData.data.lastName,
          })
        );
      } else if (redirectConfig.role == 'recruiter') {
        yield put(
          recruiterProfileActions.setRecruiterProfile({
            firstName: userRoleData.data.firstName,
            lastName: userRoleData.data.lastName,
          })
        );
      } else if (redirectConfig.role == 'franchisee') {
        yield put(franchiseeActions.setFranchiseeDetails(userRoleData.data));
      }
      if (redirectConfig.role == 'candidate') {
        yield put(candidateAction.getMatchingJobs());
      }
    } catch (error) {
      displayNotification('Something went wrong! try again.', 'danger');
    }
  }
  yield put(gActions.hideLoader());
}

export function* getCandidatePhysicalStatusSaga({ id }) {
  const userId = id || localStorage.getItem('userId');
  if (userId) {
    try {
      const response = yield gService.callApi(
        `${apiName.candidateInfoURL}/${userId}`
      );
      yield put(gActions.setPhysicalStatusData(response));
    } catch (error) {
      displayNotification('Something went wrong! try again.', 'danger');
    }
    yield put(gActions.hideLoader());
  }
}

// ***** FUNCTION TO RE-GENERATE OTP ***** //
export function* resendNewOtpSaga() {
  const phoneNumber = window.localStorage.getItem('Mobile');
  const email = window.localStorage.getItem('Email');
  const postData = {};
  if (phoneNumber && phoneNumber != 'null') {
    postData['phoneNumber'] = phoneNumber;
  } else {
    postData['email'] = email;
  }
  try {
    yield gService.callApi(apiName.sendLoginOtpURL, postData, 'POST');
    yield put(gActions.otpGeneratedResponse('New Otp generated'));
    window.localStorage.setItem('lastTime', moment(new Date()));
  } catch (error) {
    yield put(
      gActions.otpGeneratedResponse('Something went wrong! plz try again.')
    );
  }
  yield put(gActions.hideLoader());
}

export function* resendOtpSaga({ data, otpType }) {
  const postData = {};
  if (otpType === 'mobile') {
    postData['phoneNumber'] = data;
  } else {
    postData['email'] = data;
  }
  try {
    yield gService.callApi(apiName.sendLoginOtpURL, postData, 'POST');
    yield put(gActions.otpGeneratedResponse('New Otp generated'));
    window.localStorage.setItem('lastTime', moment(new Date()));
  } catch (error) {
    yield put(
      gActions.otpGeneratedResponse('Something went wrong! please try again.')
    );
  }
  yield put(gActions.hideLoader());
}

// ***** WEBSOCKET ***** //
function* initializeWebSocketsChannelSaga({ role }) {
  const channel = yield call(initWebsocket, role);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

function* getAllDistricts() {
  const { indiaDistricts } = yield select(selectors.selectGlobalStorerDomain);
  if (indiaDistricts.length === 0 && localStorage.Bearer) {
    try {
      const response = yield gService.callApi(apiName.indiaDistrictsURL);
      yield put(gActions.setIndiaDistricts(response));
    } catch (error) {
      // console.log(error);
    }
  }
}

function* getOtpOnEmailSaga({ payload }) {
  yield put(gActions.showLoader());
  try {
    const response = yield gService.callApi(
      apiName.sendEmailOtpURL,
      payload,
      'POST'
    );
    if (response?.isSent) {
      yield put(
        gActions.emailOtpRespose('Please verify with OTP sent on the E-mail')
      );
    } else if (response?.status >= 400) {
      displayNotification(
        gService.convertCapitalUpperToPascal(response.message),
        'danger'
      );
    }
  } catch (error) {
    displayNotification(`Something went wrong try again`, 'danger');
  }
  yield put(gActions.hideLoader());
}

function* submitEmailChangeSaga({ payload }) {
  yield put(gActions.showLoader());
  try {
    const response = yield gService.callApi(
      apiName.verifyEmailOtpURL,
      payload,
      'POST'
    );
    if (response?.jwt) {
      displayNotification('Email has been added Successfully');
      yield put(gActions.addEmailModalStatus(null));
      yield put(gActions.loadGlobalStoreData());
    } else if (response?.status >= 400) {
      displayNotification(
        gService.convertCapitalUpperToPascal(response.message),
        'danger'
      );
      yield put(gActions.resetOtpField(true));
    }
  } catch (error) {
    displayNotification(`Something went wrong try again`, 'danger');
  }
  yield put(gActions.hideLoader());
}

function* getOtpOnMobileSaga({ payload }) {
  yield put(gActions.showLoader());
  try {
    const response = yield gService.callApi(
      apiName.sendMobileOtpURL,
      payload,
      'POST'
    );
    if (response?.isSent) {
      yield put(
        gActions.mobileOtpRespose('Please verify with OTP sent on the Mobile')
      );
    } else if (response?.status >= 400) {
      displayNotification(
        gService.convertCapitalUpperToPascal(response.message),
        'danger'
      );
    }
  } catch (error) {
    displayNotification(`Something went wrong try again`, 'danger');
  }
  yield put(gActions.hideLoader());
}

function* submitMobileChangeSaga({ payload }) {
  yield put(gActions.showLoader());
  try {
    const response = yield gService.callApi(
      apiName.verifyMobileOtpURL,
      payload,
      'POST'
    );
    if (response?.jwt) {
      displayNotification('Mobile has been added Successfully');
      yield put(gActions.addMobileModalStatus(null));
      yield put(gActions.loadGlobalStoreData());
    } else if (response?.status >= 400) {
      displayNotification(
        gService.convertCapitalUpperToPascal(response.message),
        'danger'
      );
      yield put(gActions.resetOtpField(true));
    }
  } catch (error) {
    displayNotification(`Something went wrong try again`, 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** FUNCTION TO LOAD EMPLOYER/AGENCY PLAN DATA ***** //
export function* loadAgencyPlanSaga() {
  yield put(gActions.showLoader());
  const globalStore = yield select(selectors.selectGlobalStorerDomain);

  //check for null from global store for details.
  let companyId = null;
  if (globalStore?.userRoleData?.company?.companyId) {
    companyId = globalStore?.userRoleData?.company?.companyId;
  } else if (globalStore.dashboardRole === 'Franchisee') {
    const franchiseeDetails = yield gService.callApi(apiName.getFranchiseURL);
    companyId = franchiseeDetails?.company?.companyId;
  } else {
    companyId = globalStore.userRoleData?.companyId;
  }

  const empPricingURL = apiName.getPricingPlanCompanyIdURL + companyId;
  try {
    const responseData = yield gService.callApi(empPricingURL);
    yield put(gActions.setEmpPricingModelData(responseData));
  } catch (error) {
    displayNotification(`Something went wrong try again`, 'danger');
  }
  yield put(gActions.hideLoader());
}

export function* uploadVerificationDocsSaga({ id, data, formData, docType }) {
  yield put(gActions.showLoader());
  //check for null from global store for details.
  try {
    let imageUploadResponse = yield gService.callApi(
      apiName.imageUploadURL,
      formData,
      'POST',
      { fileUpload: true }
    );
    if (imageUploadResponse) {
      let payload = {};
      if (docType === 'company') {
        payload = {
          companyDocumentType: data,
          companyDocument: imageUploadResponse,
        };
      } else {
        payload = {
          personalDocumentType: data,
          personalDocument: imageUploadResponse,
        };
      }
      const responseData = yield gService.callApi(
        `${apiName.companyDetailURL}/${id}/verificationDocuments`,
        payload,
        'POST'
      );
      yield put(gActions.setVerificationDocs(responseData));
    }
  } catch (error) {
    displayNotification(`Something went wrong try again`, 'danger');
  }
  yield put(gActions.hideLoader());
}

export function* deleteVerificationDocsSaga({ id, docType }) {
  yield put(gActions.showLoader());
  //check for null from global store for details.
  try {
    const responseData = yield gService.callApi(
      `${apiName.deleteCompanyDocsUrl}/${id}/documentType/${docType}`,
      null,
      'DELETE'
    );
    yield put(gActions.getVerificationDocs(id));
  } catch (error) {
    displayNotification(`Something went wrong try again`, 'danger');
  }
  yield put(gActions.hideLoader());
}

export function* getVerificationDocsSaga({ id }) {
  yield put(gActions.showLoader());
  //check for null from global store for details.
  try {
    const responseData = yield gService.callApi(
      `${apiName.companyDetailURL}/${id}/verificationDocuments`
    );
    yield put(gActions.setVerificationDocs(responseData));
  } catch (error) {
    displayNotification(`Something went wrong try again`, 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** BINDING ACTION & FUNCTION LOGIC ***** //
export default function* appSaga() {
  yield takeLatest(
    gConstants.LOAD_GLOBAL_STORE_DATA,
    getDataFromApiToGlobalStoreSaga
  );
  yield takeLatest(gConstants.LOAD_DISTRICTS, getAllDistricts);
  yield takeLatest(gConstants.RESEND_OTP, resendNewOtpSaga);
  yield takeEvery(
    gConstants.INITIALIZE_WEB_SOCKETS_CHANNEL,
    initializeWebSocketsChannelSaga
  );
  yield takeEvery(gConstants.SUBMIT_EMAIL_FOR_OTP, getOtpOnEmailSaga);
  yield takeEvery(gConstants.SUBMIT_EMAIL_CHANGE, submitEmailChangeSaga);
  yield takeEvery(gConstants.SUBMIT_MOBILE_FOR_OTP, getOtpOnMobileSaga);
  yield takeEvery(gConstants.SUBMIT_MOBILE_CHANGE, submitMobileChangeSaga);
  yield takeLatest(gConstants.LOAD_EMP_PRICING_DATA, loadAgencyPlanSaga);
  yield takeLatest(gConstants.GET_VERIFICATION_DOCS, getVerificationDocsSaga);
  yield takeLatest(
    gConstants.UPLOAD_VERIFICATION_DOCS,
    uploadVerificationDocsSaga
  );
  yield takeLatest(
    gConstants.DELETE_VERIFICATION_DOCS,
    deleteVerificationDocsSaga
  );
  yield takeLatest(gConstants.RESEND_OTP_AFTER_LOGIN, resendOtpSaga);
  yield takeLatest(
    gConstants.GET_PHYSICAL_STATUS,
    getCandidatePhysicalStatusSaga
  );
}
