import { Link } from 'react-router-dom';

export const fileTypeExt = [
  '.pdf',
  '.docx',
  '.doc',
  '.jpeg',
  '.jpg',
  '.png',
  '.tif',
  '.tiff',
  '.bmp',
];

export const employerActionParams = {
  'view-profile': 'VIEW_PROFILE',
  'viewed-contact': 'VIEW_CONTACT',
  shortlisted: 'SHORLISTED',
  'downloaded-resume': 'DOWNLOAD_RESUME',
};

export const applicationStatus = {
  Open: 'OPEN',
  'Under Review': 'UNDER_REVIEW',
  Shortlisted: 'SHORTLISTED',
  'Not Shortlisted': 'NOT_SHORTLISTED',
  Accepted: 'ACCEPTED',
  Hired: 'HIRED',
  Stalled: 'STALLED',
};
export const getDashboardPath = (role) => {
  if (role === 'Candidate') {
    return 'cdashboard';
  } else if (role === 'Employer') {
    return 'edashboard';
  } else if (role === 'Recruiter') {
    return 'rdashboard';
  } else if (role === 'Franchisee') {
    return 'fdashboard';
  } else if (role === 'Institute') {
    return 'idashboard';
  }
};

export const pageNameByPath = (path, role) => {
  if (path === '/cdashboard/edit-job-preference') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/cdashboard/preferences-menu">
            <span className="text-info">Preferences</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Edit Job Preference</span>
      </span>
    );
  }
  if (path === '/cdashboard/dashboard-settings') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/cdashboard/profile-menu">
            <span className="text-info">Profile Menu</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Settings</span>
      </span>
    );
  }
  if (path === '/cdashboard/change-mobile') {
    return 'Change Mobile';
  }
  if (path === '/cdashboard/change-pin') {
    return 'Change PIN';
  }
  if (path === '/cdashboard/cd-edit-profile') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/cdashboard/profile-menu">
            <span className="text-info">Profile Menu</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Edit Profile</span>
      </span>
    );
  }
  if (path === '/cdashboard/notifications') {
    return 'Notifications';
  }
  if (path?.includes('/cdashboard/view-company')) {
    return 'Company';
  }
  if (path?.includes('/cdashboard/view-recruiter')) {
    return 'Recruiter';
  }
  if (path === '/cdashboard/candidate-view-profile-details') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/cdashboard/profile-menu">
            <span className="text-info">Profile Menu</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">My Profile</span>
      </span>
    );
  }
  if (path?.includes('/cdashboard/candidate-view-applied-job')) {
    return 'Applications';
  }
  if (path?.includes('/cdashboard/candidate-view-active-jobs')) {
    return 'Active Jobs';
  }
  if (path?.includes('/cdashboard/candidate-activites-status')) {
    return 'Activities';
  }
  if (path === '/cdashboard/cd-profile-reg') {
    return 'Create Profile';
  }
  if (path === '/cdashboard/candidate-basic-advance-job-search') {
    return 'Search Jobs';
  }
  if (path === '/cdashboard/candidate-view-job-post-full-details') {
    return 'Job Post';
  }
  if (path === '/cdashboard/academic-records') {
    return 'Academic Records';
  }
  if (path === '/fdashboard/agency-pricing-sheet') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/fdashboard/franchisee-plan">
            <span className="text-info">Pricing Plan</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Agency Pricing Plan</span>
      </span>
    );
  }
  if (path === '/cdashboard/candidate-update-resume') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/cdashboard/profile-menu">
            <span className="text-info">Profile Menu</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Update Resume</span>
      </span>
    );
  }
  if (path?.includes('/cdashboard/candidate-employer-action')) {
    return 'Employer Actions';
  }
  if (path === '/cdashboard/applied-job-list') {
    return 'Applied Jobs';
  }
  if (path === '/cdashboard/saved-job-list') {
    return 'Saved Jobs';
  }
  if (path === '/cdashboard/verification') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/cdashboard/profile-menu">
            <span className="text-info">Profile Menu</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Background Documents</span>
      </span>
    );
  }
  if (path === '/cdashboard/aadhaar-verification') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/cdashboard/profile-menu">
            <span className="text-info">Profile Menu</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Aadhaar Verification</span>
      </span>
    );
  }
  if (path === '/edashboard/institute-empanelment') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/edashboard/empanelment">
            <span className="text-info">Empanelments</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Institute Empanelment</span>
      </span>
    );
  }
  if (path === '/cdashboard/institute-empanelment') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/cdashboard/get-associated">
            <span className="text-info">Associations</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Institute Empanelment Requests</span>
      </span>
    );
  }
  if (path === '/edashboard/view-job-post-full-details') {
    return 'View Job';
  }
  if (path === '/edashboard/dashboard-settings') {
    return 'Settings';
  }
  if (path === '/edashboard/change-mobile') {
    return 'Change-Mobile';
  }
  if (path === '/edashboard/change-pin') {
    return 'Change PIN';
  }
  if (path === '/edashboard/commercials') {
    return 'Commercials';
  }
  if (path === '/edashboard/commercials/contracts') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/edashboard/commercials">
            <span className="text-info">Commercials</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Contracts</span>
      </span>
    );
  }
  if (path === '/edashboard/commercials/emp-plan') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/edashboard/commercials">
            <span className="text-info">Commercials</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Pricing Plan</span>
      </span>
    );
  }
  if (path === '/edashboard/commercials/orders-history') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/edashboard/commercials">
            <span className="text-info">Commercials</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Payment Details</span>
      </span>
    );
  }
  if (path === '/edashboard/company/view') {
    return 'View Company';
  }
  if (path === '/edashboard/company/edit') {
    return 'Edit Company';
  }
  if (path === '/edashboard/admin/view') {
    return 'My Profile';
  }
  if (path === '/edashboard/invited-by-candidates') {
    return 'Candidates Invitation';
  }
  if (path === '/edashboard/admin/edit') {
    return 'Edit Recruiter';
  }
  if (path === '/edashboard/manage-recruiters') {
    return 'Manage Users';
  }
  if (path === '/edashboard/edit-job-post') {
    return 'Edit Job Post';
  }
  if (path?.includes('/edashboard/view-candidate-application')) {
    return 'View Application';
  }
  if (path?.includes('/edashboard/view-candidate-full-detail')) {
    return 'View Candidate';
  }
  if (path === '/edashboard/manage-job-post') {
    return 'Manage Job Post';
  }
  if (path === '/edashboard/job-posts') {
    return 'Job Posts';
  }
  if (path === '/edashboard/create-job-post') {
    return 'Create Job Posts';
  }
  if (path === '/edashboard/commercials/emp-pricing-sheet') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/edashboard/commercials">
            <span className="text-info">Commercials</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Pricing Sheet</span>
      </span>
    );
  }
  if (path === '/edashboard/emp-plan') {
    return 'Pricing Plan';
  }
  if (path === '/edashboard/notifications') {
    return 'Notifications';
  }
  if (path === '/edashboard/emp-candidate-search') {
    return 'Search Candidates';
  }
  if (path === '/edashboard/shortlisted-candidates') {
    return 'Shortlisted Candidates';
  }
  if (path === '/rdashboard/company-view-details') {
    return 'View Company';
  }
  if (path === '/rdashboard/employer-view') {
    return 'Employer Profile View';
  }
  if (path === '/rdashboard/profile-view') {
    return 'Recruiter Profile';
  }
  if (path === '/rdashboard/profile-edit') {
    return 'Recruiter Profile Edit';
  }
  if (path === '/rdashboard/job-post-listing') {
    return 'Job Posts';
  }
  if (path === '/rdashboard/create-job-post') {
    return 'Create Job Posts';
  }
  if (path === '/rdashboard/edit-job-post') {
    return 'Edit Job Posts';
  }
  if (path === '/rdashboard/invited-by-candidates') {
    return 'Candidates Invitation';
  }
  if (path === '/rdashboard/manage-job-post') {
    return 'Manage Job Post';
  }
  if (path === '/rdashboard/shortlisted-candidates') {
    return 'Shortlisted Candidates';
  }
  if (path === '/rdashboard/dashboard-settings') {
    return 'Settings';
  }
  if (path === '/rdashboard/change-mobile') {
    return 'Change Mobile';
  }
  if (path === '/rdashboard/change-pin') {
    return 'Change PIN';
  }
  if (path === '/edashboard/post-job/new') {
    return 'Create Job Posts';
  }
  if (path === '/edashboard/orders-history') {
    return 'Orders History';
  }
  if (path === '/fdashboard/order-history') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/fdashboard/commercials">
            <span className="text-info">Commercials</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Franchisee Orders History</span>
      </span>
    );
  }
  if (path === '/fdashboard-details') {
    return 'Franchisee Details';
  }
  if (path === '/fdashboard/change-pin') {
    return 'Change PIN';
  }
  if (path === '/fdashboard/change-mobile') {
    return 'Change Mobile';
  }
  if (path === '/fdashboard/fdashboard-associated') {
    return sessionStorage?.userType === 'Employers'
      ? 'Employers'
      : 'My Candidates';
  }
  if (path === '/fdashboard/fdashboard-earnings') {
    return 'Order Details';
  }
  if (path === '/fdashboard/fdashboard-details') {
    return 'Profile Details';
  }
  if (path === '/fdashboard/dashboard-settings') {
    return 'Settings';
  }
  if (path === '/fdashboard/fdashboard-paid-users') {
    return 'Paid Users';
  }
  if (path === '/fdashboard/create-employer') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/fdashboard/createprofiles">
            <span className="text-info">Jobs Central</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">User Creation</span>
      </span>
    );
  }
  if (path === '/idashboard/create-employer') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/job-central">
            <span className="text-info">Job Central</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2 mr-2">
          <Link to="/idashboard/createprofiles">
            <span className="text-info">Create Company</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Create Dufther Employer</span>
      </span>
    );
  }
  if (path === '/fdashboard/internal-company') {
    return 'Company Creation';
  }
  if (path === '/fdashboard/companies') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/fdashboard/createprofiles">
            <span className="text-info">Create Profile</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Companies Created</span>
      </span>
    );
  }
  if (path === '/fdashboard/job-posts') {
    return 'Job Posts';
  }
  if (path === '/fdashboard/contract-information') {
    return 'Contract';
  }
  if (path === '/fdashboard/emp-candidate-search') {
    return 'Search Candidates';
  }
  if (path === '/fdashboard/post-job/new') {
    return 'Create Job Posts';
  }
  if (path === '/fdashboard/manage-job-post') {
    return 'Manage Job Post';
  }
  if (path === '/fdashboard/view-job-post-full-details') {
    return 'View Job';
  }
  if (path === '/fdashboard/job-post-listing') {
    return 'Job Posts';
  }
  if (path === '/fdashboard/create-job-post') {
    return 'Create Job Posts';
  }
  if (path === '/fdashboard/edit-job-post') {
    return 'Edit Job Posts';
  }
  if (path === '/fdashboard/contracts') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/fdashboard/commercials">
            <span className="text-info">Commercials</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Contracts</span>
      </span>
    );
  }
  if (path === '/fdashboard/commercials') {
    return 'Commercials';
  }
  if (path === '/fdashboard/franchisee-plan') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/fdashboard/commercials">
            <span className="text-info">Commercials</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Pricing Plan</span>
      </span>
    );
  }
  if (path === '/fdashboard/franchisee-pricing-sheet') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/fdashboard/franchisee-plan">
            <span className="text-info">Pricing Plan</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Candidate Pricing Plan</span>
      </span>
    );
  }
  if (path === '/fdashboard/placement-history') {
    return 'Placement History';
  }
  if (path === '/fdashboard/shortlisted-candidates') {
    return 'Shortlisted Candidates';
  }
  if (path === '/idashboard/candidate-listing') {
    return 'Associated Candidate';
  }
  //breadcrumb added for manage institute users.
  if (path === '/idashboard/manage-institute-users') {
    return 'Manage Institute Users';
  }
  if (path === '/idashboard/add-candidate') {
    return 'Add Candidate';
  }
  if (path === '/idashboard/consent-form') {
    return 'Consent Form';
  }
  if (path === '/idashboard/profile-edit') {
    return 'Edit profile';
  }
  if (path === '/idashboard/profile-view') {
    return 'View Profile';
  }
  if (path === '/idashboard/dashboard-settings') {
    return 'Settings';
  }
  if (path === '/idashboard/change-mobile') {
    return 'Change Mobile';
  }
  if (path === '/idashboard/change-pin') {
    return 'Change PIN';
  }
  if (path === '/idashboard/course-catalogue') {
    return 'Manage Courses / Student Batches';
  }
  if (path === '/idashboard/batch-listing') {
    return 'Manage Courses / Student Batches';
  }
  if (path === '/idashboard/empanelment') {
    return 'Empanelments';
  }
  if (path === '/idashboard/edit-application') {
    return 'Edit Application';
  }
  if (
    path === '/idashboard/broadcast-notice' ||
    path === '/edashboard/broadcast-notice'
  ) {
    return 'Broadcast Notices';
  }
  if (path === '/idashboard/specialization') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/programmes">
            <span className="text-info">Programmes</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Institute Specializations</span>
      </span>
    );
  }
  if (path === '/fdashboard/associated-company-jobs') {
    return 'Associated Companies Jobs';
  }
  if (path === '/fdashboard/viewed-candidates') {
    return 'Viewed Candidates';
  }
  if (path === '/edashboard/viewed-candidates') {
    return 'Viewed Candidates';
  }
  if (path === '/cdashboard/associated-jobs-by-so') {
    return 'Franchisee Associated Jobs';
  }
  if (path === '/idashboard/job-central') {
    return 'Jobs Central';
  }
  if (path === '/idashboard/manage-batch') {
    return 'Manage Batch';
  }
  if (path === '/idashboard/manage-placement') {
    return 'Manage Placements';
  }
  if (path === '/idashboard/post-job/new') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/job-central">
            <span className="text-info">Job Central</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Post a Job</span>
      </span>
    );
  }
  if (path === '/idashboard/internal-company') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/job-central">
            <span className="text-info">Job Central</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2 mr-2">
          <Link to="/idashboard/createprofiles">
            <span className="text-info">Create Company</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Internal Company</span>
      </span>
    );
  }
  if (path === '/idashboard/create-batch') {
    return 'Batch Creation';
  }
  if (path === '/idashboard/programmes') {
    return 'Institute Programmes';
  }
  if (path === '/idashboard/job-posts') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/job-central">
            <span className="text-info">Job Central</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Job Postings</span>
      </span>
    );
  }
  if (path === '/idashboard/students-listing') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/manage-batch">
            <span className="text-info">Manage Batch</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Student Listing</span>
      </span>
    );
  }
  if (path === '/idashboard/batch-details') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/manage-batch">
            <span className="text-info">Manage Batch</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Analytics</span>
      </span>
    );
  }
  if (path === '/idashboard/manage-students') {
    return 'Manage Students';
  }
  if (path === '/idashboard/job-central/companies-by-tier') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/job-central">
            <span className="text-info">Job Central</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Companies By Tier</span>
      </span>
    );
  }
  if (path === '/idashboard/course-catalogues') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/programmes">
            <span className="text-info">Programmes</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Courses</span>
      </span>
    );
  }
  if (path === '/idashboard/company-list') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/job-central">
            <span className="text-info">Job Central</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Companies Created</span>
      </span>
    );
  }
  if (path === '/idashboard/manage-job-post') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/job-posts">
            <span className="text-info">Job Posts</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Manage Job Post</span>
      </span>
    );
  }
  if (path === '/idashboard/edit-job-post') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/job-posts">
            <span className="text-info">Job Posts</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Edit Job</span>
      </span>
    );
  }
  if (path === '/idashboard/empanelment-requests-management') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/empanelment">
            <span className="text-info">Empanelments</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Candidate Requests</span>
      </span>
    );
  }
  if (path === '/idashboard/empanelment-request-institute-to-company') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/empanelment">
            <span className="text-info">Empanelments</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Associate With Company</span>
      </span>
    );
  }
  if (path === '/idashboard/company-association') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/empanelment">
            <span className="text-info">Empanelments</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Company Association</span>
      </span>
    );
  }
  if (role && path === `/${getDashboardPath(role)}/login_info`) {
    if (path === '/cdashboard/login_info') {
      return (
        <span>
          <span className="mr-2">
            <Link to="/cdashboard/profile-menu">
              <span className="text-info">Profile Menu</span>
            </Link>
          </span>
          <span>/</span>
          <span className="ml-2">Login Details</span>
        </span>
      );
    } else {
      return 'Login Details';
    }
  }
  if (path === '/fdashboard/empanelment-request-franch-to-company') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/fdashboard/associations">
            <span className="text-info">Associations</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Company Associations</span>
      </span>
    );
  }
  if (path === '/fdashboard/empanelment-request-manage') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/fdashboard/associations">
            <span className="text-info">Associations</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Candidate Requests</span>
      </span>
    );
  }
  if (path === '/cdashboard/empanelment-request-candidate-to-franchise') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/cdashboard/get-associated">
            <span className="text-info">Associations</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Agency Association Requests</span>
      </span>
    );
  }
  if (path === '/cdashboard/associated-agency') {
    return (
      <span>
        <span className="ml-2">Associated Agency Details</span>
      </span>
    );
  }
  if (path === '/cdashboard/agencies-associated') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/cdashboard/get-associated">
            <span className="text-info">Associations</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Agency Associations</span>
      </span>
    );
  }
  if (path === '/edashboard/empanelment-requests-management') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/edashboard/empanelment">
            <span className="text-info">Empanelments</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Agency Requests</span>
      </span>
    );
  }
  if (path === '/edashboard/agency-association') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/edashboard/empanelment">
            <span className="text-info">Empanelments</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Agency Associations</span>
      </span>
    );
  }
  if (path === '/edashboard/empanelment-institute-requests-management') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/edashboard/empanelment">
            <span className="text-info">Empanelments</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Institute Requests</span>
      </span>
    );
  }
  if (path === '/fdashboard/associations') {
    return 'Associations';
  }
  if (path === '/fdashboard/createprofiles') {
    return 'Create Profile';
  }
  if (path === '/idashboard/createprofiles') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/job-central">
            <span className="text-info">Job Central</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Create Company</span>
      </span>
    );
  }
  if (path === '/fdashboard/edit-terms-conditions') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/fdashboard/commercials">
            <span className="text-info">Commercials</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Terms & Conditions</span>
      </span>
    );
  }
  if (path === '/edashboard/empanelment-request-company-to-institute') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/edashboard/empanelment">
            <span className="text-info">Empanelments</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Institute Requests</span>
      </span>
    );
  }
  if (path === '/edashboard/institute-associations') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/edashboard/empanelment">
            <span className="text-info">Empanelments</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Institute Associations</span>
      </span>
    );
  }
  if (path === '/edashboard/empanelment') {
    return 'Empanelments';
  }
  if (path === '/cdashboard/get-associated') {
    return 'Associations';
  }
  if (path === '/cdashboard/empanelled-jobs') {
    return 'Empanelled Jobs';
  }
  if (path === '/cdashboard/jobs-posted-by-agencies') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/cdashboard/empanelled-jobs">
            <span className="text-info">Empanelled Jobs</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Agency Jobs</span>
      </span>
    );
  }
  if (path === '/cdashboard/jobs-posted-by-institutes') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/cdashboard/empanelled-jobs">
            <span className="text-info">Empanelled Jobs</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Institute Jobs</span>
      </span>
    );
  }
  if (path === '/cdashboard/preferences-menu') {
    return 'Preferences';
  }
  if (path === '/cdashboard/profile-menu') {
    return 'Profile Menu';
  }
  if (path === '/edashboard/agency-applications') {
    return 'External Applications';
  }
  if (path === '/fdashboard/franchisee-view-single-log') {
    return 'Import Log Details';
  }
  if (path === '/fdashboard/bulk-users') {
    return 'Bulk Users';
  }
  if (path === '/fdashboard/service-offerings') {
    return 'Service Offerings';
  }
  if (path === '/fdashboard/applications') {
    return 'Applications';
  }
  if (path === '/cdashboard/agency-agreements') {
    return 'Agency Agreements';
  }
  if (path === '/fdashboard/create-bulk-candidates') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/fdashboard/bulk-users">
            <span className="text-info">Bulk Users</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Bulk File Upload</span>
      </span>
    );
  }
  if (path === '/fdashboard/list-bulk-candidates') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/fdashboard/bulk-users">
            <span className="text-info">Bulk Users</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Bulk Users Listing</span>
      </span>
    );
  }
  if (path === '/fdashboard/search-candidate-user-listing') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/fdashboard/bulk-users">
            <span className="text-info">Bulk Users</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Candidates By Uploaded File</span>
      </span>
    );
  }
  if (path === '/fdashboard/search-candidate-by-process') {
    return 'Search Candidates by Uploaded File';
  }
  if (path === '/cdashboard/institutes-associated') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/cdashboard/get-associated">
            <span className="text-info">Associations</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Institute Empanelments</span>
      </span>
    );
  }
  if (path === '/cdashboard/search-preferences') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/cdashboard/preferences-menu">
            <span className="text-info">Preferences</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Search Preferences</span>
      </span>
    );
  }
  if (path === '/idashboard/bulk-users') {
    return 'Bulk Users';
  }
  if (path === '/idashboard/create-bulk-candidates') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/bulk-users">
            <span className="text-info">Bulk Users</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Bulk File Upload</span>
      </span>
    );
  }
  if (path === '/idashboard/list-bulk-candidates') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/bulk-users">
            <span className="text-info">Bulk Users</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Bulk Users Listing</span>
      </span>
    );
  }
  if (path === '/idashboard/search-candidate-user-listing') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/bulk-users">
            <span className="text-info">Bulk Users</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Candidates By Uploaded File</span>
      </span>
    );
  }
  if (path === '/idashboard/search-candidate-by-process') {
    return 'Search Candidates by Uploaded File';
  }
  if (path === '/idashboard/idashboard-associated') {
    return (
      <span>
        <span className="mr-2">
          <Link to="/idashboard/job-central">
            <span className="text-info">Job Central</span>
          </Link>
        </span>
        <span>/</span>
        <span className="ml-2">Employers Listing</span>
      </span>
    );
  }
};

export const employerActionToCandidates = {
  'Jobs Shortlisting Done': 'shortlisted',
  'Jobs Invited to Apply': 'invited',
  'Changes in Job Information': 'updates',
};

export const getRoleName = (data) => {
  if (data === 'INDV') {
    return 'Individual Employer';
  } else if (data === 'MSME') {
    return 'MSME Company';
  } else {
    return 'Corporate Employer';
  }
};

export const domainMask = {
  'Engineering/Mechanical Works': 'DOMAIN_ENGG',
  'Construction/Civil Works': 'DOMAIN_CONST',
  'Electrical Works': 'DOMAIN_ELECT',
  Logistics: 'DOMAIN_LOGISTICS',
  'Software and IT Services': 'DOMAIN_TECH',
  'ERP Systems': 'DOMAIN_ERP',
  Management: 'DOMAIN_MANAGEMENT',
  'Financial Services': 'DOMAIN_FINANCE',
  'Medical/Healthcare': 'DOMAIN_MEDICAL',
  'Road Transport/Construction Vehicles': 'DOMAIN_RD_TRANSPORT',
  'Office Support Services': 'DOMAIN_BKOFFICE',
  'Sales and Marketing': 'DOMAIN_SALES_MKTG',
  'Electric Vehicles': 'DOMAIN_ELECTRIC',
  'Environment Related': 'DOMAIN_ENV',
  'Research and Development': 'DOMAIN_TECH',
  'Chemicals/Paints/Pharma': 'DOMAIN_CHEM',
  'Diagnostics/Pharmacy': 'DOMAIN_DIAGN',
  'Security Services': 'DOMAIN_SECURITY',
  Cleaning: 'DOMAIN_CLEANING',
  'Media/Entertainment': 'DOMAIN_MEDIA',
  'Other Industries': 'Other Industries',
  Shipping: 'DOMAIN_SHIPPING',
  Airlines: 'DOMAIN_AIRLINES',
  'Hospitality/Hotels': 'DOMAIN_HOSPITALITY',
  Fitness: 'DOMAIN_FITNESS',
  'Teaching/Education': 'DOMAIN_TEACHING',
  'Animation and Multimedia': 'DOMAIN_ANIMATION',
  'Food/Beverages': 'DOMAIN_FOOD_BVG',
  Manufacturing: 'DOMAIN_MANUFACTURING',
};

export const instituteAnalyticsMask = {
  'Specialization Count': 'Specializations',
  'Associated Candidate Count': 'Manage Students',
  'Course Count': 'Courses',
  'Batch Count': 'Manage Batches',
};

export const franchiseeDetailsMenu = [
  'Company Name',
  'Job Count',
  'Active Job Count',
  'Year of Incorporation',
  'Nature of Business',
  'Business Model',
  'Entity Type',
  'Domain',
  'No. of Employees',
];

export const companyEmpanelmentRequestColumns = [
  `Created On`,
  `ID`,
  `Institute Code`,
  `InstituteName`,
  `Status`,
  `More Details`,
];

export const candidateEmpanelmentRequestColumns = [
  `Created On`,
  `Institute Code`,
  `Institute Name`,
  `Course Name`,
  `Modified On`,
  'Status',
];

export const employerEmpanelmentRequestColumns = [
  `Created On`,
  `Institute Code`,
  `Institute Name`,
  `Address`,
  `Modified On`,
  'Status',
];

export const applicationStages = {
  Placed: 'PLACED',
  'Not Accepted Offer': 'OFFER_NOT_ACCEPTED',
  'Awaiting Offer Letter': 'AWAITING_OFFER',
  'Offer Accepted': 'OFFER_ACCEPTED',
  Shortlisted: 'SHORTLISTED',
  'Not Shortlisted': 'NOT_SHORTLISTED',
};

export const FranchiseeEmpanelmentRequestColumns = [
  `Company Name`,
  `Status`,
  `Created On`,
  `Location`,
  `Action`,
  `Contract Status`,
];

export const FranchiseeEmpanelmentColumns = [
  `Company Name`,
  `Empnld Status`,
  `Empnld Created On`,
  `Location`,
  `Contact Person`,
];

export const FranchiseeCreateContractColumns = [
  `Company Name`,
  `Contract Status`,
  `Payment Status`,
  `Action`,
];

export const CandidatefranchEmpanelmentRequestColumns = [
  `Request ID`,
  `Created On`,
  `Updated Date`,
  `Agent Name`,
  `Company Name`,
  `Location`,
  `Status`,
  '',
];
