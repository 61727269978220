import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, Form } from 'react-bootstrap';
import TextArea from '../common/TextArea';
import * as gService from '../../utility/GlobalServices';
import RestyleRadioButton from 'components/common/RestyleRadioButton';

const EditTermsAndConditions = (props) => {
  const { franchiseDetailStore, saveFranchiseeData } = props;
  const { details } = franchiseDetailStore || {};
  const [userType, setUserType] = useState(false);
  const [employerTnC, setEmployerTnC] = useState('');
  const [candidateTnC, setCandidateTnC] = useState('');
  const { errors, register, handleSubmit } = useForm({
    mode: 'all',
  });

  useEffect(() => {
    if (details?.id) {
      if (userType) setCandidateTnC(details?.candidateTermConditions || '');
      else setEmployerTnC(details?.termConditions || '');
    }
  }, [details, userType]);

  const beforeSubmit = (data) => {
    const formData = {
      ...details,
      companyName: gService
        .capitalizeFirstLetter(details?.company?.companyName)
        .trim(),
      termConditions: employerTnC,
      candidateTermConditions: candidateTnC,
    };
    saveFranchiseeData(formData);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (userType) {
      setCandidateTnC(value);
    } else {
      setEmployerTnC(value);
    }
  };

  return (
    <Form onSubmit={handleSubmit(beforeSubmit)} className="mx-auto container">
      <Card.Body className="m-0 p-0">
        <RestyleRadioButton
          required
          errors={errors}
          name="forUser"
          options={[
            { label: 'Candidate', value: true },
            { label: 'Employer', value: false },
          ]}
          ref={register({ required: true })}
          defaultValue={userType}
          changeValue={setUserType}
          labelClassName="text-capitalize text-dark"
          label="Select User Type"
        />
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            className="btn btn-info btn-next"
            style={{ width: '150px' }}
          >
            Save
          </Button>
        </div>

        <TextArea
          required
          ref={register({
            required: true,
            maxLength: {
              value: 5000,
              message: 'Should be less than 500 characters',
            },
          })}
          errors={errors}
          rules={{ required: true }}
          maxLimit={5000}
          name={userType ? 'candidateTermCondition' : 'tncText'}
          rows="18"
          label="Terms & Conditions"
          placeholder="Enter Your Terms & Conditions"
          value={userType ? candidateTnC : employerTnC}
          onChange={handleChange}
          labelClass="font-size-13"
        />
      </Card.Body>
    </Form>
  );
};

export default memo(EditTermsAndConditions);
