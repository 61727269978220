/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, memo, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useForm, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { pathOr } from 'ramda';
import { initialState } from '../../CandidateBasicAdvanceJobSearch/prepare-filter-data.utils';

import { displayNotification } from '../../../config/toastConfig';

import * as gService from '../../../utility/GlobalServices';

import {
  // formatOptions,
  convertSelectOpt,
  convertMultiSelectOpt,
} from '../../../utils/form';

import Select from '../Select';
import * as actions from '../../../containers/CandidateBasicAdvanceJobSearch/actions';
import makeSelectCandidateProfileReg from '../../../containers/CandidateBasicAdvanceJobSearch/selectors';
// import Input from '../Input';
import './cd-job-search.css';

import { useInjectSaga } from '../../../utils/injectSaga';
import { useInjectReducer } from '../../../utils/injectReducer';
import candidateSearchReducer from '../../../containers/CandidateBasicAdvanceJobSearch/reducer';
import candidateSearchSaga from '../../../containers/CandidateBasicAdvanceJobSearch/saga';

import useOutSideCallback from '../../../hooks/useOutsideCallback.hook';

import SelectGoogleLocation from '../SelectGoogleLocation';

//Geo location library
// import useGeolocation from 'react-hook-geolocation';
// import * as GoogleMapService from '../../../utility/GoogleMapApiServices';

// const geolocation = () => {
//   return useGeolocation({
//     enableHighAccuracy: true,
//     maximumAge: 15000,
//     timeout: 12000,
//   }).latitude;
// };
const getCurrentLocation = async (evt, getAutoDetectedLocation) => {
  evt.preventDefault();
  navigator.geolocation.getCurrentPosition(
    (position) => {
      gService.getGeocodeByLatLng(
        position.coords.latitude,
        position.coords.longitude,
        getAutoDetectedLocation
      );
    },
    (err) => {
      console.log('error in fetching current location', err);
    },
    {
      enableHighAccuracy: true,
      maximumAge: Infinity,
      timeout: 10000,
    }
  );
};

const renderLocation = (errors, control, watchLocations, locationsData) => (
  <SelectGoogleLocation
    errors={errors}
    control={control}
    isMulti
    name="geoLocations"
    rules={{ required: false }}
    label=""
    value={watchLocations}
    defaultValue={locationsData?.map((data) => {
      return { label: data.label, value: data.place_id };
    })}
    maxLimit={5}
    placeholder="Enter Location(s) for Jobs"
  />
);

const renderMobileSearch = (
  register,
  errors,
  control,
  keywords,
  locationsData,
  getAutoDetectedLocation,
  watchSearchKey,
  watchLocations,
  searchPanel
) => (
  <div ref={searchPanel} className="row d-flex">
    <div className="col-lg-12">
      <div id="collapsesearch" className="">
        <div className="col-lg-4 pt-2 pb-2 pr-0 pl-0 search-select-header">
          {renderLocation(errors, control, watchLocations, locationsData)}
          <a
            href="#"
            className="location-img-search-mob"
            onClick={(evt) => {
              evt.preventDefault();
              getCurrentLocation(evt, getAutoDetectedLocation);
            }}
          >
            <i className="fa fa-crosshairs" aria-hidden="true"></i>
          </a>
        </div>

        <div className="col-lg-6 pb-2 pr-0 pl-0">
          <Select
            isMulti
            isCreateable
            placeholder="Enter Keyword(s) eg. Industry, Role, Skillset etc."
            errors={errors}
            control={control}
            value={watchSearchKey}
            name="searchKey"
            rules={{ required: false }}
            defaultValue={convertSelectOpt(keywords)}
            wrapperClass="searchForm mr-2"
          />
        </div>

        <div className="col-lg-2 pr-0 pl-0">
          <button
            type="search"
            className="btn btn-primary width-max-sm mb-0"
            onClick={() => sessionStorage.setItem('searchedMsg', true)}
          >
            SEARCH
          </button>
        </div>
      </div>
    </div>
  </div>
);

const renderWebSearch = (
  register,
  errors,
  control,
  keywords,
  locationsData,
  getAutoDetectedLocation,
  watchSearchKey,
  watchLocations
) => {
  return (
    <div className="row web-view-search">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-4 pr-2 search-select-header">
            {renderLocation(errors, control, watchLocations, locationsData)}
            <a
              className="location-img-search"
              onClick={(evt) => {
                evt.preventDefault();
                getCurrentLocation(evt, getAutoDetectedLocation);
              }}
            >
              <i className="fa fa-crosshairs" aria-hidden="true"></i>
            </a>
          </div>
          <div className="col-lg-8 pr-dis-search-btn">
            <Select
              isMulti
              isCreateable
              placeholder="Enter Keyword(s) eg. Industry, Role, Skillset etc."
              errors={errors}
              control={control}
              name="searchKey"
              value={watchSearchKey}
              rules={{ required: false }}
              defaultValue={convertMultiSelectOpt(keywords)}
              wrapperClass="searchForm mr-2"
            />
            <div>
              <button
                type="submit"
                style={{ width: '88px' }}
                className="btn btn-fill btn-info"
                onClick={() => sessionStorage.setItem('searchedMsg', true)}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CDJobSearch = (props) => {
  useInjectReducer({
    key: 'advanceBasicJobSearch',
    reducer: candidateSearchReducer,
  });
  useInjectSaga({ key: 'advanceBasicJobSearch', saga: candidateSearchSaga });
  const searchPanel = useRef(null);
  const {
    // change,
    searchJobsLinkRef,
    mobileDropDownMenu,
    candidateBasicJobSearchStore,
    searchJob,
    history,
    setPageNumber,
    toggleMobileDropDownMenu,
    setPayloadData,
  } = props;
  const { searchPayload } = candidateBasicJobSearchStore;
  // search location based on user input
  const keywords = pathOr([], ['keywords'], searchPayload);
  const geoLocations = pathOr([], ['geoLocations'], searchPayload);
  const params = useLocation();
  const {
    register,
    control,
    setValue,
    handleSubmit,
    errors,
    // formState: { isDirty },
    // reset,
  } = useForm();

  // const getLocationFroGeo = (geoLocations) => {
  //   if (!geoLocations || geoLocations.length == 0) {
  //     return [];
  //   }
  //   const fmtLocs = geoLocations.map((loc) => {
  //     return { label: loc.label, value: loc.city };
  //   });
  //   return fmtLocs;
  // };

  const watchSearchKey = useWatch({
    control,
    name: 'searchKey',
    defaultValue: convertSelectOpt(keywords),
  });

  const watchLocations = useWatch({
    control,
    name: 'geoLocations',
    defaultValue: geoLocations?.map((data) => {
      return { label: data.label, value: data.place_id };
    }),
  });
  // get coordinates

  const resetObject = (data) => {
    const tempData = {};
    for (let prop in data) {
      if (Array.isArray(data[prop]) && data[prop]?.length) {
        tempData[prop] = [];
      } else {
        tempData[prop] = data[prop];
      }
    }
    return tempData;
  };

  useEffect(() => {
    !keywords.length && setValue('searchKey', '');
  }, [keywords]);

  useEffect(() => {
    if (
      params.pathname === '/cdashboard/candidate-basic-advance-job-search' &&
      !(searchPayload.geoLocations.length || searchPayload.keywords.length)
    ) {
      const newPayload = {
        ...resetObject(searchPayload),
        page: 0,
        keywords: [],
        geoLocations: [],
      };
      setPayloadData(newPayload);
    }
  }, [params.pathname]);

  const getAutoDetectedLocation = (geoLoc) => {
    setValue('geoLocations', [geoLoc]);
  };

  const beforeSubmit = (data) => {
    const searchKeys = pathOr([], ['searchKey'], data);
    const geoLocations = pathOr([], ['geoLocations'], data);
    if (searchKeys.length || geoLocations.length) {
      const newPayload = {
        ...initialState(keywords),
        keywords: searchKeys.length ? searchKeys.map((item) => item.value) : [],
        geoLocations:
          geoLocations.length > 0
            ? geoLocations.map((locObj) => {
                // console.log(locObj.value.place_id);
                if (typeof locObj.value === 'string') {
                  return locObj;
                }
                return {
                  place_id: locObj.value.place_id,
                  label: locObj.label,
                  city: locObj.value.structured_formatting.main_text,
                };
              })
            : [],
      };
      newPayload['page'] = 0;
      setPageNumber(0);
      params?.pathname !== '/cdashboard/candidate-basic-advance-job-search'
        ? searchJob(newPayload, history)
        : searchJob(newPayload, null);
    } else {
      displayNotification(
        'Please enter atleast one search criteria for Location or Keyword',
        'warning'
      );
    }
  };

  useOutSideCallback(
    [searchPanel, searchJobsLinkRef],
    () => toggleMobileDropDownMenu && toggleMobileDropDownMenu(false)
  );

  return (
    <Fragment>
      <div className="container-fluid cd-jobsearch">
        <form onSubmit={handleSubmit(beforeSubmit)}>
          {renderWebSearch(
            register,
            errors,
            control,
            keywords,
            geoLocations,
            getAutoDetectedLocation,
            watchSearchKey,
            watchLocations
          )}
          <div className="mobile-view-search">
            {mobileDropDownMenu &&
              renderMobileSearch(
                register,
                errors,
                control,
                keywords,
                geoLocations,
                getAutoDetectedLocation,
                watchSearchKey,
                watchLocations,
                searchPanel
              )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

CDJobSearch.propTypes = {
  change: PropTypes.func,
  candidateBasicJobSearchStore: PropTypes.object,
  searchJob: PropTypes.func,
};

// dispatch state
const mapStateToProps = createStructuredSelector({
  candidateBasicJobSearchStore: makeSelectCandidateProfileReg(),
});

// dispatch actions
const mapDispatchToProps = (dispatch) => ({
  searchJob: (data, history) => {
    dispatch(actions.searchJob(data, history));
  },
  getNearestLocation: (distance, latitude, longitude) => {
    dispatch(actions.getNearestLocation(distance, latitude, longitude));
  },
  setPageNumber: (data) => {
    dispatch(actions.setPageNumber(data));
  },
  setPayloadData: (data) => {
    dispatch(actions.setPayloadData(data));
  },
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withRouter, withConnect, memo)(CDJobSearch);
