/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, memo, Fragment, useEffect } from 'react';
import { pathOr } from 'ramda';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import makeSelectGlobalStore from '../../../containers/App/selectors';
import makeSelectEmployerDashboard from '../../../containers/EmployerDashboard/selectors';
import faviconImage from '../../../images/FAVICON.png';
import userLogo from '../../../images/PROFILE_COMMON_HEADER.png';
import duftherLogoName from '../../../images/DUFTHER_NAME_LOGO.png';
import * as gConst from '../../../utility/GlobalConstants';
import * as eDashboardActions from '../../../containers/EmployerDashboard/actions';
import './employer-dashboard-sidebar.css';
import { displayNotification } from 'config/toastConfig';

let EmployerDashboardSidebar = (props) => {
  const {
    globalStore,
    showSideMenu,
    showMobileMenu,
    toggleMobileSideMenu,
    toggleWebSideMenu,
    employerDashboardStore,
  } = props;

  const userRoleData = pathOr('', ['userRoleData'], globalStore);
  const userLastLogin = pathOr('', ['userData', 'lastLogin'], globalStore);
  const verificationStatus = pathOr(
    '',
    ['companyDetail', 'basicDetail', 'verificationStatus'],
    employerDashboardStore
  );
  const { avatarUrl, profileImage, companyDetail } = employerDashboardStore;
  const { basicDetail } = companyDetail;
  const userStatus = basicDetail?.status;
  // pathOr('', ['userRoleData', 'status'], globalStore);

  const [userAvatar, setUserAvatar] = useState('');
  const [scriptLoaded, setScriptLoaded] = useState(false);
  // expand and collapse USer info
  const [userInfo, toggleUserInfo] = useState(true);

  const avatarId = pathOr('', ['id'], profileImage);

  useEffect(() => {
    avatarId && props.getAvatar(avatarId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarId]);

  useEffect(() => {
    avatarUrl ? setUserAvatar(avatarUrl) : setUserAvatar(userLogo);
  }, [avatarUrl]);

  // initialize checkboxes value based on set value in api

  let userNewName = `${userRoleData?.firstName} ${userRoleData?.lastName}`;

  const handleInactiveMessage = () => {
    if (userStatus === 'INACTIVE') {
      displayNotification(
        'The Company status is presently Inactive, Please complete the verification Process or contact the admin.',
        'warning',
        2000
      );
    }
  };

  const renderUser = () => (
    <div className="user">
      <div className="photo">
        <img src={userAvatar} alt="img" />
      </div>

      <div className="info " style={{ cursor: 'pointer' }}>
        <a
          data-toggle="collapse"
          className="collapsed"
          onClick={() => toggleUserInfo(userInfo)}
        >
          <OverlayTrigger
            key=""
            placement="bottom"
            overlay={<Tooltip>{userNewName}</Tooltip>}
          >
            <span className="profile-name">
              {userNewName?.length < 20
                ? userNewName
                : userNewName?.substring(0, 20) + ' ...'}
              <b className="caret"></b>
            </span>
          </OverlayTrigger>
        </a>
        {userInfo && (
          <div className="profile-wrap">
            <ul className="nav">
              <li>
                <Link
                  to="/edashboard/admin/view"
                  className="profile-dropdown"
                  onClick={() => {
                    toggleWebSideMenu(false);
                    toggleMobileSideMenu(false);
                  }}
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-id-badge" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal sidebar-dropdown ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                    style={{ color: '#2d2822' }}
                  >
                    My Profile
                  </span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/edashboard/admin/edit"
                  className="profile-dropdown"
                  onClick={() => {
                    toggleWebSideMenu(false);
                    toggleMobileSideMenu(false);
                  }}
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-wrench" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal sidebar-dropdown ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                    style={{ color: '#2d2822' }}
                  >
                    Edit Profile
                  </span>
                </Link>
              </li> */}
              <li>
                <a className="profile-dropdown status-employer">
                  <span className="sidebar-mini">
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal  ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                    style={{ color: '#2d2822' }}
                  >
                    Status:{' '}
                    <span className="dash_profile_percent">{userStatus}</span>
                  </span>
                </a>
              </li>
              <li>
                <a className="profile-dropdown status-employer">
                  <span className="sidebar-mini">
                    <i className="fa fa-camera" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal  ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                    style={{ color: '#2d2822' }}
                  >
                    {verificationStatus === 'VERIFIED'
                      ? 'Profile Verified'
                      : 'Verification Pending'}
                  </span>
                </a>
              </li>
              <li>
                <a className="profile-dropdown status-employer">
                  <span className="sidebar-mini">
                    <i className="fa fa-flag-checkered" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal  ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                    style={{ color: '#2d2822' }}
                  >
                    <span className="dash_profile_percent">
                      {gConst.getRoleName(userRoleData?.companyCategory)}
                    </span>
                  </span>
                </a>
              </li>
              {/* {franchiseeCode && (
                <li>
                  <a className="profile-dropdown status-employer">
                    <span className="sidebar-mini">
                      <i className="fa fa-life-ring" aria-hidden="true"></i>
                    </span>
                    <span
                      className={`sidebar-normal  ${
                        !showSideMenu ? 'sidebar-normal-revised' : ''
                      }`}
                      style={{ color: '#2d2822' }}
                    >
                      Agent:{' '}
                      <span className="dash_profile_percent">
                        {franchiseeCode}
                      </span>
                    </span>
                  </a>
                </li>
              )} */}

              <li>
                <Link
                  className="profile-dropdown mb-0"
                  to="/edashboard/company/view"
                  onClick={() => {
                    toggleWebSideMenu(false);
                    toggleMobileSideMenu(false);
                  }}
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-industry" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal sidebar-dropdown ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                    style={{ color: '#2d2822' }}
                  >
                    Company Profile
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );

  const renderNav = () => (
    <ul className="nav employer-dashboard-navbar">
      <li className="nav-item mt-0">
        <Link
          to="/edashboard"
          className="nav-link"
          data-toggle="collapse"
          onClick={() => {
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
          }}
        >
          <i className="fa fa-line-chart" />
          <p>Dashboard</p>
        </Link>
      </li>

      <li className="nav-item">
        <Link
          to={
            userStatus === 'INACTIVE' ? '/edashboard' : '/edashboard/job-posts'
          }
          className="nav-link"
          data-toggle="collapse"
          onClick={() => {
            handleInactiveMessage();
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
          }}
        >
          <i className="fa fa-address-card-o" aria-hidden="true"></i>
          <p>Jobs Posted</p>
        </Link>
      </li>

      {userRoleData?.companyCategory === 'CORP' && (
        <li className="nav-item">
          {!verificationStatus ||
          verificationStatus === 'PENDING' ||
          verificationStatus === 'REJECTED' ? (
            <Button
              className="nav-link"
              style={{ background: 'none' }}
              onClick={() =>
                displayNotification(
                  'Please complete the verification process to continue using the portal',
                  'warning',
                  2000
                )
              }
            >
              <i className="fa fa-cogs" aria-hidden="true"></i>
              <p>Empanelments</p>
            </Button>
          ) : (
            <Link
              className="nav-link"
              data-toggle="collapse"
              to={
                userStatus === 'INACTIVE'
                  ? '/edashboard'
                  : '/edashboard/empanelment'
              }
              onClick={() => {
                handleInactiveMessage();
                toggleWebSideMenu(false);
                toggleMobileSideMenu(false);
              }}
            >
              <i className="fa fa-cogs" aria-hidden="true"></i>
              <p>Empanelments</p>
            </Link>
          )}
        </li>
      )}
      <li className="nav-item">
        <Link
          to={
            userStatus === 'INACTIVE'
              ? '/edashboard'
              : '/edashboard/commercials'
          }
          className="nav-link"
          data-toggle="collapse"
          onClick={() => {
            handleInactiveMessage();
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
          }}
        >
          <i className="fa fa-envelope-o" aria-hidden="true" />
          <p>Commercials</p>
        </Link>
      </li>
      {(userRoleData?.companyCategory === 'CORP' ||
        userRoleData?.companyCategory === null) && (
        <>
          <li className="nav-item">
            <Link
              className="nav-link"
              data-toggle="collapse"
              to={
                userStatus === 'INACTIVE'
                  ? '/edashboard'
                  : '/edashboard/manage-recruiters'
              }
              onClick={() => {
                handleInactiveMessage();
                toggleWebSideMenu(false);
                toggleMobileSideMenu(false);
              }}
            >
              <i className="fa fa-handshake-o"></i>
              <p>Manage Recruiters</p>
            </Link>
          </li>
        </>
      )}
      <li className="nav-item">
        <Link
          className="nav-link"
          data-toggle="collapse"
          to="/edashboard/notifications"
          onClick={() => {
            toggleWebSideMenu(false);
            toggleMobileSideMenu(false);
          }}
        >
          <i
            className="fa fa-bell"
            style={{ float: showSideMenu || showMobileMenu ? 'left' : 'none' }}
          />
          <p>Notifications</p>
        </Link>
      </li>
      {/*  {userRoles?.includes('Candidate') &&
      userRoles?.includes('Employer') &&
      userRoles?.includes('Recruiter') ? null : (
        <li className="nav-item">
          <Link
            className="nav-link"
            data-toggle="collapse"
            to="/add-new-role"
            onClick={() => {
              toggleWebSideMenu(false);
              toggleMobileSideMenu(false);
            }}
          >
            <i className="fa fa-plus-square-o" />
            <p className="toggle add-role-text">Add New Role</p>
          </Link>
        </li>
      )} */}

      <li className="nav-item">
        <button
          className="nav-link"
          style={{ background: 'none' }}
          onClick={() => {
            window.open(
              'https://dufther.atlassian.net/servicedesk/customer/portal/1',
              '_blank',
              'noopener,noreferrer'
            );
          }}
        >
          <i
            className="fa fa-wrench"
            style={{ float: showSideMenu || showMobileMenu ? 'left' : 'none' }}
          />
          <p>Report Issues</p>
        </button>
      </li>
    </ul>
  );

  const renderLogo = () => (
    <Fragment>
      <div className="logo">
        <Link className="simple-text logo-mini" to="/edashboard">
          <img src={faviconImage} alt="duftherLogo" />
        </Link>
        <Link className="simple-text logo-normal" to="/edashboard">
          <img src={duftherLogoName} alt="img" />
        </Link>
        <Link
          className="simple-text logo-mini mt-globe-sidebar padding-globe-top"
          to="/edashboard"
        >
          <i
            className="fa fa-globe"
            aria-hidden="true"
            style={{ color: '#11527e' }}
          ></i>
        </Link>
        <Link
          className="last-logged-date simple-text logo-normal padding-globe-top"
          to="/edashboard"
        >
          <span className="last-logged-label">Last Logged</span>
          <span> &nbsp;{userLastLogin}</span>
        </Link>
      </div>
    </Fragment>
  );

  return (
    <div
      className={`sidebar ${!showSideMenu ? 'sidebar-mini-slip' : ''} ${
        showMobileMenu ? 'revised-show-mob-menu' : 'revised-hide-mob-menu'
      }`}
      id="sidebar_dash"
    >
      <div
        className={`sidebar-wrapper ${
          !showSideMenu ? 'sidebar-mini-slip' : ''
        }`}
      >
        {userLastLogin && renderLogo()}
        {userRoleData && renderUser()}
        {renderNav()}
      </div>
    </div>
  );
};

EmployerDashboardSidebar.propTypes = {
  globalStore: PropTypes.object.isRequired,
};

// select data from redux store
const mapStateToProps = createStructuredSelector({
  globalStore: makeSelectGlobalStore(),
  employerDashboardStore: makeSelectEmployerDashboard(),
});

// dispatch actions
const mapDispatchToProps = (dispatch) => ({
  getAvatar: (id) => dispatch(eDashboardActions.getAvatar(id)),
});

// connection of redux form with state & action dispatcher
const NewSidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerDashboardSidebar);

export default compose(memo(NewSidebar));
