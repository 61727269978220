import React from 'react';
import { Link } from 'react-router-dom';

export const Breadcrumb = (props) => {
  const {
    params,
    parentLinkName,
    childLinkName,
    parentLinkAddress = '#',
    subChildLinkName = null,
    subChildLinkAddress = '#',
  } = props;

  if (params?.pathname === '/cdashboard') {
    return null;
  }
  if (params?.pathname === '/edashboard') {
    return null;
  }
  if (params?.pathname === '/rdashboard') {
    return null;
  }
  if (params?.pathname === '/fdashboard') {
    return null;
  }
  if (params?.pathname === '/idashboard') {
    return null;
  } else {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 pad-bradcrumb-right">
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb breadcrumb-revised">
                <li className="breadcrumb-item">
                  <Link to={parentLinkAddress}>{parentLinkName}</Link>
                </li>
                {subChildLinkName ? (
                  <li className="breadcrumb-item">
                    <Link to={subChildLinkAddress}>{subChildLinkName}</Link>
                  </li>
                ) : null}
                <li className="breadcrumb-item active" aria-current="page">
                  {childLinkName}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    );
  }
};
