/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Breadcrumb } from '../candidateDashboardLayout/Breadcrumb';
import GearLoader from '../../components/LoadingIndicator/GearLoading';
import AdminDashboardSidebar from './sidebar/adminDashboardSidebar';
import AdminDashboardHeader from '../common/dashboardHeader/dashboardHeader';
import CDTranslator from '../common/translator/FrontTranslator';
import AdminDashboardFooter from '../common/dashboardFooter/dashboardFooter';
import * as gConst from '../../utility/GlobalConstants';

import '../css/dashboard-layout.css';
import './admin-dashboard.css';

const FranchiseDashboardLayout = (props) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  const params = useLocation();

  const [webSideMenu, toggleWebSideMenu] = useState(false);
  const [mobileSideMenu, toggleMobileSideMenu] = useState(false);
  // const [mobileDropDownMenu, toggleMobileDropDownMenu] = useState(false);

  const renderTranslateBanner = () => (
    <div className="candidate-dashboard admin-dashboard">
      <GearLoader />
      <div className="container-fluid language-container">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-sm-8 col-md-8 col-lg-12 text-right">
            <ul className="list-unstyled">
              <li>
                <div id="ytWidget">
                  <CDTranslator />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  const showMenuHandler = () => {
    toggleWebSideMenu(!webSideMenu);
  };

  const showMobileMenuHandler = () => {
    toggleMobileSideMenu(!mobileSideMenu);
  };

  return (
    <div className={`${!webSideMenu ? 'sidebar-mini' : ''}`}>
      <div className="wrapper candidate-dashboard">
        <AdminDashboardSidebar
          showSideMenu={webSideMenu}
          showMobileMenu={mobileSideMenu}
          toggleMobileSideMenu={toggleMobileSideMenu}
          toggleWebSideMenu={toggleWebSideMenu}
        />
        <div className={`main-panel `}>
          <AdminDashboardHeader
            showSideMenu={webSideMenu}
            showMobileMenu={mobileSideMenu}
            showMenuHandler={showMenuHandler}
            showMobileMenuHandler={showMobileMenuHandler}
            isEmployer={true}
            currentRoute={'Admin'}
          />
          {renderTranslateBanner()}
          <div className="content">
            <Breadcrumb
              {...props}
              params={params}
              parentLinkAddress={`/dashboard-admin`}
              parentLinkName="Dashboard"
              childLinkName={gConst.pageNameByPath(params?.pathname)}
              childLinkAddress={params?.pathname}
            />
            {props.children}
          </div>
          <AdminDashboardFooter />
          <div
            className={`${mobileSideMenu ? 'close-layer visible' : ''}`}
            onClick={showMobileMenuHandler}
          ></div>
        </div>
      </div>
    </div>
  );
};

FranchiseDashboardLayout.propTypes = {
  children: PropTypes.any,
};

export default memo(FranchiseDashboardLayout);
