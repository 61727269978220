/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import history from '../../../utils/history';

import './predashboard-footer.css';

function PredashboardFooter() {
  const [activeAccordian, setActiveAccordian] = useState({
    part: 0,
    flag: true,
  });
  return history?.location?.pathname?.includes('/user-agreement') ? (
    <footer className="predashboard-footer">
      <p className="footer_copyright text-center">
        All Rights Reserved © {new Date().getFullYear()} Dufther.com
      </p>
    </footer>
  ) : (
    <>
      <footer className="predashboard-footer pt-3">
        <div className="web_view_footer">
          <div className="container">
            <div className="row pl_41">
              <div className="col-sm-12 col-md-12">
                <div className="row">
                  <div className="col-sm-4 col-md-4">
                    <div className="footer_header_menu">
                      <h5 className="text-uppercase">
                        <b className="footer_header">Know More</b>
                      </h5>
                      <ul className="nav">
                        <li>
                          <a href="https://dufther.com/about-us">About Us</a>
                        </li>
                        <li>
                          <Link to="/user-agreement/contact">Contact</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 text-center">
                    <div className="footer_header_menu">
                      <h5 className="text-uppercase">
                        <b className="footer_header">POLICIES</b>
                      </h5>
                      <ul className="nav text-center">
                        <li>
                          <Link to="/user-agreement/terms">Terms</Link>
                        </li>
                        <li>
                          <Link to="/user-agreement/cookie">Cookies</Link>
                        </li>
                        <li>
                          <Link to="/user-agreement/privacy">Privacy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 text-right">
                    <div className="footer_header_menu">
                      <h5 className="text-uppercase">
                        <b className="footer_header">Connect</b>
                      </h5>
                      <ul className="nav text-right">
                        <li>
                          <a
                            href=" https://www.facebook.com/Dufther-116505893077155"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Facebook
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/dufther"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Linked In
                          </a>
                        </li>
                        <li>
                          <a
                            href=" https://www.youtube.com/channel/UCkMtaYSgsNNlD5V2gpF5wQg/?guided_help_flow=5"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Youtube
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile_view_footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2">
                <div id="MainMenu">
                  <div className="list-group panel">
                    <a
                      href="#"
                      className="list-group-item list-group-item-success text-uppercase font-weight-bold"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveAccordian({
                          ...activeAccordian,
                          part: 1,
                          flag: activeAccordian.part == 1 ? false : true,
                        });
                      }}
                    >
                      Know More
                      <i className="fa fa-caret-down float-right footer_caret" />
                    </a>
                    <div
                      className={
                        (activeAccordian.part === 1 && activeAccordian.flag
                          ? 'show '
                          : '') + 'collapse list-group-item-footer'
                      }
                    >
                      <Link
                        to="/user-agreement/about"
                        className="list-group-item"
                      >
                        About Us
                      </Link>
                      <Link
                        to="/user-agreement/contact"
                        className="list-group-item"
                      >
                        Contact
                      </Link>
                    </div>{' '}
                    <a
                      href="#"
                      className="list-group-item list-group-item-success text-uppercase font-weight-bold"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveAccordian({
                          ...activeAccordian,
                          part: 2,
                          flag: activeAccordian.part == 2 ? false : true,
                        });
                      }}
                    >
                      Support
                      <i className="fa fa-caret-down float-right footer_caret" />
                    </a>
                    <div
                      className={
                        (activeAccordian.part === 2 && activeAccordian.flag
                          ? 'show '
                          : '') + 'collapse list-group-item-footer'
                      }
                    >
                      <Link
                        to="/user-agreement/terms"
                        className="list-group-item"
                      >
                        Terms
                      </Link>
                      <Link
                        to="/user-agreement/cookie"
                        className="list-group-item"
                      >
                        Cookies
                      </Link>
                      <Link
                        to="/user-agreement/privacy"
                        className="list-group-item"
                      >
                        Privacy
                      </Link>
                    </div>{' '}
                    <a
                      href="#"
                      className="list-group-item list-group-item-success text-uppercase font-weight-bold"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveAccordian({
                          ...activeAccordian,
                          part: 3,
                          flag: activeAccordian.part == 3 ? false : true,
                        });
                      }}
                    >
                      Connect
                      <i className="fa fa-caret-down float-right footer_caret" />
                    </a>
                    <div
                      className={
                        (activeAccordian.part === 3 && activeAccordian.flag
                          ? 'show '
                          : '') + 'collapse list-group-item-footer'
                      }
                    >
                      <a
                        href=" https://www.facebook.com/Dufther-116505893077155"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="list-group-item"
                      >
                        Facebook
                      </a>
                      <a
                        href="https://www.linkedin.com/company/dufther"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="list-group-item"
                      >
                        Linked In
                      </a>
                      <a
                        href=" https://www.youtube.com/channel/UCkMtaYSgsNNlD5V2gpF5wQg/?guided_help_flow=5"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="list-group-item"
                      >
                        Youtube
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="footer_copyright text-center">
          All Rights Reserved © {new Date().getFullYear()} Dufther.com
        </p>
      </footer>
    </>
  );
}

export default PredashboardFooter;
