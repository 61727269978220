/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { compose } from 'redux';
import makeSelectGlobalStore from 'containers/App/selectors';
import * as gService from 'utility/GlobalServices';
import userLogo from '../../../images/PROFILE_COMMON_HEADER.png';
import faviconImage from 'images/FAVICON.png';
import duftherLogoName from 'images/DUFTHER_NAME_LOGO.png';
import clsx from 'clsx';

import './institute-dashboard-sidebar.css';
import { displayNotification } from 'config/toastConfig';

let InstituteDashboardSidebar = (props) => {
  const {
    globalStore,
    showSideMenu,
    showMobileMenu,
    toggleMobileSideMenu,
    toggleWebSideMenu,
    setRedirectCallback,
  } = props;

  const userRoleData = pathOr('', ['userRoleData'], globalStore);
  const userLastLogin = pathOr('', ['userData', 'lastLogin'], globalStore);
  let userNewName = `${userRoleData?.coordinatorFirstName} ${userRoleData?.coordinatorLastName}`;
  // expand and collapse USer info
  const [userInfo, toggleUserInfo] = useState(true);

  const institutePicId = pathOr(null, ['logoDocument', 'id'], userRoleData);
  // fetch company profile pic
  const [institutePic, setInstitutePic] = useState(null);
  useEffect(() => {
    if (institutePicId) {
      gService.fetchImageById(institutePicId, setInstitutePic);
    } else {
      setInstitutePic(null);
    }
  }, [institutePicId]);

  const menuItems = [
    {
      title: 'Dashboard',
      icon: 'fa-line-chart',
      link: '/idashboard',
      redirect: true,
    },
    {
      title: 'Manage Students',
      icon: 'fa-users',
      link: '/idashboard/manage-students',
      redirect: userRoleData?.candidateConsentFormProvided,
      redirectAction: () => setRedirectCallback({ consentFormModal: true }),
    },
    {
      title: 'Job Central',
      icon: 'fa-suitcase',
      link: '/idashboard/job-central',
      redirect: true,
    },
    {
      title: 'View Batches',
      icon: 'fa-user-plus',
      link: '/idashboard/create-batch',
      redirect: userRoleData?.status !== 'INACTIVE',
    },
    {
      title: 'Manage Batch',
      icon: 'fa-id-card-o',
      link: '/idashboard/manage-batch',
      redirect: userRoleData?.status !== 'INACTIVE',
    },
    {
      title: 'Manage Application',
      icon: 'fa-handshake-o',
      link: '/idashboard/edit-application',
      redirect: true,
    },
    {
      title: 'Bulk Users',
      icon: 'fa-cloud-upload',
      link: '/idashboard/bulk-users',
      redirect:
        userRoleData?.candidateConsentFormProvided,
      redirectAction: () => setRedirectCallback({ consentFormModal: true }),
    },
    {
      title: 'Empanelments',
      icon: 'fa-university',
      link: '/idashboard/empanelment',
      redirect: userRoleData?.status !== 'INACTIVE',
    },
    {
      title: 'Programmes',
      icon: 'fa fa-map-signs',
      link: '/idashboard/programmes',
      redirect: true,
    },
  ];

  const renderUser = () => (
    <div className="user">
      <div className="photo">
        <img src={userLogo} alt="img" />
      </div>
      <div className="info " style={{ cursor: 'pointer' }}>
        <a
          data-toggle="collapse"
          className="collapsed"
          onClick={() => toggleUserInfo(userInfo)}
        >
          <span className="profile-name">
            Account Manager
            <b className="caret"></b>
          </span>
        </a>
        {userInfo && (
          <div className="profile-wrap">
            <ul className="nav">
              <li>
                <Link
                  to="/idashboard/profile-view"
                  onClick={() => {
                    toggleWebSideMenu(false);
                    toggleMobileSideMenu(false);
                  }}
                  className="profile-dropdown"
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-id-badge" aria-hidden="true"></i>
                  </span>
                  <OverlayTrigger
                    key=""
                    placement="bottom"
                    overlay={<Tooltip>{userNewName}</Tooltip>}
                  >
                    <span
                      className={`sidebar-normal ${
                        !showSideMenu ? 'sidebar-normal-revised' : ''
                      }`}
                      style={{ color: '#2d2822' }}
                    >
                      {userNewName?.length < 20
                        ? userNewName
                        : userNewName?.substring(0, 20) + ' ...'}
                    </span>
                  </OverlayTrigger>
                </Link>
              </li>
              <li>
                <Link
                  to="/idashboard/profile-view"
                  className="profile-dropdown"
                  onClick={() => {
                    toggleWebSideMenu(false);
                    toggleMobileSideMenu(false);
                  }}
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-university" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                    style={{ color: '#2d2822' }}
                  >
                    View Institute
                  </span>
                </Link>
              </li>
              {/**<li>
                <Link
                  to="/idashboard/manage-institute-users"
                  className="profile-dropdown"
                  onClick={() => {
                    toggleWebSideMenu(false);
                    toggleMobileSideMenu(false);
                  }}
                >
                  <span className="sidebar-mini">
                    <i className="fa fa-cogs" aria-hidden="true"></i>
                  </span>
                  <span
                    className={`sidebar-normal ${
                      !showSideMenu ? 'sidebar-normal-revised' : ''
                    }`}
                    style={{ color: '#2d2822' }}
                  >
                    Manage Sub-Users
                  </span>
                </Link>
              </li>**/}
            </ul>
          </div>
        )}
      </div>
    </div>
  );

  const renderNav = () => (
    <>
      <ul className="nav employer-dashboard-navbar">
        {menuItems.map((menuItem, index) => (
          <li className={clsx('nav-item', index === 0 && 'mt-0')} key={index}>
            <Link
              to={menuItem.redirect && menuItem.link}
              className="nav-link"
              onClick={() => {
                toggleWebSideMenu(false);
                toggleMobileSideMenu(false);
                !menuItem.redirect &&
                  (menuItem?.redirectAction
                    ? menuItem.redirectAction()
                    : displayNotification(
                        'Your Account has been disabled, please Contact the Admin for more details',
                        'warning'
                      ));
              }}
            >
              <i
                className={`fa ${menuItem.icon}`}
                style={{
                  float: showSideMenu || showMobileMenu ? 'left' : 'none',
                }}
              />
              <p>{menuItem.title}</p>
            </Link>
          </li>
        ))}
        <li className="nav-item">
          <button
            className="nav-link"
            style={{ background: 'none' }}
            onClick={() => {
              window.open(
                'https://dufther.atlassian.net/servicedesk/customer/portal/1',
                '_blank',
                'noopener,noreferrer'
              );
            }}
          >
            <i
              className="fa fa-wrench"
              style={{
                float: showSideMenu || showMobileMenu ? 'left' : 'none',
              }}
            />
            <p>Report Issues</p>
          </button>
        </li>
      </ul>
    </>
  );

  const renderLogo = () => (
    <Fragment>
      <div className="logo">
        <Link className="simple-text logo-mini" to="/idashboard">
          <img src={faviconImage} alt="duftherLogo" />
        </Link>
        <Link className="simple-text logo-normal" to="/idashboard">
          <img src={duftherLogoName} alt="img" />
        </Link>
        <Link
          className="simple-text logo-mini mt-globe-sidebar padding-globe-top"
          to="/idashboard"
        >
          <i
            className="fa fa-globe"
            aria-hidden="true"
            style={{ color: '#11527e' }}
          ></i>
        </Link>
        <Link
          className="last-logged-date simple-text logo-normal padding-globe-top"
          to="/idashboard"
        >
          <span className="last-logged-label">Last Logged</span>
          <span> &nbsp;{userLastLogin}</span>
        </Link>
      </div>
    </Fragment>
  );

  return (
    <div
      className={`sidebar ${!showSideMenu ? 'sidebar-mini-slip' : ''} ${
        showMobileMenu ? 'revised-show-mob-menu' : 'revised-hide-mob-menu'
      }`}
      id="sidebar_dash"
    >
      <div
        className={`sidebar-wrapper ${
          !showSideMenu ? 'sidebar-mini-slip' : ''
        }`}
      >
        {userLastLogin && renderLogo()}
        {userRoleData && renderUser()}
        {renderNav()}
      </div>
    </div>
  );
};

InstituteDashboardSidebar.propTypes = {
  showSideMenu: PropTypes.bool.isRequired,
  showMobileMenu: PropTypes.bool.isRequired,
  toggleMobileSideMenu: PropTypes.func.isRequired,
  toggleWebSideMenu: PropTypes.func.isRequired,
};
InstituteDashboardSidebar.defaultProps = {
  showSideMenu: false,
  showMobileMenu: false,
  toggleMobileSideMenu: () => {},
  toggleWebSideMenu: () => {},
};

const mapStateToProps = createStructuredSelector({
  globalStore: makeSelectGlobalStore(),
});

const NewSidebar = connect(mapStateToProps)(InstituteDashboardSidebar);

export default compose(memo(NewSidebar));
