import produce from 'immer';
import {
  LOAD_ANALYTICS_DATA,
  SET_ANALYTICS_DATA,
  SET_APPLICATION_DATA,
  SET_PAGE_NUMBER_TO_STORE,
  SET_PAGE_PAGE_ITEMS_TO_STORE,
  FETCH_APPLICATION_JOBID,
  SET_APPLICATIONS_TO_STORE,
  UPDATE_APPLICATION_STATUS,
  SET_JOB_STATUS_TO_STORE,
  LOAD_APPLICATION_DATA,
  GET_CANDIDATE_CONTACT,
  SET_CANDIDATE_CONTACT,
  SET_AVATAR,
  INITIALIZE_REC_STORE,
  SET_EMP_ACTION_WAY,
  SET_APPLICATION_STATUS,
  SET_STAGE_STATUS,
} from './constants';

export const initialState = {
  dashboard: 'recruiter',
  companyId: null,
  analyticsData: {},
  appStatusArr: [],
  jobListArr: [],
  applicationData: null,
  pageNumber: 0,
  perPage: 10,
  jobStatus: 'All',
  jobId: null,
  appStatusForm: null,
  candidateUserId: null,
  contactDetail: null,
  avatarUrl: '',
  profileImage: {},
  empAction: null,
  candidateId: null,
  applicationStatus: [],
  stageStatus: [],
};

/* eslint-disable default-case, no-param-reassign */
const recruiterDashboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_ANALYTICS_DATA:
        draft.companyId = action.companyId;
        break;
      case SET_ANALYTICS_DATA:
        draft.analyticsData.applicationCount = action.data.applicationCount;
        draft.analyticsData.candidateInvitationCount =
          action.data.candidateInvitationCount;
        draft.analyticsData.candidatePlanPendingViewCount =
          action.data.candidatePlanPendingViewCount;
        draft.analyticsData.candidatePlanType = action.data.candidatePlanType;
        draft.analyticsData.followerCount = action.data.followerCount;
        draft.analyticsData.jobCount = action.data.jobCount;
        draft.analyticsData.jobViewCount = action.data.jobViewCount;
        draft.analyticsData.recommendedCandidateCount =
          action.data.recommendedCandidateCount;
        draft.analyticsData.shortListedCandidateCount =
          action.data.shortListedCandidateCount;
        draft.profileImage = action.data.profileImage;
        break;
      case SET_APPLICATION_DATA:
        draft.appStatusArr = action.data.appStatusArr;
        draft.jobListArr = action.data.jobListArr;
        break;
      case SET_PAGE_NUMBER_TO_STORE:
        draft.pageNumber = action.pageNum;
        break;
      case SET_PAGE_PAGE_ITEMS_TO_STORE:
        draft.pageNumber = 0;
        draft.perPage = action.perPage;
        break;
      case FETCH_APPLICATION_JOBID:
        draft.jobId = action.jobId;
        break;
      case SET_APPLICATIONS_TO_STORE:
        draft.applicationData = action.data;
        break;
      case SET_JOB_STATUS_TO_STORE:
        draft.pageNumber = 0;
        draft.perPage = 0;
        draft.jobStatus = action.jobStatus;
        break;
      case UPDATE_APPLICATION_STATUS:
        draft.appStatusForm = action.data;
        break;
      case LOAD_APPLICATION_DATA:
        draft.companyId = action.id;
        break;
      case GET_CANDIDATE_CONTACT:
        draft.candidateUserId = action.candidateUserId;
        break;
      case SET_CANDIDATE_CONTACT:
        draft.contactDetail = action.data;
        break;
      case SET_AVATAR:
        draft.avatarUrl = action.data;
        break;
      case SET_EMP_ACTION_WAY:
        draft.empAction = action.actionWay;
        draft.candidateId = action.id;
        break;
      case INITIALIZE_REC_STORE:
        return state;
      case SET_APPLICATION_STATUS:
        draft.applicationStatus = action.data;
        break;
      case SET_STAGE_STATUS:
        draft.stageStatus = action.data;
        break;
    }
  });

export default recruiterDashboardReducer;
