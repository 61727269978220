/*
 *
 * CdChangeMobile reducer
 *
 */
import produce from 'immer';
// import { setMatchingJobs } from './actions';

import {
  SET_CONFIG_DATA,
  SET_SEARCH_RESULT,
  SET_PAYLOAD_DATA,
  SET_PAGE_NUMBER_TO_STORE,
  SET_NEAREST_LOCATION,
  RESET_MATCHING_JOB,
  SET_MATCHING_JOB,
  SET_CONSENT_MODAL,
} from './constants';

export const initialState = {
  configData: {
    postingDate: [],
    worktype: [],
    hired: [],
    empType: [],
    workingType: [],
    workDuration: [],
    experience: [],
    salaryMode: [],
    salRange: [],
    ageRange: [],
    gender: [],
    physicalStatus: [],
    associatedIndustries: [],
    rolesPerformed: [],
    skills: [],
    jobAllowance: [],
    timeToFillPost: [],
    distanceKms: [],
    companyNameList: [],
    domain: [],
    consentResponse: [],
    locationTypes: [],
  },
  searchPayload: {
    age: '',
    allowanceProvision: [],
    annualSalary: '',
    distanctInKm: 0,
    domain: '',
    durationOfWork: [],
    employerType: [],
    experience: [],
    gender: [],
    hiringType: [],
    keywords: [],
    industry: [],
    locations: [],
    geoLocations: [],
    page: 0,
    salaryMode: [],
    physicalStatus: [],
    postingDate: [],
    requirementUrgency: [],
    role: [],
    size: 10,
    skillSet: [],
    sortBy: '',
    subDomain: [],
    workFromHome: '',
    workType: [],
    workingTime: [],
    companyName: [],
    locationType: [],
  },
  searchResult: {},
  pageNumber: 0,
  matchJobFound: false,
  consentModalStatus: false,
};

const setConfigData = (draft, responseData) => {
  const {
    postingDate,
    worktype,
    hired,
    empType,
    workingType,
    workDuration,
    experience,
    salaryMode,
    salRange,
    ageRange,
    gender,
    physicalStatus,
    associatedIndustries,
    rolesPerformed,
    skills,
    jobAllowance,
    timeToFillPost,
    distanceKms,
    companyName,
    domain,
    consentResponse,
    locationTypes,
  } = responseData;

  draft.configData.postingDate = postingDate;
  draft.configData.worktype = worktype;
  draft.configData.hired = hired;
  draft.configData.empType = empType;
  draft.configData.workingType = workingType;
  draft.configData.workDuration = workDuration;
  draft.configData.experience = experience;
  draft.configData.salaryMode = salaryMode;
  draft.configData.salRange = salRange;
  draft.configData.ageRange = ageRange;
  draft.configData.gender = gender;
  draft.configData.physicalStatus = physicalStatus;
  draft.configData.associatedIndustries = associatedIndustries;
  draft.configData.rolesPerformed = rolesPerformed;
  draft.configData.skills = skills;
  draft.configData.jobAllowance = jobAllowance;
  draft.configData.timeToFillPost = timeToFillPost;
  draft.configData.distanceKms = distanceKms;
  draft.configData.companyNameList = companyName;
  draft.configData.domain = domain;
  draft.configData.consentResponse = consentResponse;
  draft.configData.locationTypes = locationTypes;
};

const setNearestCity = (draft, location) => {
  const data = new Set(draft.searchPayload.locations);
  data.add(location);
  draft.searchPayload.locations = [...data];
};

const setMatching = (draft, data) => {
  Object.keys(data).forEach((key) => {
    if (key in draft.searchPayload) {
      draft.searchPayload[key] = data[key];
    }
  });
  draft.matchJobFound = true;
};

/* eslint-disable default-case, no-param-reassign */
const basicAdvanceJobSearchReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CONFIG_DATA:
        const responseConfigData = action.data;
        setConfigData(draft, responseConfigData);
        break;
      case SET_SEARCH_RESULT:
        draft.searchResult = action.data;
        break;
      case SET_PAYLOAD_DATA:
        draft.searchPayload = action.data;
        break;
      case SET_PAGE_NUMBER_TO_STORE:
        draft.pageNumber = action.pageIndex;
        break;
      case SET_NEAREST_LOCATION:
        setNearestCity(draft, action.location);
        break;
      case SET_MATCHING_JOB:
        setMatching(draft, action.data);
        break;
      case RESET_MATCHING_JOB:
        draft.matchJobFound = false;
        break;
      case SET_CONSENT_MODAL:
        draft.consentModalStatus = action.status;
        break;
    }
  });

export default basicAdvanceJobSearchReducer;
