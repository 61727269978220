export const LOAD_ANALYTICS_DATA = 'app/RecruiterDashboard/LOAD_ANALYTICS_DATA';

export const SET_ANALYTICS_DATA = 'app/RecruiterDashboard/SET_ANALYTICS_DATA';

export const LOAD_APPLICATION_DATA =
  'app/RecruiterDashboard/LOAD_APPLICATION_DATA';

export const SET_APPLICATION_DATA =
  'app/RecruiterDashboard/SET_APPLICATION_DATA';

export const SET_PAGE_NUMBER_TO_STORE =
  'app/RecruiterDashboard/SET_PAGE_NUMBER_TO_STORE';

export const SET_PAGE_PAGE_ITEMS_TO_STORE =
  'app/RecruiterDashboard/SET_PAGE_PAGE_ITEMS_TO_STORE';

export const FETCH_APPLICATION_JOBID =
  'app/RecruiterDashboard/FETCH_APPLICATION_JOBID';

export const SET_APPLICATIONS_TO_STORE =
  'app/RecruiterDashboard/SET_APPLICATIONS_TO_STORE';

export const SET_JOB_STATUS_TO_STORE =
  'app/RecruiterDashboard/SET_JOB_STATUS_TO_STORE';

export const UPDATE_APPLICATION_STATUS =
  'app/RecruiterDashboard/UPDATE_APPLICATION_STATUS';

export const GET_CANDIDATE_CONTACT =
  'app/RecruiterDashboard/GET_CANDIDATE_CONTACT';

export const SET_CANDIDATE_CONTACT =
  'app/RecruiterDashboard/SET_CANDIDATE_CONTACT';

export const DOWNLOAD_RESUME = 'app/RecruiterDashboard/DOWNLOAD_RESUME';
export const GET_AVATAR = 'app/RecruiterDashboard/GET_AVATAR';

export const SET_AVATAR = 'app/RecruiterDashboard/SET_AVATAR';
export const INITIALIZE_REC_STORE =
  'app/RecruiterDashboard/INITIALIZE_REC_STORE';
export const SET_EMP_ACTION_WAY = 'app/RecruiterDashboard/SET_EMP_ACTION_WAY';
export const GET_APPLICATION_STATUS =
  'app/RecruiterDashboard/GET_APPLICATION_STATUS';
export const SET_APPLICATION_STATUS =
  'app/RecruiterDashboard/SET_APPLICATION_STATUS';
export const GET_STAGE_STATUS = 'app/RecruiterDashboard/GET_STAGE_STATUS';
export const SET_STAGE_STATUS = 'app/RecruiterDashboard/SET_STAGE_STATUS';
