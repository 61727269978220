import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import ScrollUpButton from 'react-scroll-up-button';
import GearLoader from '../../components/LoadingIndicator/GearLoading';
import PredashboardHeader from '../common/predashboardHeader/PredashboardHeader';
import PredashboardFooter from './footer/PredashboardFooter';
import FrontTranslator from '../common/translator/FrontTranslator';

import './predashboard-layout.css';

function PredashboardLayout(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });
  return (
    <div className="predashboard-page-wrapper">
      <GearLoader />
      <div className="predashboard-header-container">
        <PredashboardHeader />
        <FrontTranslator />
      </div>
      <div className="pre-dashboard-content">{props.children}</div>
      <PredashboardFooter />
      {/* <ScrollUpButton
        StopPosition={0}
        ShowAtPosition={250}
        EasingType="easeOutCubic"
        AnimationDuration={1000}
        ContainerClassName="scrollTopButtonContainer"
        TransitionClassName="scrollTopButtonTransition"
      >
        <button type="button" title="Go to top">
            Top
          </button>
      </ScrollUpButton> */}
    </div>
  );
}

PredashboardLayout.propTypes = {
  children: PropTypes.any,
};

export default PredashboardLayout;
