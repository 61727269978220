import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import SelectBox from 'components/common/Select';
import { convertSelectOpt, formatOptions } from 'utils/form';
import * as gService from 'utility/GlobalServices';
import { displayNotification } from 'config/toastConfig';

const VerifyProfile = (props) => {
  const {
    uploadVerificationDocs,
    companyId,
    verificationDocs: docs,
    deleteDoc,
    verificationStatus,
    changeVerificationStatus = () => {},
    globalStore,
  } = props;

  const verificationDocs = docs || globalStore?.verificationDocs || {};
  const { dashboardRole } = globalStore || {};

  const {
    companyDocumentType,
    companyDocument,
    personalDocument,
    personalDocumentType,
  } = verificationDocs || {};

  const { id, name, uploadDate } = companyDocument || {};
  const {
    id: personalId,
    name: personalName,
    uploadDate: personalDate,
  } = personalDocument || {};

  const [companyDocUrl, setCompanyDocUrl] = useState('');
  const [personalDocUrl, setPersonalDocUrl] = useState('');

  useMemo(() => {
    if (id) {
      gService.fetchDocument(id).then((path) => setCompanyDocUrl(path));
    }
    if (personalId) {
      gService
        .fetchDocument(personalId)
        .then((path) => setPersonalDocUrl(path));
    }
  }, [id, personalId]);

  const { register, control, errors, getValues } = useForm({
    mode: 'all',
  });

  const companyDocs = [
    'Company GSTIN',
    'Company PAN Card',
    'Shops and Establishment Act',
    'Food License',
    'Corporate Identity Number',
    'Other',
  ];
  const personalDocs = [
    'Visiting Card',
    'Personal PAN Card',
    'Employee ID Card',
    'Other',
  ];

  const handleFile = async (e, type) => {
    e.preventDefault();
    const maxfilesize = 1024 * 1024 * 2;
    const formData = new FormData();
    const file = e.target.files[0];
    const filesize = file ? file.size : 0;
    let checkfileName = '';
    if (filesize > maxfilesize) {
      // Handle case where file size exceeds 2 MB
      displayNotification(
        'File size exceeds 2 MB limit. Please choose a smaller file.',
        'warning'
      );
      return;
    }
    if (file) {
      const fileName = file.name;
      checkfileName =
        fileName.endsWith('.pdf') ||
        fileName.endsWith('.docx') ||
        fileName.endsWith('.doc') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.PNG') ||
        fileName.endsWith('.jpg') ||
        fileName.endsWith('.png') ||
        fileName.endsWith('.JPG') ||
        fileName.endsWith('.tif') ||
        fileName.endsWith('.tiff') ||
        fileName.endsWith('.bmp');
    }

    if (file && checkfileName) {
      formData.append('file', file, file.name);
      const docType = getValues(
        type === 'company' ? 'companyDocumentType' : 'personalDocumentType'
      ).value;
      uploadVerificationDocs(companyId, docType, formData, type);
    }
  };

  return (
    <div className="container franchise-associated">
      <div className="text-center">
        <h5 className="mt-2 mb-2">
          {verificationStatus === 'VERIFIED'
            ? 'Profile Verified'
            : 'Verification Pending'}
        </h5>
        <span className="text-grey font-size-12">
          Please upload your Company and Personal documents for verification
        </span>
        {dashboardRole === 'Admin' && (
          <p className="mt-3 text-center">
            {verificationStatus !== 'VERIFIED' ? (
              <>
                <button
                  className="btn btn-primary mr-2"
                  onClick={() =>
                    changeVerificationStatus(companyId, 'VERIFIED')
                  }
                >
                  Mark as Verified
                </button>
                <button
                  className="btn btn-warning"
                  onClick={() =>
                    changeVerificationStatus(companyId, 'REJECTED')
                  }
                >
                  Mark as Rejected
                </button>
              </>
            ) : (
              <button
                className="btn btn-warning"
                onClick={() => changeVerificationStatus(companyId, 'REJECTED')}
              >
                Mark as Rejected
              </button>
            )}
          </p>
        )}
      </div>
      <div className="card card-wizard pb-3 mt-3 border-box-applications">
        <div className="card-header ">
          <p className="mb-3 text-center">Company KYC</p>
          <h6>
            Company Documents{' '}
            <span
              className="card-title"
              style={{ fontSize: 13, textTransform: 'capitalize' }}
            >
              (At least 1 required)
            </span>
          </h6>
        </div>
        <form>
          <div className="card-body pb-0">
            <div className="row col-md-12">
              <div className="col-md-6" style={{ paddingLeft: 0 }}>
                <SelectBox
                  required
                  ref={register({
                    required: true,
                  })}
                  wrapperClass="mb-3 mt-1"
                  labelClass="font-size-13"
                  errors={errors}
                  control={control}
                  name="companyDocumentType"
                  rules={{ required: true }}
                  defaultValue={convertSelectOpt(companyDocumentType)}
                  options={formatOptions(companyDocs)}
                  disabled={companyDocument}
                />
              </div>
              <div className="col-md-6 text-right">
                {companyDocument ? (
                  <div className="form-group file-upload">
                    <div className="clearfix" />
                    <div
                      style={{ fontSize: '12px' }}
                      className="fa-icons-upload-file mb-2"
                    >
                      <a download={name} href={companyDocUrl}>
                        <u>{name}</u>
                        <i
                          className="fa fa-download pl-2"
                          aria-hidden="true"
                          title="Download file"
                        ></i>
                      </a>
                      {verificationStatus !== 'VERIFIED' && (
                        <span className="fa-icons-upload-file">
                          <a href="javascript:;">
                            <i
                              className="fa fa-trash ml-5"
                              aria-hidden="true"
                              title="Delete file"
                              onClick={() =>
                                deleteDoc(companyId, 'COMPANY_DOCUMENT')
                              }
                              style={{ fontSize: 18, color: 'red' }}
                            />
                          </a>
                        </span>
                      )}
                    </div>
                    <span className="font-italic file-upload-date">
                      Uploaded: {uploadDate}
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-info btn-ml-lg btn-md mt-1 mr-3 "
                    onClick={() => {
                      document.getElementById('fileInput').click();
                      return false;
                    }}
                    disabled={!getValues('companyDocumentType')}
                  >
                    Upload
                    <input
                      className="form-control"
                      type="file"
                      style={{ display: 'none' }}
                      id="fileInput"
                      name="companyDocument"
                      onChange={(e) => handleFile(e, 'company')}
                      accept=".pdf,.docx,.doc, image/png, image/jpg, image/x-png,image/jpeg,.tif,.tiff,.bmp"
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="card card-wizard pb-3 mt-3 border-box-applications">
        <div className="card-header ">
          <p className="mb-3 text-center">Company Representative</p>
          <h6>
            Admin ID Proof{' '}
            <span
              className="card-title"
              style={{ fontSize: 13, textTransform: 'capitalize' }}
            >
              (At least 1 required)
            </span>
          </h6>
        </div>
        <div className="card-body pb-0">
          <div className="row col-md-12">
            <div className="col-md-6" style={{ paddingLeft: 0 }}>
              <SelectBox
                required
                ref={register({
                  required: true,
                })}
                wrapperClass="mb-3 mt-1"
                labelClass="font-size-13"
                errors={errors}
                control={control}
                name="personalDocumentType"
                rules={{ required: true }}
                defaultValue={convertSelectOpt(personalDocumentType)}
                options={formatOptions(personalDocs)}
                disabled={personalDocument}
              />
            </div>
            <div className="col-md-6 text-right">
              {personalDocument ? (
                <div className="form-group file-upload">
                  <div className="clearfix" />
                  <div
                    style={{ fontSize: '12px' }}
                    className="fa-icons-upload-file mb-2"
                  >
                    <a download={personalName} href={personalDocUrl}>
                      <u>{personalName}</u>
                      <i
                        className="fa fa-download pl-2"
                        aria-hidden="true"
                        title="Download file"
                      ></i>
                    </a>
                    {verificationStatus !== 'VERIFIED' && (
                      <span className="fa-icons-upload-file">
                        <a href="javascript:;">
                          <i
                            className="fa fa-trash ml-5"
                            aria-hidden="true"
                            title="Delete file"
                            onClick={() =>
                              deleteDoc(companyId, 'PERSONAL_DOCUMENT')
                            }
                            style={{ fontSize: 18, color: 'red' }}
                          />
                        </a>
                      </span>
                    )}
                  </div>
                  <span className="font-italic file-upload-date">
                    Uploaded: {personalDate}
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn btn-info btn-ml-lg btn-md mt-1 mr-3 "
                  onClick={() => {
                    document.getElementById('personalDocument').click();
                    return false;
                  }}
                  disabled={!getValues('personalDocumentType')}
                >
                  Upload
                  <input
                    className="form-control"
                    type="file"
                    style={{ display: 'none' }}
                    id="personalDocument"
                    name="personalDocument"
                    onChange={(e) => handleFile(e, 'hr')}
                    accept=".pdf,.docx,.doc, image/png, image/jpg, image/x-png,image/jpeg,.tif,.tiff,.bmp"
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyProfile;
