import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the employer dashboard state domain
 */

const selectEmployerDashboardDomain = (state) =>
  state.employerDashboard || initialState;

const selectUserRoleCompanyId = (state) =>
  state?.globalStore?.userRoleData?.company?.companyId || null;

/**
 * Other specific selectors
 */

const makeSelectEmployerDashboard = () =>
  createSelector(selectEmployerDashboardDomain, (substate) => substate);

export default makeSelectEmployerDashboard;
export { selectEmployerDashboardDomain, selectUserRoleCompanyId };
