/*
 *
 * CandidateDashboard actions
 *
 */

import {
  LOAD_DASHBOARD_DATA,
  SET_DASHBOARD_DATA,
  SUBMIT_AVAIABILITY_FORM,
  AVAILABLE_IMMEDIATELY_FORM_RESPONSE,
  SUBMIT_NOTICE_PERIOD_FORM,
  NOTICE_PERIOD_FORM_RESPONSE,
  SUBMIT_ACTIVELY_SEARCHING_FORM,
  ACTIVELY_SEARCHING_RESPONSE,
  RESET_NOTICE_PERIOD,
  GET_CANDIDATE_IMG_URL,
  SET_CANDIDATE_IMG_URL,
  DELETE_CANDIDATE_AVATAR_SUCCESS,
  DOMAIN_DROPDOWN,
  SET_DOMAIN_DROPDOWN,
  GET_DOMAIN_BASED_SUBDATA,
  DOMAIN_BASED_SUBDATA,
  GET_SPECIALIZED_SUBDATA,
  SPECIALIZED_SUBDATA,
  SUBMIT_DOMAIN_FORM,
  SET_CONSENT_MODAL,
  APPROVE_CONSENT_FORM,
  VERIFY_CANDIDATE,
  SET_VERIFY_CANDIDATE,
  SET_ACADEMIC_EDUCATION_DATA,
} from './constants';

export function loadDashboardPageData() {
  return {
    type: LOAD_DASHBOARD_DATA,
  };
}
export function setDashboardData(data) {
  return {
    data,
    type: SET_DASHBOARD_DATA,
  };
}

export const submitAvaiabilityForm = (data) => ({
  data,
  type: SUBMIT_AVAIABILITY_FORM,
});

export const setAvailableResponse = (data) => ({
  data,
  type: AVAILABLE_IMMEDIATELY_FORM_RESPONSE,
});

export const submitNoticePeriodForm = (data) => ({
  data,
  type: SUBMIT_NOTICE_PERIOD_FORM,
});

export const noticePeriodFormResponse = (res) => ({
  res,
  type: NOTICE_PERIOD_FORM_RESPONSE,
});

export const submitActivelySearchingForm = (data) => ({
  data,
  type: SUBMIT_ACTIVELY_SEARCHING_FORM,
});
export const activelySearchingFormResponse = (res) => ({
  res,
  type: ACTIVELY_SEARCHING_RESPONSE,
});

export const resetNoticePeriod = () => ({
  type: RESET_NOTICE_PERIOD,
});

export const getCandidateAvatar = (id) => ({
  id,
  type: GET_CANDIDATE_IMG_URL,
});

export const setCandidateAvatar = (data) => ({
  data,
  type: SET_CANDIDATE_IMG_URL,
});

export const deleteCandidateAvatarSuccess = (data) => ({
  data,
  type: DELETE_CANDIDATE_AVATAR_SUCCESS,
});

export function loadDomainDropdown() {
  return {
    type: DOMAIN_DROPDOWN,
  };
}

export function setDomainDropdown(data) {
  return {
    data,
    type: SET_DOMAIN_DROPDOWN,
  };
}

export function getDomainBasedSubdataAction(data) {
  return {
    data,
    type: GET_DOMAIN_BASED_SUBDATA,
  };
}

export function domainBasedSubdataResult(data) {
  return {
    data,
    type: DOMAIN_BASED_SUBDATA,
  };
}

export function getSpecializedSubdataAction(data) {
  return {
    data,
    type: GET_SPECIALIZED_SUBDATA,
  };
}

export function specializedSubdataResult(data) {
  return {
    data,
    type: SPECIALIZED_SUBDATA,
  };
}

export function submitDomainFormAction(data) {
  return {
    data,
    type: SUBMIT_DOMAIN_FORM,
  };
}

export function setConsentModalStatus(status) {
  return {
    status,
    type: SET_CONSENT_MODAL,
  };
}

export function approveConsentForm(instituteId, consentStatus) {
  return {
    instituteId,
    consentStatus,
    type: APPROVE_CONSENT_FORM,
  };
}

export function verifyCandidate(userId) {
  return {
    userId,
    type: VERIFY_CANDIDATE,
  };
}

export function setVerifyCandidate(data) {
  return {
    data,
    type: SET_VERIFY_CANDIDATE,
  };
}

export function setAcademicEducation(data) {
  return {
    data,
    type: SET_ACADEMIC_EDUCATION_DATA,
  };
}