// Import all the third party stuff
import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from './utils/history';
import 'sanitize.css/sanitize.css';

// Import root app
import App from './containers/App';

// Import Language Provider
import LanguageProvider from './containers/LanguageProvider';
import configureStore from './configureStore';

// import ReactGA
import ReactGA from "react-ga4";
// Import i18n messages
// import { translationMessages } from './i18n';

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);


ReactGA.initialize("G-K0QJK57QJJ");
ReactGA.send({ hitType: "pageview", page: window.location.pathname })
const AppIndex = (messages) => {

  return (
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>
  );
};

export default AppIndex;
