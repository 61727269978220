/* eslint-disable no-restricted-globals */
export const selectRequired = (value) => {
  if ((value || typeof value === 'number') && value !== 'Select') {
    return undefined;
  }
  return 'Select a value';
};

export const multiSelectRequired = (value) => {
  if (
    (value || typeof value === 'number') &&
    value !== 'Select' &&
    value.length > 0
  ) {
    return undefined;
  }
  return 'Select a value';
};

export const required = (value) =>
  value || typeof value === 'number' ? undefined : 'This field is mandatory';

export const validMobile = (value) =>
  value && !/^[6-9][0-9]{9}$/i.test(value) ? 'Not a valid mobile' : undefined;

export const charsOnly = (value) =>
  value && !/^[a-zA-Z]*$/.test(value) ? 'Only characters allowed' : undefined;

export const charsSpaceOnly = (value) =>
  value && !/^[a-zA-Z ]+$/i.test(value)
    ? 'Only charcters & space allowed'
    : undefined;

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} digits or less` : undefined;

export const maxLength15 = maxLength(15);

export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} digits or more` : undefined;

export const minLength10 = minLength(10);

export const isNumber = (value) =>
  value && isNaN(Number(value)) ? 'Must be numbers only' : undefined;

export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const minValue13 = minValue(13);

export const maxValue = (max) => (value) =>
  value && value > max ? `Cannot be more than ${max}` : undefined;

export const maxValue2020 = maxValue(2020);

export const phoneNumber = (value) =>
  value && !/^(0|[0-9][0-9]{9})$/i.test(value)
    ? 'Please enter your 10 digit mobile number'
    : undefined;

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const OTPNumber = (value) =>
  value && !/^(0|[0-9][0-9]{5})$/i.test(value)
    ? 'Please enter the 6 digit OTP'
    : undefined;

export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined;

// to allow only characters in input field
export const normalizeCharacters = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyChars = value.replace(/[^A-Za-z ]/gi, '');
  if (!previousValue || value.length > previousValue.length) {
    if (onlyChars.length === 2) {
      return onlyChars;
    }
  }
  if (onlyChars.length <= 2) {
    return onlyChars;
  }
  return onlyChars;
};

export const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums;
    }
    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + onlyNums.slice(3);
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + onlyNums.slice(3);
  }
  return onlyNums.slice(0, 3) + onlyNums.slice(3, 6) + onlyNums.slice(6, 10);
};
export default normalizePhone;

export const normalizeNumber = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length === 2) {
      return onlyNums;
    }
    if (onlyNums.length === 4) {
      return onlyNums.slice(0, 2) + onlyNums.slice(2);
    }
  }
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return onlyNums.slice(0, 2) + onlyNums.slice(2);
  }
  return (
    onlyNums.slice(0, 1) +
    onlyNums.slice(1, 2) +
    onlyNums.slice(2, 3) +
    onlyNums.slice(3, 4) +
    onlyNums.slice(4, 5) +
    onlyNums.slice(5, 6) +
    onlyNums.slice(6, 7) +
    onlyNums.slice(7, 8)
  );
};

export const normalizeOtp = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length === 2) {
      return onlyNums;
    }
    if (onlyNums.length === 4) {
      return onlyNums.slice(0, 2) + onlyNums.slice(2);
    }
  }
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return onlyNums.slice(0, 2) + onlyNums.slice(2);
  }
  return (
    onlyNums.slice(0, 1) +
    onlyNums.slice(1, 2) +
    onlyNums.slice(2, 3) +
    onlyNums.slice(3, 4) +
    onlyNums.slice(4, 5) +
    onlyNums.slice(5, 6)
  );
};

export const normalizeWage = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length === 2) {
      return onlyNums;
    }
    if (onlyNums.length === 4) {
      return onlyNums.slice(0, 2) + onlyNums.slice(2);
    }
  }
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return onlyNums.slice(0, 2) + onlyNums.slice(2);
  }
  return (
    onlyNums.slice(0, 1) +
    onlyNums.slice(1, 2) +
    onlyNums.slice(2, 3) +
    onlyNums.slice(3, 4)
  );
};

export const normalizeDisability = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length === 2) {
      return onlyNums;
    }
  }
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  return onlyNums.slice(0, 1) + onlyNums.slice(1, 2);
};

export const minMaxValid = (value, prop) => {
  const { minimumSalary, maximumSalary } = prop;
  const newMinSalary = Number(minimumSalary);
  const newMaxSalary = Number(maximumSalary);
  if (newMaxSalary < newMinSalary) {
    return 'Maximum salary cannot be less than minimum salary';
  }
  return undefined;
};

export const allowOnlyNums = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
  }
  return true;
};

export const allowOnlyNumsAndDecimal = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
  }
  return true;
};

export const allowOnlyAlphanumeric = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if ((charCode < 65 || charCode > 122) && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
  }
  return true;
};
