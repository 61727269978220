import produce from 'immer';
import {
  SET_EMP_PRICING_DATA,
  SET_CONFIG_DATA,
  SET_PRICING_SHEET_DATA,
  SET_COMPANY_DETAILS,
  SET_PAYMENT_SUCCESS,
  SET_ORDER_ID_RESPONSE,
  SET_SUMMARY_DATA,
  RESET_ORDER_RESPONSE,
  RESET_STORE,
} from './constants';

export const initialState = {
  configData: {
    pricingPlan: [],
  },
  empPricingModelData: {},
  empPricingSheetData: [],
  billingData: {
    companyId: '',
    companyName: '',
    companyAddress: '',
    employerName: '',
    pinCode: '',
    gstNumber: '',
  },
  paymentSuccess: '',
  orderIdResponseData: {},
  RecieptData: {},
};

// const setPricingSheetData = (draft, data) => {
//   data.forEach((item, index) => {
//     draft.empPricingSheetData[index] = {
//       ...item,
//     };
//   });
// };

/* eslint-disable default-case, no-param-reassign */
const empPricingReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_EMP_PRICING_DATA:
        draft.empPricingModelData = action.data;
        break;
      case SET_CONFIG_DATA:
        draft.configData.pricingPlan = action.data;
        break;
      case SET_PRICING_SHEET_DATA:
        // setPricingSheetData(draft, action.data);
        draft.empPricingSheetData = action.data;
        break;
      case SET_COMPANY_DETAILS:
        draft.billingData = action.data;
        break;
      case SET_PAYMENT_SUCCESS:
        draft.paymentSuccess = action.data;
        break;
      case SET_ORDER_ID_RESPONSE:
        draft.orderIdResponseData = action.data;
        break;
      case RESET_ORDER_RESPONSE:
        draft.orderIdResponseData = {};
        break;
      case SET_SUMMARY_DATA:
        draft.RecieptData = action.data;
        break;
      case RESET_STORE:
        return initialState;
    }
  });

export default empPricingReducer;
