export const LOAD_EMP_PRICING_DATA =
  'App/EmployerPricingPlan/LOAD_EMP_PRICING_DATA';

export const SET_EMP_PRICING_DATA =
  'App/EmployerPricingPlan/SET_EMP_PRICING_DATA';

export const GET_CONFIG_DATA = 'App/EmployerPricingPlan/GET_CONFIG_DATA';

export const SET_CONFIG_DATA = 'App/EmployerPricingPlan/SET_CONFIG_DATA';

export const GET_PRICING_SHEET_DATA =
  'App/EmployerPricingPlan/GET_PRICING_SHEET_DATA';

export const SET_PRICING_SHEET_DATA =
  'App/EmployerPricingPlan/SET_PRICING_SHEET_DATA';

export const GET_RAZOR_PAY_CALL = 'App/EmployerPricingPlan/GET_RAZOR_PAY_CALL';

export const GET_ORDER_ID = 'App/EmployerPricingPlan/GET_ORDER_ID';

export const GET_COMPANY_DETAILS =
  'App/EmployerPricingPlan/GET_COMPANY_DETAILS';

export const SET_COMPANY_DETAILS =
  'App/EmployerPricingPlan/SET_COMPANY_DETAILS';

export const SET_PAYMENT_SUCCESS =
  'App/EmployerPricingPlan/SET_PAYMENT_SUCCESS';

export const GET_PAYMENT_SUCCESS =
  'App/EmployerPricingPlan/GET_PAYMENT_SUCCESS';

export const SET_ORDER_ID_RESPONSE =
  'App/EmployerPricingPlan/SET_ORDER_ID_RESPONSE';

export const SET_SUMMARY_DATA = 'App/EmployerPricingPlan/SET_SUMMARY_DATA';

export const SAVE_COMPANY_DETAILS =
  'App/EmployerPricingPlan/SAVE_COMPANY_DETAILS';

export const RESET_ORDER_RESPONSE =
  'App/EmployerPricingPlan/RESET_ORDER_RESPONSE';

export const RESET_STORE = 'App/EmployerPricingPlan/RESET_STORE';
