import { takeLatest, put, select, call, all } from 'redux-saga/effects';
import { pathOr } from 'ramda';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import * as apiName from '../../config/apiUrl';
import * as gService from '../../utility/GlobalServices';
import * as gActions from '../App/actions';
import { displayNotification } from '../../config/toastConfig';

// ***** LOAD the config Data ***** //
export function* loadConfigDataSaga() {
  const allURLs = [
    apiName.postingDateURL,
    apiName.worktypeURL,
    apiName.hiredURL,
    apiName.empTypeURL,
    apiName.timingURL,
    apiName.workDurationURL,
    apiName.experienceURL,
    apiName.salaryModeURL,
    apiName.salRangeURL,
    apiName.ageRangeURL,
    apiName.genderURL,
    apiName.physicalStatusURL,
    apiName.associatedIndustriesURL,
    apiName.rolesPerformedURL,
    apiName.skillsURL,
    apiName.jobAllowanceURL,
    apiName.timeToFillPostURL,
    apiName.distanceKmsURL,
    apiName.companyNameURL,
    apiName.domainURL,
    apiName.getCandidateInstituteConsentStatusURL,
    apiName.locationTypeURL,
  ];
  yield put(gActions.showLoader());

  try {
    const [
      postingDate,
      worktype,
      hired,
      empType,
      workingType,
      workDuration,
      experience,
      salaryMode,
      salRange,
      ageRange,
      gender,
      physicalStatus,
      associatedIndustries,
      rolesPerformed,
      skills,
      jobAllowance,
      timeToFillPost,
      distanceKms,
      companyName,
      domain,
      consentResponse,
      locationTypes,
    ] = yield all(allURLs.map((url) => gService.callApi(url)));

    yield put(
      actions.setConfigData({
        postingDate,
        worktype,
        hired,
        empType,
        workingType,
        workDuration,
        experience,
        salaryMode,
        salRange,
        ageRange,
        gender,
        physicalStatus,
        associatedIndustries,
        rolesPerformed,
        skills,
        jobAllowance,
        timeToFillPost,
        distanceKms,
        companyName,
        domain,
        consentResponse,
        locationTypes,
      })
    );
  } catch (error) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

const parsePayLoad = (payload) => {
  let result = {};

  Object.keys(payload).forEach((key, index) => {
    const check =
      payload[key] != '' ||
      (Array.isArray(payload[key]) && payload[key].length) ||
      typeof payload[key] === 'boolean';
    if (check) {
      result = { ...result, [key]: payload[key] };
    }
  });

  return result;
};

// ***** SEARCH JOB DATA ***** //
export function* searchJobSaga({ payload, history }) {
  yield put(gActions.showLoader());
  if (payload?.workFromHome === false) {
    payload['workFromHome'] = '';
  }
  yield put(actions.setPayloadData(payload));
  const locations = yield gService.getGeocodeByPlaceId(payload.geoLocations);
  try {
    const serchResult = yield gService.callApi(
      apiName.searchApiArr[2],
      parsePayLoad({
        ...payload,
        geoLocations: locations,
      }),
      'POST'
    );

    const isIssue = pathOr(200, ['status'], serchResult);
    if (isIssue == 400) {
      displayNotification(
        'Please enter atleast one search criteria for Location or Keyword',
        'warning'
      );
      yield put(actions.setSearchResult({}));
    } else {
      yield put(actions.setSearchResult(serchResult));

      if (history) {
        yield call(
          history.push('/cdashboard/candidate-basic-advance-job-search')
        );
      }
    }
  } catch (err) {
    // displayNotification('Something went wrong! try again.', 'danger');
    yield put(gActions.hideLoader());
  }

  yield put(gActions.hideLoader());
}

// ***** SET NOTIFICATION ON SAGA ***** //
export function* setNotificationONSaga({ data }) {
  const { id, searchPayload } = data;
  yield put(gActions.showLoader());
  try {
    yield gService.callApi(`${apiName.turnOnNotifyURL}/${id}`, null, 'POST');
    yield put(actions.searchJob(searchPayload));
    displayNotification('Notification is turned ON');
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** SET NOTIFICATION OFF SAGA ***** //
export function* setNotificationOFFSaga({ data }) {
  const { id, searchPayload } = data;
  yield put(gActions.showLoader());
  try {
    yield gService.callApi(`${apiName.turnOffNotifyURL}/${id}`, null, 'POST');
    yield put(actions.searchJob(searchPayload));
    displayNotification('Notification is turned OFF');
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** SAVE JOB SAGA ***** //
export function* saveJobSaga({ data }) {
  const { id, searchPayload } = data;
  yield put(gActions.showLoader());
  try {
    yield gService.callApi(`${apiName.saveJobPostURL}/${id}`, null, 'POST');
    yield put(actions.searchJob(searchPayload));
    displayNotification('Job has been saved for future reference');
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** DELETE JOB SAGA ***** //
export function* deleteJobSaga({ data }) {
  const { id, searchPayload } = data;
  yield put(gActions.showLoader());
  try {
    yield gService.callApi(`${apiName.deleteJobPostURL}/${id}`, null, 'DELETE');
    yield put(actions.searchJob(searchPayload));
    displayNotification(
      'Job has been removed from the Shortlisted Jobs Listing'
    );
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** APPLY JOB SAGA ***** //
export function* applyJobSaga({ id, mode }) {
  const { searchPayload } = yield select(
    selectors.selectCandidateBasicAdvanceJobSearchDomain
  );

  yield put(gActions.showLoader());

  try {
    if (mode === 'single') {
      yield gService.callApi(
        `${apiName.applyJobURL}?jobId=${id}`,
        null,
        'POST'
      );
    } else if (mode === 'multi') {
      yield gService.callApi(
        `${apiName.applyMultiURL}`,
        { jobIds: id },
        'POST'
      );
    }
    yield put(actions.searchJob(searchPayload));
    displayNotification(
      'Your Application for the Job(s) has been sent to the Employer'
    );
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** GET NEAREST CITY SAGA ***** //
export function* getNearestCitySaga({ distance = 10, latitude, longitude }) {
  yield put(gActions.showLoader());

  try {
    const response = yield gService.callApi(
      `${apiName.nearestCity}?distanctInKm=${distance}&latitude=${latitude}&longitude=${longitude}`
    );
    yield put(actions.setNearestLocation(response));
    displayNotification('Cities based on your location');
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** GET MATCHING JOBS SAGA ***** //
export function* getMatchingJobsSaga() {
  yield put(gActions.showLoader());
  try {
    const response = yield gService.callApi(apiName.getMatchingJobsURL);
    yield put(actions.setMatchingJobs(response));
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// ***** GET MATCHING JOBS SAGA ***** //
export function* approveConsentFormSaga({ instituteId, consentStatus }) {
  yield put(gActions.showLoader());
  try {
    yield gService.callApi(
      `${apiName.getCandidateInstituteBaseURL}/${instituteId}/consent/${consentStatus}`,
      null,
      'PUT'
    );
    yield put(actions.setConsentModalStatus(false));
  } catch (err) {
    displayNotification('Something went wrong! try again.', 'danger');
  }
  yield put(gActions.hideLoader());
}

// Individual exports for testing
export default function* basicAdvanceJobSearchSaga() {
  yield takeLatest(constants.GET_CONFIG_DATA, loadConfigDataSaga);
  yield takeLatest(constants.SEARCH_JOB, searchJobSaga);
  yield takeLatest(constants.SET_NOTIFICATION_ON, setNotificationONSaga);
  yield takeLatest(constants.SET_NOTIFICATION_OFF, setNotificationOFFSaga);
  yield takeLatest(constants.SAVE_JOB, saveJobSaga);
  yield takeLatest(constants.DELETE_JOB, deleteJobSaga);
  yield takeLatest(constants.APPLY_JOB, applyJobSaga);
  yield takeLatest(constants.GET_NEAREST_LOCATION, getNearestCitySaga);
  yield takeLatest(constants.GET_MATCHING_JOB, getMatchingJobsSaga);
  yield takeLatest(constants.APPROVE_CONSENT_FORM, approveConsentFormSaga);
}
