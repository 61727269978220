/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

//  to initiate global store data
export const SET_USER_NAME = 'SET_USER_NAME';
export const LOAD_GLOBAL_STORE_DATA = 'LOAD_GLOBAL_STORE_DATA';
export const SET_GLOBAL_STORE_DATA = 'SET_GLOBAL_STORE_DATA';
//  to generate otp on mobile & send response of generation
export const RESEND_OTP = 'RESEND_OTP';
export const RESEND_OTP_AFTER_LOGIN = 'RESEND_OTP_AFTER_LOGIN';
export const OTP_GENERATED_RESPONSE = 'OTP_RESPONSE';
// contants to submit sidebar available immediately checkbox value

// constants to submit sidebar actively searching checkbox value

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const SET_DASHBOARD_ROLE = 'SET_DASHBOARD_ROLE';
export const INITIALIZE_WEB_SOCKETS_CHANNEL = 'INITIALIZE_WEB_SOCKETS_CHANNEL';
export const SET_NOTIFICATION_MSG = 'SET_NOTIFICATION_MSG';
export const SELECT_RECRUITER_COMPANY = 'SELECT_RECRUITER_COMPANY';
export const SET_DASHBOARD_ROLE_USER_DATA = 'SET_DASHBOARD_ROLE_USER_DATA';
export const LOAD_DISTRICTS = 'LOAD_DISTRICTS';
export const SET_INDIA_DISTRICTS = 'SET_INDIA_DISTRICTS';
export const ADD_EMAIL_MODAL_STATUS = 'ADD_EMAIL_MODAL_STATUS';
export const SUBMIT_EMAIL_FOR_OTP = 'SUBMIT_EMAIL_FOR_OTP';
export const EMAIL_OTP_RESPONSE = 'EMAIL_OTP_RESPONSE';
export const SUBMIT_EMAIL_CHANGE = 'SUBMIT_EMAIL_CHANGE';
export const ADD_MOBILE_MODAL_STATUS = 'ADD_MOBILE_MODAL_STATUS';
export const SUBMIT_MOBILE_FOR_OTP = 'SUBMIT_MOBILE_FOR_OTP';
export const MOBILE_OTP_RESPONSE = 'MOBILE_OTP_RESPONSE';
export const SUBMIT_MOBILE_CHANGE = 'SUBMIT_MOBILE_CHANGE';
export const RESET_OTP_FIELD = 'RESET_OTP_FIELD';
export const SET_INSTITUTE_EDU_SPEC_CATEGORY =
  'SET_INSTITUTE_EDU_SPEC_CATEGORY';
export const LOAD_EMP_PRICING_DATA = 'LOAD_EMP_PRICING_DATA';
export const SET_EMP_PRICING_DATA = 'SET_EMP_PRICING_DATA';
export const GET_VERIFICATION_DOCS = 'GET_VERIFICATION_DOCS';
export const SET_VERIFICATION_DOCS = 'SET_VERIFICATION_DOCS';
export const UPLOAD_VERIFICATION_DOCS = 'UPLOAD_VERIFICATION_DOCS';
export const DELETE_VERIFICATION_DOCS = 'DELETE_VERIFICATION_DOCS';
export const SET_REDIRECT_CALLBACK = 'SET_REDIRECT_CALLBACK';
export const GET_PHYSICAL_STATUS = 'GET_PHYSICAL_STATUS';
export const SET_PHYSICAL_STATUS = 'SET_PHYSICAL_STATUS';
