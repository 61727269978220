import classNames from 'classnames';
import React from 'react';

import Error from './Error';

const RestyleRadioButton = React.forwardRef(
  (
    {
      name,
      label,
      options,
      errors,
      required,
      wrapperClass,
      defaultValue,
      changeValue,
      setValue,
      noLabel = false,
      disabled,
      labelClassName,
      optionClassName,
      showValue,
      ...props
    },
    ref
  ) => {
    const changeHandler = (opt) => {
      changeValue(
        defaultValue === opt.value
          ? defaultValue
          : showValue
          ? opt.value
          : !defaultValue
      );
      setValue &&
        setValue(
          name,
          defaultValue === opt.value ? defaultValue : !defaultValue,
          { shouldDirty: true }
        );
    };

    return (
      <div className={`form-group ${wrapperClass || ''}`}>
        {noLabel ? null : (
          <>
            <label
              htmlFor="travel_international_project"
              className={classNames(
                'lbl_info radiobtn_mb_0 mt-1 mb-0',
                labelClassName
              )}
            >
              {label}
              {required ? <span className="star_symbol">*</span> : null}
            </label>
            <div className="clearfix" />
          </>
        )}
        {options &&
          options.map((opt, index) => {
            return (
              <label
                key={opt.value}
                htmlFor={`${name}-${opt.value}`}
                className={classNames(
                  'radio-inline lbl_radio_info mr-3',
                  optionClassName
                )}
                style={{ fontSize: '12px', color: '#9a9a9a' }}
                onClick={() => !disabled && changeHandler(opt)}
              >
                <input
                  type="radio"
                  name={name}
                  id={index}
                  ref={ref}
                  value={defaultValue}
                  checked={defaultValue === opt.value}
                  defaultChecked={defaultValue === opt.value}
                  onChange={() => changeHandler(opt)}
                  disabled={disabled}
                />
                {opt.label}
              </label>
            );
          })}
        {errors[name] && <Error {...errors[name]} />}
        {props.extraChildren}
      </div>
    );
  }
);

export default RestyleRadioButton;
