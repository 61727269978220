/**
 *
 * Asynchronously loads the component for Login
 *
 */

import React from 'react';
import loadable from '../../utils/loadable';
import LoadingIndicator from '../../components/LoadingIndicator';

export default loadable(() => import('./index'), {
  fallback: (
    <div className="container-login">
      <div className="wrap-login_new_use">
        <LoadingIndicator />
      </div>
    </div>
  ),
});
